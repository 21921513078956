import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Sidebar from "../../components/sidebar/Sidebar";
import "./InvoicesPayments.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { HiCreditCard } from "react-icons/hi";
import Card from "../../assets/images/card.png";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Remove from "../../assets/images/Remove.svg";
import Pdf from "../../assets/images/PdfFile.svg";
import Pagination from "react-bootstrap/Pagination";
import { API_BASE_URL, INVOICE_URL } from "../../app_url";

import { invoiceList, downloadInvoicePdf, deleteInvoice, getAllReadOverviewList, getPageContent } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import PayPalComponent from "../PaymentPages/PayPalComponent";
import PayPalPayment from "../PaymentPages/PayPalPayment";
import Conditions from "../accept-condition/Conditions";
import { useLangContext } from "../../context/languageContext";

const InvoicesPayments = () => {
  const [show, setShow] = useState(false);
  const [delshow, deletShow] = useState(false);
  const [invList,setInvList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages,setTotalPages] = useState();
  const [pageButtonsToShow,setPageButtonShow] = useState();
  const [recordId,setRecordId] = useState();
  const [ReadOverviewList, setReadOverviewList] = useState([]);
  const [payableAmount,setPayableAmount] = useState();
  const [amount,setAmount] = useState();
  const [checkBoxValue1,setCheckBoxValue1] = useState();
  const [checkBoxValue2,setCheckBoxValue2] = useState();
  const {hostLanguage,languageJson} = useLangContext();
  const [contentDetails,setContentDetails] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);


  // invoice list
  const fetchInvoiceList = async(id) => {
    const apiRes = await invoiceList(id,hostLanguage);
    const responseData = await apiRes.json();

    if(responseData.res)
    {
      setInvList(responseData.data.data);
      setPageButtonShow(responseData.data.total);
      const totalPages = Math.ceil(Number(responseData.data.total) / Number(responseData.data.per_page));
      setTotalPages(totalPages);


      console.warn(responseData.data.data.total_mail_count);
      console.warn(responseData.data.per_page)
      console.warn(totalPages)
    }
    else
    {
      NotificationManager.error(responseData.msg,'',2000);
    }
  }

  // invoice list
  // const downloadInvoice = async(record_id) => {
  //   const apiRes = await downloadInvoicePdf(record_id);
  //   const responseData = await apiRes.json()

  //   if (responseData.res) 
  //   {
  //     // NotificationManager.success(responseData.msg);
  //     const blob = await apiRes.blob();
  //     const fileName = "invoice.pdf";
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = fileName;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  //   else
  //   {
  //     NotificationManager.error(responseData.msg);
  //   }
  // }

  const downloadInvoice = async (record_id) => {
    let base_url = 'https://cleverdomizil.de';
    // NotificationManager.success('Download will start soon..')
    const apiRes = await downloadInvoicePdf(record_id);
    const responseData = await apiRes.json();

    // let file_url = responseData.data.split("uploads/invoice/")[1];
    // console.warn(responseData.data);
    let invoice_url = base_url + responseData.data.split("httpdocs")[1];
    window.open(invoice_url, '_blank');
    // const response = await fetch(invoice_url);
    // const blob = await response.blob();
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = file_url;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
};

const getPageNumbers = () => {
  const pageNumbers = [];
  const ellipsisKey = 'ellipsis';

  console.warn('pages: ' + totalPages)
  console.warn('button: ' + pageButtonsToShow)
  if (totalPages <= pageButtonsToShow)
  {
    console.warn(1);
    for (let i = 1; i <= totalPages; i++)
    {
      pageNumbers.push(i);
    }
  }
  else
  {
    console.warn(2);
    if (currentPage <= Math.ceil(pageButtonsToShow / 2))
    {
      console.warn(3);
      for (let i = 1; i <= pageButtonsToShow - 1; i++)
      {
        pageNumbers.push(i);
      }
      pageNumbers.push({ key: ellipsisKey });
      pageNumbers.push(totalPages);
    }
    else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2))
    {
      console.warn(4);
      pageNumbers.push(1);
      pageNumbers.push({ key: ellipsisKey });
      for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++)
      {
        pageNumbers.push(i);
      }
    }
    else
    {
      console.warn(5);
      pageNumbers.push(1);
      pageNumbers.push({ key: ellipsisKey });
      for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push({ key: ellipsisKey });
      pageNumbers.push(totalPages);
    }
    console.warn(6);
  }
  console.log(pageNumbers);
  return pageNumbers;
};

const handlePageChange = (newPage) => {
  // setClickableId(null);
  setCurrentPage(newPage);
  fetchInvoiceList(newPage);
};

  // invoice list
  const deleteInvoiceRecord = async() => {
    try
    {
      let apiRes = await deleteInvoice(recordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchInvoiceList(1);
        delhandleClose();
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // invoice list
  const AllReadOverviewList = async() => {
    try
    {
      let apiRes = await getAllReadOverviewList(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        // Convert object to array
        //const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
        console.log(responseData.overviewData);
        setReadOverviewList(responseData.overviewData);
        
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const handleParentData1 = (e) => {
    setCheckBoxValue1(e);
    console.log(e);
  }

  const handleParentData2 = (e) => {
    setCheckBoxValue2(e);
    console.log(e);
  }

  useEffect(()=>{
    fetchInvoiceList(1);
    AllReadOverviewList();
    getAllPageContent();
  },[hostLanguage])

  return (
    <>
    {/* <div className="lightBlue"> */}
      {/* <InnerHeader /> */}
      {/* <div className="mail-box-main-body"> */}
        {/* <Row className="mail-box-body"> */}
          {/* <Col sm={2}>
            <Sidebar />
          </Col> */}
          <Col sm={10}>
            <div className="MailboxTable">
              <div className="Titelbox">
                <span>
                  {/* <h3>Invoices & Payments</h3> */}
                  <h3>{contentDetails?.after_login?.InvoicesAndPayments}</h3>
                </span>
                <span>
                  <Link to="/Dashboard" className="DashboardBtn">
                    {" "}
                    {/* Go To Dashboard */}
                    {contentDetails?.after_login?.GoToDashboard}
                  </Link>
                </span>
              </div>

              <div className="Tabbox">
                <Tabs
                  defaultActiveKey="overview"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  {/* <Tab eventKey="overview" title="Overview">
                    <h4>Current Activities</h4>
                    <div className="InvoicesBox">
                      <h5>Postbox</h5>
                      <Row>
                        <Col sm={4}>
                          <div className="InvoicesBoxList">
                            <ul>
                              <li>
                                <label>Postboxes</label>$20.00
                              </li>
                              <li>
                                <label>Postboxes</label>$2.00
                              </li>
                              <li>
                                <label>Postboxes</label>$5.00
                              </li>
                              <li>
                                <label>Postboxes</label>$15.00
                              </li>
                              <li>
                                <label>Postboxes</label>$0.75
                              </li>
                              <li>
                                <label>Postboxes</label>$0.00
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="InvoicesBoxList">
                            <ul>

                              <li>
                                <label>Shipping & Handling</label>$0.00
                              </li>
                              

                            </ul>
                            <div className="TotalInfo">
                              Current Activities Total <span>$43.25</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="InvoicesBox">
                      <h5>Incorporation</h5>
                      <Row>
                        <Col sm={4}>
                          <div className="InvoicesBoxList">
                            <ul>
                              <li>
                                <label>Incorporation fees</label>$20.00
                              </li>
                              <li>
                                <label>Legal Address</label>$2.00
                              </li>
                              <li>
                                <label>Secretary services</label>$5.00
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="InvoicesBoxList">
                            <div className="TotalInfo">
                              Current Activities Total <span>$27.00</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </Tab> */}

                  {/* <Tab eventKey="overview" title="Overview"> */}
                  <Tab eventKey="overview" title={contentDetails?.after_login?.Overview}>
                    {/* <h4>Current Activities</h4>
                    <div className="InvoicesBox">
                      <Row>
                        <Col sm={4}>
                          <div className="InvoicesBoxList">
                            <ul>
                            {ReadOverviewList?.details?.length > 0 ? (
                              ReadOverviewList && ReadOverviewList.details.map((item,index)=>{
                                return(
                                  <>
                              <li>
                                <label>{item?.payment_for}</label>€{item?.amount}
                              </li>
                              </>
                                )
                              })
                            ):(
                              <li>
                                <label>No Record Found</label>
                              </li>
                              )}
                            </ul>
                          </div>
                        </Col>
                        
                        <Col sm={4}>
                          <div className="InvoicesBoxList">
                            <div className="TotalInfo">
                              Current Activities Total <span>€{ReadOverviewList?.sumOfAmount}</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                    
                    {/* <h4>Current Activities</h4> */}
                    <h4>{contentDetails?.after_login?.CurrentActivities}</h4>

                    {ReadOverviewList && ReadOverviewList.mail && ReadOverviewList.mail.length > 0 ? (
                      <div className="InvoicesBox">
                        {/* <h5>Cleverdomizil Mail</h5> */}
                        <h5>{contentDetails?.after_login?.CleverdomizilMail}</h5>
                        <Row>
                          <Col sm={4}>
                            <div className="InvoicesBoxList">
                              <ul>
                                {ReadOverviewList.mail.map((item, index) => (
                                  <li key={index}>
                                    <label>{item.payment_for}</label>€{item?.grand_total!=null ? item?.grand_total : item.amount}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="InvoicesBoxList">
                              <div className="TotalInfo">
                                {/* Current Activities Total <span>€{ReadOverviewList.mailTotal}</span> */}
                                {contentDetails?.after_login?.CurrentActivitiesTotal} <span>€{ReadOverviewList.mailTotal}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null}

                    {ReadOverviewList && ReadOverviewList.phone && ReadOverviewList.phone.length > 0 ? (
                      <div className="InvoicesBox">
                        {/* <h5>Cleverdomizil Phone</h5> */}
                        <h5>{contentDetails?.after_login?.CleverdomizilPhone}</h5>
                        <Row>
                          <Col sm={4}>
                            <div className="InvoicesBoxList">
                              <ul>
                                {ReadOverviewList.phone.map((item, index) => (
                                  <li key={index}>
                                    <label>{item.payment_for}</label>€{item.grand_total!= null ? item?.grand_total : item.amount}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="InvoicesBoxList">
                              <div className="TotalInfo">
                                {/* Current Activities Total <span>€{ReadOverviewList.phoneTotal}</span> */}
                                {contentDetails?.after_login?.CurrentActivitiesTotal} <span>€{ReadOverviewList.phoneTotal}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null} 

                    {ReadOverviewList && ReadOverviewList.wallet && ReadOverviewList.wallet.length > 0 ? (
                      <div className="InvoicesBox">
                        {/* <h5>Cleverdomizil Wallet</h5> */}
                        <h5>{contentDetails?.after_login?.CleverdomizilWallet}</h5>
                        <Row>
                          <Col sm={4}>
                            <div className="InvoicesBoxList">
                              <ul>
                                {ReadOverviewList.wallet.map((item, index) => (
                                  <li key={index}>
                                    <label>{item.payment_for}</label>€{item.amount}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="InvoicesBoxList">
                              <div className="TotalInfo">
                                {/* Current Activities Total <span>€{ReadOverviewList.walletTotal}</span> */}
                                {contentDetails?.after_login?.CurrentActivitiesTotal} <span>€{ReadOverviewList.walletTotal}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null}   

                  </Tab>

                  {/* <Tab eventKey="balance" title="Top Up Balance"> */}
                  <Tab eventKey="balance" title={contentDetails?.after_login?.TopUpBalance}>
                    <div className="InvoicesBox">
                      {/* <h5>Make a deposit into your account</h5> */}

                      <Row>
                        <Col sm={9}>
                          <div>
                            <Row>
                              <Col sm={6}>
                                {/* <Form.Group className="mb-4 text-left">
                                  <Form.Label>Amount</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter your top up amount"
                                  />
                                </Form.Group> */}
                                <Form.Control
                                  type="text"
                                  id='topup_amount'
                                  className="card"
                                  name="amount"
                                  value={amount}
                                  onChange={(e) => {
                                    let rawValue = e.target.value;
                                    let floatValue = parseFloat(rawValue);
                                    setAmount(rawValue);
                                    setPayableAmount(floatValue.toFixed(2));
                                  }}
                                  // placeholder="Enter Amount"
                                  placeholder={contentDetails?.after_login?.EnterAmount}
                                  maxLength={14}
                                />
                              </Col>
                              <Col sm={6}>
                                {/* <Button
                                  variant="primary"
                                  type="submit"
                                  className="cardBtn"
                                  onClick={handleShow}
                                >
                                  <HiCreditCard size="25" className="mr-2" />
                                  Add credit card
                                </Button> */}
                                {/* <PayPalComponent productInfo={productInfo} productPrice = {productPrice} type={'purchase-product'}/> */}
                                {/* <PayPalComponent/> */}

                                {/* {amount?.endsWith(".") ? (
                                  <></>
                                ):( */}
                                  {/* amount!= undefined &&  && amount!= '.' && amount!= 0 && ( */}
                                  {amount > 0 && (
                                    <div className="MainBodyRgtInner">
                                      {/* <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>Credit Card/Debit Card</Accordion.Header>
                                          <Accordion.Body>
                                            <PayPalComponent productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'}/>
                                          </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>PayPal Payment</Accordion.Header>
                                          <Accordion.Body>
                                            <PayPalPayment productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'} from={'invoice'}/>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion> */}

                                      <Conditions propsData1 = {handleParentData1} propsData2 = {handleParentData2} type='topup' amount={amount}/>
                                      {
                                        checkBoxValue1 && checkBoxValue1 === 'accept_terms' &&
                                        checkBoxValue2 && checkBoxValue2 === 'accept_contract' ? (
                                        <>
                                          {amount > 0 && (
                                            <>
                                              <h3>Payment With</h3>
                                              <PayPalComponent productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'}/>
                                              <PayPalPayment productInfo={'Top-up'} productPrice = {payableAmount} type={'Top-up'}/>
                                            </>
                                          )}
                                        </>
                                      ):(
                                        <></>
                                      )}
                                    </div>
                                  )}
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col sm={6}>
                                <Form.Group className="mb-4 text-left">
                                  <Form.Label>Amount in USD</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter your top up amount"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={6}>
                                <Form.Group className="mb-4 text-left">
                                  <Form.Label>
                                    Converted in other currency (0.00)
                                  </Form.Label>
                                  <Form.Select aria-label="Default select example">
                                    <option>Select currency</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row> */}

                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col sm={9}>
                          <div className="noteDiv">
                            <h4>Please note:</h4>
                            <p>
                              The transfer will always be made in USD. The
                              currency conversion is for your information only
                              and calculated based on daily updated exchange
                              rates.
                            </p>
                          </div>

                          <Button
                            variant="primary"
                            type="Submit"
                            className="submitBtn2"
                          >
                            Submit
                          </Button>

                          <img src={Card} className="CardImg" alt="logo" />

                          <div className="paymentInfo">
                            <h5>Payment Automation</h5>
                            <p>
                              No need to remember topping up your balance in
                              time. Enable Automatic Payments and the necessary
                              amount will be charged automatically. You will
                              receive a notification and all charges will be
                              shown at Invoices & Payments
                            </p>

                            <Button
                              variant="primary"
                              type="Submit"
                              className="submitBtn2"
                            >
                              Enable Automatic Payments
                            </Button>
                          </div>
                        </Col>
                      </Row> */}
                    </div>
                  </Tab>
                  {/* <Tab eventKey="method" title="Manage payment method">
                    <div className="InvoicesBox">
                      <div className="radioGrp radioGrp2">
                        <h5> Manual bank transfers</h5>
                        <div className="form-check checkFrm">
                          <Form.Check
                            id="monthly"
                            type="radio"
                            name="address"
                          />
                          <Form.Label
                            className="form-check-label"
                            for="monthly"
                          >
                            Default
                          </Form.Label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="form-check checkFrm checkFrm2">
                          <Form.Check
                            aria-label="option 1"
                            id="flexCheckChecked3"
                          />
                          <Form.Label
                            className="form-check-label"
                            for="flexCheckChecked3"
                          >
                            Automatic charge for required pre-payments from
                            primary credit card
                          </Form.Label>
                        </div>
                        <Button
                          variant="primary"
                          type="Submit"
                          className="submitBtn2"
                        >
                          Add New Method
                        </Button>
                      </div>
                    </div>
                  </Tab> */}

                  {/* <Tab eventKey="payments" title="Invoices & Payments"> */}
                  <Tab eventKey="payments" title={contentDetails?.after_login?.InvoicesAndPayments}>
                    <div>
                      {/* <div className="TableFiltter">
                        <Row className="align-items-center">
                          <Col sm={4}>
                            <h5>
                              <label>Running Balance </label>$ 156.00
                            </h5>
                          </Col>
                          <Col sm={8}>
                            <div className="FiltterList">
                              <Form>
                                <Row>
                                  <Col sm={6}>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Form.Label column sm="3">
                                        Show by type:
                                      </Form.Label>
                                      <Col sm="9">
                                        <Form.Select aria-label="Default select example">
                                          <option>
                                            Type your phone number
                                          </option>
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Form.Label column sm="2">
                                        Date:
                                      </Form.Label>
                                      <Col sm="5">
                                        <Form.Control type="date" />
                                      </Col>
                                      <Col sm="5">
                                        <Form.Control type="date" />
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </div> */}

                      <Table>
                        <thead>
                          <tr>
                            {/* <th>
                              <div className="form-check">
                                <Form.Check
                                  aria-label="option 1"
                                  id="flexCheckChecked2"
                                />
                              </div>
                            </th> */}
                            {/* <th>Transaction Type</th> */}
                            <th>{contentDetails?.after_login?.TransactionType}</th>
                            {/* <th>Payment For</th> */}
                            {/* <th>Date</th> */}
                            <th>{contentDetails?.after_login?.Date}</th>
                            {/* <th>Transaction ID</th> */}
                            <th>{contentDetails?.after_login?.TransactionID}</th>
                            {/* <th>Gross Total</th> */}
                            {/* <th>Net Total</th> */}
                            {/* <th>Total Amount</th> */}
                            {/* <th>PDF</th> */}
                            <th>{contentDetails?.after_login?.PDF}</th>
                            {/* <th>Actions</th> */}
                            <th>{contentDetails?.after_login?.Actions}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invList?.length > 0 ? (
                            invList?.map((item,index)=>{
                              return(
                                <>
                                  <tr className="odd_tr">
                                    {/* <td>
                                      <div className="form-check">
                                        <Form.Check aria-label="option 1" id="flexCheckChecked2"/>
                                      </div>
                                    </td> */}
                                    <td>{item?.transaction_type}</td>
                                    {/* <td>{item?.payment_for}</td> */}
                                    <td>{item?.payment_time?.slice(0,10)}</td>
                                    <td>{item?.transaction_id}</td>
                                    {/* <td>${item?.grand_total!= null ? item?.grand_total : amount}</td> */}
                                    {/* <td>$47.00</td> */}
                                    <td>
                                      <a onClick={()=>downloadInvoice(item?.transaction_id)} style={{cursor:'pointer'}}>
                                        <img
                                          src={Pdf}
                                          className="TableIcon"
                                          alt="Img"
                                        />
                                      </a>
                                    </td>
                                    <td>
                                      <a 
                                        onClick={()=>{setRecordId(item?.id);deletShow(true)}}
                                        style={{cursor:'pointer'}}
                                      >
                                        <img
                                          src={Remove}
                                          className="TableIcon"
                                          alt="Img"
                                        />
                                      </a>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colspan="11"></td>
                                  </tr>
                                </>
                              )
                            })
                          ):(
                            <tr className="odd_tr">
                              <td colSpan={5} style={{textAlign:'center'}}>{languageJson?.no_record_found}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {invList?.length > 0 ? (
                        <div className="TablePagination">
                            {/* Pagination controls start */}
                            <Pagination>
                              {currentPage >= 1 && (
                                <Pagination.Prev
                                  disabled={currentPage === 1}
                                  onClick={() => handlePageChange(currentPage - 1)}
                                  style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                                />
                              )}

                              {/* {getPageNumbers().map((pageNumber, index) => (
                                <React.Fragment key={index}>
                                  {pageNumber === <Pagination.Ellipsis /> ? (
                                    <Pagination.Ellipsis />
                                  ) : (
                                    <Pagination.Item
                                      active={currentPage === pageNumber}
                                      onClick={() => handlePageChange(pageNumber)}
                                    >
                                      {pageNumber}
                                    </Pagination.Item>
                                  )}
                                </React.Fragment>
                              ))} */}

                                {getPageNumbers().map((pageNumber, index) => (
                                  <React.Fragment key={index}>
                                    {pageNumber.key === 'ellipsis' ? (
                                      <Pagination.Ellipsis key={index} />
                                    ) : (
                                      <Pagination.Item
                                        key={index}
                                        active={currentPage === pageNumber}
                                        onClick={() => handlePageChange(pageNumber)}
                                      >
                                        {pageNumber}
                                      </Pagination.Item>
                                    )}
                                  </React.Fragment>
                                ))}


                              {currentPage <= totalPages && (
                                <Pagination.Next
                                  disabled={currentPage === totalPages}
                                  onClick={() => handlePageChange(currentPage + 1)}
                                  style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                                />
                              )}
                            </Pagination>


                          {/* Pagination controls end */}

                        </div>
                      ):null}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        {/* </Row> */}
      {/* </div> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        className="CustomModal2"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="CustomModalBody">
            <Form.Group className="mb-4 text-left">
              <Form.Label>Card number</Form.Label>
              <Form.Control
                className="card"
                type="password"
                placeholder="***************"
              />
            </Form.Group>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-4 text-left">
                  <Form.Label>Expiry date</Form.Label>
                  <Form.Control type="text" placeholder="Type first name" />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-4 text-left">
                  <Form.Label>CVC / CVV</Form.Label>
                  <Form.Control type="text" placeholder="Enter number" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-4 text-left">
              <Form.Label>Name on card</Form.Label>
              <Form.Control type="email" placeholder="Enter name on card" />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="rgtBtn" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={delshow}
        onHide={delhandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal delCustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            Do you want to delete this invoice?
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={delhandleClose}
            className="lftBtn"
          >
            No
          </Button>
          <Button variant="primary" className="rgtBtn" onClick={()=>deleteInvoiceRecord()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    {/* </div> */}
    </>
  );
};

export default InvoicesPayments;
