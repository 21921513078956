import React, { useState, useEffect } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./AllMail.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../components/sidebar/Sidebar";
import Mailboxright from "../../components/mailboxright/Mailboxright";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import SendRed from "../../assets/images/SendRed.svg";
import SendYellow from "../../assets/images/SendYellow.svg";
import ScanIconYellow from "../../assets/images/ScanIconYellow.svg";
import ScanIconRed from "../../assets/images/ScanIconRed.svg";

// import ScanEnvelopRed from "../../assets/images/ScanEnvelopRed.svg";
// import ScanEnvelopYellow from "../../assets/images/ScanEnvelopYellow.svg";
import ScanGreenAll from "../../assets/images/ScanEnvelopGreen.svg";
import Collect from "../../assets/images/Collect.svg";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TickBlue from "../../assets/images/TickBlue.svg";
import Nav from "react-bootstrap/Nav";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from 'uuid';


import 
{ 
  getAllMailsData, getAllMailCategory, updateMailCategoryById, recordMovedtoTrash,
  getScanPrice, getWalletPrice, getUserDetails, paymentInvoice,
  sendScanRequest, sendMeRequest,
  sendMeAddressAsBilling, getAllTypes, getFilterData, getPageContent
} from "../../api/apiRequest";

import { useContextValues } from "../../context/commonContext";
import NewAddress from "../new-address/NewAddress";
import { useLangContext } from "../../context/languageContext";

const AllMail = () => {
  const {setEventFire} = useContextValues();
  const {hostLanguage,languageJson} = useLangContext();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [payshow, paysetShow] = useState(false);
  const [newAddressModal,setNewAddressModal] = useState(false);
  const [selectedNewAddress,setSelectedNewAddress] = useState('1');
  const [addressshow, addresssetShow] = useState(false);
  const [thankshow, thanksetShow] = useState(false);
  const [delshow, deletShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [clickableId,setClickableId] = useState(null);
  const [deleteRecordId,setDeleteRecodId] = useState();
  const [envFile,setEnvFile] = useState();
  const [scanDocFile,setScanDocFile] = useState();
  const [planData,setPlanData] = useState();
  const [isChecked,setIsChecked] = useState(false);
  const [isVisible,setIsVisible] = useState(false);
  const [actionType,setActiontype] = useState();
  const [contentDetails,setContentDetails] = useState();

  const [newAddressData,setNewAddressData] = useState({});
  const [userDetails, setUserDetails] = useState();
  const uniqueId = uuidv4();
  const transactionId = uniqueId.replace(/-/g, '').substring(0, 15); // Remove dashes and truncate to 15 characters
  

  // filter
  const [selectedCatId,setSelectedCatId] = useState();
  const [selectedTypeId,setSelectedTypeId] = useState();
  const [selectedFromDate,setSelectedFromDate] = useState();
  const [selectedToDate,setSelectedToDate] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const payhandleClose = () => paysetShow(false);
  const payhandleShow = () => paysetShow(true);

  const addresshandleClose = () => addresssetShow(false);
  const addresshandleShow = () => addresssetShow(true);

  
  const newAddressClose = () => setNewAddressModal(false);
  const newAddressShow = () => setNewAddressModal(true);

  const thankhandleClose = () => thanksetShow(false);
  const thankhandleShow = () => thanksetShow(true);

  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);

  const [showReqPopUp, setShowReqPopUp] = useState(false);
  const [showScanPopUp, setShowScanPopUp] = useState(false);

  
  const reqhandleClose = () => addresssetShow(false);


  // const totalPages = Math.ceil(items.length / itemsPerPage);
  const [totalPages,setTotalPages] = useState();
  const [pageButtonsToShow,setPageButtonShow] = useState();
  const [categories,setCategories] = useState([]);
  const [scanDetails,setScanDetails] = useState();
  const [walletDetails,setWalletDetails] = useState();

  const [allNewMailArr,setAllNewMailArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reqId, setReqId] = useState();
  const [showTypes,setShowTypes] = useState([]);


  // fetching new mail data
  const fetchAllMailData = async(pageNumber) => {
    try
    {
      let apiRes = await getAllMailsData(pageNumber);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        // Convert object to array
        const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
        setAllNewMailArr(dataArr);
        
        setPageButtonShow(responseData.data.total);
        const totalPages = Math.ceil(Number(responseData.data.data.total_mail_count) / Number(responseData.data.per_page));
        setTotalPages(totalPages);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
       // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // fetching new mail data
  const fetchingAllMailCategories = async() => {
    try
    {
      let apiRes = await getAllMailCategory(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCategories(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get all types
  const fetchingAllTypes = async() => {
    try
    {
      let apiRes = await getAllTypes(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setShowTypes(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      {
        setUserDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // const getPageNumbers = () => {
  //   const pageNumbers = [];
  //   const ellipsis = <Pagination.Ellipsis />;

  //   if (totalPages <= pageButtonsToShow) {
  //     for (let i = 1; i <= totalPages; i++) {
  //       pageNumbers.push(i);
  //     }
  //   } else {
  //     if (currentPage <= Math.ceil(pageButtonsToShow / 2)) {
  //       for (let i = 1; i <= pageButtonsToShow - 1; i++) {
  //         pageNumbers.push(i);
  //       }
  //       pageNumbers.push(ellipsis);
  //       pageNumbers.push(totalPages);
  //     } else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2)) {
  //       pageNumbers.push(1);
  //       pageNumbers.push(ellipsis);
  //       for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++) {
  //         pageNumbers.push(i);
  //       }
  //     } else {
  //       pageNumbers.push(1);
  //       pageNumbers.push(ellipsis);
  //       for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
  //         pageNumbers.push(i);
  //       }
  //       pageNumbers.push(ellipsis);
  //       pageNumbers.push(totalPages);
  //     }
  //   }
  //   return pageNumbers;
  // };

  
  const getPageNumbers = () => {
    const pageNumbers = [];
    const ellipsisKey = 'ellipsis';
  
    console.warn('pages: ' + totalPages)
    console.warn('button: ' + pageButtonsToShow)
    if (totalPages <= pageButtonsToShow)
    {
      console.warn(1);
      for (let i = 1; i <= totalPages; i++)
      {
        pageNumbers.push(i);
      }
    }
    else
    {
      console.warn(2);
      if (currentPage <= Math.ceil(pageButtonsToShow / 2))
      {
        console.warn(3);
        for (let i = 1; i <= pageButtonsToShow - 1; i++)
        {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2))
      {
        console.warn(4);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++)
        {
          pageNumbers.push(i);
        }
      }
      else
      {
        console.warn(5);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      console.warn(6);
    }
    console.log(pageNumbers);
    return pageNumbers;
  };
  
  const handlePageChange = (newPage) => {
    setClickableId(null);
    setCurrentPage(newPage);
    fetchAllMailData(newPage);
  };

  const handleScanInfo = (record_id) => {
    paysetShow(true);
    gettingScanPrice(record_id);
    setReqId(record_id);
    setActiontype('scanType');
  }

  const handleSendMeInfo = (record_id) => {
    // paysetShow(true);
    addresssetShow(true);
    gettingScanPrice(record_id);
    setReqId(record_id);
    setActiontype('sendMeType');
    // addresssetShow(true);setReqId(item?.id);
  }

  const functionMailBox = () =>{
    thankhandleClose();
    navigate('/InvoicesPayments');
    // fetchAllMailData(1);
    // getWalletPrice();
  }

  // moved to trashed
  const MoveToTrashed = async() => {
    try
    {
      setEventFire(true);
      let apiRes = await recordMovedtoTrash(deleteRecordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchAllMailData(1);
        delhandleClose();
        setEventFire(false);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // date format
  const dateFormat = (date) => {
    let split_date = date.split('-');
    // let final_date = split_date[1] + '.' + split_date[2] + '.' + split_date[0];
    let final_date = split_date[2] + '.' + split_date[1] + '.' + split_date[0];
    return final_date
  }

  // date format
  const displayName = (name) => {
    let short_name = '';
    if( name.length > 10 )
    {
      short_name = name?.substring(0,10) + '...';
    }
    else
    {
      short_name = name;
    }
    return short_name
  }

  // clickable data
  const clickableData = (click_id) => {
    setClickableId(click_id);
    const foundObject = allNewMailArr.find(obj => obj.id === click_id);
    let base_url = 'https://cleverdomizil.de';
    if(foundObject.envelop === null || foundObject.envelop === '' || foundObject.envelop === undefined)
    {
      setEnvFile('NoUrl');
    }
    else
    {
      let env_url = base_url + foundObject.envelop?.split("httpdocs")[1]; //# Extract the desired part of the base_url
      setEnvFile(env_url);
    }

    if(foundObject.scan_document === null || foundObject.scan_document === '' || foundObject.scan_document === undefined)
    {
      setScanDocFile('NoUrl');
    }
    else
    {
      let scan_url = base_url + foundObject.scan_document?.split("httpdocs")[1];
      setScanDocFile(scan_url);
    }
  }

  // update mail category by id
  const updateCategoryById = async(record_id,cat_id) => {
    try
    {
      let apiRes = await updateMailCategoryById(record_id,cat_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchAllMailData(1);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //download files
  const handleDownload = async (record_id) => {
    try 
    {
      let base_url = 'https://cleverdomizil.de';
      const newInputs = allNewMailArr.filter((item) => item.id === record_id);
      let envelop_file = newInputs[0]?.envelop;
      let scan_document = newInputs[0]?.scan_document;

      let download_arr = [];
      if(envelop_file === '' || envelop_file === null || envelop_file === undefined)
      {
        // console.warn('envelop_file: ' + envelop_file);
      }
      else
      {
        let env_url = base_url + envelop_file?.split("httpdocs")[1];
        // await downloadFile(env_url,'envelop.png');
        download_arr.push(env_url);
      }

      if(scan_document === '' || scan_document === null || scan_document === undefined)
      {
        // console.warn('scan_document: ' + scan_document);
      }
      else
      {
        let scan_url = base_url + scan_document?.split("httpdocs")[1];
        // await downloadFile(scan_url,'scan_document.pdf');
        download_arr.push(scan_url);
      }
      console.warn(download_arr)
      download_arr?.map((item,index)=>{
        downloadFile(item)
      })
     
      // NotificationManager.success("File Download has started..",'',2000);
    }
    catch (error)
    {
      console.error(error);
    }
  };

  const downloadFile = async (url, fileName) => 
  {
    window.open(url, '_blank');
    // const response = await fetch(url);
    // const blob = await response.blob();
    

    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = fileName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  // getting scan price
  const gettingScanPrice = async(record_id) => {
    try
    {
      let apiRes = await getScanPrice(record_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setPlanData(responseData.data[0]?.plan);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
      console.error(error);
    }
  }

  // submit scan request with price
  const submitScanPrice = async(type) => {
    try
    {
      let normal_price = parseFloat(planData?.item_content_scanning).toFixed(2);
      // console.warn(planData?.item_content_scanning);  
      // console.warn(walletDetails?.wallet_balance);
      // console.log(parseFloat(planData?.item_content_scanning) <= parseFloat(walletDetails?.wallet_balance));
      // return
      
      if(parseFloat(planData?.item_content_scanning) <= parseFloat(walletDetails?.wallet_balance))
      {
        if(type === 'normal')
        {
          handleScanDocReq(reqId,1,normal_price);
        }
        else
        {
          throw new Error('Error scan price data from wallet');
        }
      }
      else
      {
        navigate('/scan-payment',{state: {
          id:reqId,
          deduct_wallet_balance: 0,
          amount:normal_price,
          type:'scan'
        }});
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // submit send me request with price
  const submitSendMePrice = async(type) => {
    try
    {
      if(selectedValue === 1)
      {
        let send_charge = 1.00;
        let normal_price = send_charge;
        let special_price = (parseFloat(normal_price) + parseFloat(planData?.special_requests)).toFixed(2);
      
        if(type === 'normal')
        {
          if(parseFloat(normal_price) <= parseFloat(walletDetails?.wallet_balance))
          {
            handleSendMeReq(reqId,1,normal_price);
          }
          else
          {
            navigate('/scan-payment',{state: {
              id: reqId,
              deduct_wallet_balance: 0,
              amount: normal_price,
              purpose: 'sameAs',
              type: 'sendMe',
              same_as_billing: 1,
            }});
          }
        }
        else if (type === 'special')
        {
          if(parseFloat(special_price) <= parseFloat(walletDetails?.wallet_balance))
          {
            handleSendMeReq(reqId,1,special_price);
          }
          else
          {
            navigate('/scan-payment',{state: {
              id: reqId,
              deduct_wallet_balance: 0,
              amount: special_price,
              purpose: 'sameAs',
              type: 'sendMe',
              same_as_billing: 1,
            }});
          }
        }
        else
        {
          console.Error('Error send price data from credit card-Same Address');
        }
      }
      else if(selectedValue === 2)
      {
        let send_charge = 1.00;
        let normal_price = send_charge;
        let special_price = (parseFloat(normal_price) + parseFloat(planData?.special_requests)).toFixed(2);
      
        if(type === 'normal')
        {
          if(parseFloat(normal_price) <= parseFloat(walletDetails?.wallet_balance))
          {
            handleSendMeReq(reqId,1,normal_price);
          }
          else
          {
            navigate('/scan-payment',{state: {
              id: reqId,
              deduct_wallet_balance: '0',
              amount: normal_price,
              purpose: 'new',
              type: 'sendMe',
              same_as_billing: '0',
              dataObj: newAddressData
            }});
          }
        }
        else if (type === 'special')
        {
          if(parseFloat(special_price) <= parseFloat(walletDetails?.wallet_balance))
          {
            handleSendMeReq(reqId,1,special_price);
          }
          else
          {
            navigate('/scan-payment',{state: {
              id: reqId,
              deduct_wallet_balance: '0',
              amount: special_price,
              purpose: 'new',
              type: 'sendMe',
              same_as_billing: '0',
              dataObj: newAddressData
            }});
          }
        }
        else
        {
          console.Error('Error send price data from credit card-New Address');
        }
      }
      else
      {
        throw new Error('Invalid Select Value Input');
      }
      
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // getting wallet price
  const gettingWalletPrice = async() => {
    try
    {
      let apiRes = await getWalletPrice();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setWalletDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //send scan document request to admin
  const handleScanDocReq = async (record_id,deduct_wallet_balance,amount) =>
  {
    try
    {
      let data = {
        id: record_id,
        deduct_wallet_balance: deduct_wallet_balance,
        amount: parseFloat(amount).toFixed(2)
      }
      let apiRes = await sendScanRequest(data);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        let values = {
          transaction_id: transactionId,
          transaction_type:'Wallet Balance',
          currency_type: userDetails?.currency === "€" ? 'EUR' : '',
          payment_for: 'Scan Payment',
          amount: parseFloat(amount).toFixed(2), 

          payer_firstname: userDetails?.full_name?.split(' ')[0],
          payer_lastname: userDetails?.full_name?.split(' ')[1],
          payer_email: userDetails?.email,
          payer_country_code: '',
          payment_time: convert_date_format(new Date()),
          payment_type: 4
        }
        let apiRes1 = await paymentInvoice(values);
        let responseData1 = await apiRes1.json();
        
        if(responseData1.res)
        {
          // thanksetShow(true);
          // NotificationManager.success(responseData.msg,'',2000);
          // navigate('/InvoicesPayments');
          thanksetShow(true);
        }
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // send me request to admin
  const handleSendMeReq = async (record_id,deduct_wallet_balance,amount) =>
  {
    try
    {
      let data = {};
      let values = {};
      if(selectedValue === 2)
      {
        console.warn(newAddressData)
        // return
        data = {
          id: newAddressData?.id,
          deduct_wallet_balance: deduct_wallet_balance,
          amount:parseFloat(amount).toFixed(2),
          same_as_billing:0,
          company_name:newAddressData?.company_name,
          title:newAddressData?.title,
          first_name: newAddressData?.first_name,
          last_name: newAddressData?.last_name,
          address: newAddressData?.address,
          country_id: newAddressData?.country_id,
          city_id: newAddressData?.city_id,
          region_id: newAddressData?.region_id,
          postal_code: newAddressData?.postal_code,
          area_code: newAddressData?.area_code,
          phone_number: newAddressData?.phone_number
        }
      }
      else if(selectedValue === 1)
      {
        data = {
          id: reqId,
          deduct_wallet_balance: deduct_wallet_balance,
          amount:parseFloat(amount).toFixed(2),
          same_as_billing:1,
        }
      }
      else
      {
        console.error('Error in Selected Value');
      }

      let apiRes = await sendMeRequest(data);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        let values = {
          transaction_id: transactionId,
          transaction_type:'Wallet Balance',
          currency_type: userDetails?.currency === "€" ? 'EUR' : '',
          payment_for: 'Send Me Payment',
          amount: parseFloat(amount).toFixed(2), 

          payer_firstname: userDetails?.full_name?.split(' ')[0],
          payer_lastname: userDetails?.full_name?.split(' ')[1],
          payer_email: userDetails?.email,
          payer_country_code: '',
          payment_time: convert_date_format(new Date()),
          payment_type: 5
        }
        let apiRes1 = await paymentInvoice(values);
        let responseData1 = await apiRes1.json();
  
        if(responseData1.res)
        {
          thanksetShow(true);
        }
        else
        {
          NotificationManager.error(responseData1.msg,'',2000);
        }
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }


    // mailbox/send-mail-me-request/1
    // try
    // {
    //   let values = {
    //     id: reqId,
    //     same_as_billing:1
    //   }
    //   let apiRes = await sendMeRequest(values);
    //   let responseData = await apiRes.json();
    //   if(responseData.res)
    //   {
    //     NotificationManager.success(responseData.msg,'',2000);
    //     reqhandleClose();
    //     fetchAllMailData(1);
    //   }
    //   else
    //   {
    //     NotificationManager.error(responseData.msg,'',2000);
    //   }
    // }
    // catch(error)
    // {
    //   // NotificationManager.error('Something went wrong!', '',2000);
    // }

    // try
    // {
    //   let values = {
    //     id: reqId,
    //     same_as_billing:1
    //   }
    //   let apiRes = await sendMeAddressAsBilling(values);
    //   let responseData = await apiRes.json();
    //   if(responseData.res)
    //   {
    //     NotificationManager.success(responseData.msg,'',2000);
    //     reqhandleClose();
    //     fetchAllMailData(1);
    //   }
    //   else
    //   {
    //     NotificationManager.error(responseData.msg,'',2000);
    //   }
    // }
    // catch(error)
    // {
    //     // NotificationManager.error('Something went wrong!', '',2000);
    // }
  }

  //convert date format
  const convert_date_format = (date) => {
    const date_format = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dateString = date_format.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
    return dateString;
  }

  // filter
  const handleFilter = async(type_id,cat_id,from_date,to_date) =>{
    console.log(to_date)
    try
    {
      if(type_id!='' || cat_id!='' || from_date!='' || to_date!='')
      {
        console.warn(type_id)
        console.warn(cat_id)
        let values = {
          // page: currentPage,
          page: 1,
          type: type_id!= undefined ? type_id : '' ,
          date_form: from_date!= undefined ? from_date : '',
          date_to: to_date!=undefined ? to_date : '',
          mail_category_id: cat_id!=undefined ? cat_id : ''
        }
        let apiRes = await getFilterData(values);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          console.warn(responseData.data);

          // Convert object to array
          const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
          setAllNewMailArr(dataArr);
          setPageButtonShow(responseData.data.total);
          const totalPages = Math.ceil(Number(responseData.data.data.total_mail_count) / Number(responseData.data.per_page));
          setTotalPages(totalPages);
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      else
      {
        fetchAllMailData(1);
        setSelectedCatId('');
        setSelectedTypeId('');
        setSelectedFromDate();
        setSelectedToDate();
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  useEffect(()=>{
    fetchAllMailData(1);
    fetchingAllMailCategories();
    gettingWalletPrice();
    fetchingAllTypes();
    getUserData();
    getAllPageContent();

    const truncatedId = uniqueId.replace(/-/g, '').substring(0, 15); // Remove dashes and truncate to 15 characters
    console.log(truncatedId);
  },[hostLanguage])

  // useEffect(()=>{
  //   console.warn(isChecked)
  // },[isChecked])

  return (
    <>
    {/* <div className="lightBlue"> */}
      {/* <InnerHeader /> */}
      {/* <div className="mail-box-main-body"> */}
        {/* <Row className="mail-box-body"> */}
          {/* <Col sm={2}>
            {eventFire ? (
              <Sidebar countUpdate={1}/>
            ):(
              <Sidebar countUpdate={0}/>
            )}
          </Col> */}
          <Col sm={8}>
            <div className="ResponsiveTable">
              <div className="MailboxTable">
                <div className="Titelbox">
                  <span>
                    {/* <h3>All Mail</h3> */}
                    <h3>{contentDetails?.after_login?.AllMail}</h3>
                  </span>
                  
                  <span>
                    <Link to="/Dashboard" className="DashboardBtn">
                      {" "}
                      {/* Go To Dashboard */}
                      {contentDetails?.after_login?.GoToDashboard}
                    </Link>
                  </span>
                </div>

                <div className="TableFiltter">
                  <Row className="align-items-center">
                    {/* <Col sm={2}></Col> */}
                    <Col sm={12}>
                      <div className="FiltterList">
                        <Form>
                          <Row>
                            <Col sm={3}>
                              <Form.Group
                                as={Row}
                                className="align-items-center"
                              >
                                <Form.Label column sm="4">
                                  {/* Show Type: */}
                                  {contentDetails?.after_login?.ShowType}:
                                </Form.Label>
                                <Col sm="8">
                                  <Form.Select aria-label="Default select example" onChange={(e)=>{setSelectedTypeId(e.target.value);handleFilter(e.target.value,'','','')}}>
                                  {/* <option selected value=''>Select Type</option> */}
                                  <option selected value=''>{contentDetails?.after_login?.SelectType}</option>
                                  {showTypes?.length > 0 ?(
                                    showTypes?.map((item,index)=>{
                                      return(
                                        <option value={item?.id}>{item?.name}</option>
                                      )
                                    })
                                  ):(
                                    // <option disabled>No Types Found</option>
                                    <option disabled>{contentDetails?.after_login?.Category}</option>
                                  )}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group
                                as={Row}
                                className="align-items-center"
                              >
                                <Form.Label column sm="4">
                                  {/* Category: */}
                                  {contentDetails?.after_login?.Category}:
                                </Form.Label>
                                <Col sm="8">
                                  <Form.Select aria-label="Default select example" onChange={(e)=>{setSelectedCatId(e.target.value);handleFilter(selectedTypeId,e.target.value,'','')}}>
                                  {/* <option selected value=''>Category</option> */}
                                  <option selected value=''>{contentDetails?.after_login?.Category}</option>
                                  {categories?.length > 0 ?(
                                    categories?.map((item,index)=>{
                                      return(
                                        <option value={item?.id}>{item?.name}</option>
                                      )
                                    })
                                  ):(
                                    // <option disabled>No Categories Found</option>
                                    <option disabled>{contentDetails?.after_login?.Category}</option>
                                  )}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            </Col>
                            <Col sm={5}>
                              <Form.Group
                                as={Row}
                                className="align-items-center"
                              >
                                <Form.Label column sm="2">
                                  {/* Date: */}
                                  {contentDetails?.after_login?.Date}:
                                </Form.Label>
                                <Col sm="5">
                                  <Form.Control type="date" onChange={(e)=>{setSelectedFromDate(e.target.value);handleFilter(selectedTypeId,selectedCatId,e.target.value,'')}}/>
                                </Col>
                                <Col sm="5">
                                  <Form.Control type="date" onChange={(e)=>{setSelectedToDate(e.target.value);handleFilter(selectedTypeId,selectedCatId,selectedFromDate,e.target.value)}}/>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Table>
                  <thead>
                    <tr>
                      <th>
                        {/* <div className="form-check">
                          <Form.Check
                            aria-label="option 1"
                            id="flexCheckChecked2"
                          />
                        </div> */}
                      </th>
                      {/* <th>From</th>
                      <th>Type</th>
                      <th>Weight</th>
                      <th>Date</th>
                      <th>Envelop</th>
                      <th>Scan</th>
                      <th>Send Me</th>
                      <th>Collect</th>
                      <th>Category</th>
                      <th>Actions</th> */}
                      <th> {contentDetails?.after_login?.From}</th>
                      <th> {contentDetails?.after_login?.Type}</th>
                      <th> {contentDetails?.after_login?.Weight}</th>
                      <th> {contentDetails?.after_login?.Date}</th>
                      <th> {contentDetails?.after_login?.Envelop}</th>
                      <th> {contentDetails?.after_login?.Scan}</th>
                      <th> {contentDetails?.after_login?.SendMe}</th>
                      <th> {contentDetails?.after_login?.Collect}</th>
                      <th> {contentDetails?.after_login?.Category}</th>
                      <th> {contentDetails?.after_login?.Actions}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allNewMailArr?.length > 0 ? (
                      allNewMailArr && allNewMailArr.map((item,index)=>{
                        return(
                          <>
                            <tr className="odd_tr">
                              <td>
                                <div className="">
                                  <div className="form-check">
                                    <Form.Check
                                      aria-label="option 1"
                                      id="flexCheckChecked2"
                                      type="radio"
                                      name="option"
                                      onChange={()=>clickableData(item?.id)}
                                      checked={clickableId === item?.id}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>{displayName(item?.from)}</td>
                              <td>{item?.mail_type?.name}</td>
                              <td>{item?.weight}g</td>
                              <td>{dateFormat(item?.get_date)}</td>

                              <td>
                                <a class="icon hover-scan-document3">
                                  <img
                                    src={ScanGreenAll}
                                    className="TableIcon  hover-scan-document3"
                                    alt="Img"
                                  />
                                </a>
                              </td>

                            {/* Scan */}
                            {item?.send_scan_request == 0 && item?.send_scan_request_status == 0 ? (
                              <td>
                                <a class="icon hover-scan-document" onClick={()=>setShowReqPopUp(false)} onMouseEnter={()=>setShowReqPopUp(false)}>
                                  <img
                                    src={ScanIconRed}
                                    className="TableIcon hover-scan-document"
                                    alt="Img"
                                  />
                                </a>
                                {!showReqPopUp && (
                                  <div class="show-scan-document-over">
                                    <div class="show-scan-document">
                                      {/* <h3>Do you want to scan this document?</h3> */}
                                      <h3>{contentDetails?.after_login?.DoYouWantToScanThisDocument}</h3>
                                      <button type="button" class="btn btn-secondary" onClick={()=>setShowReqPopUp(true)}>
                                        {/* No */}
                                        {contentDetails?.after_login?.No}
                                      </button>
                                      <button type="button" class="btn btn-primary" onClick={()=>handleScanInfo(item?.id)}>
                                        {/* Yes */}
                                        {contentDetails?.after_login?.Yes}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </td>
                            ):
                            item?.send_scan_request == 1 && item?.send_scan_request_status == 0 ? (
                              <td style={{zIndex:'0 !important'}}>
                                <a class="icon hover-scan-document2">
                                  <img
                                    src={ScanIconYellow}
                                    className="TableIcon hover-scan-document2"
                                    alt="Img"
                                  />
                                </a>
                              </td>
                            ):
                            item?.send_scan_request == 1 && item?.send_scan_request_status == 1 ? (
                              <td style={{zIndex:'0 !important'}}>
                                <a class="icon hover-scan-document2">
                                  <img
                                    src={ScanIconYellow}
                                    className="TableIcon hover-scan-document2"
                                    alt="Img"
                                  />
                                </a>
                              </td>
                            ):
                            item?.send_scan_request == 2 && item?.send_scan_request_status == 1 ? (
                              <td style={{zIndex:'0 !important'}}>
                                <a class="icon hover-scan-document2">
                                  <img
                                    src={ScanIconYellow}
                                    className="TableIcon hover-scan-document2"
                                    alt="Img"
                                  />
                                </a>
                              </td>
                            ):
                            item?.send_scan_request == 2 && item?.send_scan_request_status == 2 ? (
                              <td>
                                <a class="icon hover-scan-document3">
                                  <img
                                    src={ScanGreenAll}
                                    className="TableIcon hover-scan-document3"
                                    alt="Img"
                                  />
                                </a>
                              </td>
                            ):null}

                            {/* Send Me */}
                            {item?.send_me_request == 0 && item?.send_me_request_status == 0 ? (
                              <td>
                              <a class="icon hover-scan-document2" onMouseEnter={()=>setShowScanPopUp(false)}>
                                <img
                                  src={SendRed}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                              {!showScanPopUp && (
                                <div class="show-scan-document-over2">
                                <div class="show-scan-document2">
                                  <h3>
                                    {/* Do you want this item to be send you immediately? */}
                                    {contentDetails?.after_login?.DoYouWantThisItemToBeSentToYouImmediately}
                                  </h3>
                                  <button type="button" class="btn btn-secondary" onClick={()=>setShowScanPopUp(true)}>
                                    {/* No */}
                                    {contentDetails?.after_login?.No}
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    // onClick={addressshow}
                                    // onClick={()=>addressshow}
                                    // onClick={()=>{addresssetShow(true);setReqId(item?.id)}}
                                    onClick={()=>handleSendMeInfo(item?.id)}
                                    // onClick={addresssetShow}
                                  >
                                    {/* Yes */}
                                    {contentDetails?.after_login?.Yes}
                                  </button>
                                </div>
                                </div>
                              )}
                            </td>
                            ):
                            item?.send_me_request == 1 && item?.send_me_request_status == 0 ? (
                              <td>
                                <a class="icon hover-scan-document2">
                                  <img
                                    src={SendYellow}
                                    className="TableIcon  hover-scan-document2"
                                    alt="Img"
                                  />
                                </a>
                              </td>
                            ):
                            item?.send_me_request == 1 && item?.send_me_request_status == 2 ? (
                              <td>
                              <a class="icon hover-scan-document2">
                                <img
                                  src={SendYellow}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                            </td>
                            ):
                            item?.send_me_request == 1 && item?.send_me_request_status == 3 ? (
                              <td>
                              <a class="icon hover-scan-document2">
                                <img
                                  src={SendYellow}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                            </td>
                            ):
                            item?.send_me_request == 2 && item?.send_me_request_status == 1 ? (
                              <td>
                              <a class="icon hover-scan-document2">
                                <img
                                  src={SendYellow}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                            </td>
                            ):
                            item?.send_me_request == 2 && item?.send_me_request_status == 2 ? (
                              <td>
                              <a class="icon hover-scan-document2">
                                <img
                                  src={SendYellow}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                            </td>
                            ):
                            item?.send_me_request == 2 && item?.send_me_request_status == 3 ? (
                              <td>
                              <a class="icon hover-scan-document2">
                                <img
                                  src={SendYellow}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                            </td>
                            ):
                            item?.send_me_request == 2 && item?.send_me_request_status == 4 ? (
                              <td>
                              <a class="icon hover-scan-document2">
                                <img
                                  src={ScanGreenAll}
                                  className="TableIcon  hover-scan-document2"
                                  alt="Img"
                                />
                              </a>
                            </td>
                            ):null}
                              
                              <td>
                                {" "}
                                <img src={Collect} className="TableIcon" alt="Img" onClick={()=>handleDownload(item?.id)}/>
                              </td>
                              <td>
                                <Form.Select aria-label="Default select example" onChange={(e)=>updateCategoryById(item?.id,e.target.value)}>
                                 {categories && categories.map((cat_item,cat_index)=>{
                                  return(
                                    <option value={cat_item?.id} selected={cat_item?.id === item?.mail_category?.id ? true : false}>{cat_item?.name}</option>
                                  )
                                 })}
                                </Form.Select>
                              </td>
                              <td>
                                <a class="action_link"></a>
                                <div class="action-hover">
                                  {" "}
                                  {/* <a class="view-link" onClick={()=>navigate('/MailView',{state: {id:item?.id}})}>View</a>{" "} */}
                                  <a class="view-link" onClick={()=>navigate('/MailView',{state: {id:item?.id}})}>{contentDetails?.after_login?.View}</a>{" "}
                                  <a class="delete-link" onClick={()=>{delhandleShow();setDeleteRecodId(item?.id)}}>
                                  {/* <a class="delete-link" onClick={()=>delhandleShow();MoveToTrashed(item?.id)}> */}
                                    {/* Delete */}
                                    {contentDetails?.after_login?.Delete}
                                  </a>{" "}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="11"></td>
                            </tr>
                          </>
                        )
                      })
                    ):(
                      <tr className="odd_tr">
                        {/* <td colSpan={11} style={{textAlign:'center'}}>No Mails Found</td> */}
                        <td colSpan={11} style={{textAlign:'center'}}>{languageJson?.no_mails_found}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                {allNewMailArr?.length > 0 ? (
                  <div className="TablePagination">
                      {/* Pagination controls start */}
                      <Pagination>
                        {currentPage >= 1 && (
                          <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                          />
                        )}

                        {/* {getPageNumbers().map((pageNumber, index) => (
                          <React.Fragment key={index}>
                            {pageNumber === <Pagination.Ellipsis /> ? (
                              <Pagination.Ellipsis />
                            ) : (
                              <Pagination.Item
                                active={currentPage === pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </Pagination.Item>
                            )}
                          </React.Fragment>
                        ))} */}

                          {getPageNumbers().map((pageNumber, index) => (
                            <React.Fragment key={index}>
                              {pageNumber.key === 'ellipsis' ? (
                                <Pagination.Ellipsis key={index} />
                              ) : (
                                <Pagination.Item
                                  key={index}
                                  active={currentPage === pageNumber}
                                  onClick={() => handlePageChange(pageNumber)}
                                >
                                  {pageNumber}
                                </Pagination.Item>
                              )}
                            </React.Fragment>
                          ))}


                        {currentPage <= totalPages && (
                          <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                      </Pagination>


                    {/* Pagination controls end */}

                  </div>
                ):null}
              </div>
            </div>
          </Col>
          <Col sm={1}>
            {clickableId!= undefined ?
              <Mailboxright recordId={clickableId} envelopDoc ={envFile} scanDoc = {scanDocFile}/> : ''
            }
          </Col>
        {/* </Row> */}
      {/* </div> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Please pay for the scan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead>
              <tr>
                <th>Current Balance:</th>
                <th>Charges for scan:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$573.69</td>
                <td>$2.00</td>
              </tr>
            </tbody>
          </Table>

          <h3>
            If you want to scan the document then <span>$2.00</span> will be
            deducted from your wallet.
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="lftBtn">
            Cancel
          </Button>
          <Button variant="primary" className="rgtBtn">
            Scan Now
          </Button>
        </Modal.Footer>
      </Modal>

      {actionType === 'scanType' ?(
        <Modal
          show={payshow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Please pay for Scan</Modal.Title> */}
            <Modal.Title>{contentDetails?.after_login?.PleasePayForScan}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  {/* <th>Current Balance:</th>
                  <th>Charges for scan:</th> */}
                  <th>{contentDetails?.after_login?.CurrentBalance}</th>
                  <th>{contentDetails?.after_login?.ChargesForScan}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>€ {(walletDetails?.wallet_balance === undefined || walletDetails?.wallet_balance === null) ? '0.00' : parseFloat(walletDetails?.wallet_balance).toFixed(2)}</td>
                  {/* <td>€ {(planData?.envelope_scanning === undefined || planData?.envelope_scanning === null) ? '0.00' : parseFloat(planData?.envelope_scanning).toFixed(2)}</td> */}
                  <td>€ {(planData?.item_content_scanning === undefined || planData?.item_content_scanning === null) ? '0.00' : parseFloat(planData?.item_content_scanning).toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          {(planData?.item_content_scanning!= undefined || planData?.item_content_scanning!= null || planData?.item_content_scanning!= '') ? (
            <Modal.Footer>
            <Button
              variant="secondary"
              onClick={payhandleClose}
              className="lftBtn"
            >
              {/* No */}
              {contentDetails?.after_login?.No}
            </Button>
            {isChecked ?(
              <Button variant="primary" className="rgtBtn"
                onClick={() => {
                  submitScanPrice('Normal')
                  payhandleClose();
                }}
              >
                {/* Yes */}
                {contentDetails?.after_login?.Yes}
              </Button>
            ):(
              <Button variant="primary" className="rgtBtn"
                onClick={() => {
                  // addresshandleShow();
                  submitScanPrice('normal')
                  payhandleClose();
                }}
              >
                {/* Yes */}
                {contentDetails?.after_login?.Yes}
              </Button>
            )}
            </Modal.Footer>
          ):(
            <Modal.Footer>
            <Button
              variant="secondary"
              onClick={payhandleClose}
              className="lftBtn"
            >
              {/* No */}
              {contentDetails?.after_login?.No}
            </Button>
            {isChecked ?(
              // <Button variant="primary" className="rgtBtn" disabled>Yes</Button>
              <Button variant="primary" className="rgtBtn" disabled>{contentDetails?.after_login?.Yes}</Button>
            ):(
              // <Button variant="primary" className="rgtBtn" disabled>Yes</Button>
              <Button variant="primary" className="rgtBtn" disabled>{contentDetails?.after_login?.Yes}</Button>
            )}
            </Modal.Footer>
          )}
        </Modal>
      ):
      actionType === 'sendMeType' ?(
        <Modal
          show={payshow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal"
        >
        <Modal.Header closeButton>
          {/* <Modal.Title>Please pay for Send</Modal.Title> */}
          <Modal.Title>{contentDetails?.after_login?.please_pay_for_send}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead>
              <tr>
                {/* <th>Current Balance:</th> */}
                {/* <th>Charges for Send:</th> */}
                <th>{contentDetails?.after_login?.CurrentBalance}</th>
                <th>{contentDetails?.after_login?.charges_for_send}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>€ {(walletDetails?.wallet_balance === undefined || walletDetails?.wallet_balance === null) ? '0.00' : parseFloat(walletDetails?.wallet_balance).toFixed(2)}</td>
                {/* <td>€ {(planData?.envelope_scanning === undefined || planData?.envelope_scanning === null) ? '0.00' : parseFloat(planData?.envelope_scanning).toFixed(2)}</td> */}
                <td>€ 1.00</td>
              </tr>
            </tbody>
          </Table>
          
            {/* <Form.Check
              aria-label="option 1"
              id="flexCheckChecked2"
              type="radio"
              name="option"
              onChange={()=>clickableData(item?.id)}
              checked={clickableId === item?.id}
            /> */}

          <div class="form-check" style={{marginLeft:'-401px',marginBottom: '11px'}}> 
            <input aria-label="option 1" name="option" type="checkbox" id="flexCheckChecked2" class="form-check-input" onChange={()=>setIsChecked(!isChecked)}/>
          </div>
          <h3 style={{marginTop: '-32px'}}>
            {/* If you want to items to be sent to you immediately? then{" "} */}
            {contentDetails?.after_login?.charges_for_send_desc}{" "}
            {/* <span>€ {(planData?.special_requests === undefined || planData?.special_requests === null) ? '0.00' : parseFloat(planData?.special_requests).toFixed(2)}</span> will be deducted from your wallet. */}
            <span> {(planData?.special_requests === undefined || planData?.special_requests === null) ? '0.00' : parseFloat(planData?.special_requests).toFixed(2)} € </span> {contentDetails?.after_login?.will_be_deducted_from_your_wallet}
          </h3>
        </Modal.Body>
        
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>{payhandleClose();addresssetShow(true)}}
            className="lftBtn"
          >
            {/* No */}
            {contentDetails?.after_login?.No}
          </Button>
          
          {isChecked ?(
            <Button variant="primary" className="rgtBtn"
              onClick={() => {
                // addresshandleShow();
                submitSendMePrice('special');
                payhandleClose();
              }}
            >
              {/* Yes */}
              {contentDetails?.after_login?.Yes}
            </Button>
          ):(
            <Button variant="primary" className="rgtBtn"
              onClick={() => {
                // addresshandleShow();
                submitSendMePrice('normal')
                payhandleClose();
              }}
            >
              {/* Yes */}
              {contentDetails?.after_login?.Yes}
            </Button>
          )}
        </Modal.Footer>
        </Modal>
      ):null}

      <Modal
        show={addressshow}
        onHide={addresshandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* Do you want this items
            <br /> sent to you? */}
            {contentDetails?.after_login?.do_you_want_this_items_sent_to_you}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Please select an Address where can sent to this item.</h4> */}
          <h4>{contentDetails?.after_login?.please_select_an_address_where_can_sent_to_this_item}</h4>
          <div className="radioGrp">
            <div className="form-check checkFrm">
              <Form.Check
                id="billingaddress"
                type="radio"
                name="address"
                checked={selectedValue === 1 ? true : false}
                onClick={() => setSelectedValue(1)}
              />
              <Form.Label className="form-check-label" for="billingaddress">
                {/* Same as your billing
                <br /> address */}
                {contentDetails?.after_login?.same_as_your_billing_address}
              </Form.Label>
            </div>

            <div className="form-check checkFrm">
              <Form.Check
                id="newaddress"
                type="radio"
                name="address"
                checked={selectedValue === 2 ? true : false}
                onClick={() => setSelectedValue(2)}
              />
              <Form.Label className="form-check-label" for="newaddress">
                {/* New Address */}
                {contentDetails?.after_login?.new_address}
              </Form.Label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selectedValue === 1 ? (
            <Button
              variant="primary"
              className="rgtBtn"
              // onClick={() => handleSendMeReq()}
              // onClick={()=> handleSendMeInfo()}paysetShow(true);
              onClick={()=>{setActiontype('sendMeType');addresssetShow(true);paysetShow(true);reqhandleClose()}}
            >
              {/* Procced */}
              {contentDetails?.after_login?.proceed}
            </Button>
          ):(
            <Button 
              variant="primary" 
              className="rgtBtn" 
              // onClick={()=>navigate('/NewAddress')}>Procced
              // onClick={()=>navigate('/NewAddress', { state: { id: reqId}})}>Procced
              onClick={()=>{addresshandleClose();setNewAddressModal(true);setSelectedNewAddress('2')}}>
                {/* Procced */}
                {contentDetails?.after_login?.proceed}
              {/* onClick={()=>{paysetShow(true);addresshandleClose()}}>Procced */}
            </Button>
          )}
          <Button variant="secondary" onClick={reqhandleClose} className="lftBtn">
            {/* Cancel */}
            {contentDetails?.after_login?.cancel}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={thankshow}
        onHide={thankhandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal CustomModalTnk"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={TickBlue} className="TickBlueLogo" alt="logo" />
          {/* <h5>Thank You for requesting</h5> */}
          <h5>{languageJson?.thanks_msg}</h5>
          {/* <h2>Your order is ready for shipment.</h2> */}
          <h2>{languageJson?.we_are_processing}</h2>
          {/* <h2>We are processing your shipment.</h2> */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            onClick={thankhandleClose}
            className="lftBtn"
          >
            Check Status
          </Button> */}
          <Button variant="primary" className="rgtBtn" onClick={()=>functionMailBox()}>
          {/* Go to Overview */}
          {languageJson?.go_to_overview}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={delshow}
        onHide={delhandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal delCustomModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Delete</Modal.Title> */}
          <Modal.Title>{contentDetails?.after_login?.Delete}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            {/* Do you want this item/mail to be deleted? It will be automatically
            move to the trash for 30 day. */}
            {contentDetails?.after_login?.DoYouWantThisItemEmailToBeDeletedItWillBeAutomaticallyMovedToTheTrashFor30Days}
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={delhandleClose}
            className="lftBtn"
          >
            {/* No */}
            {contentDetails?.after_login?.No}
          </Button>
          <Button variant="primary" className="rgtBtn" onClick={()=>MoveToTrashed()}>
            {/* Yes */}
            {contentDetails?.after_login?.Yes}

          </Button>
        </Modal.Footer>
      </Modal>

      {/* New address modal */}
      <Modal
        show={newAddressModal}
        onHide={newAddressClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal delCustomModal newAddressModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Sending address</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <h3>We will send your item’s to this address as your requested</h3> */}
          {selectedNewAddress === '2' && (
            <NewAddress setNewAddressData = {setNewAddressData} paysetShow = {paysetShow} id={reqId} modalClose={newAddressClose}/>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            onClick={newAddressClose}
            className="lftBtn"
          >
            No
          </Button>
          <Button variant="primary" className="rgtBtn" onClick={()=>MoveToTrashed()}>
            Yes
          </Button> */}
        </Modal.Footer>
      </Modal>
    {/* </div> */}
    </>
  );
};

export default AllMail;
