import React, { useEffect, useState } from "react";
import logo from "../../assets/images/LogoW.svg";
import newWhitelogo from "../../assets/images/WhiteLogo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.scss";
import mastercard from "../../assets/images/Mastercard.svg";
import visa from "../../assets/images/Visa.svg";
import amex from "../../assets/images/Amex.svg";
import payPal from "../../assets/images/PayPal.svg";
import badge from "../../assets/images/Badge.svg";
import badge2 from "../../assets/images/Badge2.svg";
import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram } from "react-icons/fa"; 
import { footerPageData, getPageContent } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import { useLangContext } from "../../context/languageContext";

const Footer = () => {

  const navigate = useNavigate();
  const {hostLanguage} = useLangContext();
  const [currentYear] = useState(new Date().getFullYear()); 
  const [data,setData] = useState([]);
  
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const [isChecked, setIsChecked] = useState(false);
  const [contentDetails,setContentDetails] = useState();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [tokenexist, setTokenexist] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
	
	const [hide, setHide] = useState(false);
	const hideInstaAlert = () => { 
		    setHide(true);
        Cookies.set("showCookieNotify", "set", {
            expires: 30,
          });

        if(isChecked){
          Cookies.set("allowCookieTranslate", "set", {
            expires: 1,
          });
        }   
        
          
	}

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
   
  const GetCookie = () => {
      //alert(Cookies.get("token"));
      setTokenexist(Cookies.get("showCookieNotify"));
  };

  const fetchData = async () =>{
    try
    {
      let apiRes = await footerPageData(hostLanguage);
      let responseData = await apiRes.json();

      if(responseData.res)
      {
        setData(responseData.data);
        console.log(responseData.data.leftFooterData[0].description1);
        console.log(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const appLink = (type) =>{
    if(type == 'android')
    {
       window.open('https://play.google.com/store/apps/details?id=com.cleverdomizilmail')
    }
    else if(type == 'apple')
    {
      window.open('https://apps.apple.com/in/app/cleverdomicile/id6502281781')
    }
  }

  useEffect(()=>{
    GetCookie();
    getAllPageContent();
    fetchData();
  },[hostLanguage]);

  return (
    <div className="MainFooter">
      <Container className="FooterContainer">
        <Row>
          <Col sm={3}>
            <div className="MainFooterInfo">
              {/* <img src={logo} className="MainFooterLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/> */}
              <img src={newWhitelogo} className="MainFooterLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/>

              {/* <h3>Support</h3> */}
              <div dangerouslySetInnerHTML={{ __html: data?.rightFooterData?.[0]?.description1}} ></div> 
              {/* <p>
                Currently we only offer support in English and German and from
                Monday to Friday, 9am – 6pm
              </p> */}
              <ul>
                <li>
                  <a href="https://www.facebook.com/people/CleverDomizil/61558857249140/" target="_blank" style={{color: '#fff'}}>
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/cleverdomizil/?igsh=M2dvNnQzbjdibWh0&utm_source=qr" target="_blank" style={{color: '#fff'}}>
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/+491773613619" target="_blank" style={{color: '#fff'}}>
                    <FaTelegram />
                  </a>
                </li>
                <li>
                  <FaLinkedin />
                </li>
              </ul>
              
              {/* <p>1566 Bobby Grove, South Kimberlie, MD 77975-3235</p> */}
            </div>
          </Col>
          <Col sm={6}>
            <div className="MainFooterLink">
              {/* <h3>Information</h3> */}
              <h3>{contentDetails?.footer?.footer_heading}</h3>
              <ul>
                <li>
                  {/* <Link to='/product'>Products</Link> */}
                  {/* <Link to='/product'>Produkte</Link> */}
                  <Link to='/product'>{contentDetails?.footer?.parent_0}</Link>
                </li>
                <li>
                  {/* <Link to='/LocationsWithoutMap'>Locations</Link> */}
                  {/* <Link to='/LocationsWithoutMap'>Standorte</Link> */}
                  <Link to='/LocationsWithoutMap'>{contentDetails?.footer?.parent_1}</Link>
                </li>
                <li>
                  {/* <Link to='/PricingMailbox'>Preise</Link> */}
                  <Link to='/PricingMailbox'>{contentDetails?.footer?.parent_2}</Link>
                </li>
                <li>
                  {/* <Link to="/about-us">About us</Link> */}
                  {/* <Link to="/about-us">Über uns</Link> */}
                  <Link to="/about-us">{contentDetails?.footer?.parent_3}</Link>
                </li>
                
                <li>
                  {/* <Link to="/contact-us">Kontaktformular</Link> */}
                  <Link to="/contact-us">{contentDetails?.footer?.parent_4}</Link>
                </li>
                
                <li>
                  {/* <Link to="/terms">AGB</Link> */}
                  <Link to="/terms">{contentDetails?.footer?.parent_5}</Link>
                </li>
                <li>
                  {/* <Link to="/privacy">Datenschutzerklärung</Link> */}
                  <Link to="/privacy">{contentDetails?.footer?.parent_6}</Link>
                </li>
                <li>
                  {/* <Link to="/imprint">Imprint</Link> */}
                  {/* <Link to="/imprint">Impressum</Link> */}
                  <Link to="/imprint">{contentDetails?.footer?.parent_7}</Link>
                </li>
              </ul>
            </div>
            <div className="MainFooterLinkcard">
              {/* <h3>Accepted Payments</h3> */}
              <ul>
                <li>
                  <img src={mastercard} className="MainLogo" alt="logo" />
                </li>
                <li>
                  <img src={visa} className="MainLogo" alt="logo" />
                </li>
                <li>
                  <img src={amex} className="MainLogo" alt="logo" />
                </li>
                <li>
                  <img src={payPal} className="MainLogo" alt="logo" />
                </li>
              </ul>
            </div>
            <div className="MainFooterLinkcard2">
              {/* <h3>Applications</h3> */}
              <ul>
                <li>
                  <img src={badge} className="MainLogo" alt="logo" onClick={()=>appLink('android')} style={{cursor:'pointer'}}/>
                </li>
                <li>
                  <img src={badge2} className="MainLogo" alt="logo" onClick={()=>appLink('apple')} style={{cursor:'pointer'}}/>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={3}>
            <div className="MainFooterInfo">
            <h2>CleverDomizil</h2>
              <div dangerouslySetInnerHTML={{ __html: data?.leftFooterData?.[0]?.description1}} ></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <p style={{ textAlign:'center', color:'#fff', margin:'20px', marginBottom:'0', fontSize:'14px'}}>
              {/* Alle angegebenen Preise sind Nettopreise und verstehen sich exklusive der Mehrwertsteuer. Die Mehrwertsteuer wird bei Bedarf zusätzlich berechnet und der Rechnung hinzugefügt */}
              {/* Alle angegebenen Preise sind Bruttopreise und verstehen sich inklusive der gesetzlichen Mehrwertsteuer. Die Mehrwertsteuer wird zusätzlich berechnet und der Rechnung hinzugefügt. */}
              {contentDetails?.footer?.footer_text}
            </p>
            <div className="MainFooterBottom">
              <p>Copyright &copy; {currentYear} CleverDomizil</p>
            </div>
          </Col>
        </Row>
      </Container>





      {/* Modal of Accept all cookies */}

      {hide ? (
			<></>
		):(
        tokenexist ? (
        <></>
        ):(

          <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          className="CookiesModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {/* Privacy settings for your DATEV experience */}
              Datenschutzeinstellungen
              {/* Datenschutzeinstellungen für Ihr DATEV-Erlebnis */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {/* We use cookies and other tracking technologies that can retrieve
              or store information on your terminal device. If you give us your
              consent, we process your personal data for analyses and
              optimizations as well as for marketing and advertising purposes.
              For this purpose, information is sometimes passed on to service
              providers that may be located in a third country and do not have a
              comparable level of data protection. By clicking on "Accept all
              cookies", you consent to this processing and the transfer of your
              data. You can revoke your consent at any time in the cookie
              settings in the footer. Click on the cookie settings to learn
              more, you can find more information in our Privacy noticeImprint */}

              {/* Wir verwenden Cookies und andere Tracking-Technologien, die Daten abrufen können
              oder Informationen auf Ihrem Endgerät speichern. Wenn Sie uns Ihre geben
              Mit Ihrer Einwilligung verarbeiten wir Ihre personenbezogenen Daten für Analysen und
              Optimierungen sowie für Marketing- und Werbezwecke.
              Zu diesem Zweck werden teilweise Informationen an den Service weitergegeben
              Anbieter, die möglicherweise in einem Drittland ansässig sind und nicht über eine
              vergleichbares Datenschutzniveau. Durch Klicken auf „Alle akzeptieren“.
              Cookies“, stimmen Sie dieser Verarbeitung und der Übertragung Ihrer Daten zu
              Daten. Sie können Ihre Einwilligung jederzeit im Cookie widerrufen
              Einstellungen in der Fußzeile. Klicken Sie auf die Cookie-Einstellungen, um mehr zu erfahren
              Weitere Informationen finden Sie in unserer DatenschutzerklärungImpressum */}

              Wir verwenden Cookies und andere Tracking-Technologien, die Daten auslesen oder Informationen auf Ihrem Endgerät speichern können. Mit Ihrer Einwilligung verarbeiten wir Ihre personenbezogenen Daten zu Analyse- und Optimierungszwecken sowie zu Marketing- und Werbezwecken. Dazu werden teilweise Informationen an Dienstleister weitergegeben, die ihren Sitz in einem Drittland haben können, in dem kein vergleichbares Datenschutzniveau herrscht. Indem Sie auf "Alle akzeptieren. Cookies“ erklären Sie sich mit dieser Verarbeitung und Übermittlung Ihrer Daten einverstanden. Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen in der Fußzeile widerrufen. Klicken Sie auf die Cookie-Einstellungen, um mehr zu erfahren Weitere Informationen finden Sie in unserer DatenschutzerklärungImpressum

            </p>
            <div className="newDiv">
              <div className="newDivTop">
                <label>
                  <input
                    type="checkbox"
                    checked={true}
                    disabled
                    //onChange={handleCheckboxChange}
                  />
                  {/* Necessary cookies */}
                  Notwendige Cookies
                </label>

                <table>
                  <tr>
                    <th>Cookie</th>
                    <th>Ablaufdatum</th>
                  </tr>
                  <tr>
                    <td>PHPSESSID</td>
                    <td>
                      {/* When ending the browser session */}
                      Beim Beenden der Browsersitzung
                      </td>
                  </tr>
                  <tr>
                    <td>ci_session</td>
                    <td>
                       {/* After 2 hours */}
                       Nach 2 Stunden
                      </td>
                  </tr>
                  <tr>
                    <td>showCookieNotify</td>
                    <td>
                      {/* After 30 days */}
                      Nach 30 Tagen
                      </td>
                  </tr>
                  <tr>
                    <td>allowCookieTranslate</td>
                    <td>
                      {/* After 30 days */}
                      Nach 30 Tagen
                      </td>
                  </tr>
                </table>
              </div>
              <div className="newDivTop">
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  {/* Google Translate */}
                  Google Übersetzer
                </label>

                <table>
                  <tr>
                    <th>Cookie</th>
                    <th>Ablaufdatum</th>
                  </tr>
                  <tr>
                    <td>NID</td>
                    <td>
                      {/* After 1 day */}
                      Nach 1 Tag
                      </td>
                  </tr>
                  <tr>
                    <td>__Secure-3PAPISID</td>
                    <td>
                      {/* After 1 day */}
                      Nach 1 Tag
                      </td>
                  </tr>
                  <tr>
                    <td>__Secure-3PSID</td>
                    <td>{/* After 1 day */}
                      Nach 1 Tag</td>
                  </tr>
                  <tr>
                    <td>__Secure-3PSIDCC</td>
                    <td>{/* After 1 day */}
                      Nach 1 Tag</td>
                  </tr>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link to="/privacy" className="MOsaveBtn2">
              {/* More Information */}
              Mehr Informationen
            </Link>
            {/* <Button
              variant="primary"
              onClick={handleClose}
              className="MOsaveBtn2"
            >
              Decline
            </Button> */}

            <Button
              variant="primary"
              onClick={() => hideInstaAlert()}
              className="MOsaveBtn"
            >
              {/* Accept selection */}
              Auswahl akzeptieren
            </Button>
          </Modal.Footer>
        </Modal>

        )
      )}
      
      {/* {hide ? (
			<></>
			):(
        tokenexist ? (
        <></>
        ):(
          <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          className="CookiesModal"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy settings for your DATEV experience
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              We use cookies and other tracking technologies that can retrieve or
              store information on your terminal device. If you give us your
              consent, we process your personal data for analyses and
              optimizations as well as for marketing and advertising purposes. For
              this purpose, information is sometimes passed on to service
              providers that may be located in a third country and do not have a
              comparable level of data protection. By clicking on "Accept all
              cookies", you consent to this processing and the transfer of your
              data. You can revoke your consent at any time in the cookie settings
              in the footer. Click on the cookie settings to learn more, you can
              find more information in our Privacy noticeImprint
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleClose}
              className="MOsaveBtn2"
              
            >
              Decline
            </Button>

            <Button variant="primary" onClick={()=>hideInstaAlert()} className="MOsaveBtn">
              Accept All Cookies
            </Button>
          </Modal.Footer>
          </Modal> 
    )
    )} */}



    </div>



    





  );
};

export default Footer;
