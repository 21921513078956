import { Routes, Route } from "react-router-dom";
import "./App.css";
import 'react-notifications/lib/notifications.css';
import Register from "./pages/registration/Registration";
import LetStarted from "./pages/let-started/LetStarted";
import SelectProduct from "./pages/select-product/SelectProduct";
import "bootstrap/dist/css/bootstrap.min.css";
import SelectCountryPhoneNumber from "./pages/selectcountry-phonenumber/SelectCountryPhoneNumber";
import YourDetails from "./pages/your-details/YourDetails";
import Payment from "./pages/payment/Payment";
import MailBox from "./pages/mail-box/MailBox";
import Dashboard from "./pages/dashboard/Dashboard";
import NewAddress from "./pages/new-address/NewAddress";
import EnvelopScan from "./pages/envelop-scan/EnvelopScan";
import Scans from "./pages/scans/Scans";
import MyRequest from "./pages/my-request/MyRequest";
import Trashed from "./pages/trashed/Trashed";
import AccountSettings from "./pages/account-settings/AccountSettings";
import CurrentPlan from "./pages/current-plan/CurrentPlan";
import PlanUpgrade from "./pages/plan-upgrade/PlanUpgrade";
import ScanPayment from "./pages/scan-payment/ScanPayment";
import MailView from './pages/mail-view/MailView';
import Login from "./pages/login/Login";
import StuffDashboard from "./pages/stuff-dashboard/StuffDashboard";
import DocumentOnboarding from "./pages/document-onboarding/DocumentOnboarding";
import Postbox from "./pages/postbox/Postbox";
import Customer from "./pages/customer/Customer";
import AllMail from "./pages/all-mail/AllMail";
import InvoicesPayments from "./pages/invoices-payments/InvoicesPayments";
import {NotificationContainer} from 'react-notifications';
import Test from "./test";
import Auth from "./protectedRouter/Auth";
import NotLoginAuth from "./protectedRouter/NotLoginAuth";
import CommonLayout from "./layout/commonLayout";
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import TaxService from "./pages/service-page/TaxService";
import IncorporationService from "./pages/service-page/IncorporationService";
import StudentPortal from "./pages/service-page/StudentPortal";
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import ResetPassword from './pages/reset-password/ResetPassword';


import Product from "./pages/product/Product";
import Blog from "./pages/blog/Blog";
import BlogDeatils from './pages/blog-deatils/BlogDeatils';
import Home from "./pages/homePage/Home";
import ProductNumber from "./pages/product-number/ProductNumber";
import PricingMailbox from "./pages/pricing-mailbox/PricingMailbox";
import LocationsWithoutMap from "./pages/locations-without-map/LocationsWithoutMap";
import Error from "./pages/404/Error";
import Imprint from "./pages/imprint-page/Imprint";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import PayPalComponent from "./pages/PaymentPages/PayPalComponent";
import NewMailView from "./pages/mail-view/NewMailView";
import Conditions from "./pages/accept-condition/Conditions";
import { useEffect } from "react";
import { useLangContext } from "./context/languageContext";
import PurchasedPhoneNumber from "./pages/purchased-phone/PurchasedPhoneNumber";
import RenewPayment from "./pages/renew-payment/RenewPayment";
import AccountSetUp from "./pages/student/AccountSetUp";
import AccountDetails from "./pages/student/AccountDetails";
import StudentDashboard from "./pages/student/StudentDashboard";
import StudentProfile from "./pages/student/StudentProfile";



function App() {
  const {setHostLanguage} = useLangContext();

  useEffect(()=>{
    console.log(window.location.hostname)
    if((window.location.hostname.endsWith('.de')) || window.location.hostname.endsWith('.ch'))
    {
      setHostLanguage('de');
    }
    else if(window.location.hostname.endsWith('.com'))
    {
      setHostLanguage('en');
    }
  },[])

  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/product" element={<Product/>} />
        <Route path="/ProductNumber" element={<ProductNumber/>} />
        <Route path="/Blog" element={<Blog/>} />
        <Route path ='/BlogDeatils' element = {<BlogDeatils/>} />
        <Route path="/PricingMailbox" element={<PricingMailbox />} />
        <Route path="/LocationsWithoutMap" element={<LocationsWithoutMap />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/imprint" element={<Imprint/>} />
        <Route path="/tax-service" element={<TaxService/>} />
        <Route path="/incorporation-service" element={<IncorporationService/>} />
        <Route path="/student-portal" element={<StudentPortal/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/cond-test" element={<Conditions />} />


        <Route element={<NotLoginAuth/>}>
          <Route path="/register" element={<Register />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route exact path='/reset-password/:id' element={<ResetPassword />} />
        </Route>

        {/* <Route element={<Auth/>}> */}
        <Route element={<Auth allowedRoles={['client']}/>}>
          <Route path="/LetStarted" element={<LetStarted />} />
          <Route path="/SelectProduct" element={<SelectProduct />} />
          <Route path="/YourDetails" element={<YourDetails />} />
          <Route path="/SelectCountryPhoneNumber" element={<SelectCountryPhoneNumber />}/>
          <Route path="/Payment" element={<Payment />} />

          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/NewAddress" element={<NewAddress />} />
          <Route path="/AccountSettings" element={<AccountSettings />} />
          <Route path="/CurrentPlan" element={<CurrentPlan/>} />
          <Route path="/PlanUpgrade" element={<PlanUpgrade />} />
          <Route path="/MailView" element={<MailView />} />
          <Route path="/NewMailView" element={<NewMailView/>} />
          <Route path='/scan-payment' element={<ScanPayment/>} />
          <Route path='/renew-payment' element={<RenewPayment/>} />

          <Route element={<CommonLayout/>}>
            <Route path="/AllMail" element={<AllMail />} />
            <Route path="/MailBox" element={<MailBox />} />
            <Route path="/EnvelopScan" element={<EnvelopScan />} />
            <Route path="/Scans" element={<Scans />} />
            <Route path="/MyRequest" element={<MyRequest />} />
            <Route path="/Trashed" element={<Trashed />} />
            <Route path="/InvoicesPayments" element={<InvoicesPayments />} />
            <Route path="/purchased-phone-number" element={<PurchasedPhoneNumber />} />
          </Route>
        
        </Route>


        <Route element={<Auth allowedRoles={['student']}/>}>
          <Route path="/student-account-set-up" element={<AccountSetUp />} />
          <Route path="/student-account-details" element={<AccountDetails />} />
          <Route path= "/student-dashboard" element={<StudentDashboard />}/>
          <Route path= "/student-profile" element={<StudentProfile />}/>
        </Route>


        <Route path="/paypal" element={<PayPalComponent/>} />
        <Route path="*" element={<Error/>} />

        <Route path="/test" element={<Test/>} />
      
      </Routes>
      <NotificationContainer/>
    </>
  );
}

export default App;
