import React, { useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./PlanUpgrade.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import graphic from "../../assets/images/Graphic.svg";
import mailBox from "../../assets/images/MailBox.svg";
import Button from "react-bootstrap/Button";
import cloud from "../../assets/images/CloudIcon.svg";
import { useNavigate, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  HiOutlinePlus,
  HiCheckCircle,
  HiOutlineCreditCard,
} from "react-icons/hi";

const PlanUpgrade = () => {
  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedValue, setSelectedValue] = useState(1);

  return (
    <div className="lightBlue">
      <InnerHeader />

      <div className="DashboardBox">
        <div className="Titelbox">
          <span>
            <h3>Your Plans</h3>
          </span>
          <span>
            <button
              type="button"
              className="DashboardBtn45"
              onClick={handleShow}
            >
              Renewal Plans
            </button>
          </span>
        </div>

        <div className="TableFiltter TableFiltter2">
          <Row className="align-items-center">
            <Col sm={12}>
              <h5>
                <label>Your selected cleverdomizil services </label>
              </h5>
            </Col>
          </Row>
        </div>

        <div className=" check-divRadio">
          <Row className="align-items-center MainRow3">
            <Col sm={4}>
              <div className="WhiteBox">
                <div className="form-check">
                  <Form.Check type="checkbox" id="flexCheckChecked1" />

                  <Form.Label
                    className="form-check-label"
                    for="flexCheckChecked1"
                  >
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={cloud} className="iconImg" alt="Img" />
                              <h4>ClevverMail in Zurich</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              <p>Price</p>
                              <h5>$ 9.95</h5>
                              <p>/monthly</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                        <ul>
                          <li>
                            <label>Contract Period</label>
                            <span>Monthly 21/08/2023 - 20/09/2023</span>
                          </li>
                          <li>
                            <label>Net price</label> <span>$ 9.95</span>
                          </li>
                          <li>
                            <label>VAT 0 %</label>
                            <span>$ 0.00</span>
                          </li>
                        </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>Postbox LITE</h4>
                        <h6>
                          <em>Next Renewal Date</em>
                          <br />
                          19/09/2023
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                        <h6>
                          {" "}
                          <HiCheckCircle size={20} color="#03AE00" /> Active
                        </h6>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="WhiteBox">
                <div className="form-check">
                  <Form.Check type="checkbox" id="flexCheckChecked2" />

                  <Form.Label
                    className="form-check-label"
                    for="flexCheckChecked2"
                  >
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={cloud} className="iconImg" alt="Img" />
                              <h4>ClevverMail in Zurich</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              <p>Price</p>
                              <h5>$ 9.95</h5>
                              <p>/monthly</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                        <ul>
                          <li>
                            <label>Contract Period</label>
                            <span>Monthly 21/08/2023 - 20/09/2023</span>
                          </li>
                          <li>
                            <label>Net price</label> <span>$ 9.95</span>
                          </li>
                          <li>
                            <label>VAT 0 %</label>
                            <span>$ 0.00</span>
                          </li>
                        </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>Postbox LITE</h4>
                        <h6>
                          <em>Next Renewal Date</em>
                          <br />
                          19/09/2023
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                        <h6>
                          {" "}
                          <HiCheckCircle size={20} color="#03AE00" /> Active
                        </h6>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="WhiteBox">
                <div className="form-check">
                  <Form.Check type="checkbox" id="flexCheckChecked3" />

                  <Form.Label
                    className="form-check-label"
                    for="flexCheckChecked3"
                  >
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={cloud} className="iconImg" alt="Img" />
                              <h4>ClevverMail in Zurich</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              <p>Price</p>
                              <h5>$ 9.95</h5>
                              <p>/monthly</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                        <ul>
                          <li>
                            <label>Contract Period</label>
                            <span>Monthly 21/08/2023 - 20/09/2023</span>
                          </li>
                          <li>
                            <label>Net price</label> <span>$ 9.95</span>
                          </li>
                          <li>
                            <label>VAT 0 %</label>
                            <span>$ 0.00</span>
                          </li>
                        </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>Postbox LITE</h4>
                        <h6>
                          <em>Next Renewal Date</em>
                          <br />
                          19/09/2023
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                        <h6>
                          {" "}
                          <HiCheckCircle size={20} color="#03AE00" /> Active
                        </h6>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center MainRow3">
            <Col sm={4}>
              <div className="WhiteBox">
                <div className="form-check">
                  <Form.Check type="checkbox" id="flexCheckChecked4" />

                  <Form.Label
                    className="form-check-label"
                    for="flexCheckChecked4"
                  >
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={cloud} className="iconImg" alt="Img" />
                              <h4>ClevverMail in Zurich</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              <p>Price</p>
                              <h5>$ 9.95</h5>
                              <p>/monthly</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                        <ul>
                          <li>
                            <label>Contract Period</label>
                            <span>Monthly 21/08/2023 - 20/09/2023</span>
                          </li>
                          <li>
                            <label>Net price</label> <span>$ 9.95</span>
                          </li>
                          <li>
                            <label>VAT 0 %</label>
                            <span>$ 0.00</span>
                          </li>
                        </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>Postbox LITE</h4>
                        <h6>
                          <em>Next Renewal Date</em>
                          <br />
                          19/09/2023
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                        <h6>
                          {" "}
                          <HiCheckCircle size={20} color="#03AE00" /> Active
                        </h6>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="WhiteBox">
                <div className="form-check">
                  <Form.Check type="checkbox" id="flexCheckChecked5" />

                  <Form.Label
                    className="form-check-label"
                    for="flexCheckChecked5"
                  >
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={cloud} className="iconImg" alt="Img" />
                              <h4>ClevverMail in Zurich</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              <p>Price</p>
                              <h5>$ 9.95</h5>
                              <p>/monthly</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                        <ul>
                          <li>
                            <label>Contract Period</label>
                            <span>Monthly 21/08/2023 - 20/09/2023</span>
                          </li>
                          <li>
                            <label>Net price</label> <span>$ 9.95</span>
                          </li>
                          <li>
                            <label>VAT 0 %</label>
                            <span>$ 0.00</span>
                          </li>
                        </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>Postbox LITE</h4>
                        <h6>
                          <em>Next Renewal Date</em>
                          <br />
                          19/09/2023
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                        <h6>
                          {" "}
                          <HiCheckCircle size={20} color="#03AE00" /> Active
                        </h6>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="WhiteBox">
                <div className="form-check">
                  <Form.Check type="checkbox" id="flexCheckChecked7" />

                  <Form.Label
                    className="form-check-label"
                    for="flexCheckChecked7"
                  >
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={cloud} className="iconImg" alt="Img" />
                              <h4>ClevverMail in Zurich</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              <p>Price</p>
                              <h5>$ 9.95</h5>
                              <p>/monthly</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                        <ul>
                          <li>
                            <label>Contract Period</label>
                            <span>Monthly 21/08/2023 - 20/09/2023</span>
                          </li>
                          <li>
                            <label>Net price</label> <span>$ 9.95</span>
                          </li>
                          <li>
                            <label>VAT 0 %</label>
                            <span>$ 0.00</span>
                          </li>
                        </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>Postbox LITE</h4>
                        <h6>
                          <em>Next Renewal Date</em>
                          <br />
                          19/09/2023
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                        <h6>
                          {" "}
                          <HiCheckCircle size={20} color="#03AE00" /> Active
                        </h6>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="CustomModal45"
      >
        <Modal.Header closeButton>
          <div className="ModalHr">
            <div className="ModalHrLft">
              <h4>It’s time for your upgrade!</h4>
              <p>Please upgrade now to get more opportunity</p>
            </div>
            <div className="ModalHrRgt">
              <p>Total Amount in USD</p>
              <h5>$455.73</h5>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead>
              <tr>
                <th>Current Wallet Balance:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>$573.69</td>
              </tr>
            </tbody>
          </Table>

          <h3>
            <span>You want to book by:</span>
          </h3>

          <div className="radioGrp">
            <div className="form-check checkFrm">
              <Form.Check
                id="billingaddress"
                type="radio"
                name="address"
                checked={selectedValue === 1 ? true : false}
                onClick={() => setSelectedValue(1)}
              />
              <Form.Label className="form-check-label" for="billingaddress">
                Wallet
              </Form.Label>
            </div>

            <div className="form-check checkFrm">
              <Form.Check
                id="newaddress"
                type="radio"
                name="address"
                checked={selectedValue === 2 ? true : false}
                onClick={() => setSelectedValue(2)}
              />
              <Form.Label className="form-check-label" for="newaddress">
                Credit card
              </Form.Label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="lftBtn">
            Cancel
          </Button>
          <Button variant="primary" className="rgtBtn">
            Processed to Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlanUpgrade;
