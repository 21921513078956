import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from '@paypal/react-paypal-js';
import { paymentInvoice, submitStepFour, submitTopup, sendScanRequest, sendMeRequest, notPurchasedProductInvoice, sendInvoiceViaMail, submitRenewProductInvoice, updateRenewPackage } from '../../api/apiRequest';
import { NotificationManager } from 'react-notifications';
import { getProductListStep4 } from '../../api/apiRequest';

const PayPalComponent = (props) => {
  console.log(props)
  console.log(props?.scanData)
    const {productInfo,productPrice,type,scanData} = props;
    const [productDetails, setProductDetails] = useState([]);

    const [mailData,setMailData] = useState([]);
    const [phoneData,setPhoneData] = useState([]);

    console.log(props);
    // console.warn(scanData?.dataObj?.company_name)
    console.warn(productInfo);
    console.warn(productPrice);
    console.warn(scanData);
    // console.warn(props?.productDetails?.product_name);
    const navigate = useNavigate();
    
  const paypalOptions = {
    // 'client-id': 'AZOy1H0lqUaJdBbN4UZ0bUSrTQY4yFtR54iX2vidFSOLStmyEwXoB2szisz8uSIfjQC5ErfeUoNGRKzE', // Replace with your actual client ID
    //'client-id': 'AVPlsa_kigpllpcek2cHJEOLrTl7q9BkX0dgVLgIs-TOac7AxdA32Qwwq0uemIQ5ZA9XH4YtrmmWmbgf', // sandbox
    'client-id': 'AWuJAMxWf5AnZIixrep5cS8QPLiaBT7TH3J8r_vAQoKDF4vRaflxgoduxwGD28sum7q9W1nB4CG_SUxI',  // live
    // currency: 'USD',
    currency: 'EUR',
  };

  // const createOrder = (data, actions) => {
  //   return actions.order.create({
  //     purchase_units: [
  //       {
  //         amount: {
  //           value: productPrice!= undefined  ? productPrice : '',
  //         },
  //       },
  //     ],
  //     application_context: {
  //       shipping_preference: 'NO_SHIPPING',
  //     },
  //   }).then((orderID) => {
  //     return orderID;
  //   });
  // };

  //convert date format
  
  const createOrder = (data, actions) => {
    if(type === 'Top-up') 
    {
      const inputPrice = parseFloat(document.getElementById('topup_amount').value) || 0;
      const price = parseFloat(inputPrice) || 0; 
      console.warn(inputPrice);
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: inputPrice,
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      }).then((orderID) => {
        return orderID;
      });
    }
    else
    {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: productPrice,
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      }).then((orderID) => {
        return orderID;
      });
    }
  };
  
  
  
  const convert_date_format = (date) => {
    const date_format = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dateString = date_format.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
    return dateString;
  }

  
  const ProductListStep4 = async() =>
  {
    try
    {
      let apiRes = await getProductListStep4();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        let tempArr = [];
        responseData.data?.map((item,index)=>{
          item.map((item1,index1)=>{
            tempArr.push(item1)
          })
        })
        setProductDetails(tempArr);
        console.warn(responseData.data[0]);
        console.warn(responseData.data[1]);
        let mailArr = [];
        let phoneArr = [];

        responseData.data[0].map((item,index)=>{
          mailArr.push(item);
        })
        responseData.data[1].map((item,index)=>{
          phoneArr.push(item);
        })

        setMailData(mailArr);
        setPhoneData(phoneArr);
        
        console.warn(tempArr.length);
      }
      else
      {
        NotificationManager.error(responseData.msg);
      }
    }
    catch(error)
    {
      console.error(error);
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }


  const paypalPaymentInvoice = async (data) => {
    try
    {
      if(type === 'purchase-product')
      {
        let values = {
          transaction_id: data.purchase_units[0].payments.captures[0].id,
          transaction_type:'Credit Card',
          currency_type: data.purchase_units[0].amount.currency_code,
          // payment_for: type === 'purchase-product' ? productInfo : '' ,
          // amount: data.purchase_units[0].amount.value,
          payer_firstname: data.payer.name.given_name,
          payer_lastname: data.payer.name.surname,
          payer_email: data.payer.email_address,
          payer_country_code: data.payer.address.country_code,
          payment_time: convert_date_format(data.purchase_units[0].payments.captures[0].create_time),
          // payment_type: 1
        }
        // let apiRes = await paymentInvoice(values);
        let apiRes = await notPurchasedProductInvoice(values);
        let responseData = await apiRes.json();
  
        if(responseData.res)
        {
          let apiRes1 = await submitStepFour();
          let responseData1 = await apiRes1.json();
          if(responseData1.res)
          {
            NotificationManager.success(responseData1.msg, '',2000);
            // setShow(false);
            // navigate("/Dashboard");
            navigate('/InvoicesPayments');
           
            let apiRes2 = await sendInvoiceViaMail(data.purchase_units[0].payments.captures[0].id);
            let responseData2 = await apiRes2.json();
            if(responseData2.res)
            {
              console.log('Mail Send.');
            }
            else
            {
              console.log('Mail Not Send.');
            }
          }
          else
          {
            NotificationManager.error(responseData1.msg, '',2000);
          }
          // }
        }
        else
        {
          console.error('Error in purchase-product section');
        }
      }
      else if(type === 'Top-up')
      {
        let values = {
          // transaction_id: data.id
          transaction_id: data.purchase_units[0].payments.captures[0].id,
          transaction_type:'Credit Card',
          currency_type: data.purchase_units[0].amount.currency_code,
          payment_for: type === 'Top-up' ? 'Top up' : '' ,
          amount: data.purchase_units[0].amount.value,
          payer_firstname: data.payer.name.given_name,
          payer_lastname: data.payer.name.surname,
          payer_email: data.payer.email_address,
          payer_country_code: data.payer.address.country_code,
          payment_time: convert_date_format(data.purchase_units[0].payments.captures[0].create_time)
        }
        let apiRes = await paymentInvoice(values);
        let responseData = await apiRes.json();
  
        if(responseData.res)
        {
          let apiRes1 = await submitTopup(data.purchase_units[0].amount.value);
          let responseData1 = await apiRes1.json();
          if(responseData1.res)
          {
            window.close();
            NotificationManager.success(responseData1.msg, '',2000);
            // handleClose();
            // navigate("/Dashboard");
            navigate("/InvoicesPayments");
            let apiRes2 = await sendInvoiceViaMail(data.purchase_units[0].payments.captures[0].id);
            let responseData2 = await apiRes2.json();
            if(responseData2.res)
            {
              console.log('Mail Send.');
            }
            else
            {
              console.log('Mail Not Send.');
            }
          }
          else
          {
            NotificationManager.error(responseData1.msg, '',2000);
          }
        }
        else
        {
          console.error('Error in Top-up section');
        }
      }
      else if(type === 'scan-payment')
      {
        let values = {
          // transaction_id: data.id
          transaction_id: data.purchase_units[0].payments.captures[0].id,
          transaction_type:'Credit Card',
          currency_type: data.purchase_units[0].amount.currency_code,
          payment_for: type === 'scan-payment' ? 'Scan Payment' : '' ,
          amount: data.purchase_units[0].amount.value,
          payer_firstname: data.payer.name.given_name,
          payer_lastname: data.payer.name.surname,
          payer_email: data.payer.email_address,
          payer_country_code: data.payer.address.country_code,
          payment_time: convert_date_format(data.purchase_units[0].payments.captures[0].create_time),
          payment_type: 4
        }
        let apiRes = await paymentInvoice(values);
        let responseData = await apiRes.json();
  
        if(responseData.res)
        {
          let data = {
            id: scanData?.id,
            deduct_wallet_balance: scanData?.deduct_wallet_balance,
            amount: scanData?.amount
          }
          let apiRes1 = await sendScanRequest(data);
          let responseData1 = await apiRes1.json();
          if(responseData1.res)
          {
            NotificationManager.success(responseData1.msg,'',2000);
            // setThanksShow(true);
            navigate('/InvoicesPayments');
            let apiRes2 = await sendInvoiceViaMail(data.purchase_units[0].payments.captures[0].id);
            let responseData2 = await apiRes2.json();
            if(responseData2.res)
            {
              console.log('Mail Send.');
            }
            else
            {
              console.log('Mail Not Send.');
            }
          }
          else
          {
            NotificationManager.error(responseData1.msg, '',2000);
          }
        }
        else
        {
          throw new Error(responseData.msg);
        }
      }
      else if(type === 'sendMe-payment')
      {
        let values = {
          // transaction_id: data.id
          transaction_id: data.purchase_units[0].payments.captures[0].id,
          transaction_type:'Credit Card',
          currency_type: data.purchase_units[0].amount.currency_code,
          payment_for: type === 'sendMe-payment' ? 'Send Me Payment' : '' ,
          amount: data.purchase_units[0].amount.value,
          payer_firstname: data.payer.name.given_name,
          payer_lastname: data.payer.name.surname,
          payer_email: data.payer.email_address,
          payer_country_code: data.payer.address.country_code,
          payment_time: convert_date_format(data.purchase_units[0].payments.captures[0].create_time),
          payment_type: 5
        }
        let apiRes = await paymentInvoice(values);
        let responseData = await apiRes.json();
        // console.warn(props);
        if(responseData.res)
        {
          let data = {};
          console.warn(scanData)
        
          if(scanData?.same_as_billing == '1')
          {
            data = {
              id: scanData?.id,
              deduct_wallet_balance: scanData?.deduct_wallet_balance,
              amount: scanData?.amount,
              same_as_billing: scanData?.same_as_billing
            }
          }
          else if(scanData?.same_as_billing == '0')
          {
            // console.warn(scanData);
            // console.warn(scanData?.dataObj);
            // return
            console.warn(scanData);
            console.warn(scanData?.amount);
            console.warn(scanData?.deduct_wallet_balance);
            console.warn(scanData?.same_as_billing);
            data = {
              id: scanData?.id ? scanData?.id : '',
              deduct_wallet_balance: scanData?.deduct_wallet_balance ? scanData?.deduct_wallet_balance : '',
              amount: scanData?.amount ? scanData?.amount : '',
              same_as_billing: scanData?.same_as_billing ? scanData?.same_as_billing : '',
              company_name: scanData?.dataObj?.company_name ? scanData?.dataObj?.company_name : '',
              title: scanData?.dataObj?.title ? scanData?.dataObj?.title : '',
              first_name: scanData?.dataObj?.first_name ? scanData?.dataObj?.first_name : '',
              last_name: scanData?.dataObj?.last_name ? scanData?.dataObj?.last_name: '',
              address: scanData?.dataObj?.address ? scanData?.dataObj?.address : '',
              country_id: scanData?.dataObj?.country_id ? scanData?.dataObj?.country_id : '',
              city_id: scanData?.dataObj?.city_id ? scanData?.dataObj?.city_id: '',
              region_id: scanData?.dataObj?.region_id ? scanData?.dataObj?.region_id : '',
              postal_code: scanData?.dataObj?.postal_code ?  scanData?.dataObj?.postal_code : '',
              area_code: scanData?.dataObj?.area_code ? scanData?.dataObj?.area_code : '',
              phone_number: scanData?.dataObj?.phone_number ? scanData?.dataObj?.phone_number : ''
            }
          }
          
          console.warn(data);
          let apiRes1 = await sendMeRequest(data);
          let responseData1 = await apiRes1.json();
          if(responseData1.res)
          {
            NotificationManager.success(responseData1.msg,'',2000);
            // setThanksShow(true);
            navigate('/InvoicesPayments');
            let apiRes2 = await sendInvoiceViaMail(data.purchase_units[0].payments.captures[0].id);
            let responseData2 = await apiRes2.json();
            if(responseData2.res)
            {
              console.log('Mail Send.');
            }
            else
            {
              console.log('Mail Not Send.');
            }
          }
          else
          {
            NotificationManager.error(responseData1.msg, '',2000);
          }
        }
        else
        {
          console.error(responseData.msg);
        }
      }
      else if(type === 'renewal-payment')
      {
        let values = {
          transaction_id: data.purchase_units[0].payments.captures[0].id,
          currency_type: data.purchase_units[0].amount.currency_code,
          payer_firstname: data.payer.name.given_name,
          payer_lastname: data.payer.name.surname,
          payer_email: data.payer.email_address,
          payer_country_code: data.payer.address.country_code,
          payment_time: convert_date_format(data.purchase_units[0].payments.captures[0].create_time),
          transaction_type:'Credit Card',
          id: props?.scanData
        }
        // let apiRes = await paymentInvoice(values);
        let apiRes = await submitRenewProductInvoice(values);
        let responseData = await apiRes.json();
  
        if(responseData.res)
        {
          let apiRes1 = await updateRenewPackage(props?.scanData);
          let responseData1 = await apiRes1.json();
          if(responseData1.res)
          {
            NotificationManager.success(responseData1.msg,'',2000);
            // setShow(false);
            // navigate("/Dashboard");
            navigate('/InvoicesPayments');
           
            let apiRes2 = await sendInvoiceViaMail(data.purchase_units[0].payments.captures[0].id);
            let responseData2 = await apiRes2.json();
            if(responseData2.res)
            {
              console.log('Mail Send.');
            }
            else
            {
              console.log('Mail Not Send.');
            }
          }
          else
          {
            NotificationManager.error(responseData1.msg, '',2000);
          }
          // }
        }
        else
        {
          console.error('Error in purchase-product section');
        }
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function(details) {
      // alert('Transaction completed by ' + details.payer.name.given_name);
      // console.warn(details);
      paypalPaymentInvoice(details);
    });
  };


  return (
    <PayPalScriptProvider options={paypalOptions}>


{/* fundingSource={[FUNDING.PAYPAL,FUNDING.CARD]} */}
{/* fundingSource={[FUNDING.PAYPAL, FUNDING.CARD]} */}
{/* disableFunding={[]} // Ensure no funding sources are disabled */}

<PayPalButtons
  style={{
    layout: 'horizontal',
    color: 'white', // Customize color
    shape: 'rect',
  }}
  fundingSource={FUNDING.CARD}
  createOrder={createOrder}
  onApprove={onApprove}
  
//   onCancel={onCancel}
  label="Custom Label" // Rename the PayPal button
/>

     
    </PayPalScriptProvider>
  );
};

export default PayPalComponent;










