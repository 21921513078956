import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
// import logo from "../../assets/images/LogoW.svg";
// import newlogo from "../../assets/images/Clever-New-Logo.png";
import newWhitelogo from "../../assets/images/WhiteLogo.png";
import "./Header.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineBell } from "react-icons/hi";
// import { GrCart } from "react-icons/gr";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import {changeTitle, onLogout} from "../../utils/commonData";
import { getUserDetails, getAllUnreadNotification, getAllReadNotification, getUserStatus, getProductListStep4, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";
import { useContextValues } from "../../context/commonContext";

const InnerHeader = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const {cartCount,setCartCount} = useContextValues();
  const [unreadNotification,setUnreadNotification] = useState();
  const [notificationData,setNotificationData] = useState();

  const [loginData, setLoginData] = useState();
  const [userDetails, setUserDetails] = useState();
  const [toggle, setToggle] = useState(false);
  const [productId,setProductId] = useState();
  const [step,setStep] = useState();
  const [contentDetails,setContentDetails] = useState();

  // get all unread notifications
  const fetchallUnreadNotification = async() => {
    try
    {
      let apiRes = await getAllUnreadNotification();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setUnreadNotification(responseData.data);
        setNotificationData(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //get all read notifications
  const fetchallReadNotification = async() => {
    try
    {
      let apiRes = await getAllReadNotification();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setUnreadNotification(responseData.data);
        fetchallUnreadNotification();
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      { 
        console.log(responseData.data);
        setUserDetails(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getCartCount = async() =>
  {
    try
    {
      let apiRes = await getProductListStep4(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        if(responseData.totalCount > 0)
        {
          setCartCount(responseData.totalCount);
        }
        else
        {
          setCartCount('0');
        }
      }
      else
      {
        setCartCount('0');
        console.error(responseData.msg);
      }
    }
    catch(error)
    {
      console.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //get user status
  // const handleUserStatus = async () => {
  //   try
  //   {
  //     let apiRes = await getUserStatus();
  //     let responseData = await apiRes.json();
  //     if(responseData.res)
  //     {
  //       // console.warn(responseData.data)
  //       // console.warn(responseData.data.go_to_step)
  //       if(responseData.data.product_id === 1)
  //       {
  //         setProductId(responseData.data.product_id);
  //         setStep(responseData.data.go_to_step);

  //         // step_forwarding(responseData.data.product_id,responseData.data.go_to_step);
  //       }
  //       else if(responseData.data.product_id === 2)
  //       {
  //         setProductId(responseData.data.product_id);
  //         setStep(responseData.data.go_to_step);

  //         // step_forwarding(responseData.data.product_id,responseData.data.go_to_step);
  //       }
  //     }
  //     else
  //     {
  //       // NotificationManager.error(responseData.msg, '',2000);
  //     }
  //     // goPage("/LetStarted")
  //   }
  //   catch(error)
  //   {
  //     // NotificationManager.error('Something went wrong!', '',2000);
  //   }
  // }

  // const step_mail_forwarding = () => {
  //   handleUserStatus();
  //     if(step == 1)
  //     {
  //       navigate("/LetStarted");
  //     }
  //     else if(step == 2)
  //     {
  //       navigate("/SelectProduct");
  //     }
  //     else if(step == 3)
  //     {
  //       navigate("/YourDetails");
  //     }
  //     else if(step == 4)
  //     {
  //       navigate("/Payment");
  //     }
  //     else if(step == 5)
  //     {
  //       navigate("/Dashboard");
  //     }
  // }

  // const step_phone_forwarding = () => {
  //   alert(step);
  //   return
  //   if(step==1)
  //   {
  //     navigate("/LetStarted");
  //   }
  //   else if(step==2)
  //   {
  //     navigate("/SelectProduct");
  //   }
  //   else if(step==3)
  //   {
  //     navigate("/SelectCountryPhoneNumber");
  //   }
  //   else if(step==4)
  //   {
  //     navigate("/Payment");
  //   }
  //   else if(step==5)
  //   {
  //     navigate("/Dashboard");
  //   }
  // }

  const Logout = () => {
    onLogout();
    navigate("/Login");
  };

  const goPage = (page) => {
    navigate(page);
  };

const initials = userDetails?.full_name
.split(' ')
.map(word => word.charAt(0))
.join('');

const handleClick = () => {
  setToggle(!toggle);
  // fetchallReadNotification();
};


useEffect(() => {
  //CreateBroadcast();
  //let data = logInDetails();
  let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
  
 if (value == undefined) {
    navigate("/Login");
  }
  // setLoginData(value["user"]);
  getUserData();
  fetchallUnreadNotification();
  getCartCount();
  // handleUserStatus();
}, []);


useEffect(()=>{
  getAllPageContent();
},[hostLanguage])

// useEffect(() => {
//   // Create a container div for Google Translate element
//   const translateContainer = document.createElement('div');
//   translateContainer.id = 'google_translate_container';
//   document.body.appendChild(translateContainer);

//   // Load Google Translate script dynamically
//   const script = document.createElement('script');
//   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//   script.async = true; // Set async attribute to true
//   translateContainer.appendChild(script);

//   // Initialize Google Translate
//   window.googleTranslateElementInit = () => {
//     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
//   };

//   return () => {
//     // Clean up function
//     document.body.removeChild(translateContainer);
//     delete window.googleTranslateElementInit;
//   };
// }, []);

// useEffect(() => {
//   // Initialize Google Translate
//   window.googleTranslateElementInit = () => {
//     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
//   };

//   // Load Google Translate script dynamically
//   const script = document.createElement('script');
//   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//   script.async = true; // Set async attribute to true
//   document.body.appendChild(script);

//   return () => {
//     // Clean up function
//     document.body.removeChild(script);
//     delete window.googleTranslateElementInit;
//   };
// }, []);

// useEffect(() => {
//   // Load Google Translate script dynamically
//   const script = document.createElement('script');
//   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//   script.async = true; // Set async attribute to true
//   document.body.appendChild(script);

//   // Initialize Google Translate
//   window.googleTranslateElementInit = () => {
//     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
//   };

//   return () => {
//     // Clean up function
//     if (document.body.contains(script)) {
//       // Check if script is still a child of document.body
//       document.body.removeChild(script);
//     }
//     delete window.googleTranslateElementInit;
//   };
// }, []);

// useEffect(() => {
//   // Load Google Translate script dynamically
//   const script = document.createElement('script');
//   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//   script.async = true; // Set async attribute to true
//   document.body.appendChild(script);

//   // Initialize Google Translate
//   window.googleTranslateElementInit = () => {
//     try {
//       new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
//     } catch (error) {
//       console.error("Error initializing Google Translate:", error);
//     }
//   };

//   return () => {
//     // Clean up function
//     if (document.body.contains(script)) {
//       // Check if script is still a child of document.body
//       document.body.removeChild(script);
//     }
//     delete window.googleTranslateElementInit;
//   };
// }, []);

// useEffect(() => {
//   const script = document.createElement('script');
//   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//   script.async = true;

//   // Callback function for when the script is loaded
//   script.onload = () => {
//     // Check if the google.translate object is available
//     if (window.google && window.google.translate) {
//       // Initialize Google Translate
//       window.googleTranslateElementInit();
//     } else {
//       // Handle case where google.translate is not available
//       console.error('Google Translate API not available.');
//     }
//   };

//   document.body.appendChild(script);

//   // Function to initialize Google Translate
//   window.googleTranslateElementInit = () => {
//     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
//   };

//   // Cleanup function
//   return () => {
//     if (document.body.contains(script)) {
//       document.body.removeChild(script);
//     }
//     delete window.googleTranslateElementInit;
//   };
// }, []);

    // working
  //   useEffect(() => {
  //     const googleTranslateElementInit = () => {
  //         let cookieLang = "";
  //         const userLang = navigator.language || navigator.userLanguage;
  //         console.log("The language is: " + userLang);
  //         if (userLang.indexOf("-") !== -1) {
  //             const Lang = userLang.split("-");
  //             cookieLang = Lang[0];
  //         } else {
  //             cookieLang = userLang;
  //         }
  
  //         new window.google.translate.TranslateElement({
  //             pageLanguage: 'en',
  //             // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
  //         }, 'google_translate_element');
  //     }
  
  //     if (!window.google) {
  //         const script = document.createElement('script');
  //         script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //         script.async = true;
  //         document.body.appendChild(script);
  //         script.onload = googleTranslateElementInit;
  //     } else {
  //         googleTranslateElementInit();
  //     }
  // }, []);

  return (
    <div id="Header">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="">
            {/* <img src={logo} className="MainLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/> */}
            <img src={newWhitelogo} className="MainLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/>
          </Navbar.Brand>

          <Nav className="">
            {/* <Form.Control type="email" placeholder="Search here" /> */}

            <div className="NotiBtn">
              <Nav.Link onClick={handleClick}>
                <HiOutlineBell size="1.5rem" color="#fff" />
                <p className="NotificationNuber">
                {(unreadNotification?.length > 0 && unreadNotification?.length < 10) ? `0${unreadNotification?.length}`: unreadNotification?.length}
                </p>
              </Nav.Link>

              {toggle && notificationData?.length > 0 && (
                <>
                  <ul class="list-group NotiList" style={{overflowY:'auto',maxHeight:'300px'}}>
                    {notificationData?.map((item,index)=>{
                      return(
                        <li class="list-group-item">{item?.notification}</li>
                      )
                    })}
                    <li class="list-group-item"><Link onClick={()=>fetchallReadNotification()}>Clear All</Link></li>
                    
                  </ul>
                  
                </>
              )}
            </div>

            <div className="NotiBtn">
              <Nav.Link onClick={()=>navigate('/Payment')}>
                {/* <GrCart size="1.5rem" color="#fff" /> */}
                <FaShoppingCart  size="1.5rem" color="#fff" />
                <p className="NotificationNuber">{cartCount}</p>
              </Nav.Link>
            </div>

            <Dropdown className="HrDropItem">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <span className="UserImg">{initials}</span>
                {userDetails?.full_name} 
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item  onClick={()=>goPage("/Dashboard")}>Dashboard</Dropdown.Item> */}
                <Dropdown.Item  onClick={()=>goPage("/Dashboard")}>{contentDetails?.after_login_header?.parent_0}</Dropdown.Item>
                {/* <Dropdown.Item onClick={()=>goPage("/CurrentPlan")}>All Purchase items</Dropdown.Item> */}
                <Dropdown.Item onClick={()=>goPage("/CurrentPlan")}>{contentDetails?.after_login_header?.parent_1}</Dropdown.Item>
                {/* <Dropdown.Item onClick={()=>goPage("/AccountSettings")}>Account Settings</Dropdown.Item> */}
                <Dropdown.Item onClick={()=>goPage("/AccountSettings")}>{contentDetails?.after_login_header?.parent_2}</Dropdown.Item>
                {/* <Dropdown.Item onClick={()=>goPage("/CurrentPlan")}>Current Plan</Dropdown.Item> */}
                {/* <Dropdown.Item href="">Language</Dropdown.Item> */}
                {/* {productId === 1 ? (
                  <Dropdown.Item onClick={()=>step_mail_forwarding()}>Go to Clever Store1</Dropdown.Item>
                ):(
                  <Dropdown.Item onClick={()=>step_phone_forwarding()}>Go to Clever Store2</Dropdown.Item>
                )} */}
                {/* <Dropdown.Item onClick={()=>goPage("/LetStarted")}>Go to Clever Store</Dropdown.Item> */}
                <Dropdown.Item onClick={()=>goPage("/LetStarted")}>{contentDetails?.after_login_header?.parent_3}</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => Logout()} >Logout</Dropdown.Item> */}
                <Dropdown.Item onClick={() => Logout()} >{contentDetails?.after_login_header?.parent_4}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default InnerHeader;
