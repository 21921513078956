import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./AccountSettings.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../components/sidebar/Sidebar";
import Mailboxright from "../../components/mailboxright/Mailboxright";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ScanEnvelop from "../../assets/images/ScanEnvelop.svg";
import ScanIcon from "../../assets/images/ScanIcon.svg";
import Send from "../../assets/images/Send.svg";
import Collect from "../../assets/images/Collect.svg";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TickBlue from "../../assets/images/TickBlue.svg";
import Nav from "react-bootstrap/Nav";
import { useNavigate, Link } from "react-router-dom";
import  axios  from "axios";
import { API_BASE_URL } from "../../app_url";
import {NotificationManager} from 'react-notifications';
import InputGroup from "react-bootstrap/InputGroup";

import{ 
    BillingAddressUpdate, EmailUpdate, PasswordUpdate, getCityByRegionId,  getCountry, getStateByCountryId,
    getUserBillingDetails, getUserDetails, checkVATNumber, getPageContent, 
    deleteUser,
    deliveryAddressUpdate} from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";
// import { toast } from 'react-toastify';

const AccountSettings = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessagePassword, setSuccessMessagePassword] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");

  const [successMessageBillingAddress, setSuccessMessageBillingAddress] = useState("");
  const [errorMessageBillingAddress, setErrorMessageBillingAddress] = useState("");
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState("");
  const [userData, setLoginData] = useState();
  const [userDetails, setUserDetails] = useState();
  const [showAllCountries,setShowAllCountries] = useState([]);
  const [vatNumber, setVatNumber] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [selectedLegalRegionByCountryId,setLegalRegionByCountryId] = useState([]);
 
  const [selectedCityWithId, setSelectedCityWithId] = useState([]);
  const [vatNo,setVatNo] = useState();
  const [buttonType,setButtonType] = useState();
  const [contentDetails,setContentDetails] = useState();
  const [billingPhoneCode, setBillingPhoneCode] = useState(); // this is for billing country code
  const [deliveryPhoneCode, setDeliveryPhoneCode] = useState(); // this is for billing country code
 
  const [showAllCountries2,setShowAllCountries2] = useState([]);
  const [selectedCityWithId2, setSelectedCityWithId2] = useState([]);
  const [selectedLegalRegionByCountryId2,setLegalRegionByCountryId2] = useState([]);
  const [selectedLegalRegionByCountryId3,setLegalRegionByCountryId3] = useState([]);
  const [selectedCityWithId3, setSelectedCityWithId3] = useState([]);
  
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const deleteModalClose = () => setDeleteModalShow(false);

  useEffect(() => {
    //CreateBroadcast();
    //let data = logInDetails();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    
;    if (value == undefined) {
      navigate("/");
    }
    // setLoginData(value["user"]);
    getUserData();
    handleCountryList();
    UserBillingDetails();
  }, []);
  
  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      { //console.log(responseData.data);
        setUserDetails(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

//Update email start
  const [formDataEmail, setformDataEmail] = useState({
    email: "",
  });
  const [formErrorsEmail, setFormErrorsEmail] = useState({
    email: "",
  });

  const handleChangeEmail = e => {
    const {name, value} = e.target;
    setformDataEmail({...formDataEmail, [name]: value});
  };

  
  const handleEmailUpdate = async(e) => {
    e.preventDefault();
    if (validateFormEmail()) {
      try
      {
        let apiRes = await EmailUpdate(formDataEmail);
        let responseData = await apiRes.json();
        
        if(responseData)
        { 
          if(responseData.res==false){//console.log(responseData.msg);
            setSuccessMessage("");
            setErrorMessage(responseData.msg);
            NotificationManager.error(responseData.msg, '',2000);
          }else{
            setErrorMessage("");
            setSuccessMessage(responseData.msg);
            NotificationManager.success(responseData.msg, '',2000);
            getUserData();
          }
        // navigate("/SelectProduct");
        }
        else
        {
          NotificationManager.error(responseData.msg, '',2000);
        }
      }
      catch(error)
      {
        // NotificationManager.error('Something went wrong!', '',2000);
      }
    }
  }

  const validateFormEmail = () => {
    let isValid = true;
    const errors = {
      email: "",
    };

    if (formDataEmail.email === "") {
      errors.email = "Please enter your email";
      isValid = false;
    }

    setFormErrorsEmail(errors);
    return isValid;
  };
//Update email end

const handleCountryList2 = async() =>
{
  try
  {
    let apiRes = await getCountry();
    let responseData = await apiRes.json();
    if(responseData)
    {
      setShowAllCountries2(responseData.data);
    }
    else
    {
      console.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}



//Update password
  const [formDataPassword, setformDataPassword] = useState({
    password: "",
    ConfirmPassword: "",
});
  const [formErrorsPassword, setFormErrorsPassword] = useState({
    password: "",
    ConfirmPassword: "",
  });

  const handleChangePassword = e => {
    const {name, value} = e.target;
    setformDataPassword({...formDataPassword, [name]: value});
  };

 
  const handlePasswordUpdate = async(e) =>
  {
    e.preventDefault();
    if (validateFormPassword())
    {
      try
      {
        let apiRes = await PasswordUpdate(formDataPassword);
        let responseData = await apiRes.json();
        
        if(responseData)
        { 
          if(responseData.res==false)
          {
            setSuccessMessagePassword("");
            // setErrorMessagePassword(responseData.msg);
            setErrorMessagePassword(languageJson?.PasswordValidation2);
            // NotificationManager.error(languageJson?.PasswordValidation2, '',2000);
          }
          else
          {
            setformDataPassword({
              password: "",
              ConfirmPassword: "",
            })

            setErrorMessagePassword("");
            setSuccessMessagePassword(responseData.msg);
            NotificationManager.success(responseData.msg, '',2000);
            getUserData();
          }
        // navigate("/SelectProduct");
        }
        else
        {
          NotificationManager.error(responseData.msg, '',2000);
        }
      }
      catch(error)
      {
        // NotificationManager.error('Something went wrong!', '',2000);
      }
    }
  }

  const validateFormPassword = () => {
    let isValid = true;
    const errors = {
      password: "",
      ConfirmPassword: "",
    };

    if (formDataPassword.password === "") {
      errors.password = "Please enter your password";
      isValid = false;
    }
    
    if (formDataPassword.ConfirmPassword === "") {
      errors.ConfirmPassword = "Please enter confirm new password";
      isValid = false;
    }

    setFormErrorsPassword(errors);
    return isValid;
  };
//Update password end

  //getting all country start
  const handleCountryList = async() =>
  {
    try
    {
      let apiRes = await getCountry();
      let responseData = await apiRes.json();
      if(responseData)
      {
        setShowAllCountries(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }
 // get Location Cities

 
//getting all country end

const handleVerification = async () => {
  try {
    const response = await axios.get(`http://localhost:5000/vat-validation/${vatNumber}`);
    setIsValid(response.data.valid);
  } catch (error) {
    console.error('Error verifying VAT:', error);
  }
};


//Update BillingAddress start

 // handle Legal Region by Country Id
 const UserBillingDetails = async () => {
  try
  {
    let apiRes = await getUserBillingDetails();
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      console.warn(responseData.data[0])
      setformDataBillingAddress(
        {
          first_name: responseData.data[0].first_name,
          last_name: responseData.data[0].last_name,
          company_name: responseData.data[0].company_name,
          title: responseData.data[0].title,
          address: responseData.data[0].address,
          city_id: responseData.data[0].city_id,
          country_id: responseData.data[0].country_id,
          postal_code: responseData.data[0].postal_code,
          phone_number: responseData.data[0].phone_number,
          region_id: responseData.data[0].region_id,
          vat_number: responseData.data[0].vat_number,
        }
      );
      setDeliveryAddress(
        {
          first_name: responseData.data[0].f_first_name,
          last_name: responseData.data[0].f_last_name,
          company_name: responseData.data[0].f_company_name,
          title: responseData.data[0].f_title,
          address: responseData.data[0].f_address,
          city_id: responseData.data[0].f_city_id,
          country_id: responseData.data[0].f_country_id,
          postal_code: responseData.data[0].f_postal_code,
          phone_number: responseData.data[0].f_phone_number,
          region_id: responseData.data[0].f_region_id,
        }
      );
      setBillingPhoneCode(responseData.data[0].phonecode);
      setDeliveryPhoneCode(responseData.data[0].f_phonecode);
      setVatNo(responseData.data[0].vat_number);
      
        handleRegionsByCountryId(responseData.data[0].country_id);
        getCityByRegionData(responseData.data[0].region_id);

        handleDeliveryRegionsByCountryId(responseData.data[0].f_country_id);
        getDeliveryCityByRegionData(responseData.data[0].f_region_id);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

 const handleRegionsByCountryId = async (country_id) => {
  try
  {
    let apiRes = await getStateByCountryId(country_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setLegalRegionByCountryId2(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

 const handleDeliveryRegionsByCountryId = async (country_id) => {
  try
  {
    let apiRes = await getStateByCountryId(country_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      // console.log(responseData)
      setLegalRegionByCountryId3(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const getCityByRegionData = async (region_id) => {
  try
  {
    let apiRes = await getCityByRegionId(region_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setSelectedCityWithId2(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const getDeliveryCityByRegionData = async (region_id) => {
  try
  {
    let apiRes = await getCityByRegionId(region_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setSelectedCityWithId3(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

// get page content
const getAllPageContent = async() => {
  try
  {
    let apiRes = await  getPageContent(hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      setContentDetails(responseData.data);
      // console.warn(responseData.data.header);
    }
    else
    {
      NotificationManager.error(responseData.msg,'',2000);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

//delete account
const deleteAccount = async() =>{
  try
  {
    let apiRes = await deleteUser(hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      NotificationManager.success(languageJson?.account_delete_msg);
      localStorage.removeItem("virtualOfficeLoginInfo");
      localStorage.clear("virtualOfficeLoginInfo");
      navigate('/Login');
    }
    else
    {
      console.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

const [formDataBillingAddress, setformDataBillingAddress] = useState({
  first_name: "",
  last_name: "",
  company_name: "",
  title: "",
  address: "",
  city_id: "",
  country_id: "",
  postal_code: "",
  phone_number: "",
  phonecode: "",
  region_id: "",
  vat_number: vatNo,
});
const [formErrorsBillingAddress, setFormErrorsBillingAddress] = useState({
  first_name: "",
  last_name: "",
  company_name: "",
  title: "",
  address: "",
  city_id: "",
  country_id: "",
  postal_code: "",
  phone_number: "",
  region_id: "",
  vat_number: "",
});

const [deliveryAddress, setDeliveryAddress] = useState({
  first_name: "",
  last_name: "",
  company_name: "",
  title: "",
  address: "",
  city_id: "",
  country_id: "",
  postal_code: "",
  phone_number: "",
  phonecode: "",
  region_id: ""
});
const [deliveryAddressError, setDeliveryAddressError] = useState({
  first_name: "",
  last_name: "",
  company_name: "",
  title: "",
  address: "",
  city_id: "",
  country_id: "",
  postal_code: "",
  phone_number: "",
  region_id: ""
});

const handleChangeBillingAddress = e => {
  const {name, value} = e.target;
  setformDataBillingAddress({...formDataBillingAddress, [name]: value});
  setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
};

const handleChangeDeliveryAddress = e => {
  const {name, value} = e.target;
  setDeliveryAddress({...deliveryAddress, [name]: value});
  setDeliveryAddressError({...deliveryAddressError, [name]: ''});
};

const handleCountryTOState = e => {
  handleRegionsByCountryId(e.target.value);
  const {name, value} = e.target;
  setformDataBillingAddress({...formDataBillingAddress, [name]: value});
  setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
  const foundItem = showAllCountries2.find(item => item.id === parseInt(e.target.value));
  setBillingPhoneCode(foundItem.phonecode);
};

const deliveryStatesByCountryId = e => {
  handleDeliveryRegionsByCountryId(e.target.value);
  const {name, value} = e.target;
  setDeliveryAddress({...deliveryAddress, [name]: value});
  setDeliveryAddressError({...deliveryAddressError, [name]: ''});
  const foundItem = showAllCountries2.find(item => item.id === parseInt(e.target.value));
  setDeliveryPhoneCode(foundItem.phonecode);
};

const handleRegionTOCity = e => {
  getCityByRegionData(e.target.value);
  const {name, value} = e.target;
  setformDataBillingAddress({...formDataBillingAddress, [name]: value});
  setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
};

const deliveryCitiesByRegionId = e => {
  getDeliveryCityByRegionData(e.target.value);
  const {name, value} = e.target;
  setDeliveryAddress({...deliveryAddress, [name]: value});
  setDeliveryAddressError({...deliveryAddressError, [name]: ''});
};


const handleBillingAddressUpdate = async(e) => {
  e.preventDefault();
  if (validateFormBillingAddress()) {
    try
    {
      // console.log(formDataBillingAddress)
      // // phonecode: billingPhoneCode ? billingPhoneCode : '',
      // let temp_obj= {};
      // temp_obj ={...formDataBillingAddress};
      // console.log(temp_obj)
      // return
      
      // formDataBillingAddress.vat_number = vatNo;
      // formDataBillingAddress.phonecode = billingPhoneCode ? billingPhoneCode : '';
      // console.log(formDataBillingAddress)
      // return

      let temp_obj = {...formDataBillingAddress};
      temp_obj.phonecode = billingPhoneCode;
      // setformDataBillingAddress(formDataBillingAddress);
      setformDataBillingAddress(temp_obj);
      setVatNo(formDataBillingAddress.vat_number);
      // setformDataBillingAddress({...formDataBillingAddress, formDataBillingAddress});
      // console.warn(formDataBillingAddress);
      // console.warn(temp_obj)
      // let apiRes = await BillingAddressUpdate(formDataBillingAddress);
      let apiRes = await BillingAddressUpdate(temp_obj);
      let responseData = await apiRes.json();
      if(responseData)
      { 
        if(responseData.res==false){//console.log(responseData.msg);
          setSuccessMessageBillingAddress("");
          setErrorMessageBillingAddress(responseData.msg);
          NotificationManager.error(responseData.msg, '',2000);
        }else{
          
          // setformDataPassword({
          //   password: "",
          //   ConfirmPassword: "",
          // })

          setErrorMessageBillingAddress("");
          setSuccessMessageBillingAddress(responseData.msg);
          UserBillingDetails();
          NotificationManager.success(contentDetails?.after_login?.billing_addr_succ_msg, '',2000);
          getUserData();
        }
      // navigate("/SelectProduct");
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
}

const validateFormBillingAddress = () => {
  let isValid = true;
  const errors = {
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  };
  
  if (formDataBillingAddress.first_name === "")
  {
    errors.first_name =  billAddError?.first_name;
    isValid = false;
  }

  if (formDataBillingAddress.last_name === "")
  {
    errors.last_name = billAddError?.last_name;
    isValid = false;
  }

  if (formDataBillingAddress.company_name === "")
  {
    errors.company_name = billAddError?.company_name;
    isValid = false;
  }

  if (formDataBillingAddress.title === "")
  {
    errors.title = billAddError?.title;
    isValid = false;
  }

  if (formDataBillingAddress.city_id === "")
  {
    errors.city_id = billAddError?.city;
    isValid = false;
  }

  if (formDataBillingAddress.region_id === "")
  {
    errors.region_id = billAddError?.region;
    isValid = false;
  }

  if (formDataBillingAddress.country_id === "")
  {
    errors.country_id = billAddError?.country;
    isValid = false;
  }

  if (formDataBillingAddress.postal_code === "")
  {
    errors.postal_code = billAddError?.postal_code;
    isValid = false;
  }

  if (formDataBillingAddress.address === "")
  {
    errors.address = billAddError?.address;
    isValid = false;
  }

  if (formDataBillingAddress.phone_number === "")
  {
    errors.phone_number = billAddError?.phone_number;
    isValid = false;
  }
  setFormErrorsBillingAddress(errors);
  return isValid;
};

const handleDeliveryAddressUpdate = async(e) => {
  console.log(deliveryAddress)
  e.preventDefault();
  if (validateFormDeliveryAddress()) {
    try
    {
      let temp_obj = {...deliveryAddress};
      temp_obj.phonecode = deliveryPhoneCode;
      setDeliveryAddress(temp_obj);

      let apiRes = await deliveryAddressUpdate(temp_obj);
      let responseData = await apiRes.json();
      if(responseData)
      { 
        if(responseData.res==false)
        {
          NotificationManager.error(responseData.msg, '',2000);
        }
        else
        {
          setSuccessMessageBillingAddress(responseData.msg);
          UserBillingDetails();
          NotificationManager.success(contentDetails?.after_login?.billing_addr_succ_msg, '',2000);
          getUserData();
        }
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      console.log(error)
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  else
  {
    console.log('Blank field is not allowed');
  }
}

const validateFormDeliveryAddress = () => {
  let isValid = true;
  const errors = {
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  };

  if (deliveryAddress.first_name === "")
  {
    errors.first_name =  billAddError?.first_name;
    isValid = false;
  }

  if (deliveryAddress.last_name === "")
  {
    errors.last_name = billAddError?.last_name;
    isValid = false;
  }

  if (deliveryAddress.company_name === "")
  {
    errors.company_name = billAddError?.company_name;
    isValid = false;
  }

  if (deliveryAddress.title === "")
  {
    errors.title = billAddError?.title;
    isValid = false;
  }

  if (deliveryAddress.city_id === "")
  {
    errors.city_id = billAddError?.city;
    isValid = false;
  }

  if (deliveryAddress.region_id === "")
  {
    errors.region_id = billAddError?.region;
    isValid = false;
  }

  if (deliveryAddress.country_id === "")
  {
    errors.country_id = billAddError?.country;
    isValid = false;
  }

  if (deliveryAddress.postal_code === "")
  {
    errors.postal_code = billAddError?.postal_code;
    isValid = false;
  }

  if (deliveryAddress.address === "")
  {
    errors.address = billAddError?.address;
    isValid = false;
  }

  if (deliveryAddress.phone_number === "")
  {
    errors.phone_number = billAddError?.phone_number;
    isValid = false;
  }
  setDeliveryAddressError(errors);
  return isValid;
};



// Check number of letters(button clicked)
const handleCheckingVATNumber = async () => {
  try
  {
    let values = {vat_number:vatNo}    // vat_number:DE254549143 => valid
    let apiRes = await checkVATNumber(values);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      NotificationManager.success(responseData.msg, '',2000);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

const [billAddError] = useState({
  title: languageJson?.title_validation,
  first_name: languageJson?.first_name_validation,
  last_name: languageJson?.last_name_validation,
  company_name: languageJson?.company_name_validation,
  address: languageJson?.address_validation,
  country: languageJson?.select_country_validation,
  region: languageJson?.select_region_validation,
  city: languageJson?.select_city_validation,
  postal_code: languageJson?.postal_code_validation,
  phone_number: languageJson?.phone_number_validation
})


useEffect(()=>{
  getAllPageContent();
  handleCountryList2();
},[hostLanguage])

  return (
    <div className="lightBlue">
      <InnerHeader />
      <div className="mail-box-main-body">
        <Row className="mail-box-body">
          <Col sm={2}>
            <Sidebar />
          </Col>
          <Col sm={10}>
            <div className="NewaddressBox">
              {/* <h2>Account settings</h2> */}
              <h2>{contentDetails?.after_login?.AccountSettings}</h2>

              <div className="NewaddressBoxtInner">
                {/* <h4>Account Details</h4> */}
                <h4>{contentDetails?.after_login?.AccountDetails}</h4>

                <div className="DetailsBox">
                  {/* <Row className="DetailsList">
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>Account ID:</em>
                        C00125577
                      </h5>
                    </Col> 
                    <Col sm={6} className="pl-5">
                      <h5>
                        <em>Account Type:</em>
                        Business
                      </h5>
                    </Col>
                  </Row>*/}
                  <Row className="DetailsList">
                    <Col sm={6} className="pr-5">
                      <h5>
                        {/* <em>Current Email:</em> */}
                        <em>{contentDetails?.after_login?.CurrentEmail}</em>
                        {userDetails?.email} 
                      </h5>
                      <Button variant="outline-primary" className="BtnSubmit2" type="submit" onClick={()=>setDeleteModalShow(true)}>
                        {contentDetails?.after_login?.deleteAccount}
                      </Button>
                      
                      <form className="form" onSubmit={handleEmailUpdate}>
                      
                        <Form.Group className="mt-5 text-left">
                          {/* <Form.Label>New Email</Form.Label> */}
                          {/* <Form.Label>{contentDetails?.after_login?.NewEmail}</Form.Label> */}
                          {/* {successMessage && <div className="success-message" >{successMessage}</div>} */}
                          {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
                          {/* <input
                            name="email"
                            value={formDataEmail.email}
                            onChange={handleChangeEmail}
                            type="text"
                            placeholder={contentDetails?.after_login?.EnterYourEmail}
                            className="form-control"
                          /> */}
                        </Form.Group>
                        {/* {formErrorsEmail.email && <span className="error-message">{formErrorsEmail.email}</span>} */}
                        {/* {formErrorsEmail.email && <span className="error-message">{contentDetails?.after_login?.email_validation}</span>} */}
                        {/* <Button
                          variant="outline-primary"
                          className="BtnSubmit2"
                          type="submit"
                        > */}
                          {/* Change Email */}
                          {/* {contentDetails?.after_login?.ChangeEmail} */}
                        {/* </Button> */}
                      </form>
                      
                    </Col>
                    <Col sm={6} className="pl-5">
                      {/* <h5>
                        <em>Current password:</em>
                        mganitop@12589
                      </h5> */}
                    <form className="form" onSubmit={handlePasswordUpdate}>
                      <Form.Group className="mt-5 text-left">
                        {/* <Form.Label>New Password</Form.Label> */}
                        <Form.Label>{contentDetails?.after_login?.NewPassword}</Form.Label>
                        {/* <Form.Control
                          type="password"
                          placeholder="Enter New Password"
                        /> */}
                      {successMessagePassword && <div className="success-message" >{successMessagePassword}</div>}
                          {errorMessagePassword && <div className="error-message">{errorMessagePassword}dsadsa</div>}
                          <input
                          name="password"
                          value={formDataPassword.password}
                          onChange={handleChangePassword}
                          type="text"
                          // placeholder="Enter your password"
                          placeholder={contentDetails?.after_login?.EnterYourPassword}
                          className="form-control"
                        />
                        </Form.Group>
                        {/* {formErrorsPassword.password && <span className="error-message">{formErrorsPassword.password}</span>} */}
                        {formErrorsPassword.password && <span className="error-message">{contentDetails?.after_login?.password_validation}</span>}

                      <Form.Group className="mt-5 text-left">
                        {/* <Form.Label>Confirm New Password</Form.Label> */}
                        <Form.Label>{contentDetails?.after_login?.ConfirmNewPassword}</Form.Label>
                        {/* <Form.Control
                          type="password"
                          placeholder="Enter Confirm New Password"
                        /> */}
                        <input
                          name="ConfirmPassword"
                          value={formDataPassword.ConfirmPassword}
                          onChange={handleChangePassword}
                          type="text"
                          // placeholder="Enter confirm new password"
                          placeholder={contentDetails?.after_login?.EnterConfirmNewPassword}
                          className="form-control"
                        />
                        </Form.Group>
                        {/* {formErrorsPassword.ConfirmPassword && <span className="error-message">{formErrorsPassword.ConfirmPassword}</span>} */}
                        {formErrorsPassword.ConfirmPassword && <span className="error-message">{contentDetails?.after_login?.con_password_validation}</span>}
                      
                      <Button
                        variant="outline-primary"
                        className="BtnSubmit2"
                        type="submit"
                      >
                        {/* Change Password */}
                        {contentDetails?.after_login?.ChangePassword}
                      </Button>
                      </form>
                    </Col>
                  </Row>
                </div>
                  {/* Billing Address   */}
                <form className="form" onSubmit={handleBillingAddressUpdate}>
                  <div className="DetailsBox">
                    {/* <h4>Billing address</h4> */}
                    <h4>{contentDetails?.after_login?.BillingAddress}</h4>
                    
                    <Row className="DetailsList">
                    <Col sm={6} className="pr-5">
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                        <Form.Select 
                          aria-label="Default select example" 
                          id="title" 
                          name="title" 
                          onChange={handleChangeBillingAddress}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("first_name").focus();
                            }
                          }}
                        >
                          <option value="" selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                          <option value="Mr." selected={formDataBillingAddress?.title === "Mr."}>{contentDetails?.your_details_page_data?.Mr}</option>
                          <option value="Mrs." selected={formDataBillingAddress?.title === "Mrs."}>{contentDetails?.your_details_page_data?.Mrs}</option>
                          <option value="Dr." selected={formDataBillingAddress?.title === "Dr."}>{contentDetails?.your_details_page_data?.Dr}</option>
                          <option value="Prof.Dr." selected={formDataBillingAddress?.title === "Prof.Dr."}>{contentDetails?.your_details_page_data?.Prof_Dr}</option>
                        </Form.Select>
                        {formErrorsBillingAddress.title && (<span className="error-message">{formErrorsBillingAddress.title}</span>)}
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                        <Form.Control
                          id="last_name"
                          name="last_name"
                          value={formDataBillingAddress.last_name}
                          // onChange={handleChangeBillingAddress}
                          onChange={(e) => {
                            const { value } = e.target;
                            // if (/^[A-Za-z]*$/.test(value))
                            if (/^[A-Za-z\s-]*$/.test(value))
                            {
                              handleChangeBillingAddress(e);
                            }
                          }}
                          type="text"
                          placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("company_name").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.last_name && <span className="error-message">{formErrorsBillingAddress.last_name}</span>}
                        
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.address_label}</Form.Label>
                        <Form.Control
                          id="address"
                          name="address"
                          value={formDataBillingAddress.address}
                          onChange={handleChangeBillingAddress}
                            type="text"
                            placeholder={contentDetails?.your_details_page_data?.address_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("country_id").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.address && <span className="error-message">{formErrorsBillingAddress.address}</span>}
                      </Form.Group>
                      
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                        <Form.Select 
                        aria-label="Default select example" 
                        id="region" 
                        name="region_id"  
                        onChange={handleRegionTOCity} 
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter' || e.key === 'Tab')
                          {
                            e.preventDefault();
                            document.getElementById("city").focus();
                          }
                        }}
                        >
                        <option value="" selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                        {selectedLegalRegionByCountryId2.length > 0 ? (
                          selectedLegalRegionByCountryId2.map((item,index)=>{
                            return(
                              <option value={item.id} selected={formDataBillingAddress?.region_id === item.id}>{item.name}</option>
                            )
                          })
                        ):(
                          <option disabled>{languageJson?.noRegionFound}</option>
                        )}
                        </Form.Select>
                        {formErrorsBillingAddress.region_id && (<span className="error-message">{formErrorsBillingAddress.region_id}</span>)}
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                        <Form.Control 
                          id="postal_code"
                          name="postal_code"
                          value={formDataBillingAddress.postal_code}
                          onChange={handleChangeBillingAddress}
                          type="number"
                          placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("phone_number").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.postal_code && <span className="error-message">{formErrorsBillingAddress.postal_code}</span>}
                      </Form.Group>
                    </Col>

                    <Col sm={6} className="pl-5">
                      <Form.Group className="mb-4 text-left">
                      <Form.Label>{contentDetails?.your_details_page_data?.First_Name}</Form.Label>
                        <Form.Control
                          id="first_name"
                          name="first_name"
                          value={formDataBillingAddress.first_name}
                          // onChange={handleChangeBillingAddress}
                          onChange={(e) => {
                            const { value } = e.target;
                            // if (/^[A-Za-z]*$/.test(value))
                            if (/^[A-Za-z\s-]*$/.test(value))
                            {
                              handleChangeBillingAddress(e);
                            }
                          }}
                          type="text"
                          placeholder={contentDetails?.your_details_page_data?.Enter_First_Name}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("last_name").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.first_name && <span className="error-message">{formErrorsBillingAddress.first_name}</span>}
                      </Form.Group>  

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                        <Form.Control
                          id="company_name"
                          name="company_name"
                          value={formDataBillingAddress.company_name}
                          onChange={handleChangeBillingAddress}
                          type="text"
                          placeholder={contentDetails?.your_details_page_data?.companyName_placeholder}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("address").focus();
                            }
                          }}
                        />
                        {formErrorsBillingAddress.company_name && <span className="error-message">{formErrorsBillingAddress.company_name}</span>}
                      </Form.Group>  

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.country_label}</Form.Label>
                        <Form.Select 
                          id="country_id"
                          aria-label="Default select example" 
                          name="country_id" 
                          onChange={handleCountryTOState}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("region").focus();
                            }
                          }}
                        >
                        <option value="" selected hidden>{contentDetails?.your_details_page_data?.countrySelectBoxDefaultText}</option>
                        {showAllCountries2.map((item,index)=>{
                          return(
                            <option value={item.id} selected={formDataBillingAddress?.country_id === item.id}>{item.name}</option>
                          )
                        })}
                        </Form.Select>
                        {formErrorsBillingAddress.country_id && <span className="error-message">{formErrorsBillingAddress.country_id}</span>}
                      </Form.Group>

                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                        <Form.Select 
                          id="city"
                          aria-label="Default select example" 
                          name="city_id" 
                          onChange={handleChangeBillingAddress}
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("postal_code").focus();
                            }
                          }}
                        >
                        <option value="" selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                        {selectedCityWithId2?.length > 0 ? (
                          selectedCityWithId2?.map((item,index)=>{
                            return(
                              <option value={item.id} selected={formDataBillingAddress?.city_id === item.id}>{item?.name}</option>
                            )})
                        ):(
                          <option disabled>{languageJson?.cityNotFound}</option>
                        )}
                        </Form.Select>
                        {formErrorsBillingAddress.city_id && <span className="error-message">{formErrorsBillingAddress.city_id}</span>}
                      </Form.Group>
                        
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            {billingPhoneCode? `+${billingPhoneCode}`:null}
                          </InputGroup.Text>
                          <Form.Control
                            id="phone_number"
                            type="number"
                            placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                            name="phone_number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={formDataBillingAddress.phone_number}
                            onChange={(e) => {
                              if (e.target.value.length <= 11) {
                                handleChangeBillingAddress(e);
                              }
                            }}
                          />
                        </InputGroup>
                        {formErrorsBillingAddress.phone_number && <span className="error-message">{formErrorsBillingAddress.phone_number}</span>}
                      </Form.Group>
                    </Col>
                  </Row>
                    
                  </div>

                <div className="DetailsBox">
                  {/* <h4>VAT Verification</h4> */}
                  <h4>{contentDetails?.after_login?.VAT_verification}</h4>

                  <Row className="DetailsList">
                    <Col sm={6}>
                      <Form.Group className="mb-4 text-left">
                        <Form.Label>
                          {/* Vat Number: */}
                          {contentDetails?.after_login?.vat_number}
                          <br />
                          {/* <strong>(only for EU company)</strong> */}
                          <strong>({contentDetails?.after_login?.only_for_msg})</strong>
                        </Form.Label>
                        <Form.Control
                        name="vat_number"
                        // value={formDataBillingAddress.vat_number}
                        value = {vatNo}
                        onChange={(e)=>setVatNo(e.target.value)}
                          type="text"
                          // placeholder="Add your VAT number"
                          placeholder={contentDetails?.after_login?.add_your_vat_number}
                          //onChange={(e) => setVatNumber(e.target.value)}
                        />
                      </Form.Group>

                      <Button
                        variant="outline-primary"
                        className="BtnSubmit2"
                        type="button" 
                        // onClick={handleVerification}
                        onClick={()=>handleCheckingVATNumber()}
                        // onClick={()=>setButtonType('check')}
                      >
                        {/* Check */}
                        {contentDetails?.after_login?.check_btn}
                      </Button>

                      
                        {/* {isValid !== null && (
                          <p>{isValid ? 'VAT is valid' : 'VAT is not valid'}</p>
                        )} */}

                      <Button
                        variant="outline-primary"
                        className="BtnSubmit2"
                        type="submit"
                        onClick={()=>setButtonType('save')}
                      >
                        {/* Save */}
                        {contentDetails?.after_login?.save_btn}
                      </Button>
                    </Col>
                  </Row>

                             
                </div>
                </form> 

                {/* Delivery Address */}
                <form className="form" onSubmit={handleDeliveryAddressUpdate}>
                  <div className="DetailsBox">
                    {/* <h4>Delivery address</h4> */}
                    <h4>{contentDetails?.after_login?.delivery_address}</h4>
                    
                    <Row className="DetailsList">
                      <Col sm={6} className="pr-5">
                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                          <Form.Select 
                            aria-label="Default select example" 
                            id="d_title" 
                            name="title" 
                            onChange={handleChangeDeliveryAddress}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_first_name").focus();
                              }
                            }}
                          >
                            <option value="" selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                            <option value="Mr." selected={deliveryAddress?.title === "Mr."}>{contentDetails?.your_details_page_data?.Mr}</option>
                            <option value="Mrs." selected={deliveryAddress?.title === "Mrs."}>{contentDetails?.your_details_page_data?.Mrs}</option>
                            <option value="Dr." selected={deliveryAddress?.title === "Dr."}>{contentDetails?.your_details_page_data?.Dr}</option>
                            <option value="Prof.Dr." selected={deliveryAddress?.title === "Prof.Dr."}>{contentDetails?.your_details_page_data?.Prof_Dr}</option>
                          </Form.Select>
                          {deliveryAddressError.title && (<span className="error-message">{deliveryAddressError.title}</span>)}
                        </Form.Group>

                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                          <Form.Control
                            id="d_last_name"
                            name="last_name"
                            value={deliveryAddress.last_name}
                            // onChange={handleChangeDeliveryAddress}
                            onChange={(e) => {
                              const { value } = e.target;
                              // if (/^[A-Za-z]*$/.test(value))
                              if (/^[A-Za-z\s-]*$/.test(value))
                              {
                                handleChangeDeliveryAddress(e);
                              }
                            }}
                            type="text"
                            placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_company_name").focus();
                              }
                            }}
                          />
                          {deliveryAddressError.last_name && <span className="error-message">{deliveryAddressError.last_name}</span>}
                          
                        </Form.Group>

                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.address_label}</Form.Label>
                          <Form.Control
                            id="d_address"
                            name="address"
                            value={deliveryAddress.address}
                            onChange={handleChangeDeliveryAddress}
                              type="text"
                              placeholder={contentDetails?.your_details_page_data?.address_placeholder}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_country_id").focus();
                              }
                            }}
                          />
                          {deliveryAddressError.address && <span className="error-message">{deliveryAddressError.address}</span>}
                        </Form.Group>
                        
                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                          <Form.Select 
                          aria-label="Default select example" 
                          id="d_region" 
                          name="region_id"  
                          onChange={deliveryCitiesByRegionId} 
                          onKeyDown={(e)=>{
                            if(e.key === 'Enter' || e.key === 'Tab')
                            {
                              e.preventDefault();
                              document.getElementById("d_city").focus();
                            }
                          }}
                          >
                          <option value="" selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                          {selectedLegalRegionByCountryId3.length > 0 ? (
                            selectedLegalRegionByCountryId3.map((item,index)=>{
                              return(
                                <option value={item.id} selected={deliveryAddress?.region_id === item.id}>{item.name}</option>
                              )
                            })
                          ):(
                            <option disabled>{languageJson?.noRegionFound}</option>
                          )}
                          </Form.Select>
                          {deliveryAddressError.region_id && (<span className="error-message">{deliveryAddressError.region_id}</span>)}
                        </Form.Group>

                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                          <Form.Control 
                            id="d_postal_code"
                            name="postal_code"
                            value={deliveryAddress.postal_code}
                            onChange={handleChangeDeliveryAddress}
                            type="number"
                            placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_phone_number").focus();
                              }
                            }}
                          />
                          {deliveryAddressError.postal_code && <span className="error-message">{deliveryAddressError.postal_code}</span>}
                        </Form.Group>
                      </Col>

                      <Col sm={6} className="pl-5">
                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.First_Name}</Form.Label>
                          <Form.Control
                            id="d_first_name"
                            name="first_name"
                            value={deliveryAddress.first_name}
                            // onChange={handleChangeDeliveryAddress}
                            onChange={(e) => {
                              const { value } = e.target;
                              // if (/^[A-Za-z]*$/.test(value)) {
                              if (/^[A-Za-z\s-]*$/.test(value)) {
                                handleChangeDeliveryAddress(e);
                              }
                            }}
                            type="text"
                            placeholder={contentDetails?.your_details_page_data?.Enter_First_Name}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_last_name").focus();
                              }
                            }}
                          />
                          {deliveryAddressError.first_name && <span className="error-message">{deliveryAddressError.first_name}</span>}
                        </Form.Group>  

                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                          <Form.Control
                            id="d_company_name"
                            name="company_name"
                            value={deliveryAddress.company_name}
                            onChange={handleChangeDeliveryAddress}
                            type="text"
                            placeholder={contentDetails?.your_details_page_data?.companyName_placeholder}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_address").focus();
                              }
                            }}
                          />
                          {deliveryAddressError.company_name && <span className="error-message">{deliveryAddressError.company_name}</span>}
                        </Form.Group>  

                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.country_label}</Form.Label>
                          <Form.Select 
                            id="d_country_id"
                            aria-label="Default select example" 
                            name="country_id" 
                            onChange={deliveryStatesByCountryId}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_region").focus();
                              }
                            }}
                          >
                          <option value="" selected hidden>{contentDetails?.your_details_page_data?.countrySelectBoxDefaultText}</option>
                          {showAllCountries2.map((item,index)=>{
                            return(
                              <option value={item.id} selected={deliveryAddress?.country_id === item.id}>{item.name}</option>
                            )
                          })}
                          </Form.Select>
                          {deliveryAddressError.country_id && <span className="error-message">{deliveryAddressError.country_id}</span>}
                        </Form.Group>

                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                          <Form.Select 
                            id="d_city"
                            aria-label="Default select example" 
                            name="city_id" 
                            onChange={handleChangeDeliveryAddress}
                            onKeyDown={(e)=>{
                              if(e.key === 'Enter' || e.key === 'Tab')
                              {
                                e.preventDefault();
                                document.getElementById("d_postal_code").focus();
                              }
                            }}
                          >
                          <option value="" selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                          {selectedCityWithId3?.length > 0 ? (
                            selectedCityWithId3?.map((item,index)=>{
                              return(
                                <option value={item.id} selected={deliveryAddress?.city_id === item.id}>{item?.name}</option>
                              )})
                          ):(
                            <option disabled>{languageJson?.cityNotFound}</option>
                          )}
                          </Form.Select>
                          {deliveryAddressError.city_id && <span className="error-message">{deliveryAddressError.city_id}</span>}
                        </Form.Group>
                          
                        <Form.Group className="mb-4 text-left">
                          <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              {deliveryPhoneCode? `+${deliveryPhoneCode}`:null}
                            </InputGroup.Text>
                            <Form.Control
                              id="d_phone_number"
                              type="number"
                              placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                              name="phone_number"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={deliveryAddress.phone_number}
                              onChange={(e) => {
                                if (e.target.value.length <= 11) {
                                  handleChangeDeliveryAddress(e);
                                }
                              }}
                            />
                          </InputGroup>
                          {deliveryAddressError.phone_number && <span className="error-message">{deliveryAddressError.phone_number}</span>}
                        </Form.Group>
                      </Col>
                    </Row>
                      <button type="submit" class="BtnSubmit2 btn btn-outline-primary">{contentDetails?.after_login?.delivery_btn}</button>
                  </div>
                </form> 

              </div>
              <Modal
                show={deleteModalShow}
                onHide={deleteModalClose}
                backdrop="static"
                keyboard={false}
                centered
                className="CustomModal delCustomModal"
              >
                <Modal.Header closeButton>
                  {/* <Modal.Title>Delete Account</Modal.Title> */}
                  <Modal.Title>{contentDetails?.after_login?.deleteAccount}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h3>{contentDetails?.after_login?.deleteAccount_desc}</h3>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={deleteModalClose} className="lftBtn">{contentDetails?.after_login?.delete_button_no}</Button>
                  <Button variant="primary" className="rgtBtn"  onClick={()=>deleteAccount()}>{contentDetails?.after_login?.delete_button_yes}</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountSettings;
