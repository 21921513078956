import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
// import bluelogo from "../../assets/images/BlueLogo.svg";
import newlogo from "../../assets/images/Clever-New-Logo.png";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./Mainheader.scss";
import { FaShoppingCart } from "react-icons/fa";
import NavDropdown from "react-bootstrap/NavDropdown";
import PhnIcon from "../../assets/images/PhnIcon.svg";
import EMailIcon from "../../assets/images/EMailIcon.svg";
import Image from "react-bootstrap/Image";


import { getAllLanguages, getPageContent} from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { useLangContext } from "../../context/languageContext";

const Mainheader = () => {
  const navigate = useNavigate();
  const {hostLanguage,setHostLanguage,languageJson, changeLanguage} = useLangContext();
  let userData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
  const [language,setLanguage] = useState([]);
  const [selectedLanguage,setSelectedLanguage] = useState('DE');
  const [contentDetails,setContentDetails] = useState();

  const [tokenexist, setTokenexist] = useState(false);
  const [glexist, setGlexist] = useState(false);
  const GetCookie = () => {
    //alert(Cookies.get("token"));
    setTokenexist(Cookies.get("showCookieNotify"));

    setGlexist(Cookies.get("allowCookieTranslate"));

    if(Cookies.get("googtrans")){
      setSelectedLanguage(Cookies.get("googtrans"));
    }
  };

  const setLanguages = async (l) => {
    setSelectedLanguage(l)
      if(glexist){
        Cookies.set("googtrans", l, {
          expires: 1,
        });
      }
    
    //alert(l);
  }
  
  const getLanguages = async () => {
    let apiRes = await getAllLanguages();
    let responseData = await apiRes.json();

    if(responseData.res)
    {
      setLanguage(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg,'',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  

  useEffect(()=>{
    console.warn(languageJson)
    GetCookie();
    getLanguages();
    getAllPageContent();
    console.log(userData);
  },[hostLanguage])

      // working
    //   useEffect(() => {
    //     const googleTranslateElementInit = () => {
    //         let cookieLang = "";
    //         const userLang = navigator.language || navigator.userLanguage;
    //         console.log("The language is: " + userLang);
    //         if (userLang.indexOf("-") !== -1) {
    //             const Lang = userLang.split("-");
    //             cookieLang = Lang[0];
    //         } else {
    //             cookieLang = userLang;
    //         }
    
    //         new window.google.translate.TranslateElement({
    //             pageLanguage: 'en',
    //             // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
    //         }, 'google_translate_element');
    //     }
    
    //     if (!window.google) {
    //         const script = document.createElement('script');
    //         script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //         script.async = true;
    //         document.body.appendChild(script);
    //         script.onload = googleTranslateElementInit;
    //     } else {
    //         googleTranslateElementInit();
    //     }
    // }, []);

  return (
    <div id="WebHeader">
      <Navbar expand="lg" className="MainHr">
        <Container fluid className="MainHrContainer">
          <Navbar.Brand href="">
            {/* <img src={bluelogo} className="MainLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/> */}
            <img src={newlogo} className="MainLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/>
          </Navbar.Brand>

          <div className="MainHrLft">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="MainHrLftMenu">
                <NavDropdown
                  // title="Product"
                  // title="Produkte"
                  title={contentDetails?.header.parent_0}
                  id="basic-nav-dropdown"
                  className="MenuDrp"
                >
                  <NavDropdown.Item>
                    <Link to="/Product">
                      {" "}
                      <Image src={EMailIcon} className="PhoneBig" alt="PhoneBig" />
                      {/* Cleverdomizil Mail */}
                      {/* Digitale Postfach- & Geschäftsadresse */}
                      {contentDetails?.header.child_0_0}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/ProductNumber">
                      {" "}
                      <Image
                        src={PhnIcon}
                        className="PhoneBig"
                        alt="PhoneBig"
                      />
                      {/* Cleverdomizil Number */}
                      {/* Lokale Rufnummer */}
                      {contentDetails?.header.child_0_1}
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  // title="Service"
                  // title="Leistungen"
                  title={contentDetails?.header.parent_1}
                  id="basic-nav-dropdown"
                  className="MenuDrp"
                >
                  <NavDropdown.Item>
                    <Link to="/tax-service">
                      {" "}
                      {/* <Image src={EMailIcon} className="PhoneBig" alt="PhoneBig" /> */}
                      {/* Tax & Accounting Service */}
                      {/* Steuer- & Buchhaltungsservice */}
                      {contentDetails?.header.child_1_0}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/incorporation-service">
                      {" "}
                      {/* <Image
                        src={PhnIcon}
                        className="PhoneBig"
                        alt="PhoneBig"
                      /> */}
                      {/* Incorporation service */}
                      {/* Gründungsservice */}
                      {contentDetails?.header.child_1_1}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/student-portal">
                      {" "}
                      {/* <Image
                        src={PhnIcon}
                        className="PhoneBig"
                        alt="PhoneBig"
                      /> */}
                      {/* Incorporation service */}
                      {/* Gründungsservice */}
                      {contentDetails?.header.child_1_2}
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>

                {/* <Link to="/LocationsWithoutMap">Locations</Link> */}
                {/* <Link to="/LocationsWithoutMap">Standorte</Link>
                <Link to="/Blog">Blog</Link>
                <Link to="/PricingMailbox">Preise</Link> */}
                {/* <Link to="/about-us">About us</Link> */}
                {/* <Link to="/about-us">Über uns</Link> */}

                <Link to="/LocationsWithoutMap">{contentDetails?.header.parent_2}</Link>
                <Link to="/Blog">{contentDetails?.header.parent_3}</Link>
                <Link to="/PricingMailbox">{contentDetails?.header.parent_4}</Link>
                <Link to="/about-us">{contentDetails?.header.parent_5}</Link>
              </Nav>
            </Navbar.Collapse>

            <div className="MainHrDrop">
              
              <Dropdown className="MainHrDropItem country-dropdown">
                <Dropdown.Toggle variant="" id="dropdown-en">
                  {/* EN */}
                  {/* {selectedLanguage} */}
                  {/* {hostLanguage?.toUpperCase()} */}
                  {
                    hostLanguage == "de" ? <img src='https://ipdata.co/flags/de.png'/>:
                    hostLanguage == "en" ? <img src='https://ipdata.co/flags/us.png'/>:
                    hostLanguage == "es" ? <img src='https://ipdata.co/flags/es.png'/>:
                    hostLanguage == "fa" ? <img src='https://ipdata.co/flags/ir.png'/>:
                    hostLanguage == "fr" ? <img src='https://ipdata.co/flags/fr.png'/>:
                    hostLanguage == "pt" ? <img src='https://ipdata.co/flags/pt.png'/>:
                    hostLanguage == "ru" ? <img src='https://ipdata.co/flags/ru.png'/>:
                    hostLanguage == "tr" ? <img src='https://ipdata.co/flags/tr.png'/>:
                    hostLanguage == "zh" ? <img src='https://ipdata.co/flags/cn.png'/>:''
                  }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {language?.map((item,index)=>{
                   return(
                    <Dropdown.Item>
                      {/* <a onClick={(e)=>setSelectedLanguage(item?.short_name.toUpperCase())}>{item?.short_name.toUpperCase()}<br/></a> */}
                      {/* <Link onClick={(e)=>{changeLanguage(item?.short_name);setHostLanguage(item?.short_name)}}>{item?.short_name.toUpperCase()}<br/></Link> */}
                      <Link onClick={(e)=>{changeLanguage(item?.short_name);setHostLanguage(item?.short_name)}}>
                        {/* {item?.short_name.toUpperCase()}<br/> */}
                        {
                          item?.country_code == "de" ? <img src='https://ipdata.co/flags/de.png'/>:
                          item?.country_code == "us" ? <img src='https://ipdata.co/flags/us.png'/>:
                          item?.country_code == "es" ? <img src='https://ipdata.co/flags/es.png'/>:
                          item?.country_code == "ir" ? <img src='https://ipdata.co/flags/ir.png'/>:
                          item?.country_code == "fr" ? <img src='https://ipdata.co/flags/fr.png'/>:
                          item?.country_code == "pt" ? <img src='https://ipdata.co/flags/pt.png'/>:
                          item?.country_code == "ru" ? <img src='https://ipdata.co/flags/ru.png'/>:
                          item?.country_code == "tr" ? <img src='https://ipdata.co/flags/tr.png'/>:
                          item?.country_code == "cn" ? <img src='https://ipdata.co/flags/cn.png'/>:''
                        }
                        <br/>
                      </Link>
                    </Dropdown.Item>
                    )
                })}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="MainHrDropItem">
                <Dropdown.Toggle variant="" id="dropdown-en">
                  EUR
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link>Euro</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="MainHrLftBtnGrp">
              {/* <Button variant="primary" type="button" className="CartBtn">
                <FaShoppingCart className="CartBtnIcon" />
                Cart (0)
              </Button> */}

              {userData!=undefined || userData!=null ?(
                <>
                  {userData?.role == "client" ? (
                    <>
                      <Button variant="primary" type="button" className="LogBtn" onClick={()=>navigate('/LetStarted')}>
                        {/* Clever Store */}
                        {/* Jetzt buchen */}
                        {contentDetails?.header.after_login_button_1}
                      </Button>
                  
                      <Button variant="primary" type="button" className="RegBtn" onClick={()=>navigate('/AccountSettings')}>
                        {/* AccountSettings */}
                        {/* Mein Konto */}
                        {contentDetails?.header.after_login_button_2}
                      </Button>
                    </>
                  ):
                  userData?.role == "student" && userData?.upload_file == 1 ? (
                    <Button variant="primary" type="button" className="RegBtn" onClick={()=>navigate('/student-dashboard')}>
                      {languageJson?.Dashboard}
                    </Button>
                  ):
                  userData?.role == "student" && userData?.upload_file == 0 ? (
                    <Button variant="primary" type="button" className="RegBtn" onClick={()=>navigate('/student-account-set-up')}>
                      {contentDetails?.header.after_login_button_2}
                    </Button>
                  ):
                  null}
                </>
              ):(
                <>
                  <Button variant="primary" type="button" className="LogBtn" onClick={()=>navigate('/Login')}>
                    {/* Log In */}
                    {/* Jetzt buchen */}
                    {/* Einloggen */}
                    {contentDetails?.header.before_login_button_1}
                  </Button>
              
                  <Button variant="primary" type="button" className="RegBtn" onClick={()=>navigate('/register')}>
                    {/* Register Now */}
                    {/* Mein Konto */}
                    {/* Registrieren */}
                    {contentDetails?.header.before_login_button_2}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Mainheader;
