import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "./SelectCountryPhoneNumber.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import RedCross from "../../assets/images/RedCross.svg";
import DefaultIcon from "../../assets/images/block 1.svg";
import Button from "react-bootstrap/Button";
import { HiArrowNarrowRight, HiArrowNarrowLeft, HiCheck } from "react-icons/hi";
import { useNavigate, Link } from "react-router-dom";
import {NotificationManager} from 'react-notifications'; 
import DOMPurify from 'dompurify';
import Modal from "react-bootstrap/Modal";
import TickBlue from "../../assets/images/TickBlue.svg";
import Remove from "../../assets/images/Remove.svg";
import InputGroup from "react-bootstrap/InputGroup";

import { getProductPhoneCountries, getPhoneAreaByCountryId, getPhoneFeaturesByCountryId, 
         getPhoneNumbers, getUserStatus, completeStep3_phone , getCountry, getUserBillingDetails,
         getStateByCountryId, getCityByRegionId, DeleteProductListStep4, getPageContent, 
         getLegalRegionByCountryId,
         BillingAddressUpdate} from "../../api/apiRequest";
import { useLangContext,languageJson } from "../../context/languageContext";

const SelectCountryPhoneNumber = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();

  const [selectedValue, setSelectedValue] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedAreaById, setSelectedAreaById] = useState();
  const [billingModal, setBillingModal] = useState(false);
  const billingModalClose = () => setBillingModal(false);


  const [selectedCountries,setSelectedCountries] = useState([]); // fetch countries
  const [selectedCountryId,setSelectedCountryId] = useState(); // selected country
  const [selectedAreas,setSelectedAreas] = useState([]); // fetch areas
  const [showError,setShowError] = useState(false);
  const [errorList,setErrorList] = useState({
    // country: 'Please Select Country',
    // area: 'Please Select Area',
    // terms: 'Please accept the Terms',
    // phone_used_for: 'Please Select the purpose',
    // phone: 'Please Select Mobile Number'
    country: languageJson?.select_country_validation,
    area: languageJson?.select_area_validation,
    terms: languageJson?.accept_the_terms_validation,
    phone_used_for: languageJson?.select_purpose_validation,
    phone: languageJson?.select_mobile_number_validation
  });
  const [acceptError,setAcceptError] = useState(false);
  const [acceptTerms,setAcceptTerms] = useState(false);
  const [phoneFeatures,setPhoneFeatures] = useState([]); // phone features
  const [selectedFeesDetails,setSelectedFeesDetails] = useState(); // fees details
  const [phoneArr,setPhoneArr] = useState([]); // phone number array
  const [uploadFileCount, setUploadFileCount] = useState(0); //number of file count
  const [isUpload, setIsUpload] = useState(false);
  const [fileUploadArray,setFileUploadArray] = useState([]);

  const [formDataError] = useState({
    Title: 'Select Title',
    FirstName: 'Enter First Name',
    LastName: 'Enter Last Name',
    Company: 'Enter Company Name',
    Address: 'Enter Address',
    Country: 'Select Country',
    City: 'Select City',
    PostalCode: 'Select Postal Code',
    Region: 'Please Select Region',
    AreaCode: 'Select Area Code',
    PhoneNumber: 'Enter Phone Number',
    fileuploadError: 'Please Upload a file(.pdf, .jpg, .png)',
  })
  const [showFromError,setShowFromError] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [selectedCountryInformation,setSelectedCountryInformataion] = useState();
  const [selectedPhoneId,setSelectedPhoneId] = useState();
  const [showAllCountries2,setShowAllCountries2] = useState([]);
  const [selectedLegalRegionByCountryId2,setLegalRegionByCountryId2] = useState([]);
  const [selectedCityWithId2, setSelectedCityWithId2] = useState([]);
  const [uploadingFileArr,setUploadingFileArr] = useState([]);
  const [uploadingAnotherFileArr,setUploadingAnotherFileArr] = useState([]);
  const [phoneUsedFor,setPhoneUsedFor] = useState();
  const [lastUserStatus,setLastUserStatus] = useState(); //for id
  const [verificationTitle,setVerificationTitle] = useState();
  const [verificationFirstName,setVerificationFirstName] = useState();
  const [verificationLastName,setVerificationLastName] = useState();
  const [verificationCompanyName,setVerificationCompanyName] = useState();
  const [verificationAddress,setVerificationAddress] = useState();
  const [verificationCountry,setVerificationCountry] = useState();
  const [verificationRegion,setVerificationRegion] = useState();
  const [verificationCity,setForwardingAddressCity] = useState();
  const [verificationPostalCode,setVerificationPostalCode] = useState();
  const [verificationPhoneNumber,setVerificationPhoneNumber] = useState();
  const [mandatoryFile,setMandatoryFile]= useState(false);

  const [contentDetails,setContentDetails] = useState();
  const [selectedLegalRegionByCountryId,setLegalRegionByCountryId] = useState([]);
  const [formDataBillingAddress, setformDataBillingAddress] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    phonecode: "",
    region_id: "",
    vat_number:"",
  });

  const [formErrorsBillingAddress, setFormErrorsBillingAddress] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  });

  const [billingPhoneCode, setBillingPhoneCode] = useState(); // this is for billing country code
  const [billingAddressExist,setBillingAddressExist] = useState(false);

  const [billAddError] = useState({
    title: languageJson?.title_validation,
    first_name: languageJson?.first_name_validation,
    last_name: languageJson?.last_name_validation,
    company_name: languageJson?.company_name_validation,
    address: languageJson?.address_validation,
    country: languageJson?.select_country_validation,
    region: languageJson?.select_region_validation,
    city: languageJson?.select_city_validation,
    postal_code: languageJson?.postal_code_validation,
    phone_number: languageJson?.phone_number_validation
  })

  const handleBack = () => {
    navigate("/LetStarted");
  };

  const fetchBillingDetails = async () => {
    try
    {
      let apiRes = await getUserBillingDetails();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        if(responseData.data.length > 0)
        {
          setBillingAddressExist(true);
          setBillingModal(false);
          setformDataBillingAddress(
            {
              first_name: responseData.data[0].first_name,
              last_name: responseData.data[0].last_name,
              company_name: responseData.data[0].company_name,
              title: responseData.data[0].title,
              address: responseData.data[0].address,
              city_id: responseData.data[0].city_id,
              country_id: responseData.data[0].country_id,
              postal_code: responseData.data[0].postal_code,
              phone_number: responseData.data[0].phone_number,
              region_id: responseData.data[0].region_id,
              vat_number: responseData.data[0].vat_number,
            }
          );
          setBillingPhoneCode(responseData.data[0].phonecode);
          handleRegionsByCountryId(responseData.data[0].country_id);
          getCityByRegionData(responseData.data[0].region_id);
        }
        else
        {
          // console.log(responseData.data.length)
          setBillingAddressExist(false);
        }
      }
      else
      {
        console.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const validateFormBillingAddress = () => {
    let isValid = true;
    const errors = {
      first_name: "",
      last_name: "",
      company_name: "",
      title: "",
      address: "",
      city_id: "",
      country_id: "",
      postal_code: "",
      phone_number: "",
      region_id: "",
      vat_number:"",
    };
    
    if (formDataBillingAddress.first_name === "")
    {
      errors.first_name =  billAddError?.first_name;
      isValid = false;
    }
  
    if (formDataBillingAddress.last_name === "")
    {
      errors.last_name = billAddError?.last_name;
      isValid = false;
    }
  
    if (formDataBillingAddress.company_name === "")
    {
      errors.company_name = billAddError?.company_name;
      isValid = false;
    }
  
    if (formDataBillingAddress.title === "")
    {
      errors.title = billAddError?.title;
      isValid = false;
    }
  
    if (formDataBillingAddress.city_id === "")
    {
      errors.city_id = billAddError?.city;
      isValid = false;
    }
  
    if (formDataBillingAddress.region_id === "")
    {
      errors.region_id = billAddError?.region;
      isValid = false;
    }
  
    if (formDataBillingAddress.country_id === "")
    {
      errors.country_id = billAddError?.country;
      isValid = false;
    }
  
    if (formDataBillingAddress.postal_code === "")
    {
      errors.postal_code = billAddError?.postal_code;
      isValid = false;
    }
  
    if (formDataBillingAddress.address === "")
    {
      errors.address = billAddError?.address;
      isValid = false;
    }
  
    if (formDataBillingAddress.phone_number === "")
    {
      errors.phone_number = billAddError?.phone_number;
      isValid = false;
    }
    setFormErrorsBillingAddress(errors);
    return isValid;
  };

  const handleChangeBillingAddress = e => {
    const {name, value} = e.target;
    setformDataBillingAddress({...formDataBillingAddress, [name]: value});
    setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
  };
  
  const handleCountryTOState = e => {
    handleRegionsByCountryId2(e.target.value);
    const {name, value} = e.target;
    setformDataBillingAddress({...formDataBillingAddress, [name]: value});
    setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
    const foundItem = showAllCountries2.find(item => item.id === parseInt(e.target.value));
    setBillingPhoneCode(foundItem.phonecode);
  };

  const handleRegionsByCountryId = async (country_id) => {
    try
    {
      let apiRes = await getLegalRegionByCountryId(country_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setLegalRegionByCountryId(responseData);
      }
      else
      {
        console.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  
  const handleRegionTOCity = e => {
    getCityByRegionData(e.target.value);
    const {name, value} = e.target;
    setformDataBillingAddress({...formDataBillingAddress, [name]: value});
    setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
  };

  // phone countries
  const handleGetPhoneCountries = async() =>
  {
    try
    {
      let apiRes = await getProductPhoneCountries();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setSelectedCountries(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const deleteRecord = async() =>{
    try
    {
      let apiRes = await DeleteProductListStep4(lastUserStatus);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        navigate('/Dashboard')
      }
      else
      {
        navigate('/Dashboard');
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  // area list
  const handleAreasByCountryId = async (country_id) => {
    try
    {
      setSelectedAreas([]);
      setSelectedAreaById();

      let apiRes = await getPhoneAreaByCountryId(country_id,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setSelectedAreas(responseData.data);
        const index = selectedCountries.find(item => parseInt(country_id) === parseInt(item?.id));
        setCountryCode(index.country_code);
        setSelectedCountryInformataion(index);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // area details
  const handleAreaWithDetails = async(e) => {
    setSelectedPhoneId();
    let data = e.target.value;
    let split_data = data.split('-');
    setSelectedAreaById(split_data[0]); //id

    handlePhoneFeaturesByCountryId(split_data[1]); //country_id
    const index = selectedAreas.findIndex(item => parseInt(split_data[0]) === parseInt(item?.id));
    // console.warn(selectedAreas[index]);
    setSelectedFeesDetails(selectedAreas[index]);
    handleGetPhoneNumbers(split_data[1],split_data[0]);
    // console.log(selectedAreas[index]);
  }

  const handleBillingAddressUpdate = async() => {
    if (validateFormBillingAddress())
    {
      try
      {
        let temp_obj = {...formDataBillingAddress};
        temp_obj.phonecode = billingPhoneCode;
        setformDataBillingAddress(temp_obj);
        let apiRes = await BillingAddressUpdate(temp_obj);
        let responseData = await apiRes.json();
        if(responseData.res)
        { 
          if(responseData.res)
          {
            NotificationManager.success(contentDetails?.after_login?.billing_addr_succ_msg, '',2000);
            fetchBillingDetails();
            billingModalClose();
            setBillingModal(false);
          }
          else
          {
            console.error(responseData.msg);
            setBillingModal(false);
          }
        }
        else
        {
          console.error(responseData.msg);
          setBillingModal(false);
        }
      }
      catch(error)
      {
        console.error(error);
      }
    }
  }

  const redirectURL = () => {
    if(validateFormBillingAddress())
    {
      handleBillingAddressUpdate();
    }
  };

// area list
const handlePhoneFeaturesByCountryId = async (country_id) => {
  try
  {
    let apiRes = await getPhoneFeaturesByCountryId(country_id,hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      setPhoneFeatures(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const handleCountryList2 = async() =>
{
  try
  {
    let apiRes = await getCountry();
    let responseData = await apiRes.json();
    if(responseData)
    {
      setShowAllCountries2(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const handleRegionsByCountryId2 = async (country_id) => {
  try
  {
    let apiRes = await getStateByCountryId(country_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setLegalRegionByCountryId2(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const getCityByRegionData = async (region_id) => {
  try
  {
    let apiRes = await getCityByRegionId(region_id);
    let responseData = await apiRes.json();
    if(responseData)
    {
      setSelectedCityWithId2(responseData);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

// get all phone numbers
const handleGetPhoneNumbers = async (country_id,area_id) => {
  try
  {
    let apiRes = await getPhoneNumbers(country_id,area_id);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      setPhoneArr(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

const convertHtml = (data) => {
  // console.warn(data)
    const sanitizedHTML = DOMPurify.sanitize(data);
    // console.warn(sanitizedHTML)
    return sanitizedHTML;
};

// Add more file
const handleAddMoreFile = () => {
  // console.warn(uploadingFileArr);
  // console.warn(uploadingAnotherFileArr);
  // console.warn(uploadingAnotherFileArr.length);

  const newInput = {
    id: uploadingAnotherFileArr.length + 1,
    file: null,
  };
  setUploadingAnotherFileArr([...uploadingAnotherFileArr, newInput]);


  if(uploadingAnotherFileArr.length < 4 )
  {
    setIsUpload(true);
    setUploadFileCount(uploadingAnotherFileArr.length + 1);
    setFileUploadArray(prev => [...prev,[]]);
  }
  else
  {
    // console.warn(1);
    setIsUpload(true);
  }
}

// upload two mandatory files
const handleUploadingFile = (e) =>{
  if(e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')
  {
    setMandatoryFile(false);
    let fileArr = [...uploadingFileArr];
    fileArr.push(
      {
        file: e.target.files[0],
        fileName: e.target.files[0].name,
      }
    )
    setUploadingFileArr(fileArr);
  }
  else
  {
    e.target.value = '';
    setMandatoryFile(true);
    NotificationManager.error('Preferred file formats are PDF, JPG, and PNG.', '',3000);
  }
  
}

// upload another 3 files
const handleAnotherUploadingFiles = (e,id) =>{
  if(e.target.files[0].type == 'application/pdf' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png')
  {
    const newInputs = [...uploadingAnotherFileArr];
      const index = newInputs.findIndex((input) => input.id === id);
      if (index !== -1)
      {
        newInputs[index].file = e.target.files[0];
        setUploadingAnotherFileArr(newInputs);
      }
  }
  else
  {
    e.target.value = '';
    NotificationManager.error('Preferred file formats are PDF, JPG, and PNG.', '',3000);
  }
}

//delete another 3 file
const handleDeleteAnotherFile = (id) =>{    
 const newInputs = uploadingAnotherFileArr.filter((input) => input.id !== id);
 setFileUploadArray(newInputs);
 setUploadingAnotherFileArr(newInputs);
}

// console.warn(uploadingAnotherFileArr)

//phone number used purpose
const handlePhoneUsedFor = (value) =>{
  setPhoneUsedFor(value);
}

//get user status
const handleUserStatus = async () => {
  try
  {
    let apiRes = await getUserStatus();
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      setLastUserStatus(responseData.data?.id); // last user_status_id
    }
    else
    {
      NotificationManager.error(responseData.msg, '',2000);
    }
  }
  catch(error)
  {
    // NotificationManager.error('Something went wrong!', '',2000);
  }
}

// submit details(step3-phone)
const handleProceedStep3 = async() => {
  try
  {
    const formData = new FormData();
    if(phoneUsedFor == undefined || selectedCountryId == undefined || selectedAreaById == undefined || selectedPhoneId == undefined)
    {
      setShowError(true);
      NotificationManager.error(languageJson?.blank_field_is_not_allowed, '',2000);
      window.scrollTo(500, 0);
    }
    else
    {
      if(selectedFeesDetails?.show_verification === 1)
      {
        if(
          verificationTitle == undefined || verificationFirstName == undefined || verificationLastName == undefined ||
          verificationCompanyName == undefined || verificationAddress == undefined || verificationCountry == undefined ||
          verificationRegion == undefined || verificationCity == undefined || verificationPostalCode == undefined ||
          verificationPhoneNumber == undefined || uploadingFileArr[0] == undefined || uploadingFileArr[1]== undefined
        )
        {
          setShowFromError(true);
          NotificationManager.error(languageJson?.blank_field_is_not_allowed, '',2000);
        }
        else
        {
          if(acceptTerms)
          {
            setShowFromError(false);
            setAcceptError(false);
            formData.append('id', lastUserStatus);
            formData.append('purpose', phoneUsedFor);
            formData.append('phone_county_id', selectedCountryId);
            formData.append('phone_area_id', selectedAreaById);
            formData.append('phone_id', selectedPhoneId);
            formData.append('verification', selectedFeesDetails?.show_verification);
            formData.append('title', selectedFeesDetails?.show_verification === 1 ? verificationTitle : '');
            formData.append('first_name', selectedFeesDetails?.show_verification === 1 ? verificationFirstName : '');
            formData.append('last_name', selectedFeesDetails?.show_verification === 1 ? verificationLastName : '');
            formData.append('company_name', selectedFeesDetails?.show_verification === 1 ? verificationCompanyName : '');
            formData.append('address', selectedFeesDetails?.show_verification === 1 ? verificationAddress : '');
            formData.append('country_id', selectedFeesDetails?.show_verification === 1 ? verificationCountry : '');
            formData.append('region_id', selectedFeesDetails?.show_verification === 1 ? verificationRegion : '');
            formData.append('city_id', selectedFeesDetails?.show_verification === 1 ? verificationCity : '');
            formData.append('postal_code', selectedFeesDetails?.show_verification === 1 ? verificationPostalCode : '');
            formData.append('phone_number', selectedFeesDetails?.show_verification === 1 ? verificationPhoneNumber : '');
            formData.append('document1', selectedFeesDetails?.show_verification === 1 ? uploadingFileArr[0].file : '');
            formData.append('document2', selectedFeesDetails?.show_verification === 1 ?uploadingFileArr[1].file : '');
            uploadingAnotherFileArr.forEach((item, index) => {
              selectedFeesDetails?.show_verification === 1 ? (
                formData.append(`document${index + 3}`, item.file)
              ):(
                formData.append(`document${index + 3}`, '')
              )
            })
        
              let apiRes = await completeStep3_phone(formData);
              if(apiRes)
              {
                navigate("/Payment");
                setShowError(false);
                setShowFromError(false);
              }
              else
              {
                NotificationManager.error(apiRes.msg, '',2000);
              }
          }
          else
          {
            setAcceptError(true);
          }
        }
      }
      else
      {
        if(billingAddressExist)
        {
          setBillingModal(false);
          setShowFromError(false);
          setAcceptError(false);
          formData.append('id', lastUserStatus);
          formData.append('purpose', phoneUsedFor);
          formData.append('phone_county_id', selectedCountryId);
          formData.append('phone_area_id', selectedAreaById);
          formData.append('phone_id', selectedPhoneId);
          formData.append('verification', selectedFeesDetails?.show_verification);
        
          let apiRes = await completeStep3_phone(formData);
          if(apiRes)
          {
            navigate("/Payment");
            setShowError(false);
            setShowFromError(false);
          }
          else
          {
            NotificationManager.error(apiRes.msg, '',2000);
          }
        }
        else
        {
          fetchBillingDetails();
          setBillingModal(true);
          setFormErrorsBillingAddress({});
        }
      }
    }
  }
  catch(error)
  {
    console.error(error);
  }
};

// get page content
const getAllPageContent = async() => {
  try
  {
    let apiRes = await  getPageContent(hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      setContentDetails(responseData.data);
      // console.warn(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg,'',2000);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

useEffect(()=>{
  handleGetPhoneCountries();
  handleUserStatus();
  handleCountryList2();
  getAllPageContent();
  fetchBillingDetails();
},[hostLanguage])

  return (
    <div className="lightBlue">
      <Header />
      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={5}>
              {/* <h2>Set up your account</h2> */}
              <h2>{contentDetails?.lets_start_page_data?.set_up_your_account}</h2>
            </Col>
            <Col sm={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Let's Started</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    {/* <span class="form-steps__step-number">02</span> */}
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Select Product</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.select_product}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">03</span>
                  </div>
                  {/* <span class="form-step__step-name">Your Details</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.your_details}</span>
                </div>

                <div class="form-steps__step form-steps__step--last">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">04</span>
                  </div>
                  {/* <span class="form-step__step-name">Payment</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.payment}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={12}>
              {/* <h4>Buy a cleverdomizil Number</h4> */}
              <h4>{contentDetails?.your_details_page_data?.Buy_a_cleverdomizil_Number}</h4>
            </Col>
          </Row>
        </div>

        <div className="container mb-4">
          <Row className="">
            <Col sm={6}>
              <div className="FrmBody FrmBodyLft">
                {/* <h2>Phone number is used for:</h2> */}
                <h2>{contentDetails?.your_details_page_data?.Phone_number_is_used_for}</h2>
                {/* <Form.Check type="radio" label='For myself' name="phone_no_purpose" value="Myself" onClick={(e)=>handlePhoneUsedFor(e.target.value)}/> */}
                <Form.Check type="radio" label={contentDetails?.your_details_page_data?.For_myself_radio_label} name="phone_no_purpose" value="Myself" onClick={(e)=>handlePhoneUsedFor(e.target.value)}/>
                {/* <Form.Check type="radio" label='On behalf of another person or for my company' name="phone_no_purpose" value="Another Person" onClick={(e)=>handlePhoneUsedFor(e.target.value)}/> */}
                <Form.Check type="radio" label={contentDetails?.your_details_page_data?.On_behalf_of_another_person_or_for_my_company_radio_label} name="phone_no_purpose" value="Another Person" onClick={(e)=>handlePhoneUsedFor(e.target.value)}/>
                {showError && phoneUsedFor == undefined && <span className="error" style={{marginRight: '250px'}}>{errorList.phone_used_for}</span>}
                {/* <h2 style={{marginTop: '18px'}}>Choose location for your Number</h2> */}
                <h2 style={{marginTop: '18px'}}>{contentDetails?.your_details_page_data?.Choose_location_for_your_Number}</h2>
                <p>
                  {/* Product details on the right will change according to your
                  choice */}
                  {contentDetails?.your_details_page_data?.Product_details_on_the_right_will_change_according_to_your_choice}
                </p>

                <div>
                  <Form.Group
                    className="mb-5 text-left"
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    {/* <Form.Label>Country</Form.Label> */}
                    <Form.Label>{contentDetails?.your_details_page_data?.Country}</Form.Label>
                    <Form.Select aria-label="Default select example" name="country" onChange={(e)=>{handleAreasByCountryId(e.target.value);setSelectedCountryId(e.target.value)}}>
                      {/* <option selected hidden>Select Country</option> */}
                      <option selected hidden>{contentDetails?.your_details_page_data?.countryOptionalSelectBoxDefaultText}</option>
                      {selectedCountries?.map((item,index)=>{
                        return(
                          <option value={item?.id}>{item?.name}</option>
                        )
                      })}
                    </Form.Select>
                    {showError && selectedCountryId == undefined && <span className="error">{errorList.country}</span>}
                  </Form.Group>
                  <Form.Group className="mb-5 text-left">
                    {/* <Form.Label>Area</Form.Label> */}
                    <Form.Label>{contentDetails?.your_details_page_data?.Area}</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e)=> handleAreaWithDetails(e)}>
                      {/* <option selected hidden>Select Area</option> */}
                      <option selected hidden>{contentDetails?.your_details_page_data?.Area_select_default_text}</option>
                      {selectedAreas.length > 0 ?(
                        selectedAreas?.map((item,index)=>{
                          return(
                            // <option value={item?.id}>{item?.area}</option>
                            // <option value={`${item.id}-${item.country_id}`} key={index}>{item?.area}</option>
                            <option value={`${item.id}-${item.country_id}`} key={index}>{item?.area_text}</option>
                          )
                        })
                      ):(
                          // <option disabled>No Area Found</option>
                          <option disabled>{languageJson?.noAreaFound}</option>
                      )}
                    </Form.Select>
                    {showError && selectedAreaById == undefined && <span className="error">{errorList.area}</span>}
                  </Form.Group>
                  {!selectedCountry || !selectedAreaById ? (
                    <>
                      {/* <h2 className="mb-3">
                        Limitations & verification requirements
                      </h2> */}
                      {selectedFeesDetails?.show_condition == 1 ?(
                        <div className="form-check checkFrm mb-4">
                          <Form.Check
                            aria-label="option 1"
                            id="flexCheckChecked3"
                            // onChange={(e)=>{alert(e.target.value);setAcceptTerms(!acceptTerms)}}
                            // onClick={alert(1)}
                          />
                          <Form.Label
                            className="form-check-label"
                            for="flexCheckChecked3"
                          >
                            {/* I have read and understood the verification
                            requirements I am aware that the phone number will
                            only be activated, after the verification is completed */}
                            {contentDetails?.after_login?.read_and_understood_desc}
                          </Form.Label>
                          {/* {showError && !selectedAreaById && <span className="error">{errorList.terms}</span>} */}
                        </div>
                      ):(
                        <></>
                      )}
                    </>
                  ) : null}

                  {selectedCountry && selectedAreaById && selectedFeesDetails.show_condition == 1 && selectedFeesDetails.show_verification == 1? (
                    <div className="newChwckDiv">
                      {/* <h5>Limitations & verification requirements</h5> */}
                      <h5>{contentDetails?.after_login?.limitations_and_verification_requirements}</h5>

                      <div className="newChwckDivTxtBox">
                        {/* <p>
                          Legal Restriction(s):- -For geographical numbers, the
                          location of the end user (place of residence/business)
                          corresponding to relevant geographic zone may be
                          required. This information will be sent to
                          cleverdomizil within 24 hours of receiving the
                          request, otherwise the number may be disconnected
                        </p> */}

                        {/* <p>
                          -Phone numbers cannot be used for post-paid calling
                          card applications.
                        </p> */}

                        {/* <p>
                          -Customers providing ECS (Electronic communications
                          services) must be notified operators.
                        </p> */}
                        {/* <p>
                          Service Restriction(s): -Toll-free numbers are not
                          reachable from other countries than Austria.
                        </p> */}
                        {/* <hr/> */}
                        {/* <p dangerouslySetInnerHTML={{ __html: selectedCountryInformation?.legal_restrictions || '' }}></p> */}
                        {/* <p dangerouslySetInnerHTML={{ __html: convertHtml()}}></p> */}
                        {/* <p>{convertHtml()}</p> */}
                        {/* <p>{selectedCountryInformation.legal_restrictions}</p> */}
                        <p dangerouslySetInnerHTML={{ __html: convertHtml(selectedCountryInformation.legal_restrictions)}} />
                      </div>

                      <div className="newFrmBoxBottom">
                        {/* <h5>Local address verification required</h5>
                        <p>
                          Please note, that a local address verification
                          requires you to have already a verifiable address in
                          the same city where you book the phone number. Use
                          that address here for verification
                        </p> */}
                        <h5>{contentDetails?.after_login?.local_address_verification_required}</h5>
                        <p>{contentDetails?.after_login?.local_address_desc}</p>

                        <div className="newFrmBoxBottomFrm">
                          <Form.Group className="mb-4 FrmRgt">
                            <Form.Label>Title</Form.Label>
                            <Form.Select aria-label="Default select example" name="title" onChange={(e)=>setVerificationTitle(e.target.value)}>
                              <option value="" selected hidden> Select Title</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Dr.">Dr.</option>
                              <option value="Prof.Dr.">Prof.Dr.</option>
                            </Form.Select>
                            {showFromError && verificationTitle == undefined && <span className="error">{formDataError.Title}</span>}
                          </Form.Group>
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>First Name</Form.Label>
                            
                            <Form.Control
                              type="text"
                              placeholder="Enter First Name"
                              value={verificationFirstName}
                              onChange={(e)=>setVerificationFirstName(e.target.value)}
                            />
                            {/* {showFromError && verificationFirstName == undefined && <span className="error">{formDataError.FirstName}</span>} */}
                            {showFromError && (verificationFirstName === undefined || verificationFirstName.trim() === '') && (
                              <span className="error">{formDataError.FirstName}</span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Last Name"
                              value={verificationLastName}
                              onChange={(e)=>setVerificationLastName(e.target.value)}
                            />
                            {/* {showFromError && verificationLastName == undefined && <span className="error">{formDataError.LastName}</span>} */}
                            {showFromError && (verificationLastName === undefined || verificationLastName.trim() === '') && (
                              <span className="error">{formDataError.LastName}</span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Company Name"
                              value={verificationCompanyName}
                              onChange={(e)=>setVerificationCompanyName(e.target.value)}
                            />
                            {/* {showFromError && verificationCompanyName == undefined && <span className="error">{formDataError.Company}</span>} */}
                            {showFromError && (verificationCompanyName === undefined || verificationCompanyName.trim() === '') && (
                              <span className="error">{formDataError.Company}</span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              value={verificationAddress}
                              onChange={(e)=>setVerificationAddress(e.target.value)}
                            />
                            {/* {showFromError && verificationAddress == undefined && <span className="error">{formDataError.Address}</span>} */}
                            {showFromError && (verificationAddress === undefined || verificationAddress.trim() === '') && (
                              <span className="error">{formDataError.Address}</span>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Country</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e)=>{setVerificationCountry(e.target.value);handleRegionsByCountryId2(e.target.value)}}>
                              <option selected hidden>Select Country</option>
                              {showAllCountries2.length > 0 ? (
                                showAllCountries2.map((item,index)=>{
                                  return(
                                    <option value={item.id}>{item.name}</option>
                                  )
                                })
                              ):(
                                <option disabled>No countries found</option>
                              )}
                            </Form.Select>
                            {showFromError && verificationCountry == undefined && <span className="error">{formDataError.Country}</span>}
                          </Form.Group>

                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Region</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e)=>{setVerificationRegion(e.target.value);getCityByRegionData(e.target.value)}}>
                            <option selected hidden>Select Region</option>
                            {selectedLegalRegionByCountryId2.length > 0 ? (
                              selectedLegalRegionByCountryId2.map((item,index)=>{
                                return(
                                  <option value={item.id}>{item.name}</option>
                                )
                              })
                            ):(
                              // <option disabled>No regions found</option>
                              <option disabled>{languageJson?.noRegionFound}</option>
                            )}
                            </Form.Select>
                            {showFromError && verificationRegion == undefined && <span className="error">{formDataError.Region}</span>}
                          </Form.Group>

                          <Form.Group className="mb-4 text-left">
                            <Form.Label>City</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e)=>setForwardingAddressCity(e.target.value)}>
                            <option selected hidden>Select City</option>
                            {selectedLegalRegionByCountryId2.length > 0 ? (
                              selectedCityWithId2?.map((item,index)=>{
                              return(
                                <option value={item.id}>{item?.name}</option>
                              )})
                            ):(
                              <option disabled>No cities found</option>
                            )}
                            </Form.Select>
                            {showFromError && verificationCity == undefined && <span className="error">{formDataError.City}</span>}
                          </Form.Group>

                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Postal Code"
                              value={verificationPostalCode}
                              onChange={(e)=>setVerificationPostalCode(e.target.value)}
                            />
                            {/* {showFromError && verificationPostalCode == undefined && <span className="error">{formDataError.PostalCode}</span>} */}
                            {showFromError && (verificationPostalCode === undefined || verificationPostalCode.trim() === '') && (
                              <span className="error">{formDataError.PostalCode}</span>
                            )}
                          </Form.Group>
                          
                          {/* <Form.Group className="mb-4 text-left">
                            <Form.Label>Area Code</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select code</option>
                            </Form.Select>
                            {showFromError && <span className="error">{formDataError.AreaCode}</span>}
                          </Form.Group> */}
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Phone Number"
                              value={verificationPhoneNumber}
                              // onChange={(e)=>setVerificationPhoneNumber(e.target.value)}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  setVerificationPhoneNumber(e.target.value);
                                }
                              }}
                            />
                            {/* {showFromError && verificationPhoneNumber == undefined && <span className="error">{formDataError.PhoneNumber}</span>} */}
                            {showFromError && (verificationPhoneNumber === undefined || verificationPhoneNumber.trim() === '') && (
                              <span className="error">{formDataError.PhoneNumber}</span>
                            )}
                          </Form.Group>

                          <p>
                            <strong>
                              Please upload two personal identification
                              documents. At least one must have your photo
                              (Passport, personal ID, driver's license)​.
                            </strong>
                          </p>

                          <p>
                            <strong>
                              Please provide two different ID documents. The
                              front and back of the same document will only
                              count as one.​​
                            </strong>
                          </p>
                          <Form.Group className="mb-4 text-left">
                            <Form.Label>First ID</Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="Enter First ID"
                              onChange={(e)=>handleUploadingFile(e)}
                            />
                           {showFromError && uploadingFileArr[0] ==  undefined && <span className="error">{formDataError.fileuploadError}</span>}
                          </Form.Group>

                          <Form.Group className="mb-4 text-left">
                            <Form.Label>Second ID</Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="Enter Second ID"
                              onChange={(e)=>handleUploadingFile(e)}
                            />
                            {showFromError && uploadingFileArr[1] ==  undefined && <span className="error">{formDataError.fileuploadError}</span>}
                          </Form.Group>
                        
                          {uploadingAnotherFileArr.length < 3 ? (
                            // <a
                            //   role="button"
                            //   tabindex="0"
                            //   // href="/MailBox"
                            //   type="submit"
                            //   class="BtnSubmit2 btn btn-primary"
                            //   onClick={()=>handleAddMoreFile()}
                            // >
                            //   Add More
                            // </a>
                            <Button
                              role="button"
                              tabindex="0"
                              type="submit"
                              className="BtnSubmit2 btn btn-primary"
                              onClick={()=>handleAddMoreFile()}
                            >
                              Add More
                            </Button>

                            
                          ):(
                            <Button className="BtnSubmit2 btn btn-primary"  
                            // style={{cursor:'not-allowed'}}
                              disabled
                            >Add More</Button>
                          )}
                         
                          {uploadingAnotherFileArr.map((item,index)=>{
                            return(
                              <div key={item.id}>
                                <Form.Group className="mb-4 text-left">
                                <Form.Label>Another ID (Optional)</Form.Label>
                                {/* <a variant="danger" onClick={() => handleDeleteAnotherFile(item.id)}>DELETE</a> */}
                                {/* <Form.Control type="file" placeholder="Enter Second ID"  onChange={(e)=>handleAnotherUploadingFiles(e,item.id)}/> */}
                                {/* {showFromError && uploadingAnotherFileArr[index] && <span className="error">{formDataError.fileuploadError}</span>} */}
                                </Form.Group>

                                <InputGroup className="mb-4">
                                  {/* <Form.Control
                                    type="file"
                                    placeholder="Enter Second ID" */}
                                    <Form.Control type="file" placeholder="Enter Second ID"  onChange={(e)=>handleAnotherUploadingFiles(e,item.id)}/>
                                    <Button
                                      variant="outline-secondary"
                                      id="button-addon2"
                                      style={{marginLeft: '6px'}}
                                    >
                                      <img src={Remove} alt="Img" onClick={() => handleDeleteAnotherFile(item.id)}/>
                                    </Button>
                                </InputGroup>
                                {/* { (index == 0 ? showFileError1 :index == 1 ? showFileError2 :showFileError3) && uploadingAnotherFileArr[index] && 
                                  <span className="error">{formDataError.fileuploadError}</span>
                                } */}
                                {/* {showFromError && uploadingAnotherFileArr[index] && 
                                  <span className="error">{formDataError.fileuploadError}</span>
                                } */}

                              </div>
                            )
                          })}
                        

                          {/* {isUpload && <span className="error">You can upload a maximum of 5 files.</span>} */}

                          <div className="form-check checkFrm mb-4">
                            <Form.Check
                              aria-label="option 1"
                              id="flexCheckChecked4"
                              onChange={(e)=>setAcceptTerms(!acceptTerms)}
                            />
                            <Form.Label
                              className="form-check-label"
                              for="flexCheckChecked4"
                            >
                              I have read and understood the verification
                              requirements I am aware that the phone number will
                              only be activated, after the verification is
                              completed
                            </Form.Label>
                            {acceptError && !acceptTerms && <span className="error">{errorList.terms}</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>

            <Col sm={6}>
              {selectedCountry && selectedAreaById ? (
                <div className="FrmBody FrmBodyRgt">
                  {/* <h2>Product details</h2> */}
                  <h2>{contentDetails?.your_details_page_data?.Product_details}</h2>
                  {/* <h3>Mobile number ({phoneArr?.length})</h3> */}
                  <h3>{contentDetails?.your_details_page_data?.Mobile_number} ({phoneArr?.length})</h3>

                  <div className="PhnBox">
                    <ul>
                      {phoneArr?.map((item,index)=>{
                        return(
                          <li key={index}>
                            <div className="form-check checkFrm">
                              <Form.Check id="phn1" type="radio" name="address" value={item.id} onClick={(e)=>setSelectedPhoneId(e.target.value)}/>
                              <Form.Label className="form-check-label" for="phn1">
                                {countryCode} {selectedFeesDetails?.area_code} {item?.phone_number}
                              </Form.Label>
                            </div>
                          </li>
                        )
                      })}
                      {/* {showError && selectedPhoneId == undefined && <span className="error">{errorList.phone}</span>} */}
                    </ul>
                    {showError && selectedPhoneId == undefined && <span className="error">{errorList.phone}</span>}
                  </div>

                  <div className="Txtlist">
                    <ul>
                      <li>
                        {/* <label>Setup</label>$ 22.05 */}
                        {/* <label>Setup</label>€ {parseFloat(selectedFeesDetails?.setup_charges).toFixed(2)} */}
                        <label>{contentDetails?.your_details_page_data?.Setup}</label>€ {parseFloat(selectedFeesDetails?.setup_charges).toFixed(2)}
                      </li>
                      <li>
                        {/* <label>VAT Charges</label> {parseFloat(selectedFeesDetails?.vat_charge).toFixed(2)} % */}
                        <label>{contentDetails?.your_details_page_data?.VAT_Charges}</label> {parseFloat(selectedFeesDetails?.vat_charge).toFixed(2)} %
                      </li>
                      <li>
                        {/* <label>Monthly fee:</label>$ 10.82 per month */}
                        {/* <label>{contentDetails?.your_details_page_data?.Monthly_fee}:</label>€ {parseFloat(selectedFeesDetails?.monthly_fee).toFixed(2)} per month */}
                        <label>{contentDetails?.your_details_page_data?.Monthly_fee}:</label>€ {parseFloat(selectedFeesDetails?.monthly_fee).toFixed(2)} {contentDetails?.your_details_page_data?.per_month}
                      </li>
                      <li>
                        {/* <label>Talking fee:</label>$ 0.01 per min */}
                        {/* <label>{contentDetails?.your_details_page_data?.Talking_fee}</label>€ {parseFloat(selectedFeesDetails?.talking_fee).toFixed(2)} per min */}
                        <label>{contentDetails?.your_details_page_data?.Talking_fee}</label>€ {parseFloat(selectedFeesDetails?.talking_fee).toFixed(2)} {contentDetails?.your_details_page_data?.per_min}
                      </li>
                      <li>
                        {/* <label>Contract:</label> */}
                        <label>{contentDetails?.your_details_page_data?.Contract}</label>
                        {/* 12 Months */}
                        {/* {parseInt(selectedFeesDetails?.contract)} Months */}
                        {parseInt(selectedFeesDetails?.contract)} {contentDetails?.your_details_page_data?.Months}
                      </li>
                      <li>
                        {/* <label>Total:</label>$ 151.91 */}
                        {/* <label>Total:</label>€ {parseFloat(selectedFeesDetails?.total.replace(/,/g, '')).toFixed(2)} */}
                        <label>{contentDetails?.your_details_page_data?.Total}</label>€ {parseFloat(selectedFeesDetails?.total.replace(/,/g, '')).toFixed(2)}
                          {/* <label>Total:</label>$ {parseFloat(selectedFeesDetails?.total).toFixed(2)} */}
                      </li>
                    </ul>
                  </div>

                  <div className="Featuresbox">
                    <h3>Features:</h3>
                    <ul>
                      {phoneFeatures?.map((item,index)=>{
                        return(
                          <li>
                            {" "}
                            {item?.access_status == 0 ? (
                              <img src={RedCross} alt="Img" />
                            ):
                            item?.access_status == 1 ? (
                              <img src={SmllTick} alt="Img" />
                            ):
                            item?.access_status == 2 ? (
                              <img src={DefaultIcon} alt="Img" />
                            ):null}
                            {item?.feature}
                          </li>
                        )
                      })}
                      {/* <li>
                        {" "}
                        <img src={SmllTick} alt="Img" /> Make calls
                      </li>
                      <li>
                        {" "}
                        <img src={SmllCross} alt="Img" /> Receive calls
                      </li>
                      <li>
                        <img src={SmllTick} alt="Img" /> You can use any address
                        world-wide to buy phone number
                      </li>
                      <li>
                        {" "}
                        <img src={SmllTick} alt="Img" />
                        Can't receive SMS
                      </li>
                      <li>
                        {" "}
                        <img src={SmllTick} alt="Img" />
                        Can’t receive fax
                      </li>
                      <li>
                        {" "}
                        <img src={SmllCross} alt="Img" /> Free test phone
                        numbers
                      </li> */}
                    </ul>
                  </div>
                </div>
              ) : (
                // <div className="FrmBody FrmBodyRgt">
                <div>
                  {/* <h2>Product details</h2> */}
                  {/* <h2>{contentDetails?.your_details_page_data?.Product_details}</h2> */}
                  <p>{contentDetails?.your_details_page_data?.Product_details?.To_see_the_details_please_specify_the_location}</p>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="container">
          <div className="BtnBottom">
            <Button
              variant="primary"
              type="button"
              className="BtnBack"
              onClick={() => handleBack()}
              // href="/LetStarted"
            >
              <HiArrowNarrowLeft /> 
              {/* Back */}
              {contentDetails?.your_details_page_data?.back_button}
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              onClick={() => deleteRecord()}
              style={{top:'15px'}}
            >
              {/* Cancel */}
              {contentDetails?.your_details_page_data?.cancel_button}
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              // href="/YourDetails"
              onClick={() => handleProceedStep3()}
            >
              {/* Proceed  */}
              {contentDetails?.your_details_page_data?.Proceed_button_text}
              <HiArrowNarrowRight />
            </Button>



            {/* <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              onClick={() => setBillingModal(true)}
            > Test 
            </Button> */}


          </div>
        </div>
          <Modal 
            size="lg"
            show={billingModal}
            onHide={billingModalClose}
            backdrop="static"
            keyboard={false}
            centered
            className="CustomModal CustomModalTnk"
          >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{contentDetails?.your_details_page_data?.billing_address_heading}</h5>
            <div className="FrmBox">
              <div className="FrmBody" style={{boxShadow:'none'}}>
              <Row className="DetailsList">
                <Col sm={6} className="pr-5">
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                    <Form.Select 
                      aria-label="Default select example" 
                      id="title" 
                      name="title" 
                      onChange={handleChangeBillingAddress}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("first_name").focus();
                        }
                      }}
                    >
                      <option value="" selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                      <option value="Mr." selected={formDataBillingAddress?.title === "Mr."}>{contentDetails?.your_details_page_data?.Mr}</option>
                      <option value="Mrs." selected={formDataBillingAddress?.title === "Mrs."}>{contentDetails?.your_details_page_data?.Mrs}</option>
                      <option value="Dr." selected={formDataBillingAddress?.title === "Dr."}>{contentDetails?.your_details_page_data?.Dr}</option>
                      <option value="Prof.Dr." selected={formDataBillingAddress?.title === "Prof.Dr."}>{contentDetails?.your_details_page_data?.Prof_Dr}</option>
                    </Form.Select>
                    {formErrorsBillingAddress.title && (<span className="error-message">{formErrorsBillingAddress.title}</span>)}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                    <Form.Control
                      id="last_name"
                      name="last_name"
                      value={formDataBillingAddress.last_name}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("company_name").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.last_name && <span className="error-message">{formErrorsBillingAddress.last_name}</span>}
                    
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.address_label}</Form.Label>
                    <Form.Control
                      id="address"
                      name="address"
                      value={formDataBillingAddress.address}
                      onChange={handleChangeBillingAddress}
                        type="text"
                        placeholder={contentDetails?.your_details_page_data?.address_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("country_id").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.address && <span className="error-message">{formErrorsBillingAddress.address}</span>}
                  </Form.Group>
                  
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                    <Form.Select 
                    aria-label="Default select example" 
                    id="region" 
                    name="region_id"  
                    onChange={handleRegionTOCity} 
                    onKeyDown={(e)=>{
                      if(e.key === 'Enter' || e.key === 'Tab')
                      {
                        e.preventDefault();
                        document.getElementById("city").focus();
                      }
                    }}
                    >
                    <option value="" selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                    {selectedLegalRegionByCountryId2.length > 0 ? (
                      selectedLegalRegionByCountryId2.map((item,index)=>{
                        return(
                          <option value={item.id} selected={formDataBillingAddress?.region_id === item.id}>{item.name}</option>
                        )
                      })
                    ):(
                      <option disabled>{languageJson?.noRegionFound}</option>
                    )}
                    </Form.Select>
                    {formErrorsBillingAddress.region_id && (<span className="error-message">{formErrorsBillingAddress.region_id}</span>)}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                    <Form.Control 
                      id="postal_code"
                      name="postal_code"
                      value={formDataBillingAddress.postal_code}
                      onChange={handleChangeBillingAddress}
                      type="number"
                      placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("phone_number").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.postal_code && <span className="error-message">{formErrorsBillingAddress.postal_code}</span>}
                  </Form.Group>
                </Col>

                <Col sm={6} className="pl-5">
                  <Form.Group className="mb-4 text-left">
                  <Form.Label>{contentDetails?.your_details_page_data?.First_Name}</Form.Label>
                    <Form.Control
                      id="first_name"
                      name="first_name"
                      value={formDataBillingAddress.first_name}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.Enter_First_Name}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("last_name").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.first_name && <span className="error-message">{formErrorsBillingAddress.first_name}</span>}
                  </Form.Group>  

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                    <Form.Control
                      id="company_name"
                      name="company_name"
                      value={formDataBillingAddress.company_name}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.companyName_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("address").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.company_name && <span className="error-message">{formErrorsBillingAddress.company_name}</span>}
                  </Form.Group>  

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.country_label}</Form.Label>
                    <Form.Select 
                      id="country_id"
                      aria-label="Default select example" 
                      name="country_id" 
                      onChange={handleCountryTOState}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("region").focus();
                        }
                      }}
                    >
                    <option value="" selected hidden>{contentDetails?.your_details_page_data?.countrySelectBoxDefaultText}</option>
                    {showAllCountries2.map((item,index)=>{
                      return(
                        <option value={item.id} selected={formDataBillingAddress?.country_id === item.id}>{item.name}</option>
                      )
                    })}
                    </Form.Select>
                    {formErrorsBillingAddress.country_id && <span className="error-message">{formErrorsBillingAddress.country_id}</span>}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                    <Form.Select 
                      id="city"
                      aria-label="Default select example" 
                      name="city_id" 
                      onChange={handleChangeBillingAddress}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("postal_code").focus();
                        }
                      }}
                    >
                    <option value="" selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                    {selectedCityWithId2?.length > 0 ? (
                      selectedCityWithId2?.map((item,index)=>{
                        return(
                          <option value={item.id} selected={formDataBillingAddress?.city_id === item.id}>{item?.name}</option>
                        )})
                    ):(
                      <option disabled>{languageJson?.cityNotFound}</option>
                    )}
                    </Form.Select>
                    {formErrorsBillingAddress.city_id && <span className="error-message">{formErrorsBillingAddress.city_id}</span>}
                  </Form.Group>
                    
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        {billingPhoneCode? `+${billingPhoneCode}`:null}
                      </InputGroup.Text>
                      <Form.Control
                        id="phone_number"
                        type="number"
                        placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                        name="phone_number"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={formDataBillingAddress.phone_number}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            handleChangeBillingAddress(e);
                          }
                        }}
                      />
                    </InputGroup>
                    {formErrorsBillingAddress.phone_number && <span className="error-message">{formErrorsBillingAddress.phone_number}</span>}
                  </Form.Group>
                </Col>
              </Row>
              </div>
              
              <div className="BtnBottom" style={{margin:'-10px',height:'0'}}>
                <Button variant="primary" type="button" className="BtnBack" onClick={() => billingModalClose()}>
                  <HiArrowNarrowLeft /> {contentDetails?.your_details_page_data?.cancel_button}
                </Button>

                <Button
                  variant="primary" type="submit" className="BtnSubmit" onClick={() => redirectURL()}>
                  {contentDetails?.your_details_page_data?.proceed_button} <HiArrowNarrowRight />
                </Button>
              </div>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
          </Modal>
      </div>
    </div>
  );
};

export default SelectCountryPhoneNumber;
