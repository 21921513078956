import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import laptopbig from "../../assets/images/LaptopBig.png";
import mailimg from "../../assets/images/MailImg.png";
import numberimg from "../../assets/images/NumberImg.png";
// import video from "../../assets/images/Video.png";
import newVideo from "../../assets/images/newVideo.png";
import playButton from "../../assets/images/Play-Button.png";
// import iPhoneimg from "../../assets/images/iPhoneImg.png";
// import iPhoneimg from "../../assets/images/iPhoneimg-new.png";
import iPhoneimg from "../../assets/images/updated_iPhone-15-Pro.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import { NotificationManager } from "react-notifications";
import Image from "react-bootstrap/Image";
import badge from "../../assets/images/Badge.svg";
import badge2 from "../../assets/images/Badge2.svg";
import australia from "../../assets/images/australia.png";
import finland from "../../assets/images/finland.png";
import germany from "../../assets/images/germany.png";
import belgium from "../../assets/images/belgium.png";
import BannerImg from "../../assets/images/Hero.png";
import ReactPlayer from 'react-player/vimeo';

import "./Home.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Carousel from "react-bootstrap/Carousel";

import { 
  getPageContent, getDigitalMailCountryList, getPhoneNumberCountryList, getAllBanners, 
  getCounterInBanner, getAllTestimonials, getProducts, getHomePageData, getUserDetails } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";
import VideoPlayer from "../video-modal/VideoPlayer";


const Home = () => {
  const [loggedData] = useState(JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")));
  const {hostLanguage} = useLangContext();

  const [mailCountries,setMailCountries] = useState([]);
  const [phoneCountries,setPhoneCountries] = useState([]);
  const [bannersDetails,setBannersDetails] = useState([]);
  const [bannersText,setBannersText] = useState([]);
  const [counterDetails,setcounterDetails] = useState({});
  const [testimonialDetails,setTestimonialDetails] = useState([]);
  const [productDetails,setProductDetails] = useState([]);
  const [homeData,setHomeData] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [contentDetails,setContentDetails] = useState();

  // const loggedData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
  // console.log(loggedData);
  // console.log(loggedData?.authorisation?.token);
  // get all mail based countries

  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleVideoModalClose = () => setShowVideoModal(false);
  const handlevideoModalShow = () => setShowVideoModal(true);
  const [videoUrl,setVideoUrl] = useState();
  const [bannerVideolink,setBannerVideolink] = useState();
  const [explanationVideolink, setExplanationVideolink] = useState();

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.home_page_data.explanation_video_url);
        
        // setBannerVideolink(responseData.data.home_page_data.explanation_video_url);
        setExplanationVideolink(responseData.data.home_page_data.explanation_video_url);
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const fetchMailProductCountries = async() => {
    try
    {
      let apiRes = await getDigitalMailCountryList();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        const dataArr = Object.values(responseData.data).filter(obj => typeof obj === 'object');
        setMailCountries(dataArr);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get all phone based countries
  const fetchPhoneProductCountries = async() => {
    try
    {
      let apiRes = await getPhoneNumberCountryList();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setPhoneCountries(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get all banner information
  const allBanners = async() => {
    try
    {
      let apiRes = await getAllBanners(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setBannersDetails(responseData.data);
        setBannersText(responseData.banner_below_text);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  
  // get banner image link
  const getBannerImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get banner counter information
  const getBannerCounterInfo = async() => {
    try
    {
      let apiRes = await getCounterInBanner();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setcounterDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get all testimonials
  const getAllTestimonialsInfo = async() => {
    try
    {
      let apiRes = await getAllTestimonials(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setTestimonialDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get productsInfo
  const getAllProductsInfo = async() => {
    try
    {
      let apiRes = await getProducts(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setProductDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  // get all banner information
  const homePageData = async() => {
    try
    {
      let apiRes = await getHomePageData();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setHomeData(responseData.data[0]);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //user details information
  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      {
        setUserDetails(responseData.data);
      }
      else
      {
        console.error(responseData.msg);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const appLink = (type) =>{
    if(type == 'android')
    {
       window.open('https://play.google.com/store/apps/details?id=com.cleverdomizilmail')
    }
    else if(type == 'apple')
    {
      window.open('https://apps.apple.com/in/app/cleverdomicile/id6502281781')
    }
  }

  const videoModalFunc = (video_type_url,video_url) =>{
    // console.log(video_type_url,video_url);
    // return
    setVideoUrl(video_type_url);
    setBannerVideolink(video_url);
    setShowVideoModal(true);
  }
  
  // useEffect(() => {
  //   Promise.all([
  //     fetchMailProductCountries(),
  //     fetchPhoneProductCountries(),
  //     allBanners(),
  //     getBannerCounterInfo(),
  //     getAllTestimonialsInfo(),
  //     getAllProductsInfo()
  //   ])
  //     .then((responses) => {
  //       console.log('Success', responses);
  //     })
  //     .catch((error) => 
  //     {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);
  
  useEffect(() => {
    getAllPageContent();
    getUserData();
    fetchMailProductCountries();
    fetchPhoneProductCountries();
    allBanners();
    getBannerCounterInfo();
    homePageData();
    getAllTestimonialsInfo();
    getAllProductsInfo();
    console.log(loggedData);
  },[hostLanguage]);
  
  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="BannerScetion">
          <Carousel data-bs-theme="dark">
            {bannersDetails?.map((item,index)=>{
              return(
                <Carousel.Item>
                  {item?.video_url ==null || item?.video_url == '' || item?.video_url == undefined ? (
                    <></>
                  ):(
                    <Image src={playButton} style={{cursor:'pointer'}} className="player-icon" alt="Player icon" onClick={()=>videoModalFunc('banner_video_url',item?.video_url)}/>
                  )}
                  <img className="d-block w-100" src={getBannerImgLink(item?.image)} alt="First slide"/>
                <Carousel.Caption>
                  <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
          <div className="BannerScetionBottom">
            <Container className="">
              <Row>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox ">
                    {/* <h4>Firmengründung</h4> */}
                    <h4>{bannersText[0]}</h4>
                    {/* <p>{counterDetails?.company_foundation}+ Länder</p> */}
                    <p>{counterDetails?.company_foundation}+ {bannersText['last_text']}</p>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox ">
                    {/* <h4>Geschäftsadresse</h4> */}
                    <h4>{bannersText[1]}</h4>
                    {/* <p>{counterDetails?.customer_foundation}+ Länder</p> */}
                    <p>{counterDetails?.customer_foundation}+ {bannersText['last_text']}</p>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox ">
                    {/* <h4>Lokale Rufnumme</h4> */}
                    <h4>{bannersText[2]}</h4>
                    {/* <p>{counterDetails?.number_world_wide}+ Länder</p> */}
                    <p>{counterDetails?.number_world_wide}+ {bannersText['last_text']}</p>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox last-child">
                    {/* <h4>Steuer- und Buchhaltungs-dienstleistungen</h4> */}
                    <h4>{bannersText[3]}</h4>
                    {/* <p>{counterDetails?.register_agents}+ Länder</p> */}
                    <p>{counterDetails?.register_agents}+ {bannersText['last_text']}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="InfoScetion">
          <Container className="InfoScetionContainer">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="InfoScetionLft">
                  {/* <div dangerouslySetInnerHTML={{ __html: homeData?.description1}} />
                  <Link>Weltweit expandieren? Seien Sie clever!</Link> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.home_page_data?.description1}} />
                  <Link>{contentDetails?.home_page_data?.description1_below_heading}</Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="InfoScetionRgt">
                  <Image
                    src={laptopbig}
                    className="laptopbig"
                    alt="LaptopBig"
                    fluid
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="ServicesScetion">
          <Container className="InfoScetionContainer">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <p>
                    Buchen Sie die folgenden Dienstleistungen über unsere Cleverdomizil-Plattform.
                  </p>
                  <h2>Unsere Dienstleistungen</h2> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: homeData?.product_heading}} /> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.home_page_data?.product_heading}} />
                </div>
              </Col>
            </Row>
            <Row>
              {productDetails?.map((item,index)=>{
                return(
                  <Col sm={6}>
                  <div className="ServicesBox">
                    <div className="ServicesBoxLft">
                      <Image
                        src={item?.logo === 'EMailIcon.svg' ? mailimg : item?.logo === 'PhnIcon.svg' ? numberimg:null}
                        className="laptopbig"
                        alt="LaptopBig"
                      />
                    </div>
                    <div className="ServicesBoxRgt">
                      <h4>{item?.product_name}</h4>
                      <p>{item?.product_details}</p>
                      {loggedData ? (
                        userDetails?.currency_id == 1 && userDetails?.language_id == 1 ? (
                          <Link to="/SelectProduct">
                            {/* mehr Informationen */}
                            {contentDetails?.home_page_data?.mail_product_button_link}
                            <IoIosArrowRoundForward size={27} />
                          </Link>
                        ):(
                          <Link to="/LetStarted">
                            {/* mehr Informationen */}
                            {contentDetails?.home_page_data?.phone_product_button_link}
                            <IoIosArrowRoundForward size={27} />
                          </Link>
                        )
                      ):(
                        <Link to="/register">
                          {/* mehr Informationen */}
                          {contentDetails?.home_page_data?.mail_product_button_link}
                          <IoIosArrowRoundForward size={27} />
                        </Link>
                      )}
                    </div>
                  </div>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </div>
        <div className="VideoScetion">
          <Container className="VideoScetionContainer">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <div dangerouslySetInnerHTML={{ __html: homeData?.heading_of_description_two}} /> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.home_page_data?.heading_of_description_two}} />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="VideoScetionLft">
                  {/* <div dangerouslySetInnerHTML={{ __html: homeData?.description2}} /> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.home_page_data?.description2}} />
                  {/* <Link to ='/register'>Produkte</Link> */}
                  <Link to ='/register'>{contentDetails?.home_page_data?.description2_button}</Link>
                </div>
              </Col>
              <Col sm={6} className="right-player-box">
                {/* <Image src={playButton} className="player-icon" alt="player icon" onClick={()=>videoModalFunc('explanation_video_url')}/> */}
                <Image
                  // src={video}
                  style={{cursor:'pointer'}}
                  src={newVideo}
                  className="laptopbig"
                  alt="LaptopBig"
                  onClick={()=>videoModalFunc('explanation_video_url',contentDetails?.home_page_data?.explanation_video_url)}
                  fluid
                />
                
              </Col>
            </Row>
          </Container>
        </div>
        <div className="ClientScetion">
          <Container className="VideoScetionContainer">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <p>CleverDomizil Kundenmeinungen</p>
                  <h2>Unsere Kunden</h2> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: homeData?.testimonial_heading}} /> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.home_page_data?.testimonial_heading}} />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col sm={8}>
                <Carousel data-bs-theme="dark">
                {testimonialDetails?.map((item,index)=>{
                  return(
                    <Carousel.Item>
                    <h5>{item?.name}</h5>
                    <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </Carousel.Item>
                  )
                })}
                </Carousel>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="AppScetion">
          <Container className="VideoScetionContainer">
            <Row className="align-items-center">
              <Col sm={6}>
                <Image
                  src={iPhoneimg}
                  className="laptopbig"
                  alt="LaptopBig"
                  fluid
                />
              </Col>
              <Col sm={6}>
                <div className="AppScetionTxt">
                  {/* <h4>CleverDomizil Mobile</h4>
                  <h5>
                  Verwalten Sie Ihr Unternehmen, Buchen Sie ihre Pakete und nutzen Sie unser CleverDomizil-App für das virtuelle Büro.
                  </h5> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: homeData?.description3}} /> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.home_page_data?.description3}} />
                  <div className="ImgGrp">
                    <span className="ImgGrpImg">
                      <img src={badge} alt="badge" onClick={()=>appLink('android')} style={{cursor:'pointer'}}/>
                    </span>
                    <span className="ImgGrpImg">
                      <img src={badge2} alt="badge" onClick={()=>appLink('apple')} style={{cursor:'pointer'}}/>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="NetworkScetion">
          <Container className="VideoScetionContainer">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <h2>Unser globales Netzwerk</h2> */}
                  <h2>{contentDetails?.home_page_data?.city_heading}</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="NetworkBox">
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                   
                    {/* <Tab eventKey="home" title="CleverDomizil Adresse"> */}
                    <Tab eventKey="home" title={contentDetails?.home_page_data?.cleverDomizil_address_btn}>
                      <div className="NetworkList">
                        <ul>
                          {mailCountries?.map((item,index)=>{
                            return(
                              <li>
                                <img src={item?.flag} alt="badge" /> {item[0]?.country?.name}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Tab>
                    
                    {/* <Tab eventKey="profile" title="CleverDomizil Rufnummer"> */}
                    <Tab eventKey="profile" title={contentDetails?.home_page_data?.cleverdomizil_phone_number_btn}>
                      <div className="NetworkList">
                        <ul>
                          {phoneCountries?.map((item,index)=>{
                            return(
                              <li>
                                <img src={item?.flag} alt="badge"/> {item?.name}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          show={showVideoModal}
          onHide={handleVideoModalClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal video-modal"
        >
          <Modal.Header closeButton>
            {/* <button type="button" class="btn-close" aria-label="Close" onClick={handleVideoModalClose}></button> */}
            {/* <Modal.Title>Video Modal</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <VideoPlayer videoUrl = {videoUrl == 'banner_video_url' ? bannerVideolink : videoUrl == 'explanation_video_url' ? bannerVideolink:null} style={{width : "100%", height : "400px"}} />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleVideoModalClose} className="lftBtn">
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Home
