// import React, { useState } from 'react';

// const Test = () => {
//   // State to manage selected country and city
//   const [selectedCountry, setSelectedCountry] = useState('');
//   const [selectedCity, setSelectedCity] = useState('Please select city');

//   // Dummy data for countries and cities (you can replace it with your data)
//   const countries = ['Country 1', 'Country 2', 'Country 3'];
//   const cities = {
//     'Country 1': ['City 1-1', 'City 1-2', 'City 1-3'],
//     'Country 2': ['City 2-1', 'City 2-2', 'City 2-3'],
//     'Country 3': ['City 3-1', 'City 3-2', 'City 3-3'],
//   };

//   // Event handler for country dropdown change
//   const handleCountryChange = (event) => {
//     const newCountry = event.target.value;
//     setSelectedCountry(newCountry);

//     // Reset city to "Please select city" when the country changes
//     setSelectedCity('Please select city');
//   };

//   // Event handler for city dropdown change
//   const handleCityChange = (event) => {
//     const newCity = event.target.value;
//     setSelectedCity(newCity);
//   };

//   return (
//     <div>
//       <label htmlFor="country">Country:</label>
//       <select id="country" value={selectedCountry} onChange={handleCountryChange}>
//         <option value="">Please select country</option>
//         {countries.map((country) => (
//           <option key={country} value={country}>
//             {country}
//           </option>
//         ))}
//       </select>

//       <br />

//       <label htmlFor="city">City:</label>
//       <select id="city" value={selectedCity} onChange={handleCityChange}>
//         <option value="">Please select city</option>
//         {cities[selectedCountry]?.map((city) => (
//           <option key={city} value={city}>
//             {city}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default Test;

// import React, { useState } from 'react';

// const Test = () => {
//   const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);

//   const handleAddFileInput = () => {
//     const newInput = {
//       id: fileInputs.length + 1,
//       file: null,
//     };

//     setFileInputs([...fileInputs, newInput]);
//   };

//   const handleFileChange = (e, id) => {
//     const newInputs = [...fileInputs];
//     const index = newInputs.findIndex((input) => input.id === id);

//     if (index !== -1) {
//       newInputs[index].file = e.target.files[0];
//       setFileInputs(newInputs);
//     }
//   };

//   const handleDeleteFileInput = (id) => {
//     const newInputs = fileInputs.filter((input) => input.id !== id);
//     setFileInputs(newInputs);
//   };

//   const handleUpload = () => {
//     // Implement file upload logic here.
//     console.log("Files to upload:", fileInputs);
//   };

//   return (
//     <div>
//       {fileInputs.map((input) => (
//         <div key={input.id}>
//           <input type="file" onChange={(e) => handleFileChange(e, input.id)} />
//           <button onClick={() => handleDeleteFileInput(input.id)}>Delete</button>
//         </div>
//       ))}

//       <button onClick={handleAddFileInput}>Add File Upload</button>
//       <button onClick={handleUpload}>Upload</button>
//     </div>
//   );
// };

// export default Test;


// import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
// import { HiArrowNarrowRight } from 'react-icons/hi';

// const Test = () => {
//   const [files, setFiles] = useState(Array(4).fill(null));
//   const [errors, setErrors] = useState(Array(4).fill(''));

//   const handleUploadingFile = (index, e) => {
//     const selectedFile = e.target.files[0];
//     const updatedFiles = [...files];
//     updatedFiles[index] = selectedFile;
//     setFiles(updatedFiles);

//     if (!selectedFile) {
//       setErrors([...errors.slice(0, index), 'Please select a file']);
//     } else {
//       setErrors([...errors.slice(0, index), '']);
//     }
//   };

//   const handleProceed = () => {
//     const newErrors = files.map((file, index) => (!file ? 'Please select a file' : ''));
//     setErrors(newErrors);

//     if (!newErrors.some((error) => error !== '')) {
//       // Continue with your logic for handling non-blank files
//       console.log('Proceeding...');
//     }
//   };

//   return (
//     <div>
//       <Form>
//         {files.map((file, index) => (
//           <Form.Group key={index} controlId={`file${index + 1}`}>
//             <Form.Control
//               type="file"
//               placeholder="Enter ID"
//               onChange={(e) => handleUploadingFile(index, e)}
//             />
//             {files[index] === null && <span style={{ color: 'red' }}>{errors[index]}</span>}
//           </Form.Group>
//         ))}

//         <Button
//           variant="primary"
//           type="submit"
//           className="BtnSubmit"
//           onClick={handleProceed}
//         >
//           Proceed <HiArrowNarrowRight />
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default Test;


// import React from 'react';

// const Test = () => {
//   const handleDownload = async () => {
//     try {
//       await downloadFile(
//         'https://cleverdomizil.de/virtual_office/virtual_admin/public/uploads/mailDocuments/7622137561703153420.png',
//         'image.png'
//       );
//       await downloadFile(
//         'https://cleverdomizil.de/virtual_office/virtual_admin/public/uploads/mailDocuments/12060226901703682547.pdf',
//         'document.pdf'
//       );
//     } catch (error) {
//       console.error('Error downloading files:', error);
//     }
//   };

//   const downloadFile = async (url, fileName) => {
//     const response = await fetch(url);
//     const blob = await response.blob();

//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = fileName;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <div>
//       <button onClick={handleDownload}>Download Files</button>
//     </div>
//   );
// };

// export default Test;

// import React, { useState } from 'react';

// const Test = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showCloseButton, setShowCloseButton] = useState(false);

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setSearchTerm(value);
//     setShowCloseButton(!!value); // Show the close button when there is some input
//   };

//   const handleClearSearch = () => {
//     setSearchTerm('');
//     setShowCloseButton(false);
//   };

//   return (
//     <div>
//       <div>
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={handleInputChange}
//           placeholder="Search..."
//         />
//         {showCloseButton && (
//           <button onClick={handleClearSearch}>X</button>
//         )}
//       </div>
//       {/* Other search-related content goes here */}
//     </div>
//   );
// };

// export default Test;


// import React, { useEffect } from 'react';

// const Test = () => {
//   useEffect(() => {
//     // Load the Google Translate API script
//     const script = document.createElement('script');
//     script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//     script.async = true;
//     document.body.appendChild(script);

//     // Initialize the translation widget
//     window.googleTranslateElementInit = () => {
//       new window.google.translate.TranslateElement(
//         { pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE },
//         'google-translate-widget'
//       );
//     };
//   }, []);

//   return <div id="google-translate-widget"></div>;
// };

// export default Test;


// import React, { useEffect } from 'react';

// const Test = () => {
//   useEffect(() => {
//     // Load Google Translate script
//     const script = document.createElement('script');
//     script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//     script.async = true;
//     document.body.appendChild(script);

//     // Initialize Google Translate
//     window.googleTranslateElementInit = () => {
//       new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
//     };

//     return () => {
//       // Clean up script when component unmounts
//       document.body.removeChild(script);
//       delete window.googleTranslateElementInit;
//     };
//   }, []);

//   return (
//     <div>
//       <h1>My Web Page</h1>
//       <p>Hello everybody!</p>
//       <p>Translate this page:</p>
//       <div id="google_translate_element"></div>
//       <p>You can translate the content of this page by selecting a language in the select box.</p>
//     </div>
//   );
// };

// export default Test;


// import React, { useRef } from 'react';

// function Test() {
//   const fileInputRef = useRef(null);

//   const handleClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (e) => {
//     const fileName = e.target.files[0].name;
//     console.log(fileName); // You can do whatever you want with the file name
//   };

//   const clearFileInput = () => {
//     fileInputRef.current.value = ''; // Clear the file input value
//   };

//   return (
//     <>
//       <label htmlFor="fileInput" className="custom-file-upload" onClick={handleClick}>
//         Choose File
//       </label>
//       <input
//         type="file"
//         id="fileInput"
//         className="form-control"
//         ref={fileInputRef}
//         onChange={handleFileChange}
//         style={{ display: 'none' }}
//       />
//       <button onClick={clearFileInput}>Clear File Input</button>
//     </>
//   );
// }

// export default Test;


import React, { useState, useEffect } from 'react';
import './test.css';

const steps = [
    { label: "Application received", date: "01/11/2024" },
    { label: "Application published", date: "17/11/2024" },
    { label: "Application sent for evaluation", date: "26/04/2024" },
    { label: "Application approved", date: "01/11/2033" }
];

const ProgressBar = ({ steps, currentStep }) => {
    return (
        <div className="progress-container">
            {steps.map((step, index) => (
                <div key={index} className="progress-step">
                    <div className={`circle ${index <= currentStep ? 'active' : ''}`}>
                        {index === currentStep && <div className="marker"></div>}
                    </div>
                    <div className="label">{step.date}<br />{step.label}</div>
                    {index < steps.length - 1 && (
                        <div className={`line ${index < currentStep ? 'active' : ''}`}></div>
                    )}
                </div>
            ))}
        </div>
    );
};

const Test = () => {
    const [currentStep, setCurrentStep] = useState(10);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentStep(prev => (prev < steps.length - 1 ? prev + 1 : 0));
        }, 2000); // Change step every 2 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="App">
            <h1>Application Process</h1>
            <ProgressBar steps={steps} currentStep={currentStep}/>
        </div>
    );
}

export default Test;






