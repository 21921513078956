import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./CurrentPlan.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import graphic from "../../assets/images/Graphic.svg";
import mailBox from "../../assets/images/MailBox.svg";
import Button from "react-bootstrap/Button";
import cloud from "../../assets/images/CloudIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import { useNavigate, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {NotificationManager} from 'react-notifications'; 
import {
  HiOutlinePlus,
  HiCheckCircle,
  HiOutlineCreditCard,
} from "react-icons/hi";
import { getAllPurchasedProductByUser, getUserDetails, getFilterProductByUser, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const CurrentPlan = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();

  const [delshow, deletShow] = useState(false);
  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);
  const [modalType,setModalType] = useState();

  const [userDetails, setUserDetails] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [mailData,setMailData] = useState([]);
  const [phoneData,setPhoneData] = useState([]);
  const [contentDetails,setContentDetails] = useState();
  const [renewalProductId,setRenewalProductId] = useState();
  
  const handleRoute = (route) => {
    navigate(route);
  };

  useEffect(() => {
    //CreateBroadcast();
    //let data = logInDetails();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    
    if (value == undefined) {
      navigate("/");
    }
      getUserData();
      getPurchaseProductByUser();
      //getExpireProductByUser2();
    
  }, []);

  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      { 
        // console.log(responseData.data);
        setUserDetails(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getExpireProductByUser2 = async(data) =>
  {
    try
    {
      let sorting = {sorting:data,lang:hostLanguage};
      let apiRes = await getFilterProductByUser(sorting);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
         setProductDetails(responseData.data)
        // if(responseData.data.length > 0 )
        if(responseData.data['mail'].length > 0 || responseData.data['phone'].length > 0)
        {
          setMailData([]);
          setPhoneData([]);

          let mailArr = [];
          let phoneArr = [];
          if(responseData.data['mail'].length > 0)
          {
            responseData.data['mail'].map((item,index)=>{
              mailArr.push(item);
            })
            setMailData(mailArr);
          }

          if(responseData.data['phone'].length > 0)
          {
            responseData.data['phone'].map((item,index)=>{
              phoneArr.push(item);
            })
            setPhoneData(phoneArr);
          }
          
          

          // console.log(responseData.data.length);
          // let tempArr = [];
          // responseData.data?.map((item,index)=>{
          //   item.map((item1,index1)=>{
          //     tempArr.push(item1)
              
          //   })
          // })
          // console.warn(tempArr)
          //setProductDetails(tempArr)
        }
        else
        {
          //navigate('/LetStarted');
          setMailData([]);
          setPhoneData([]);
        }
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
     // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getPurchaseProductByUser = async() =>
  {
    try
    {
      let apiRes = await getAllPurchasedProductByUser(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        let mailArr = [];
        let phoneArr = [];
        
        console.log(responseData.data['mail'])
        console.log(responseData.data['phone'])

        if(responseData.data['mail']!= undefined)
        {
          // responseData.data[0].map((item,index)=>{
          if(responseData.data['mail'].length > 0)
          {
            responseData.data['mail'].map((item,index)=>{
              mailArr.push(item);
            })
            setMailData(mailArr);
          }
        }

        if(responseData.data['phone']!= undefined)
        {
          if(responseData.data['phone'].length > 0)
          {
            responseData.data['phone'].map((item,index)=>{
              console.log(responseData.data['phone'].length)
              phoneArr.push(item);
            })
            setPhoneData(phoneArr);
          }
        }
        else
        {
          console.error('error');
        }
        
        
        

        console.warn(responseData.data[0]);
         setProductDetails(responseData.data);
         
        // if(responseData.data.length > 0 )
        // {//alert(responseData.data.length);
        //   let tempArr = [];
        //   responseData.data?.map((item,index)=>{
        //     item.map((item1,index1)=>{
        //       tempArr.push(item1)
              
        //     })
        //   })
        //   console.warn(tempArr)
        //   //setProductDetails(tempArr)
        //   console.log('here01')
        // }
        // else
        // {
        //   console.log('here02')
        //   //navigate('/LetStarted');
        // }
      }
      else
      {
        // NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
     // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getPlanFilter = e => {
    //console.log(e.target.value);
    if(e.target.value==1)
    {
      getPurchaseProductByUser();
    }
    else
    {
      getExpireProductByUser2(e.target.value);
    }
  };

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // const calculatingVAT = (total_price,vat_percentage) =>{
  //   let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
  //   return vat.toFixed(2);
  // }

  // const calculatingPrice = (total_price,vat_percentage) =>{
  //   let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
  //   let amt = parseFloat(total_price) + parseFloat(vat);
  //   return amt.toFixed(2);
  // }

  const calculatingVAT = (total_price,vat_percentage) =>{
    // let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
    let vat = ((parseFloat(total_price)) * (parseFloat(vat_percentage)) / (parseFloat(100) + parseFloat(vat_percentage)));
    return vat.toFixed(2);
  }
  
  const calculatingPrice = (total_price,vat_percentage) =>{
    // VAT = 39,90 x 19 / ( 100 + 19 ) = 6.37   and the Net price is =  39,90 / ( 1+ 19/100 ) = 33,53
    // let price = ((parseFloat(total_price)) / (parseFloat(1) + ((parseFloat(vat_percentage)) / parseFloat(100))));
    
    // let percent = 39,90 / ( 1+ 19/100 = 1.19 )
    let cal = (vat_percentage / 100);
    let price = parseFloat(total_price / (1 + cal));
  
    return price.toFixed(2);
  }

  const addOneMonth = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + 1);
    return newDate;
  };

  //renewal function
  const renewalFunc = (product_id) => {
    setRenewalProductId(product_id);
    deletShow(true);
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="lightBlue">
      <InnerHeader />

      <div className="DashboardBox">
        <div className="Titelbox">
          <span>
            {/* <h3>Your Plans</h3> */}
            <h3>{contentDetails?.after_login?.YourPlans}</h3>
          </span>
          <span>
            <Link to="/LetStarted" className="DashboardBtn35">
              <HiOutlinePlus size={25} className="PlusIcon" />
              {/* Add another product */}
              {contentDetails?.after_login?.AddAnotherProduct}
            </Link>
          </span>
        </div>

        <div className="TableFiltter TableFiltter2">
          <Row className="align-items-center">
            <Col sm={8}>
              {mailData.length > 0 || phoneData.length > 0 ? (
                <h5>
                  {/* <label>Your selected cleverdomizil services </label> */}
                  <label>{contentDetails?.after_login?.YourSelectedCleverdomizilServices}</label>
                </h5>
              ):(
              <h5>
                {/* <label>Plan not found</label> */}
                <label>{languageJson?.plan_not_found}</label>
              </h5>
              )}
              
            </Col>
            <Col sm={4}>
              <div className="FiltterList">
                <Form>
                  <Form.Group className="align-items-center">
                    <Col sm="12">
                      <Form.Select aria-label="Default select example"  onChange={getPlanFilter}>
                        {/* <option value="1">Sorting</option>
                        <option value="1">All</option>
                        <option value="Active">Active</option>
                        <option value="Expired Soon">Expire Soon</option>
                        <option value="Expired">Expired</option> */}
                        <option value="1">{contentDetails?.after_login?.Sorting}</option>
                        <option value="1">{contentDetails?.after_login?.All}</option>
                        <option value="Active">{contentDetails?.after_login?.Active}</option>
                        <option value="Expired Soon">{contentDetails?.after_login?.ExpireSoon}</option>
                        <option value="Expired">{contentDetails?.after_login?.Expired}</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="align-items-center MainRow3 MainRow45">
        {mailData?.length > 0 ? (
          mailData?.map((item,index)=>{
              const today = new Date();
              let diffInMonths = 0;
              let diffInMonths2 = 0;
              //let durationEnd;
              
              // if(item?.product_id == 1){
              //   const durationEnd = new Date(item?.duration_end);
              // }else{
              //   const durationEnd = new Date(item[0]?.duration_end);
              // }
              const durationEnd = new Date(item?.duration_end);
              

              if (durationEnd instanceof Date && !isNaN(durationEnd)) {
                diffInMonths = (durationEnd.getMonth() - today.getMonth()) +
                  (12 * (durationEnd.getFullYear() - today.getFullYear()));
              } else {
                console.error(`Invalid or missing date for item at index ${index}`);
                // You might want to handle this case appropriately, e.g., by setting diffInMonths to a default value
              }
              const durationEnd2 = new Date(item?.duration_end);
              
              
              if (durationEnd2 instanceof Date && !isNaN(durationEnd2)) {
                diffInMonths2 = (durationEnd2.getMonth() - today.getMonth()) +
                  (12 * (durationEnd2.getFullYear() - today.getFullYear()));
              } else {
                console.error(`Invalid or missing date for item at index ${index}`);
                // You might want to handle this case appropriately, e.g., by setting diffInMonths to a default value
              }
                      
            return(
              item.product_id == 1 && (
                
              <Col sm={4}>
                <div className="WhiteBox">
                  <div className="WhiteBoxPrice">
                    <div className="PriceBox">
                      <Row>
                        <Col sm={6}>
                          <div className="iconBox">
                            <img src={cloud} className="iconImg" alt="Img" />
                            <h4>{item.product.product_name}</h4>
                          </div>
                        </Col>
                        <Col sm={6} className="">
                          <div className="PriceTxt">
                            {/* <p>Price</p> */}
                            <p>{item?.price_text}</p>
                            {/* <h5>€ {calculatingPrice(item?.plan?.price,item?.plan?.vat_charge)}</h5> */}
                            <h5>€ {item?.plan?.price}</h5>
                            <p>/{item?.contract_period?.name}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="PriceBoxBottom">
                      <ul>
                        <li>
                          {/* <label>Contract Period</label> */}
                          <label>{item?.contract_period_text}</label>
                          <span>{item?.contract_period?.name} {item.duration_start} - {item.duration_end}</span>
                        </li>
                        <li>
                          {/* <label>Net price</label> <span>€ {calculatingPrice(item?.plan?.price,item?.plan?.vat_charge)}</span> */}
                          <label>{item?.net_price_text}</label> <span>€ {calculatingPrice(item?.plan?.price,item?.plan?.vat_charge)}</span>
                        </li>
                        <li>
                          {/* <label>VAT {item?.plan?.vat_charge} %</label> */}
                          <label>{item?.vat_text} {item?.plan?.vat_charge} %</label>
                          <span>€ {calculatingVAT(item?.plan?.price, item?.plan?.vat_charge)}</span>
                        </li>
                        <li>
                          <label>{contentDetails?.after_login?.city_level}</label>
                          <span>{item?.city?.name}</span>
                        </li>
                        <li>
                          <label>{contentDetails?.after_login?.country_level}</label>
                          <span>{item?.country?.name}</span>
                        </li>
                      </ul>
                    </div>

                    <div className="DeleteBottom">
                      <h4>{item?.plan?.title}</h4>
                      <h6>
                        {/* <em>Next Renewal Date</em> */}
                        <em>{item?.next_renewal_date_text}</em>
                        <br />
                        {item?.duration_end}
                      </h6>
                    </div>
                    <div className="DeleteBottom2">
                      
                    {durationEnd instanceof Date && !isNaN(durationEnd) ? (
                        <>
                          {typeof durationEnd.getTime === 'function' ? (
                            <>
                              {diffInMonths >= 1 ? (
                                // <h6><HiCheckCircle size={20} color="#03AE00" /> Active</h6>
                                <h6><HiCheckCircle size={20} color="#03AE00" /> {contentDetails?.after_login?.plan1}</h6>
                              ) : durationEnd.getTime() > today.getTime() && diffInMonths < 1 ? (
                                <h6 className="Renewal" onClick={()=>renewalFunc(item?.id)} style={{cursor:'pointer'}}>
                                  <HiOutlineCreditCard size={20} color="#F2B04E" /> 
                                  {/* Renewal */}
                                  {contentDetails?.after_login?.plan2}
                                </h6>
                              ) : durationEnd.getTime() < today.getTime() ? (
                                <h6 className="Expire">
                                  <HiOutlineCreditCard size={20} color="#E8222E" /> 
                                  {/* Expire */}
                                  {contentDetails?.after_login?.plan3}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <p>Error: 'getTime' is not a function</p>
                          )}
                        </>
                      ) : (
                        <p>Error: Invalid or missing date</p>
                      )}
                      
                    </div>
                  </div>
                </div>
              </Col>
              )
            )
          })       
        ):(
          <></>
        )}   


        {phoneData?.length > 0 && (
          phoneData?.map((item,index)=>{
              const today = new Date();
              let diffInMonths = 0;
              let diffInMonths2 = 0;
              //let durationEnd;
              
              // if(item?.product_id == 1){
              //   const durationEnd = new Date(item?.duration_end);
              // }else{
              //   const durationEnd = new Date(item[0]?.duration_end);
              // }
              const durationEnd = new Date(item?.duration_end);

              if (durationEnd instanceof Date && !isNaN(durationEnd)) {
                diffInMonths = (durationEnd.getMonth() - today.getMonth()) +
                  (12 * (durationEnd.getFullYear() - today.getFullYear()));
              } else {
                console.error(`Invalid or missing date for item at index ${index}`);
                // You might want to handle this case appropriately, e.g., by setting diffInMonths to a default value
              }
              const durationEnd2 = new Date(item?.duration_end);
              
              
              if (durationEnd2 instanceof Date && !isNaN(durationEnd2)) {
                diffInMonths2 = (durationEnd2.getMonth() - today.getMonth()) +
                  (12 * (durationEnd2.getFullYear() - today.getFullYear()));
              } else {
                console.error(`Invalid or missing date for item at index ${index}`);
                // You might want to handle this case appropriately, e.g., by setting diffInMonths to a default value
              }
                      
            return(
              item.product_id == 2 && (
                <Col sm={4}>
                  <div className="WhiteBox">
                    <div className="WhiteBoxPrice">
                      <div className="PriceBox">
                        <Row>
                          <Col sm={6}>
                            <div className="iconBox">
                              <img src={phone} className="iconImg" alt="Img" />
                              <h4>{item?.product?.product_name}</h4>
                            </div>
                          </Col>
                          <Col sm={6} className="">
                            <div className="PriceTxt">
                              {/* <p>Price</p> */}
                              <p>{item?.price_text}</p>
                              <h5>€ {item?.phone_area?.total}</h5>
                              {/* <p>/Yearly</p> */}
                              <p>/{item?.yearly_text}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="PriceBoxBottom">
                      <ul>
                        <li>
                          {/* <label>Phone Number</label> */}
                          <label>{item?.phone_number_text}</label>
                          <span>{item.phone_country.country_code} {item.phone_area.area_code} {item.phone.phone_number}</span>
                        </li>
                        <li>
                          {/* <label>Contract Period</label> */}
                          <label>{item?.contract_period_text}</label>
                          <span>
                            {/* Yearly  */}
                            {item?.yearly_text}
                            {item?.duration_start} - {item?.duration_end}</span>
                        </li>
                        <li>
                          {/* <label>Net price</label> <span>€ {calculatingPrice(item?.phone_area?.total, item?.phone_area?.vat_charge)}</span> */}
                          <label>{contentDetails?.after_login?.NetPrice}</label> <span>€ {calculatingPrice(item?.phone_area?.total, item?.phone_area?.vat_charge)}</span>
                        </li>
                        <li>
                          {/* <label>VAT {item.phone_area.vat_charge} %</label> */}
                          <label>{item?.vat_text} {item.phone_area.vat_charge} %</label>
                          <span>€ {calculatingVAT(item?.phone_area?.total,item.phone_area.vat_charge)}</span>
                        </li>
                        <li>
                          <label>{contentDetails?.after_login?.area_level}</label>
                          <span>{item?.phone_area?.area}</span>
                        </li>
                        <li>
                          <label>Country</label>
                          <span>{item?.phone_country?.name}</span>
                        </li>
                      </ul>
                      </div>

                      <div className="DeleteBottom">
                        <h4>{item?.phone_area?.area_text}</h4>
                        <h6>
                          {/* <em>Next Renewal Date</em> */}
                          <em>{item?.next_renewal_date_text}</em>
                          <br />
                          {item?.duration_end}
                        </h6>
                      </div>
                      <div className="DeleteBottom2">
                      {durationEnd2 instanceof Date && !isNaN(durationEnd2) ? (
                        <>
                          {typeof durationEnd2.getTime === 'function' ? (
                            <>
                              {diffInMonths2 >= 1 ? (
                                // <h6><HiCheckCircle size={20} color="#03AE00" /> Active</h6>
                                <h6><HiCheckCircle size={20} color="#03AE00" /> {contentDetails?.after_login?.plan1}</h6>
                              ) : durationEnd2.getTime() > today.getTime() && diffInMonths < 1 ? (
                                <h6 className="Renewal" onClick={()=>renewalFunc(item?.id)} style={{cursor:'pointer'}}>
                                  <HiOutlineCreditCard size={20} color="#F2B04E" /> 
                                  {/* Renewal */}
                                  {contentDetails?.after_login?.plan2}
                                </h6>
                              ) : durationEnd2.getTime() < today.getTime() ? (
                                <h6 className="Expire">
                                  <HiOutlineCreditCard size={20} color="#E8222E" /> 
                                  {/* Expire */}
                                  {contentDetails?.after_login?.plan3}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            // <p>Error: 'getTime' is not a function</p>
                            <></>
                          )}
                        </>
                      ) : (
                        // <p>Error: Invalid or missing date</p>
                        <></>
                      )}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            )
          })       
        )}  
        </Row>
      </div>
      <Modal
        show={delshow}
        onHide={delhandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal delCustomModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Product Renewal</Modal.Title> */}
          <Modal.Title>{contentDetails?.after_login?.product_renewal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h3>Do you want to renew this product?</h3> */}
          <h3>{contentDetails?.after_login?.renewal_alert}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{delhandleClose();setRenewalProductId()}} className="lftBtn"> {contentDetails?.after_login?.alert_no_button}</Button>
          <Button variant="primary" className="rgtBtn" onClick={() => navigate('/renew-payment', { state: renewalProductId })}> {contentDetails?.after_login?.alert_yes_button}</Button>
          
          </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CurrentPlan;
