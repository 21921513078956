import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./About.scss";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import laptopbig from "../../assets/images/laptopLoc.png";
import Button from "react-bootstrap/Button";
import SmllTick from "../../assets/images/SmllTick.svg";
import RightImg from "../../assets/images/RightImg.png";
import BoxImg from "../../assets/images/LeftImg.png";
import BoxIcon12 from "../../assets/images/BoxIcon12.svg";
import BoxIcon13 from "../../assets/images/BoxIcon13.svg";
import BoxIcon14 from "../../assets/images/BoxIcon14.svg";
import BoxIcon15 from "../../assets/images/BoxIcon15.svg";
import AboutImgLft from "../../assets/images/AboutImgLft.png";

import { aboutPageData, getCounterInBanner, getMailProductCity, getPageContent } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import { useLangContext } from "../../context/languageContext";


const About = () => {
  const {hostLanguage} = useLangContext();
  const [data,setData] = useState([]);
  const [counterDetails,setcounterDetails] = useState([]);
  const [selectedCities,setSelectedCities] = useState([]);
  const [cities,setCities] = useState([]);
  const [contentDetails,setContentDetails] = useState();


  //details of about page
  const fetchData = async () =>{
    try
    {
      let apiRes = await aboutPageData();
      let responseData = await apiRes.json();

      if(responseData.res)
      {
        setData(responseData.data[0]);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  
  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get banner counter information
  const getBannerCounterInfo = async() => {
    try
    {
      let apiRes = await getCounterInBanner();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setcounterDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get image link
  const getImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //Get All Mail Product City
  const fetchMailProductCity = async(product_id) => {
    try
    {
      let apiRes = await getMailProductCity(product_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCities(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }


  useEffect(()=>{
    fetchData();
    getAllPageContent();
    getBannerCounterInfo();
    fetchMailProductCity(1);
  },[hostLanguage])

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="AboutBannerScetion">
          <div className="BannerScetionBottom">
            <Container className="">
              <Row>
                <Col sm={12}>
                  {/* <h5>
                    Ihr All-in-One-Anbieter für Virtuelle Büros, Lokale Telefonnummern, Buchhaltungsservice und maßgeschneiderte Dienstleistungen
                  </h5> */}
                  <div dangerouslySetInnerHTML={{__html: contentDetails?.aboutUs_page_data?.banner_text}}></div>
                  {/* <h3>{data?.title}</h3> */}
                  <h3>{contentDetails?.aboutUs_page_data?.title}</h3>
                </Col>
              </Row>

            </Container>
          </div>
        </div> 
        <div className="BannerScetionBottom BannerScetionBottomAbout">
            <Container className="">

              <Row className="justify-content-md-center">
                <Col sm={3}>
                  <div className="BannerScetionBottomBox">
                    {/* <h4>International Locations</h4>
                    <p>130</p> */}
                    {/* <h4>Firmengründung</h4> */}
                    <h4>{contentDetails?.banner_below_text[0]}</h4>
                    {/* <p>{counterDetails?.company_foundation} Länder</p> */}
                    <p>{counterDetails?.company_foundation}+ {contentDetails?.banner_below_text['last_text']}</p>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox ">
                    {/* <h4>Active Customers</h4>
                    <p>10.000+</p> */}
                    {/* <h4>Geschäftsadresse</h4> */}
                    <h4>{contentDetails?.banner_below_text[1]}</h4>
                    {/* <p>{counterDetails?.customer_foundation}+ Länder</p> */}
                    <p>{counterDetails?.customer_foundation}+ {contentDetails?.banner_below_text['last_text']}</p>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox ">
                    {/* <h4>Lokale Rufnumme</h4> */}
                    <h4>{contentDetails?.banner_below_text[2]}</h4>
                    {/* <p>{counterDetails?.number_world_wide}+ Länder</p> */}
                    <p>{counterDetails?.number_world_wide}+ {contentDetails?.banner_below_text['last_text']}</p>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="BannerScetionBottomBox last-child">
                    {/* <h4>Founded in Berlin</h4>
                    <p>2013</p> */}
                    {/* <h4>Steuer- und Buchhaltungs-dienstleistungen</h4> */}
                    <h4>{contentDetails?.banner_below_text[3]}</h4>
                    {/* <p>{counterDetails?.register_agents}+ Länder</p> */}
                    <p>{counterDetails?.register_agents}+ {contentDetails?.banner_below_text['last_text']}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        <div className="InfoScetion">
          <Container className="InfoScetionContainer">
            <Row className="align-items-center">
              <Col sm={6}>


              {/* <div dangerouslySetInnerHTML={{ __html: data?.description1}} className="InfoScetionLft"></div> */}
              {/* <div className="InfoScetionLft">
                  <h2>Our Locations</h2>
                  <h3>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form.
                  </h3>
                  <ul>
                    <li>Clevver Inc./Chicago</li>

                    <li>Clevver GmbH/Berlin</li>
                    <li>Clevver Representative/Hanoi</li>
                  </ul>
                </div> */}
                <div className="InfoScetionLft">
                  {/* <h2>Unsere Standorte</h2>
                  
                  <ul>
                  {cities?.map((city_item, city_index) => (
                    <>
                    <li>Clevver GmbH/{city_item?.city.name}</li>
                    </>                    
                  ))}
                  </ul> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.description1}} ></div> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description1}} ></div>
                </div>
                
              </Col>
              <Col sm={6}>
                <div className="InfoScetionRgt">
                  <Image
                    src={laptopbig}
                    className="laptopbig"
                    alt="LaptopBig"
                    fluid
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="DigitalmailboxScetion">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="DigitalmailboxScetionInner">
                  <Row className="align-items-center">
                    <Col sm={7}>
                      {/* <div className="DigitalmailboxinLft DigitalmailboxinLftp" dangerouslySetInnerHTML={{ __html: data?.description2}} > */}
                      <div className="DigitalmailboxinLft DigitalmailboxinLftp" dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description2}} >
                      </div>

                      {/* <div className="DigitalmailboxinLft DigitalmailboxinLftp">                      
                        <p>
                          Vision, Mission & Values
                        </p>
                        <h4>
                          It has survived not only five centuries, but also the
                          leap into electronic typesetting, remaining
                          essentially unchanged.
                        </h4>
                      </div>  */}


                    </Col>
                    <Col sm={5}>
                      <div className="DigitalmailboxinRgt">
                        {/* <Image src={getImgLink(data?.image1)} className="BoxImg" alt="BoxImg" /> */}
                        <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image1)} className="BoxImg" alt="BoxImg" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="FourBoxScetion">
          <Container className="">
            <Row>
              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      // src={getImgLink(data?.image2)}
                      src={getImgLink(contentDetails?.aboutUs_page_data?.image2)}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  {/* <h3>versatile</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.description3}}></div> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description3}}></div>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      // src={getImgLink(data?.image3)}
                      src={getImgLink(contentDetails?.aboutUs_page_data?.image3)}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  {/* <h3>excellent</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.description4}}></div> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description4}}></div>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      // src={getImgLink(data?.image4)}
                      src={getImgLink(contentDetails?.aboutUs_page_data?.image4)}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  {/* <h3>progressive</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.description5}}></div> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description5}}></div>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      // src={getImgLink(data?.image5)}
                      src={getImgLink(contentDetails?.aboutUs_page_data?.image5)}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  {/* <h3>integer</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: data?.description6}}></div> */}
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description6}}></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="AboutInfoScetion">
          <Container className="">
            <Row>
              <Col sm={6}>
                {/* <div className="AboutInfoScetionLft" dangerouslySetInnerHTML={{ __html: data?.description7}}></div> */}
                <div className="AboutInfoScetionLft" dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description7}}></div>
                {/* <div className="AboutInfoScetionLft" >
                  <h4>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested.
                  </h4>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more or less normal distribution of letters, as opposed to
                    using making it look like readable English. Many desktop
                    publishing packages and web page editors now use Lorem Ipsum
                    as their default model text, and a search for 'lorem ipsum'
                    will uncover many web sites still in their infancy.
                  </p>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur.
                  </p>
                </div> */}
              </Col>
              <Col sm={6}>
                {/* <Image src={getImgLink(data?.image6)} className="BoxImg" alt="BoxImg" fluid /> */}
                <Image src={getImgLink(contentDetails?.aboutUs_page_data?.image6)} className="BoxImg" alt="BoxImg" fluid />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="AboutInfoScetion2">
          <Container className="">
            <Row>
              <Col sm={6}>
                <Image
                  // src={getImgLink(data?.image7)}
                  src={getImgLink(contentDetails?.aboutUs_page_data?.image7)}
                  className="BoxImg"
                  alt="BoxImg"
                  fluid
                />
              </Col>
              <Col sm={6}>
              {/* <div className="AboutInfoScetionLft" dangerouslySetInnerHTML={{ __html: data?.description8}}></div> */}
              <div className="AboutInfoScetionLft" dangerouslySetInnerHTML={{ __html: contentDetails?.aboutUs_page_data?.description8}}></div>
                {/* <div className="AboutInfoScetionLft">
                  <h4>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested.
                  </h4>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more or less normal distribution of letters, as opposed to
                    using making it look like readable English. Many desktop
                    publishing packages and web page editors now use Lorem Ipsum
                    as their default model text, and a search for 'lorem ipsum'
                    will uncover many web sites still in their infancy.
                  </p>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur.
                  </p>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
