import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Header from "../../components/header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./Blog.scss";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import BlogImg1 from "../../assets/images/BlogImg1.jpg";
import { useNavigate, Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import Pagination from "react-bootstrap/Pagination";
import { getblogs } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import { useLangContext } from "../../context/languageContext";

const Blog = () => {
  const {hostLanguage,languageJson} = useLangContext();
  const [Blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages,setTotalPages] = useState();
  const [pageButtonsToShow,setPageButtonShow] = useState();
  const navigate = useNavigate();

  const getBannerImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }
  
  const fetchingAllBlog = async(pageNumber) => {
    try
    {
      let apiRes = await getblogs(pageNumber,hostLanguage);
      let responseData = await apiRes.json();
      
      if(responseData.res)
      { // alert();
        //setBlogs(responseData.data);

        const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
        setBlogs(dataArr);
        setPageButtonShow(responseData.data.total);
        const totalPages = Math.ceil(Number(responseData.data.total) / Number(responseData.data.per_page));
        setTotalPages(totalPages);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }console.log(Blogs);
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchingAllBlog(newPage);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const ellipsisKey = 'ellipsis';
  
    // console.warn('pages: ' + totalPages)
    // console.warn('button: ' + pageButtonsToShow)
    if (totalPages <= pageButtonsToShow)
    {
      // console.warn(1);
      for (let i = 1; i <= totalPages; i++)
      {
        pageNumbers.push(i);
      }
    }
    else
    {
      console.warn(2);
      if (currentPage <= Math.ceil(pageButtonsToShow / 2))
      {
        console.warn(3);
        for (let i = 1; i <= pageButtonsToShow - 1; i++)
        {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2))
      {
        console.warn(4);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++)
        {
          pageNumbers.push(i);
        }
      }
      else
      {
        console.warn(5);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      console.warn(6);
    }
    console.log(pageNumbers);
    return pageNumbers;
  };

  useEffect(()=>{
    fetchingAllBlog(1);
  },[hostLanguage])

  

//   const formatDate = (inputDate) => {
//     const options = { day: 'numeric', month: 'short', year: 'numeric' };
//     const date = new Date(inputDate);
//     const formattedDate = date.toLocaleDateString('en-US', options);
  
//     // Extract day, month, and year
//     const day = date.getDate();
//     const month = date.toLocaleDateString('en-US', { month: 'short' });
//     const year = date.getFullYear();
  
//     // Add the appropriate suffix to the day
//     const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th');
  
//     // return `${dayWithSuffix} ${month} ${year}`;
//     return `${dayWithSuffix} ${month} ${year}`;
// };

const formatDate = (inputDate) => {
  // const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  // return date.toLocaleDateString('en-GB', options);

  // const date = new Date(inputDate);
  // const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  // return date.toLocaleDateString('en-GB', options);
  
  const date = new Date(inputDate);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-GB', options).replace(/\//g, '.');

}

  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
  
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
  
    return text;
  };

  return (
    <div className="WebBody">
      <Mainheader />
      {/* <Header />  */}
      <div className="BlogBody">
        <Container className="BlogContainer">
          <Row>
            <Col sm={12}>
              <div className="ScetionTitel">
                {/* <h2>Unser Blog</h2> */}
                <h2>{languageJson?.our_blog}</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="BlogList">
                <ul>
                {Blogs?.length > 0 ? (
                  Blogs && Blogs?.map((item,index)=>{ 
                  const htmlContent = item?.description;
                  const htmlContent2 = truncateText(htmlContent, 30);
                  return(
                  <>
                  <li>
                    <Card className="BlogBox">
                      <Card.Img variant="top" src={getBannerImgLink(item?.image)} />
                      <Card.Body>
                        <h5>{formatDate(item?.created_at)}</h5>
                        <h4>
                          {item?.title}
                        </h4>
                        {/*  {item?.description} */}
                        <p>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent2 }} />
                        </p>
                        <a style={{cursor:'pointer'}} class="view-link" onClick={()=>navigate('/BlogDeatils',{state: {id:item?.id}})}>
                          {/* Learn more <IoIosArrowRoundForward size={30} /> */}
                          {languageJson?.learn_more}<IoIosArrowRoundForward size={30} />
                        </a>
                      </Card.Body>
                    </Card>
                  </li>
                  </>
                  )
                })):(
                //  <> No Record Found</>
                 <>{languageJson?.no_record_found}</>
              )}
                  

                  
                </ul>
              </div>
              <Col className="FullCol">
                {/* <Pagination className="BlogPag">
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination> */}

{Blogs?.length > 0 ? (
                  <div className="TablePagination">
                      {/* Pagination controls start */}
                      <Pagination>
                        {currentPage >= 1 && (
                          <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                        
                        {getPageNumbers().map((pageNumber, index) => (
                          <React.Fragment key={index}>
                            {pageNumber.key === 'ellipsis' ? (
                              <Pagination.Ellipsis key={index} />
                            ) : (
                              <Pagination.Item
                                key={index}
                                active={currentPage === pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </Pagination.Item>
                            )}
                          </React.Fragment>
                        ))}

                        {currentPage <= totalPages && (
                          <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                      </Pagination>


                    {/* Pagination controls end */}

                  </div>
                ):null}


              </Col>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
