import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./Contact.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Formik} from 'formik';
import * as yup from 'yup';

import { getPageContent, submitInquiry } from "../../api/apiRequest";
import { NotificationManager } from "react-notifications";
import { useLangContext } from "../../context/languageContext";

const Contact = () => {
  const [isSubmit,setIsSubmit] = useState(false);
  const {hostLanguage} = useLangContext();
  const [contentDetails,setContentDetails] = useState();

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // send Inquiry
  const sendInquiry = async(values, {resetForm})=>{
    try
    {
      setIsSubmit(true);
      let data_values = {
        companyname: values.companyname,
        name: values.name,
        email: values.email,
        phonenumber: values.phonenumber,
        message: values.message
      }

      let apiRes = await submitInquiry(data_values);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setIsSubmit(false);
        resetForm({values:''});
        NotificationManager.success(responseData.msg,'',2000);
      }
      else
      {
        setIsSubmit(false);
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      setIsSubmit(false);
      console.error(error);
    }
  }

  let schemaGer = yup.object().shape({

    companyname : yup
    .string()
    .required('Firmenname ist erforderlich'),

    name: yup
    .string()
    .required('Name ist erforderlich')
    .matches(/^[A-Za-z\s.'-]+$/ ,"Geben Sie einen gültigen Namen ein"),

    email: yup
    .string()
    .required('E-Mail ist erforderlich')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Ungültige E-Mail-Adresse!"),

    phonenumber: yup
    .string()
    .required('Telefonnummer ist erforderlich')
    .min(10, 'Die Telefonnummer muss mindestens 10 Ziffern haben')
    .max(11, 'Die Telefonnummer darf nicht länger als 11 Ziffern sein'),

    message: yup
    .string()
    .required('Nachricht ist erforderlich')
  })

  let schemaEn = yup.object().shape({

    companyname : yup
    .string()
    .required('Company Name is required'),

    name: yup
    .string()
    .required('Name is required')
    .matches(/^[A-Za-z\s.'-]+$/ ,"Enter a valid name"),

    email: yup
    .string()
    .required('Email is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),

    phonenumber: yup
    .string()
    .required('Phone Number is required')
    .min(10, 'Phone Number must be at least 10 digits')
    .max(11, 'Phone Number must not exceed 11 digits'),

    message: yup
    .string()
    .required('Message is required')
  })

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="ContactBannerScetion">
          {/* <h5>In Kontakt treten</h5> */}
          <h5>{contentDetails?.contact_us_page_data?.contact}</h5>
        </div>
        <div className="ContactFrmScetion">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col sm={8}>
                <Formik
                  initialValues={{
                    companyname: '',
                    name: '',
                    email: '',
                    phonenumber: '',
                    message: ''
                  }}
                  onSubmit={(values,{resetForm})=>{sendInquiry(values,{resetForm})}}
                  validationSchema={(hostLanguage == 'ch' || hostLanguage == 'de') ? schemaGer : schemaEn}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    values,
                    touched
                  })=>(
                  <>
                    <Row>
                      <Col>
                        <Form.Group className="mb-4">
                          {/* <Form.Label>Firmenname</Form.Label> */}
                          <Form.Label>{contentDetails?.contact_us_page_data?.company_name}</Form.Label>
                          {/* <Form.Control type="text" placeholder="Geben Sie den Firmennamen ein" name="companyname" onChange={handleChange} value={values.companyname}/> */}
                          <Form.Control type="text" placeholder={contentDetails?.contact_us_page_data?.company_name_placeholder} name="companyname" onChange={handleChange} value={values.companyname}/>
                          {errors?.companyname && touched.companyname ? <div className="error">{errors.companyname}</div> : null}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-4">
                          {/* <Form.Label>Name</Form.Label> */}
                          <Form.Label>{contentDetails?.contact_us_page_data?.name}</Form.Label>
                          {/* <Form.Control type="text" placeholder="Geben Sie den Namen ein" name="name" onChange={handleChange} value={values.name}/> */}
                          <Form.Control type="text" placeholder={contentDetails?.contact_us_page_data?.name_placeholder} name="name" onChange={handleChange} value={values.name}/>
                          {errors.name && touched.name ? <div className="error">{errors.name}</div> : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-4">
                          {/* <Form.Label>E-Mail </Form.Label> */}
                          <Form.Label>{contentDetails?.contact_us_page_data?.e_mail}</Form.Label>
                          {/* <Form.Control type="email" placeholder="Geben Sie die E-Mail-Adresse ein" name="email" onChange={handleChange} value={values.email}/> */}
                          <Form.Control type="email" placeholder={contentDetails?.contact_us_page_data?.e_mail_placeholder} name="email" onChange={handleChange} value={values.email}/>
                          {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-4">
                          {/* <Form.Label>Telefonnummer</Form.Label> */}
                          <Form.Label>{contentDetails?.contact_us_page_data?.telephone_number}</Form.Label>
                          <Form.Control 
                            type="text" 
                            // placeholder="Geben Sie die Telefonnummer ein"
                            placeholder={contentDetails?.contact_us_page_data?.telephone_number_placeholder}
                            name="phonenumber" 
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(/\D/g, '');
                              handleChange({ target: { name: 'phonenumber', value: numericValue } });
                            }}
                            value={values.phonenumber}
                            maxLength={10}
                          />
                            {errors.phonenumber  && touched.phonenumber ? <div className="error">{errors.phonenumber}</div> :  null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          {/* <Form.Label>Nachricht</Form.Label> */}
                          <Form.Label>{contentDetails?.contact_us_page_data?.news}</Form.Label>
                          {/* <Form.Control as="textarea" rows={5} placeholder="Geben Sie die Nachricht ein" name="message" onChange={handleChange} value={values.message}/> */}
                          <Form.Control as="textarea" rows={5} placeholder={contentDetails?.contact_us_page_data?.news_placeholder} name="message" onChange={handleChange} value={values.message}/>
                          {errors.message && touched.message ? <div className="error">{errors.message}</div> :  null}
                        </Form.Group>
                      </Col>
                    </Row>
                     {isSubmit ? (
                        // <Button variant="primary" type="submit" className="BtnSubmit" disabled style={{cursor:"not-allowed"}}>Absenden</Button>
                        <Button variant="primary" type="submit" className="BtnSubmit" disabled style={{cursor:"not-allowed"}}>{contentDetails?.contact_us_page_data?.button_text}</Button>
                     ):(
                        // <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>handleSubmit()}>Absenden</Button>
                        <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>handleSubmit()}>{contentDetails?.contact_us_page_data?.button_text}</Button>
                     )}
                  </>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
