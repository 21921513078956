import React, { useEffect, useState } from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import StudentHeader from '../../components/header/StudentHeader';
import userImg from "../../assets/images/userImg.jpg";

import { getStudentDetails } from '../../api/apiRequest';
import { useLangContext } from '../../context/languageContext';

const StudentProfile = () => {
  const {hostLanguage,languageJson} = useLangContext();
  const [studentData,setStudentData] = useState();

 const fetchDetails = async() => {
    let apiRes = await getStudentDetails();
    let responseData = await apiRes.json();
    if(responseData.res)
    {
        console.log(responseData);
        setStudentData(responseData);
    }
    else
    {
        console.error(responseData.res);
    }
 }

 useEffect(()=>{
    fetchDetails();
 },[hostLanguage])

  return (
    <div className="lightBlue">
      <StudentHeader/>
      <div className="mail-box-main-body">
        <Row className="mail-box-body">
          <Col sm={10}>
            <div className="NewaddressBox">
              <h2>{languageJson?.student_profile}</h2>

              <div className="NewaddressBoxtInner">
                {/* <h4>Student Details</h4>
                <div className="DetailsBox">
                  <Row className="DetailsList">
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em> Full Name:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>Address:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>Country:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>City:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>Region:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                    <Col sm={6} className="pr-5">
                      <h5>
                        <em>Postal Code:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                    <Col sm={6} className="pl-5">
                      <h5>
                        <em>Phone Number:</em>
                        <span>C00125577</span>
                      </h5>
                    </Col>
                  </Row>
                  
                </div> */}
                    <div class="student-profile py-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="card shadow-sm">
                              <div class="card-header bg-transparent text-center">
                                {/* <img class="profile_img" src={userImg} alt="" style={{width:'30%', height: '30%'}}/> */}
                                {
                                  studentData?.data?.full_name!= null ? (
                                    <h3> {studentData?.data?.full_name} </h3>
                                    // <h3>{studentData?.student_details?.title} {studentData?.student_details?.first_name} {studentData?.student_details?.last_name}</h3>
                                  ):(
                                    <></>
                                  )
                                }
                                
                              </div>
                              <div class="card-body">
                                <p class="mb-0"><strong class="pr-1">{languageJson?.email_id}:</strong> {studentData?.data?.email}</p>
                                {studentData?.Country?.length > 0 ? (
                                  <p class="mb-0"><strong class="pr-1">{languageJson?.phone_number}:</strong> +{studentData?.Country[0]?.phonecode}{studentData?.student_details?.phone_number}</p>
                                ):(
                                  <p class="mb-0"><strong class="pr-1">{languageJson?.phone_number}:</strong> ---</p>
                                )}
                                
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-8">
                            <div class="card shadow-sm">
                              <div class="card-header bg-transparent border-0">
                                <h3 class="mb-0"><i class="far fa-clone pr-1"></i>{languageJson?.general_information}</h3>
                              </div>
                              <div class="card-body pt-0">
                                <table class="table table-bordered">
                                  <tr>
                                    <th width="30%">{languageJson?.address}</th>
                                    <td width="2%">:</td>
                                    {studentData?.student_details!= null ? (
                                      <td>{studentData?.student_details?.address}</td>
                                    ):(
                                      <td>--</td>
                                    )}
                                  </tr>
                                  <tr>
                                    <th width="30%">{languageJson?.country}</th>
                                    <td width="2%">:</td>
                                    {studentData?.Country?.length > 0 ? (
                                      <td>{studentData?.Country[0]?.name}</td>
                                    ):(
                                      <td>--</td>
                                    )}
                                  </tr>
                                  <tr>
                                    <th width="30%">{languageJson?.city}</th>
                                    <td width="2%">:</td>
                                    {studentData?.City?.length > 0 ? (
                                      <td>{studentData?.City[0]?.name}</td>
                                    ):(
                                      <td>--</td>
                                    )}
                                  </tr>
                                  <tr>
                                    <th width="30%">{languageJson?.state}</th>
                                    <td width="2%">:</td>
                                    {studentData?.State?.length > 0 ? (
                                      <td>{studentData?.State[0]?.name}</td>
                                    ):(
                                      <td>--</td>
                                    )}
                                  </tr>
                                  <tr>
                                    <th width="30%">{languageJson?.postal_code}</th>
                                    <td width="2%">:</td>
                                    {studentData?.student_details!= null ? (
                                      <td>{studentData?.student_details?.postcode}</td>
                                    ):(
                                      <td>--</td>
                                    )}
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default StudentProfile