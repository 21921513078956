// import React, {useState} from "react";
// import {Navigate, Outlet} from "react-router-dom";

// const NotLoginAuth = () => {
//   const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")) ? true : false);
//   return (
//     !isLoggedIn ?  <Outlet />  : <Navigate to= {'/LetStarted'}/>
//   )
// }

// export default NotLoginAuth


import React, {useState, useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";

const NotLoginAuth = () => {
    const [userRole] = useState(JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role ? JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role : null);
    // console.warn(userRole)
    return (
      !userRole? <Outlet/> : <Navigate to= '/'/>
    )
}

export default NotLoginAuth
