import React, { useState, useEffect } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TickBlue from "../../assets/images/TickBlue.svg";
import Nav from "react-bootstrap/Nav";
import Remove from "../../assets/images/Remove.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import "./MyRequest.scss";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { getMyRequestData, getAllMailCategory, recordMovedtoTrash, updateMailCategoryById, getPageContent } from "../../api/apiRequest";
import { useContextValues } from "../../context/commonContext";
import { useLangContext } from "../../context/languageContext";

const MyRequest = () => {
  const {setEventFire} = useContextValues();
  const [show, setShow] = useState(false);
  const [payshow, paysetShow] = useState(false);
  const [addressshow, addresssetShow] = useState(false);
  const [thankshow, thanksetShow] = useState(false);
  const [delshow, deletShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const payhandleClose = () => paysetShow(false);
  const payhandleShow = () => paysetShow(true);

  const addresshandleClose = () => addresssetShow(false);
  const addresshandleShow = () => addresssetShow(true);

  const thankhandleClose = () => thanksetShow(false);
  const thankhandleShow = () => thanksetShow(true);

  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);


  const [allReqArr,setAllReqArr] = useState([]);
  const [totalPages,setTotalPages] = useState();
  const [pageButtonsToShow,setPageButtonShow] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [categories,setCategories] = useState([]);
  const [deleteRecordId,setDeleteRecodId] = useState();
  const {hostLanguage,languageJson} = useLangContext();
  const [contentDetails,setContentDetails] = useState();

  //all myrequest data
  const fetchAllReqData = async(pageNumber) =>{
    try
    {
      let apiRes = await getMyRequestData(pageNumber,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        // Convert object to array
        const dataArr = Object.values(responseData.data.data).filter(obj => typeof obj === 'object');
        setAllReqArr(dataArr);
        setPageButtonShow(responseData.data.total);
        const totalPages = Math.ceil(Number(responseData.data.data.total_mail_count) / Number(responseData.data.per_page));
        setTotalPages(totalPages);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // all mail categories
  const fetchingAllMailCategories = async() => {
    try
    {
      let apiRes = await getAllMailCategory(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCategories(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // update mail category by id
  const updateCategoryById = async(record_id,cat_id) => {
    try
    {
      let apiRes = await updateMailCategoryById(record_id,cat_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchAllReqData(1);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // date format
  const dateFormat = (date) => {
    let split_date = date.split('-');
    // let final_date = split_date[1] + '.' + split_date[2] + '.' + split_date[0];
    let final_date = split_date[2] + '.' + split_date[1] + '.' + split_date[0];
    return final_date
  }
  
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchAllReqData(newPage);
  };
  
  const getPageNumbers = () => {
    const pageNumbers = [];
    const ellipsisKey = 'ellipsis';
  
    // console.warn('pages: ' + totalPages)
    // console.warn('button: ' + pageButtonsToShow)
    if (totalPages <= pageButtonsToShow)
    {
      // console.warn(1);
      for (let i = 1; i <= totalPages; i++)
      {
        pageNumbers.push(i);
      }
    }
    else
    {
      console.warn(2);
      if (currentPage <= Math.ceil(pageButtonsToShow / 2))
      {
        console.warn(3);
        for (let i = 1; i <= pageButtonsToShow - 1; i++)
        {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      else if (currentPage >= totalPages - Math.floor(pageButtonsToShow / 2))
      {
        console.warn(4);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = totalPages - pageButtonsToShow + 2; i <= totalPages; i++)
        {
          pageNumbers.push(i);
        }
      }
      else
      {
        console.warn(5);
        pageNumbers.push(1);
        pageNumbers.push({ key: ellipsisKey });
        for (let i = currentPage - Math.floor(pageButtonsToShow / 2) + 1; i <= currentPage + Math.floor(pageButtonsToShow / 2) - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push({ key: ellipsisKey });
        pageNumbers.push(totalPages);
      }
      console.warn(6);
    }
    console.log(pageNumbers);
    return pageNumbers;
  };
  
  // moved trashed data
  const MoveToTrashed = async() => {
    try
    {
      setEventFire(true);
      let apiRes = await recordMovedtoTrash(deleteRecordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        fetchAllReqData(1);
        delhandleClose();
        setEventFire(false);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  useEffect(()=>{
    fetchingAllMailCategories();
    fetchAllReqData(1);
    getAllPageContent();
  },[hostLanguage])

  return (
    <>
    {/* <div className="lightBlue"> */}
      {/* <InnerHeader /> */}
      {/* <div className="mail-box-main-body"> */}
        {/* <Row className="mail-box-body"> */}
          {/* <Col sm={2}>
            <Sidebar />
          </Col> */}
          <Col sm={10}>
            <div className="MailboxTable">
              <div className="Titelbox">
                <span>
                  {/* <h3>My Request</h3> */}
                  <h3>{contentDetails?.after_login?.MyRequest}</h3>
                </span>
                <span>
                  <Link to="/Dashboard" className="DashboardBtn">
                    {" "}
                    {/* Go To Dashboard */}
                    {contentDetails?.after_login?.GoToDashboard}
                  </Link>
                </span>
              </div>

              <Table>
                <thead>
                  <tr>
                    {/* <th>
                      <div className="form-check">
                        <Form.Check
                          aria-label="option 1"
                          id="flexCheckChecked2"
                        />
                      </div>
                    </th> */}
                    {/* <th>From</th>
                    <th>Type</th>
                    <th>Weight</th>
                    <th>Date</th>
                    <th>Category</th>
                    <th>Scan Status</th>
                    <th>Send Me Status</th>
                    <th>Actions</th> */}
                    <th> {contentDetails?.after_login?.From}</th>
                    <th> {contentDetails?.after_login?.Type}</th>
                    <th> {contentDetails?.after_login?.Weight}</th>
                    <th> {contentDetails?.after_login?.Date}</th>
                    <th> {contentDetails?.after_login?.Category}</th>
                    <th> {contentDetails?.after_login?.ScanStatus}</th>
                    <th> {contentDetails?.after_login?.SendMeStatus}</th>
                    <th> {contentDetails?.after_login?.Actions}</th>
                  </tr>
                </thead>
                <tbody>
                {allReqArr?.length > 0 ? (
                    allReqArr && allReqArr.map((item,index)=>{
                      return(
                        <>
                          <tr className="odd_tr">
                            {/* <td>
                              <div className="form-check">
                                <Form.Check
                                  aria-label="option 1"
                                  id="flexCheckChecked2"
                                />
                              </div>
                            </td> */}
                            <td>{item?.from}</td>
                            <td>{item?.mail_type?.name}</td>
                            <td>{item?.weight}g</td>
                            <td>{dateFormat(item?.get_date)}</td>
                            <td>
                              <Form.Select aria-label="Default select example" onChange={(e)=>updateCategoryById(item?.id,e.target.value)}>
                                {/* <option>Insurance</option> */}
                                {categories && categories.map((cat_item,cat_index)=>{
                                  return(
                                    // <option value={cat_item?.id} selected={cat_item?.id === item?.mail_category?.id ? true : false}>{cat_item?.id}{cat_item?.name}{item?.mail_category?.id}</option>
                                    <option value={cat_item?.id} selected={cat_item?.id === item?.mail_category?.id ? true : false}>{cat_item?.name}</option>
                                  )
                                })}
                              </Form.Select>
                            </td>
                            <td>
                              {
                                // item?.send_scan_request_status === 0 ? 'Not Processed' :
                                // item?.send_scan_request_status === 1 ? 'Process' :
                                // item?.send_scan_request_status === 2 ? 'Complete' : null  {contentDetails?.after_login?.Actions}
                                item?.send_scan_request_status === 0 ?  contentDetails?.after_login?.NotProcessed :
                                item?.send_scan_request_status === 1 ?  contentDetails?.after_login?.Process :
                                item?.send_scan_request_status === 2 ?  contentDetails?.after_login?.Complete : null
                              }
                            </td>
                            {item?.send_me_request == 1 || item?.send_me_request == 2 ? (
                              <td>
                                {
                                  // item?.send_me_request_status === 0 ? 'Not Processed' :
                                  // item?.send_me_request_status === 1 ? 'In Process' :
                                  // item?.send_me_request_status === 2 ? 'Ready to shipment' : 
                                  // item?.send_me_request_status === 3 ? 'Shipped' : 
                                  // item?.send_me_request_status === 4 ? 'Complete' : null
                                  item?.send_me_request_status === 0 ? contentDetails?.after_login?.NotProcessed :
                                  item?.send_me_request_status === 1 ? contentDetails?.after_login?.InProcess :
                                  item?.send_me_request_status === 2 ? contentDetails?.after_login?.ReadyToShipment : 
                                  item?.send_me_request_status === 3 ? contentDetails?.after_login?.Shipped : 
                                  item?.send_me_request_status === 4 ? contentDetails?.after_login?.Complete : null
                                }
                              </td>
                            ):(
                              <td></td>
                            )}
                            <td>
                              <a href="javascript:void(0)" onClick={()=>{delhandleShow();setDeleteRecodId(item?.id)}}>
                                <img src={Remove} className="TableIcon" alt="Img"/>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="11"></td>
                          </tr>
                        </>
                      )
                    })):(
                      <tr className="odd_tr">
                        {/* <td colSpan={8} style={{textAlign:'center'}}>No Requests Found</td> */}
                        <td colSpan={8} style={{textAlign:'center'}}>{languageJson?.no_request_found}</td>
                      </tr>
                  )}
                </tbody>
              </Table>

              {/* <div className="TablePagination">
                <Pagination>
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{8}</Pagination.Item>
                </Pagination>
              </div> */}
                {allReqArr?.length > 0 ? (
                  <div className="TablePagination">
                      {/* Pagination controls start */}
                      <Pagination>
                        {currentPage >= 1 && (
                          <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                        
                        {getPageNumbers().map((pageNumber, index) => (
                          <React.Fragment key={index}>
                            {pageNumber.key === 'ellipsis' ? (
                              <Pagination.Ellipsis key={index} />
                            ) : (
                              <Pagination.Item
                                key={index}
                                active={currentPage === pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </Pagination.Item>
                            )}
                          </React.Fragment>
                        ))}

                        {currentPage <= totalPages && (
                          <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                          />
                        )}
                      </Pagination>

                    {/* Pagination controls end */}

                  </div>
                ):null}

            </div>
          </Col>
        {/* </Row> */}
      {/* </div> */}

      <Modal
        show={delshow}
        onHide={delhandleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="CustomModal delCustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            Do you want this item/mail to be deleted? It will be automatically
            move to the trash for 30 day.
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={delhandleClose}
            className="lftBtn"
          >
            No
          </Button>
          <Button variant="primary" className="rgtBtn" onClick={()=>MoveToTrashed()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    {/* </div> */}
    </>
  );
};

export default MyRequest;
