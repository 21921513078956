import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header/Header";
import "./Payment.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import cloud from "../../assets/images/CloudIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import SmllTick from "../../assets/images/SmllTick.svg";
import RedCross from "../../assets/images/RedCross.svg";
import { IoCheckmarkCircleSharp, IoCloseCircle } from "react-icons/io5";
import { HiOutlinePlusSm, HiOutlineTrash, HiCheck } from "react-icons/hi";
import { useNavigate, Link, redirect } from "react-router-dom";
import {NotificationManager} from 'react-notifications'; 
import { DeleteProductListStep4, getProductListStep4, getUserDetails, submitStepFour, getMailBoxAndScanData, getPageContent, checkPhoneAvailability } from "../../api/apiRequest";
import PayPalComponent from "../PaymentPages/PayPalComponent";
import PayPalPayment from "../PaymentPages/PayPalPayment";
import Conditions from "../accept-condition/Conditions";
import { useLangContext } from "../../context/languageContext";
import { useContextValues } from "../../context/commonContext";

const Payment = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const {cartCount,setCartCount} = useContextValues();
  const buttonRef = useRef(null);
  const [userDetails, setUserDetails] = useState();
  const [userData, setLoginData] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [successMessagePassword, setSuccessMessagePassword] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [productInfo,setProductInfo] = useState();
  const [productPrice,setProductPrice] = useState();
  
  
  const [wallerPrice,setWalletPrice] = useState();
  const [deleteRecordId,setDeleteRecordId] = useState();
  const [sumAmount,setSumAmout] = useState();


  const [pricingArray,setPricingArray] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [priceData,setPriceData] = useState();
  const handleDeleteRecord = () => setDeleteModal(false);

  const [sumOfTotalPrice, setSumOfTotalPrice] = useState();

  const [checkBoxValue1,setCheckBoxValue1] = useState();
  const [checkBoxValue2,setCheckBoxValue2] = useState();

  const [contentDetails,setContentDetails] = useState();
  const [isDelete,setIsDelete] = useState(false);
  const [updatePhoneStatus,setUpdatePhoneStatus] = useState();
  const [phonePrice,setPhonePrice] = useState();
  const [isData,setIsData] = useState('1');


  // console.log('isData:', isData)

  useEffect(() => {
    //CreateBroadcast();
    //let data = logInDetails();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    
    if (value == undefined) {
      navigate("/");
    }
    getUserData();
    getDashboardData();
  }, []);

  useEffect(()=>{
    ProductListStep4();
    console.log(productDetails);
  },[])
  
  // useEffect(() => {
  //   ProductListStep4();
  //   const intervalId = setInterval(() => {
  //     ProductListStep4();
  //   },  10000); // 5000 milliseconds = 5 seconds    60000
  
  //   // Clean-up function to clear the interval when the component unmounts or the dependency changes
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    console.log("Updated pricingArray:", pricingArray);
    console.warn(sumOfTotalPrice)
    pricingArray.map((item,index)=>{})
  }, [pricingArray]);
  

  const calculateArrayPrice = () => {
    console.warn(pricingArray);
    let i = 0;
    let sum = 0;
    let total = 0;
    for(i = 0; i<pricingArray?.length; i++)
    {
      // sum = parseFloat(sum) + parseFloat(pricingArray[i].totalPrice);
      sum = parseFloat(sum) + parseFloat(pricingArray[i].price);
    }
  
    console.warn(pricingArray)
    console.warn(sum);
    let floatAmounnt = parseFloat(sum)?.toFixed(2);
    setSumOfTotalPrice(floatAmounnt);
    console.warn(floatAmounnt)

    // console.warn(sumOfVat);
    // totalPaypalAmount = calculatingPrice(sum,sumOfVat);
    // console.warn(totalPaypalAmount);


    // setPricingArray(prev => [...prev,
    //   {
    //     vat: item1?.plan?.vat_charge,
    //     price: item1?.plan?.price,
    //     totalPrice: calculatingPrice(item1?.plan?.price, item1?.plan?.vat_charge)
    //   }
  }

  useEffect(()=>{
    calculateArrayPrice();
  },[pricingArray])


  
  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
      }
      else
      {
        console.error(responseData.msg);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const getUserData = async() =>
  {
    try
    {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if(responseData)
      { //console.log(responseData.data);
        setUserDetails(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const getDashboardData = async() =>
  {
    try
    {
      let apiRes = await getMailBoxAndScanData();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setWalletPrice(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const handleParentData1 = (e) => {
    setCheckBoxValue1(e);
    console.log(e);
  }

  const handleParentData2 = (e) => {
    setCheckBoxValue2(e);
    console.log(e);
  }

//  const calculatingVAT = (total_price,vat_percentage) =>{
//     let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
//     return vat.toFixed(2);
//  }

//  const calculatingPrice = (total_price,vat_percentage) =>{
//   let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
//   let amt = parseFloat(total_price) + parseFloat(vat);
//   return amt.toFixed(2);
//  }

// const calculatingPrice = (total_price) =>{
//   // let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
//   // let amt = parseFloat(total_price) + parseFloat(vat);
//   return total_price.toFixed(2);
//  }

const calculatingVAT = (total_price,vat_percentage) =>{
  // let vat = ((parseFloat(total_price)) *(parseFloat(vat_percentage)) / 100);
  let vat = ((parseFloat(total_price)) * (parseFloat(vat_percentage)) / (parseFloat(100) + parseFloat(vat_percentage)));
  return vat.toFixed(2);
}

const calculatingPrice = (total_price,vat_percentage) =>{
  // VAT = 39,90 x 19 / ( 100 + 19 ) = 6.37   and the Net price is =  39,90 / ( 1+ 19/100 ) = 33,53
  // let price = ((parseFloat(total_price)) / (parseFloat(1) + ((parseFloat(vat_percentage)) / parseFloat(100))));
  
  // let percent = 39,90 / ( 1+ 19/100 = 1.19 )
  let cal = (vat_percentage / 100);
  let price = parseFloat(total_price / (1 + cal));

  return price.toFixed(2);
}

  const calculateSum = (property) => {
          return pricingArray.reduce((accumulator, currentItem) => {
          return accumulator + parseFloat(currentItem[property]);
      }, 0).toFixed(2);
    // }, 0)).toFixed(2);

      
    // const sumOfVat = calculateSum('vat');
    // const sumOfPrice = calculateSum('price');
    // const sumOfTotalPrice = calculateSum('totalPrice');
  }
  
 
  const DeleteProductList = async () => {
    try
    {
      setProductPrice();
      setPricingArray([]);

      let apiRes = await DeleteProductListStep4(deleteRecordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCartCount(prev=>prev > 0 && prev - 1);
        NotificationManager.success(languageJson?.successfully_deleted, '',2000);
        console.log(responseData.data.length)
        if(responseData.data.length === 0 )
        {
          setProductDetails([]);
        }
        ProductListStep4();
        handleDeleteRecord();
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      console.error('Something went wrong!');
    }
  }

  const ProductListStep4 = async() =>
  {
    try
    {
      let mail_price = 0;
      let phone_price = 0;
      let total_price = 0;
      let normal_price = 0;
      let withVATPrice = 0;
      let withOutVATPrice = 0;

      // let apiRes = await getProductListStep4(hostLanguage);
      let apiRes = await checkPhoneAvailability(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        console.log(isData)
        // if(responseData?.data?.length > 0)
        if(responseData?.totalCount > 0)
        {
          // setIsData('2');
          setUpdatePhoneStatus(responseData.update_status);
          setProductPrice();
          setPricingArray([]);
          setPriceData('1');
          let tempArr = [];
          responseData.data?.map((item,index)=>{
            item.map((item1,index1)=>{
              tempArr.push(item1)
            })
          })
          setProductDetails(tempArr);
          setProductInfo(tempArr[0].product.product_name);

          if(responseData.type === 'Mail,')
          {
            responseData.data[0]?.map((item1,index1)=>{
              setPricingArray(prev => [...prev,
                {
                  vat: item1?.plan?.vat_charge,
                  price: item1?.plan?.price,
                  totalPrice: calculatingPrice(item1?.plan?.price, item1?.plan?.vat_charge)
                  // totalPrice: item1?.plan?.price
                }
              ])
              
              mail_price = parseFloat(mail_price) + parseFloat(item1?.plan?.price);
              total_price = parseFloat(mail_price);
              // setProductPrice(total_price);
              setProductPrice(calculatingPrice(total_price,calculateSum('vat')));
              // setProductPrice(total_price);
            })
          }
          else if(responseData.type === 'Phone')
          {
            responseData.data[0]?.map((item,index)=>{
              setPricingArray(prev=>[...prev,
                {
                  vat: item?.phone_area?.vat_charge,
                  price: item?.phone_area?.total,
                  totalPrice: calculatingPrice(item?.phone_area?.total, item?.phone_area?.vat_charge)
                  // totalPrice: calculatingPrice(item?.phone_area?.total)
                  // totalPrice: item?.phone_area?.total
                }
              ])
              // setSumOfTotalPrice(prev => prev + calculatingPrice(item?.phone_area?.total, item?.phone_area?.vat_charge));
              phone_price = parseFloat(phone_price) + parseFloat(item?.phone_area?.total);
              total_price = parseFloat(mail_price).toFixed(2);
              // setProductPrice(total_price);
              setProductPrice(calculatingPrice(total_price,calculateSum('vat')));
              // setProductPrice(total_price);
              // console.log(item?.phone_area?.total)
              // console.log('ff')
              setPhonePrice(item?.phone_area?.total);
            })
          }
          else if(responseData.type === 'Mail,Phone')
          {
            responseData.data[0]?.map((item,index)=>{
              setPricingArray(prev => [...prev,
                {
                  vat: item?.plan?.vat_charge,
                  price: item?.plan?.price,
                  totalPrice: calculatingPrice(item?.plan?.price, item?.plan?.vat_charge)
                  // totalPrice: item?.plan?.price
                }
              ])
              normal_price = parseFloat(mail_price) + parseFloat(item?.plan?.price);
              setSumOfTotalPrice(prev => prev + calculatingPrice(item?.plan?.price, item?.plan?.vat_charge));
            })

            responseData.data[1]?.map((item2,index2)=>{
              setPricingArray(prev=>[...prev,
                {
                  vat: item2?.phone_area?.vat_charge,
                  price: item2?.phone_area?.total,
                  totalPrice: calculatingPrice(item2?.phone_area?.total,item2?.phone_area?.vat_charge)
                  // totalPrice: item2?.phone_area?.total
                }
              ])
              // console.warn(item2?.phone_area?.total)
              setPhonePrice(item2?.phone_area?.total);
              phone_price = parseFloat(phone_price) + parseFloat(item2?.phone_area?.total);
              setSumOfTotalPrice(prev => prev + calculatingPrice(item2?.phone_area?.total, item2?.phone_area?.vat_charge));
            })
            total_price = parseFloat(phone_price) + parseFloat(normal_price);
            setProductPrice(calculatingPrice(total_price,calculateSum('vat')));
            setProductPrice(total_price);
          }
          else
          {
            console.warn('Type is not matched: ' + responseData.type);
          }
        }
        else
        {
          setPriceData('0');
        }
      }
      else
      {
        // console.warn(3);
        // NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }


  const [cartData, setCartData] = useState({
    card_number: "",
    expiry_date: "",
    name_card: "",
    CVC: "",
  });
  

  const [ErrorsCartData, setErrorsCartData] = useState({
    card_number: "",
    expiry_date: "",
    name_card: "",
    CVC: "",
  });
 
  const handleChangeSubmit = e => {
    const { name, value } = e.target;
    const formatCardNumber = (cardNumber) => {
      return cardNumber.replace(/\D/g, "").replace(/(\d{4})/g, "$1 ").trim();
    };
    
    if (name === "expiry_date") {
      const numericValue = value.replace(/\D/g, '');
      const formattedValue =
        numericValue.length >= 2
          ? numericValue.substring(0, 2) + '/' + numericValue.substring(2)
          : numericValue;
      setCartData({ ...cartData, [name]: formattedValue });
    }else if (name === "CVC") {
      const numericValue = value.replace(/\D/g, '');
      const formattedValue =
        numericValue.length >= 2
          ? numericValue.substring(0, 2) + numericValue.substring(2)
          : numericValue;
      setCartData({ ...cartData, [name]: formattedValue });
    } else if (name === "card_number") {
          const numericValue = value.replace(/\D/g, "");
          
          const isValidCardNumber = /^\d{4} \d{4} \d{4} \d{4}$/.test(
            formatCardNumber(numericValue)
          );

          if (isValidCardNumber) {
            const formattedValue = formatCardNumber(numericValue);
            setCartData({ ...cartData, [name]: formattedValue });
          } else {
            setCartData({ ...cartData, [name]: value });
          }
    }else {
      // For other fields, update the state normally
      setCartData({ ...cartData, [name]: value });
    }
  };

  const handleRedirect = () => {
    navigate('/SelectProduct');
  }

 const handleSubmitStepFour = async (e) => {
  e.preventDefault();
  if (validateCardDetails()) {
    try
    {
      let apiRes = await submitStepFour(cartData);
      let responseData = await apiRes.json();
      if(responseData)
      {
        NotificationManager.success(responseData.msg, '',2000);
        navigate("/Dashboard");
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  }

  const validateCardDetails = () => {
    let isValid = true;
    const errors = {
      card_number: "",
      expiry_date: "",
      name_card: "",
      CVC: "",
    };
  if (cartData.card_number === "") {
      errors.card_number = "Please enter card number";
      isValid = false;
    }
    
    if (cartData.expiry_date === "") {
      errors.expiry_date = "Please enter expiry date";
      isValid = false;
    }
    
    if (cartData.name_card === "") {
      errors.name_card = "Please enter name of card";
      isValid = false;
    }
  
    if (cartData.CVC === "") {
      errors.CVC = "Please enter CVC";
      isValid = false;
    }
  
  
    setErrorsCartData(errors);
    return isValid;
  };


  







//Bank Transfer

  const [cartData2, setCartData2] = useState({
    card_number2: "",
    expiry_date2: "",
    name_card2: "",
    CVC2: "",
  });
  

  const [ErrorsCartData2, setErrorsCartData2] = useState({
    card_number2: "",
    expiry_date2: "",
    name_card2: "",
    CVC2: "",
  });
 
  // const handleChangeSubmit2 = e => {
  //   const {name, value} = e.target;
  //   setCartData2({...cartData2, [name]: value});
  // };

  const handleChangeSubmit2 = e => {
    const { name, value } = e.target;
    const formatCardNumber = (cardNumber) => {
      return cardNumber.replace(/\D/g, "").replace(/(\d{4})/g, "$1 ").trim();
    };
    
    if (name === "expiry_date2") {
      const numericValue = value.replace(/\D/g, '');
      const formattedValue =
        numericValue.length >= 2
          ? numericValue.substring(0, 2) + '/' + numericValue.substring(2)
          : numericValue;
      setCartData2({ ...cartData2, [name]: formattedValue });
    }else if (name === "CVC2") {
      const numericValue = value.replace(/\D/g, '');
      const formattedValue =
        numericValue.length >= 2
          ? numericValue.substring(0, 2) + numericValue.substring(2)
          : numericValue;
      setCartData2({ ...cartData2, [name]: formattedValue });
    } else if (name === "card_number2") {
          const numericValue = value.replace(/\D/g, "");
          
          const isValidCardNumber = /^\d{4} \d{4} \d{4} \d{4}$/.test(
            formatCardNumber(numericValue)
          );

          if (isValidCardNumber) {
            const formattedValue = formatCardNumber(numericValue);
            setCartData2({ ...cartData2, [name]: formattedValue });
          } else {
            setCartData2({ ...cartData2, [name]: value });
          }
    }else {
      // For other fields, update the state normally
      setCartData2({ ...cartData2, [name]: value });
    }
  };

  

 const handleSubmitStepFour2 = async (e) => {
  e.preventDefault();
  if (validateCardDetails()) {
    try
    {
      let apiRes = await submitStepFour(cartData2);
      let responseData = await apiRes.json();
      if(responseData)
      {
        NotificationManager.success(responseData.msg, '',2000);
        navigate("/Dashboard");
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  }

  const validateCardDetails2 = () => {
    let isValid = true;
    const errors = {
      card_number2: "",
      expiry_date2: "",
      name_card2: "",
      CVC2: "",
    };
  if (cartData2.card_number2 === "") {
      errors.card_number2 = "Please enter card number";
      isValid = false;
    }
    
    if (cartData2.expiry_date2 === "") {
      errors.expiry_date2 = "Please enter expiry date";
      isValid = false;
    }
    
    if (cartData2.name_card2 === "") {
      errors.name_card2 = "Please enter name of card";
      isValid = false;
    }
  
    if (cartData2.CVC2 === "") {
      errors.CVC2 = "Please enter CVC";
      isValid = false;
    }

    setErrorsCartData2(errors);
    return isValid;
  };


  const handleRoute = (route) => {
    navigate(route);
  };

  const handleSubmitClick = () => {
    buttonRef.current.click();
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  let netTotal = 0;
  return (
    <div className="lightBlue">
      <Header />
      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center MainRow">
            <Col sm={5}>
              {/* <h2>Set up your account</h2> */}
              <h2>{contentDetails?.lets_start_page_data?.set_up_your_account}</h2>
            </Col>
            <Col sm={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Let's Started</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Select Product</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.select_product}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      {" "}
                      <HiCheck size="20" />{" "}
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Your Details</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.your_details}</span>
                </div>

                <div class="form-steps__step form-steps__step--last form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">04</span>
                  </div>
                  {/* <span class="form-step__step-name">Payment</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.payment}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container">
          <Row className="">
            <Col sm={4}>
              <div className="MainBodyLft">
              {priceData!= '0' ?(
                  <>
                    {/* <h5>Your selected cleverdomizil services</h5>
                    <p>Your selected cleverdomizil services</p> */}
                    <h5>{contentDetails?.your_details_page_data?.Your_selected_cleverdomizil_services}</h5>
                    <p>{contentDetails?.your_details_page_data?.Your_selected_cleverdomizil_services}</p>
                  </>
              ):(
                <>
                  {/* <h5>Cleverdomizil services</h5> */}
                  <h5>{contentDetails?.your_details_page_data?.cleverdomizil_services}</h5>
                  {/* <p>Your selected cleverdomizil services</p> */}
                </>
              )}
               {productDetails?.length > 0 ? (
                <>
                  {productDetails?.map((item,index)=>{
                    return(
                      item.product_id == 1 ? (
                        <div className="MainBodyLftBox">
                        <div className="PriceBox">
                          <Row>
                            <Col sm={8}>
                              <div className="iconBox">
                                <img src={cloud} className="iconImg" alt="Img" />
                                <h4>{item.product.product_name}</h4>
                              </div>
                            </Col>
                            <Col sm={4}>
                              {(userDetails?.user_billing_details?.vat_number!= null || userDetails?.user_billing_details?.vat_number!= '' || userDetails?.user_billing_details?.vat_number!= undefined) ? (
                                <div className="PriceTxt">
                                  {/* <p>Price</p> */}
                                  <p>{contentDetails?.your_details_page_data?.price}</p>
                                  <h5>
                                    {/* {userDetails?.currency}  */}
                                  {/* // calculatingPrice(item.plan.price,item?.plan?.vat_charge)}</h5> */}
                                  {/* // calculatingPrice(item.plan.price,item?.plan?.vat_charge)}</h5> */}
                                  {/* {calculatingPrice(item.plan.price,item?.plan?.vat_charge)}</h5> */}
                                  {userDetails?.currency} {item.plan.price} </h5>
                                  {/* {item.plan.price} */}
                                  {/* </h5> */}
                                  <p>/{item.contract_period.name}</p>
                                </div>
                              ):(
                                <div className="PriceTxt">
                                  {/* <p>Price</p> */}
                                  <p>{contentDetails?.your_details_page_data?.price}</p>
                                  <h5>{userDetails?.currency} {item.plan.price}</h5>
                                  <p>/{item.contract_period.name}</p>
                                </div>
                              )}
                              
                            </Col>
                          </Row>
                        </div>
      
                        <div className="PriceBoxBottom">
                          <ul>
                            <li>
                              {/* <label>Contract term</label> */}
                              <label>{contentDetails?.your_details_page_data?.contract_term}</label>
                              {item.contract_period.name} • {item.duration_start} - {item.duration_end}
                            </li>
                            
                            <li>
                              {/* <label>Internet price</label> */}
                              {/* <label>Net price</label> */}
                              <label>{contentDetails?.your_details_page_data?.net_price}</label>
                                {/* {userDetails?.currency} {item.plan.price} */}
                                {userDetails?.currency} {calculatingPrice(item.plan.price,item?.plan?.vat_charge)}
                            </li>
                            <li>
                              {/* VAT */}
                              <label>{contentDetails?.your_details_page_data?.VAT} {parseFloat(item?.plan?.vat_charge).toFixed(2)} %</label>
                              {userDetails?.currency} {calculatingVAT(item.plan.price,item?.plan?.vat_charge)}
                              {/* {userDetails?.currency} {parseFloat(item?.plan?.vat_charge).toFixed(2)} */}
                            </li>
                            <li>
                              <label>{contentDetails?.after_login?.city_level}</label>
                              <span>{item?.city?.name}</span>
                            </li>
                          <li>
                            <label>{contentDetails?.after_login?.country_level}</label>
                            <span>{item?.country?.name}</span>
                          </li>
                            
                          </ul>
                        </div>
                        <div className="DeleteBottom">
                          <h4>{item?.plan?.title}</h4>
                          {/* <h4>{productDetails.area_text}</h4> */}


                          <Button
                            onClick={() =>{setDeleteModal(true);setDeleteRecordId(item.id)}}
                            variant="primary"
                            type="button"
                            className="BtnDelete"
                          >
                            <HiOutlineTrash size="20" /> 
                            {/* Delete */}
                            {contentDetails?.your_details_page_data?.delete}
                          </Button>
                        </div>
                        </div>
                      ):
                      item.product_id == 2 ? (
                        <div className="MainBodyLftBox">
                        <div className="PriceBox">
                          <Row>
                            <Col sm={8}>
                              <div className="iconBox">
                                <img src={phone} className="iconImg" alt="Img" />
                                <h4>{item.product.product_name}</h4>
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="PriceTxt">
                                {/* <p>Price</p> */}
                                <p>{contentDetails?.your_details_page_data?.price}</p>                                
                                {/* <h5>{userDetails?.currency} {calculatingPrice(item.phone_area.total,item?.phone_area?.vat_charge)}</h5> */}
                                <h5>{userDetails?.currency} {item.phone_area.total}</h5>
                                {/* <p>/Yearly</p> */}
                                <p>/{contentDetails?.your_details_page_data?.yearly}</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="DeleteBottom">
                          <h4 style={{color: '#155c8f'}}>{item.phone_country.country_code} {item.phone_area.area_code} {item.phone.phone_number}</h4>
                          <Button variant="primary" type="button" className="BtnDelete">
                            {
                              updatePhoneStatus == 'available' ? <><IoCheckmarkCircleSharp size="1.5rem" color="green"/><h4 style={{color:'green'}}>Available</h4></> :
                              updatePhoneStatus == 'not_available' ? <><IoCloseCircle size="1.5rem" color="red"/><h4 style={{color:'red'}}>Not-available</h4></> :
                              updatePhoneStatus == 'swipe' ? <><IoCheckmarkCircleSharp size="1.5rem" color="green"/><h4 style={{color:'green'}}>Swipe</h4></> : ''
                            }
                            {/* <>
                            <IoCheckmarkCircleSharp size="1.5rem" color="green"/>
                            <h4 style={{color:'green'}}>{updatePhoneStatus}</h4>
                            </> */}
                            
                          </Button>
                        </div>
                        <div className="PriceBoxBottom">
                          <ul>
                            <li>
                              {/* <label>Contract term</label> */}
                              <label>{contentDetails?.your_details_page_data?.contract_term}</label>
                              {/* Yearly • {item.duration_start} - {item.duration_end} */}
                              {contentDetails?.your_details_page_data?.yearly} • {item.duration_start} - {item.duration_end}
                            </li>
                            {/* <li>
                              <label>Installation fee</label>
                              {item.phone_area.setup_charges}
                            </li> */}
                            <li>
                              {/* <label>Internet price</label> */}
                              {/* <label>Net price</label> */}
                              <label>{contentDetails?.your_details_page_data?.net_price}</label>
                              {/* {item.phone_area.total} */}
                              {userDetails?.currency} {calculatingPrice(item.phone_area.total,item?.phone_area?.vat_charge)}
                              
                            </li>
                            
                            <li>
                              {/* <label>Net price</label>{userDetails?.currency} {item.plan.price} */}
                            </li>
                            <li>
                              {/* VAT */}
                              <label>{contentDetails?.your_details_page_data?.VAT} {parseFloat(item?.phone_area?.vat_charge).toFixed(2)} %</label>
                              {/* {userDetails?.currency} {calculatingVAT(item.phone_area.total,item?.phone_area?.vat_charge)} */}
                              {userDetails?.currency} {calculatingVAT(item.phone_area.total,item?.phone_area?.vat_charge)}
                              {/* {userDetails?.currency} {parseFloat(item?.phone_area?.vat_charge).toFixed(2)} */}
                            </li>
                            <li>
                              <label>{contentDetails?.after_login?.area_level}</label>
                              <span>{item?.phone_area?.area}</span>
                            </li>
                            <li>
                              <label>Country</label>
                              <span>{item?.phone_country?.name}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="DeleteBottom">
                          {/* <h4>Postbox LITE</h4> */}
                          <h4>{item?.phone_area?.area_text}</h4>
                          <Button
                            onClick={() =>{setDeleteModal(true);setDeleteRecordId(item.id)}}
                            variant="primary"
                            type="button"
                            className="BtnDelete"
                          >
                            <HiOutlineTrash size="20" /> 
                            {/* Delete */}
                            {contentDetails?.your_details_page_data?.delete}
                          </Button>
                        </div>
                        </div>
                      ):null
                    )
                  })}
                    
                    <>
                    <Button variant="primary" type="button" className="AddBtn" onClick={()=>handleRedirect()}>
                    <HiOutlinePlusSm size="25"to />
                    {/* <Link to='/SelectProduct'> */}
                      {/* Add another product */}
                      {contentDetails?.your_details_page_data?.Add_another_product}
                    {/* </Link>  */}
                    </Button>

                    <div className="MainBodyLftBox">
                    <div className="FinalPriceBox">
                      <ul>
                        <li>
                          <label>
                            {/* Net TOTAL | <span>{userDetails?.currency}</span> {calculateSum('price')} */}
                          </label>
                          <h6>
                            {/* <em>Gross Total</em> */}
                            <em>{contentDetails?.your_details_page_data?.Gross_Total}</em>

                            <br />
                            {/* {userDetails?.currency} {calculateSum('totalPrice')} */}
                            {userDetails?.currency}
                            {
                              updatePhoneStatus == 'available' ?   calculateSum('price'):
                              updatePhoneStatus == 'not_available' ? (parseFloat(calculateSum('price')) - parseFloat(phonePrice)).toFixed(2):
                              updatePhoneStatus == 'swipe' ?  calculateSum('price'): calculateSum('price')
                            }
                          </h6>
                        </li>
                        {/* <li> */}
                          {/* <label>Current Account Balance</label> */}
                          {/* <h6>{userDetails?.currency} 0.00</h6> */}
                          {/* <h6>{userDetails?.currency} {parseFloat(wallerPrice?.wallet_balance).toFixed(2)}</h6> */}
                        {/* </li> */}
                        <li>
                          <label>
                            {/* Payment method | <span>pre-payment</span> */}
                            {contentDetails?.your_details_page_data?.Payment_method} | <span>{contentDetails?.your_details_page_data?.Pre_payment}</span>
                          </label>
                          {(userDetails?.user_billing_details?.vat_number!= null || userDetails?.user_billing_details?.vat_number!= '' || userDetails?.user_billing_details?.vat_number!= undefined) ? (
                            <h6>
                              {/* <em>Gross Total</em> */}
                              <em>{contentDetails?.your_details_page_data?.Gross_Total}</em>
                              <br />
                              {/* {userDetails?.currency}  {calculateSum('price')} */}
                              {userDetails?.currency}
                              {
                                updatePhoneStatus == 'available' ?   calculateSum('price'):
                                updatePhoneStatus == 'not_available' ? (parseFloat(calculateSum('price')) - parseFloat(phonePrice)).toFixed(2):
                                updatePhoneStatus == 'swipe' ?  calculateSum('price'): calculateSum('price')
                              }
                            </h6>
                          ):(
                            <h6>
                              {/* <em>Gross Total</em> */}
                              <em>{contentDetails?.your_details_page_data?.Gross_Total}</em>
                              <br />{userDetails?.currency}  {calculateSum('price')}
                            </h6>
                          )}
                        </li>
                      </ul>
                    </div>
                    </div>
                    </>
                   
                    </>   
               ):(
                <>
                  {/* <p>No Record Found</p> */}
                  <p>{contentDetails?.your_details_page_data?.no_record_found}</p>
                  <Button variant="primary" type="button" className="AddBtn" onClick={()=>handleRedirect()}>
                    <HiOutlinePlusSm size="25" /> 
                    {/* Add another product */}
                    {contentDetails?.your_details_page_data?.Add_another_product}
                  </Button>
                </>
               )}
               

                
              </div>
            </Col>
            {productDetails?.length > 0 && (
              <Col sm={8}>
              <Conditions propsData1 = {handleParentData1} propsData2 = {handleParentData2} type='purchase-product'/>
              {
                checkBoxValue1 && checkBoxValue1 === 'accept_terms' &&
                checkBoxValue2 && checkBoxValue2 === 'accept_contract' ? (
                  <>
                    {sumOfTotalPrice > 0 && (
                      // <Col sm={8}>
                        <div className="MainBodyRgt">
                          {/* <h5>Select payment method</h5> */}
                          <h5>{contentDetails?.your_details_page_data?.Select_payment_method}</h5>
                          {/* <p>Your selected cleverdomizil services</p> */}
                          <div className="MainBodyRgtInner">
                            {/* <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>Credit Card/Debit Card</Accordion.Header>
                                <Accordion.Body>
                                  <PayPalComponent productInfo={productInfo} productPrice={sumOfTotalPrice? sumOfTotalPrice:1} type={'purchase-product'}/>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion> */}

                            {/* <Accordion defaultActiveKey="1">
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>PayPal Payment</Accordion.Header>
                                <Accordion.Body>
                                  <PayPalPayment productInfo={productInfo} productPrice={sumOfTotalPrice ? sumOfTotalPrice : 1} type={'purchase-product'}/>
                                </Accordion.Body>
                              </Accordion.Item> */}
                              {/* <h3>Payment with</h3> */}
                              <h3>{contentDetails?.your_details_page_data?.payment_with}</h3>
                              {/* <PayPalComponent productInfo={productInfo} productPrice={calculateSum('price')} type={'purchase-product'}/> */}
                              {/* <PayPalPayment productInfo={productInfo} productPrice={sumOfTotalPrice ? sumOfTotalPrice : 1} type={'purchase-product'}/> */}

                              {
                              updatePhoneStatus == 'available' ? (
                                <>
                                  <PayPalComponent productInfo={productInfo} productPrice={calculateSum('price')} type={'purchase-product'}/>
                                  <PayPalPayment productInfo={productInfo} productPrice={sumOfTotalPrice ? sumOfTotalPrice : 1} type={'purchase-product'}/>
                                </>
                              ):
                              updatePhoneStatus == 'not_available' ? (
                                // (parseFloat(calculateSum('price')) - parseFloat(phonePrice)).toFixed(2)
                                <>
                                  <PayPalComponent productInfo={productInfo} productPrice={(parseFloat(calculateSum('price')) - parseFloat(phonePrice)).toFixed(2)} type={'purchase-product'}/>
                                  <PayPalPayment productInfo={productInfo} productPrice={(parseFloat(calculateSum('price')) - parseFloat(phonePrice)).toFixed(2)} type={'purchase-product'}/>
                                </>
                              ):
                              updatePhoneStatus == 'swipe' ? (
                                <>
                                  <PayPalComponent productInfo={productInfo} productPrice={calculateSum('price')} type={'purchase-product'}/>
                                  <PayPalPayment productInfo={productInfo} productPrice={sumOfTotalPrice ? sumOfTotalPrice : 1} type={'purchase-product'}/>
                                </>
                              ): (
                                <>
                                  <PayPalComponent productInfo={productInfo} productPrice={calculateSum('price')} type={'purchase-product'}/>
                                  <PayPalPayment productInfo={productInfo} productPrice={sumOfTotalPrice ? sumOfTotalPrice : 1} type={'purchase-product'}/>
                                </>
                              )
                            }
                              {/* <Accordion.Item eventKey="1">
                                <Accordion.Header>Bank Transfer</Accordion.Header>
                                <Accordion.Body>
                                  <div>
                                  {successMessagePassword && <div className="success-message" >{successMessagePassword}</div>}
                                  {errorMessagePassword && <div className="error-message">{errorMessagePassword}</div>}
                                    <Form.Group className="mb-4 text-left">
                                      <Form.Label>Card number</Form.Label>
                                      <Form.Control
                                        className="card"
                                        name="card_number2"
                                        value={cartData2.card_number2}
                                        onChange={handleChangeSubmit2}
                                        placeholder="***************"
                                        maxLength={19} 
                                      />
                                      {ErrorsCartData2.card_number2 && <span className="error-message">{ErrorsCartData2.card_number2}</span>}
                                    </Form.Group>
                                    
                                    <Row>
                                      <Col sm={6}>
                                        <Form.Group className="mb-4 text-left">
                                          <Form.Label>Expiry date</Form.Label>
                                          <Form.Control
                                            name="expiry_date2"
                                            value={cartData2.expiry_date2}
                                            onChange={handleChangeSubmit2}
                                            type="text"
                                            placeholder="Type expiry date"
                                            maxLength={5}
                                          />
                                          {ErrorsCartData2.expiry_date2 && <span className="error-message">{ErrorsCartData2.expiry_date2}</span>}
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6}>
                                        <Form.Group className="mb-4 text-left">
                                          <Form.Label>CVC / CVV</Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="CVC2"
                                            value={cartData2.CVC2}
                                            onChange={handleChangeSubmit2}
                                            placeholder="Type vcc number"
                                            maxLength={3}
                                          />
                                          {ErrorsCartData2.CVC2 && <span className="error-message">{ErrorsCartData2.CVC2}</span>}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    <Form.Group className="mb-4 text-left">
                                      <Form.Label>Name on card</Form.Label>
                                      <Form.Control
                                      name="name_card2"
                                      value={cartData2.name_card2}
                                      onChange={handleChangeSubmit2}
                                        type="text"
                                        placeholder="Enter name on card"
                                        maxLength={50} 
                                      />
                                      {ErrorsCartData2.name_card2 && <span className="error-message">{ErrorsCartData2.name_card2}</span>}
                                    </Form.Group>
                                    {productDetails.length > 0 ? (
                                    
                                        <>
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        className="BtnSubmit2"
                                        // href="/Dashboard"
                                        //onClick={() => handleRoute("/Dashboard")}
                                        onClick={handleSubmitStepFour2}
                                      >
                                        Process Payment
                                      </Button>
                                      </>
                                  
                                    
                                    ):(
                                      <></>
                                    )}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item> */}
                            {/* </Accordion> */}
                          </div>
                        </div>
                      // </Col>
                    )}
                  </>
              ):(
                <></>
              )}

              </Col>
            )}
           
          </Row>
        </div>
        <Modal
          show={deleteModal}
          onHide={handleDeleteRecord}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal delCustomModal"
        >
        <Modal.Header closeButton>
          {/* <Modal.Title>Delete</Modal.Title> */}
          <Modal.Title>{contentDetails?.your_details_page_data?.delete_modal_heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            {/* Do you want to delete this? */}
            {contentDetails?.your_details_page_data?.delete_modal_desc}
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleDeleteRecord}
            className="lftBtn"
          >
            {/* No */}
            {contentDetails?.your_details_page_data?.delete_modal_no}
          </Button>
          <Button variant="primary" className="rgtBtn" onClick={()=>DeleteProductList()}>
            {/* Yes */}
            {contentDetails?.your_details_page_data?.delete_modal_yes}
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
};

export default Payment;
