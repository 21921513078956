import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./TaxService.scss";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import TickImg from "../../assets/images/TickTick.svg";
import taxImg from "../../assets/images/tax-service.jpg";
import { NotificationManager } from "react-notifications";

import { getCmsDataById, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const TaxService = () => {

  const [data,setData] = useState([]);
  const {hostLanguage} = useLangContext();
  const [contentDetails,setContentDetails] = useState();

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
    
  // get image link
  const getImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage]);

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="PricelistBanner MapBanner">
          <Container>
            <Row>
              <Col sm={8}>
                {/* <p>Service</p> */}
                <h3>{contentDetails?.tax_service_page_data?.title}</h3>
                {/* <ul className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox3">
                  <li>
                    <Form.Select aria-label="Default select example" onChange={(e)=>handleState(1,e.target.value)}>
                      <option selected hidden>Country</option>
                      {countries?.length > 0 ? (
                        countries?.map((item,index)=>{
                          return(
                            <option value={item[0]?.country?.id}>{item[0]?.country?.name}</option>
                          )
                        })
                      ):(
                        <option disabled>No Countries Found.</option>
                      )}
                    </Form.Select>
                  </li>

                  <li>
                    <Form.Select aria-label="Default select example">
                      <option selected hidden>State</option>
                      {states?.length > 0 ? (
                        states?.map((item,index)=>{
                          return(
                            <option>{item?.state?.name}</option>
                          )
                        })
                      ):(
                        <option disabled>No States Found.</option>
                      )}
                    </Form.Select>
                  </li>
                </ul> */}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="PricelistBody">
          <Container>
          <div dangerouslySetInnerHTML={{ __html: contentDetails?.tax_service_page_data?.description1}} ></div>
          </Container>
          <Col >
            {contentDetails?.tax_service_page_data?.image1!=undefined && (
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image src={getImgLink(contentDetails?.tax_service_page_data?.image1)} className="BoxImg" alt="BoxImg" style={{width: '82%', position: 'relative'}}/>
              </div>
            )}
            <br/>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Image src={taxImg} className="BoxImg" alt="BoxImg" style={{width: '82%', position: 'relative'}}/>
            </div>
          </Col>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TaxService;
