import React, { useEffect, useState } from "react";
import "./Registration.scss";
import logo from "../../assets/images/Logo.svg";
import newlogo from "../../assets/images/Clever-New-Logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import {NotificationManager} from 'react-notifications'; 
import { HiArrowNarrowRight, HiArrowNarrowLeft, HiCheck } from "react-icons/hi";


import viewEye from "../../assets/images/view.png";
import hideEye from "../../assets/images/hide.png";
import InputGroup from "react-bootstrap/InputGroup";

import { registerUser, registerStudent, sendVerifyEmail, login, getPageContent} from "../../api/apiRequest";
import Cookies from "js-cookie";
import { useLangContext } from "../../context/languageContext";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import {API_BASE_URL} from "../../app_url";

const Registration = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext ();
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [contentDetails,setContentDetails] = useState();
  const [registrationType,setRegistrationType] = useState();

  const [show2, setShow2] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [tokenexist, setTokenexist] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
	const [hide, setHide] = useState(false);
	const hideInstaAlert = () => { 
		    setHide(true);
        Cookies.set("showCookieNotify", "set", {
            expires: 30,
          });

        if(isChecked){
          Cookies.set("allowCookieTranslate", "set", {
            expires: 1,
          });
        }   
        
          
	}
   
  const GetCookie = () => {
      //alert(Cookies.get("token"));
      setTokenexist(Cookies.get("showCookieNotify"));
  };


  const [registrationData,setRegistrationData] = useState({
    full_name: '',
    email: '',
    password: '',
    lang: hostLanguage
  });
  const [errors,setErrors] = useState({});
  const [otpError,setOtpError] = useState(false);
  const [isDisabledButton,setIsDisabledButton] = useState(false);
  const [otpDisabledButton,setOtpDisabledButton] = useState(false);

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
       console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const handleInputChange = (e) =>{
    setErrors({});
    const { name, value} = e.target;
    setRegistrationData({
      ...registrationData,
      [name]: value,
    });
  };

  useEffect(() => {console.log(tokenexist)
    GetCookie();
    getAllPageContent();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    if (value != undefined) {
      console.log(value)
      if(value.go_to_step==1){
        navigate("/LetStarted");
      }else if(value.go_to_step==2){
        navigate("/SelectProduct");
      }else if(value.go_to_step==3){
        navigate("/YourDetails");
      }else if(value.go_to_step==4){
        navigate("/Payment");
      }else if(value.go_to_step==5){
        navigate("/Dashboard");
      }
    }
    
  }, []);
  
  const validateForm = () => {
    const newErrors = {};
  
    // Name validation
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!registrationData.full_name.trim())
    {
      newErrors.full_name = languageJson?.FullNameValidation1;
    }
    else if (!nameRegex.test(registrationData.full_name))
    {
      newErrors.full_name = languageJson?.FullNameValidation2;
    }
  
    // Email validation
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!registrationData.email.trim())
    {
      newErrors.email = languageJson?.EmailValidation1;
    }
    else if (!emailRegex.test(registrationData.email))
    {
      newErrors.email = languageJson?.EmailValidation2;
    }
  
    // Password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!registrationData.password.trim())
    {
      newErrors.password = languageJson?.PasswordValidation1;
    }
    else if (registrationData.password.length < 8 || !passwordRegex.test(registrationData.password)) 
    {
      newErrors.password = languageJson?.PasswordValidation2;
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleVerifyEmail = async (e,type) => {
    console.log(type);
    setRegistrationType(type);
    try
    {
      setIsDisabledButton(true);
      e.preventDefault();

      if(!validateForm())
      {
        setIsDisabledButton(false);
        return
      }
      else
      {
        let values = {email:registrationData.email}
        let apiRes = await sendVerifyEmail(values)
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          const formattedTime = new Date().toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });

          registrationData.email_verified_at = responseData.data.created_date + ' ' + formattedTime;

          registrationData.otp = responseData.data.otp;
          handleShow();
          setIsDisabledButton(false);
        }
        else
        {
          NotificationManager.error(responseData.msg, '',2000);
          setIsDisabledButton(false);
        }
      }
    }
    catch(error)
    {
      console.error('Something went wrong!', '',2000);
      setIsDisabledButton(false);
    }
  }

  const handleRegister = async() => {
    try
    {
      setOtpDisabledButton(false);
      if(otp == '')
      {
        setOtpError(true);
      }
      else
      {
        setOtpError(false);
        if(otp ==  registrationData.otp)
        {
          delete registrationData.otp;

          if(registrationType == 'user')
          {
            let apiRes = await registerUser(registrationData);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
              let values = {
                email: registrationData?.email,
                password: registrationData?.password,
                lang: hostLanguage
              }
              let apiRes = await login(values)
              let responseLogin = await apiRes.json();
              if (responseLogin.res == true)
              {
                const token = responseLogin.data.token;
                localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(responseLogin.data));
                let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
                if (tokenexist)
                {
                  Cookies.set("cookies_user_data", value, {
                    expires: 2/24,  // 2 hour
                  });
                }
                if(responseLogin.data.role=='client')
                {
                  if(responseLogin.data.go_to_step==1) 
                  {
                    navigate("/LetStarted");
                  }
                  else if(responseLogin.data.go_to_step==2)
                  {
                    navigate("/SelectProduct");
                  }
                  else if(responseLogin.data.go_to_step==3)
                  {
                    navigate("/YourDetails");
                  }
                  else if(responseLogin.data.go_to_step==4)
                  {
                    navigate("/Payment");
                  }
                  else if(responseLogin.data.go_to_step==5)
                  {
                    navigate("/Dashboard");
                  }
                }
                else
                {
                  console.log(responseLogin.data.role);
                }
              }
              else
              {
                console.error(responseLogin.res);
              }
            }
            else
            {
              console.error(responseData.res);
              setOtpDisabledButton(false);
            }
          }
          else if(registrationType == 'student')
          {
            let apiRes = await registerStudent(registrationData);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
              let values = {
                email: registrationData?.email,
                password: registrationData?.password,
                lang: hostLanguage
              }
              let apiRes = await login(values)
              let responseLogin = await apiRes.json();
              if (responseLogin.res == true)
              {
                localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(responseLogin.data));
                let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
                if (tokenexist)
                {
                  Cookies.set("cookies_user_data", value, {
                    expires: 2/24,  // 2 hour
                  });
                }
                
                if(responseLogin.data.role=='student') 
                {
                  navigate('/student-account-set-up');
                }
              }
              else
              {
                console.error(responseLogin.res);
              }
            }
            else
            {
              console.error(responseData.res);
              setOtpDisabledButton(false);
            }
          }
          else
          {
            console.error(registrationType);
          }
        }
        else
        {
          setOtpError(true);
          setOtpDisabledButton(false);
        }
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
      setOtpError(false);
      setOtpDisabledButton(false);
    }
  };

  // registerUser
  useEffect(()=>{
    getAllPageContent();
    console.warn(languageJson);
  },[hostLanguage])

  return (
    <div>
      <div className="MainBody">
        <Link to='/'>
          {/* <img src={logo} className="MainLogo" alt="logo"/> */}
          <img src={newlogo} className="MainLogo" alt="logo"/>
        </Link>
        <h1>{contentDetails?.registration_page_data?.registration}</h1>
        <p>{contentDetails?.registration_page_data?.youNeedToSignUpFirstToBookAnyService}</p>

        <div className="InnerBox">
          
          <Form>
            <Form.Group className="mb-4 name" controlId="">
              <Form.Label>{contentDetails?.registration_page_data?.fullName}</Form.Label>
              <Form.Control 
                type="text" 
                name="full_name"
                placeholder={contentDetails?.registration_page_data?.fullName_placeholder}
                value={registrationData.full_name}
                onChange={handleInputChange}
              />
              {errors.full_name && <span className="error">{errors.full_name}</span>}
            </Form.Group>

            <Form.Group className="mb-4 mail" controlId="">
              <Form.Label>{contentDetails?.registration_page_data?.emailAddress}</Form.Label>
              <Form.Control 
                type="email"
                name="email"
                placeholder={contentDetails?.registration_page_data?.emailAddress_placeholder}
                value={registrationData.email}
                onChange={handleInputChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </Form.Group>

            <Form.Group className="mb-4" controlId="">
              <Form.Label>{contentDetails?.registration_page_data?.password}</Form.Label>
              <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={contentDetails?.registration_page_data?.password_placeholder}
                value={registrationData.password}
                onChange={handleInputChange}
              />
              <Button
                  variant="outline-secondary"
                  className="viewbtn"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={hideEye} className="viewimg" alt="logo" />
                  ) : (
                    <img src={viewEye} className="hideimg" alt="logo" />
                  )}
                </Button>
              </InputGroup>
              {errors.password && <span className="error">{errors.password}</span>}
            </Form.Group>

            <Form.Group className="mb-4 text-center">
              <Form.Text className="text-inf">
                {contentDetails?.registration_page_data?.byRegisteringYouAgreeToOur} <Link to='/terms'>{contentDetails?.registration_page_data?.termsAndCondition}</Link>{" "}
                {contentDetails?.registration_page_data?.andHaveTakenNoteOfTheData} <Link to='/privacy'>{contentDetails?.registration_page_data?.privacyPolicy}</Link>.
              </Form.Text>
            </Form.Group>
            <div className="google-button-group">
              {isDisabledButton ? (
                <Button variant="primary" className="submitBtn" disabled style={{cursor:'not-allowed'}}>
                  {contentDetails?.registration_page_data?.button_text}
                </Button>
              ):(
                <>
                  <Button variant="primary" className="submitBtn" onClick={(e)=>handleVerifyEmail(e,'user')}>
                    {contentDetails?.registration_page_data?.button_text}
                  </Button>
                  {/* or <GoogleLogin type="icon" shape="square" /> */}
                  or
                  <div className="google-btn">
                    <GoogleOAuthProvider 
                      clientId="247115028285-a37tc4cpi926sq5ka0n7nj96g7mvkvo9.apps.googleusercontent.com">
                      <GoogleLogin
                        // type="icon"
                        // shape="square"
                        // shape="circle"
                        // text="signup_with"
                        theme="filled_blue"
                        shape="circle"
                        text="signup_with"
                        onSuccess={async(credentialResponse) => {
                          const detailsInfo = jwtDecode(credentialResponse.credential);
                          console.log(detailsInfo)
                          try
                          {
                            let apiRes =  await axios.post(`${API_BASE_URL}user/social-login`, {
                              social_media_id:'@gmail',
                              full_name:detailsInfo.name,
                              email:detailsInfo.email,
                              lang:hostLanguage,
                              social_media:'gmail',
                              role: 'client'
                            });
                            if (apiRes.data.res == true)
                            {
                              localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(apiRes.data.data));
                              if(apiRes.data.data.role == 'client')
                              {
                                navigate('/Dashboard');
                                console.log('user-login successful');
                              }
                              // else if(apiRes.data.data.role == 'student')
                              // {
                              //   navigate('/student-account-set-up');
                              //   console.log('student-login successful');
                              // }
                            }
                            else
                            {
                              console.log('login not-successful');
                            }
                          }
                          catch(error)
                          {
                            console.warn(error.msg);
                          }
                        }}
                        onError={() => {
                          console.log('Login Failed');
                        }}
                      />
                    </GoogleOAuthProvider>
                  </div>

                </>
              )}
            </div>

            <div className="google-button-group">
              <Button
                variant="primary"
                type="button"
                // disabled
                className="BtnBack"
                // onClick={()=>navigate('/student-account-set-up')}
                onClick={(e)=>handleVerifyEmail(e,'student')}
                style={{width:'auto',padding:'0 35px',margin:'0 auto',display:'block',marginTop:'15px'}}>
                {/* Register Student Portal */}
                {languageJson?.register_student}
              </Button> or
              {/* <GoogleLogin type="icon" shape="square" /> */}
              <div className="google-btn">
                <GoogleOAuthProvider 
                  clientId="247115028285-a37tc4cpi926sq5ka0n7nj96g7mvkvo9.apps.googleusercontent.com">
                  <GoogleLogin
                    // type="icon"
                    // shape="square"
                    // shape="circle"
                    // text="signup_with"
                    theme="filled_blue"
                    shape="circle"
                    text="signup_with"
                    onSuccess={async(credentialResponse) => {
                      const detailsInfo = jwtDecode(credentialResponse.credential);
                      console.log(detailsInfo)
                      try
                      {
                        let apiRes =  await axios.post(`${API_BASE_URL}user/social-login`, {
                          social_media_id:'@gmail',
                          full_name:detailsInfo.name,
                          email:detailsInfo.email,
                          lang:hostLanguage,
                          social_media:'gmail',
                          role: 'student'
                        });
                        if (apiRes.data.res == true)
                        {
                          localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(apiRes.data.data));
                          if(apiRes.data.data.role == 'student')
                          {
                            navigate('/student-account-set-up');
                            console.log('student-login successful');
                          }
                          // else if(apiRes.data.data.role == 'student')
                          // {
                          //   navigate('/student-account-set-up');
                          //   console.log('student-login successful');
                          // }
                        }
                        else
                        {
                          console.log('login not-successful');
                        }
                      }
                      catch(error)
                      {
                        console.warn(error.msg);
                      }
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>

            <Form.Group className="mt-3 text-center">
              <Form.Text className="text-bottom">
                {contentDetails?.registration_page_data?.alreadyHaveAnAccount}? <Link to="/Login">{contentDetails?.registration_page_data?.signIn}</Link>
              </Form.Text>
            </Form.Group>
          </Form>

        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="CustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{contentDetails?.registration_page_data?.EnterOTP}</Modal.Title>
          {/* <Modal.Title>{languageJson?.EnterOTP}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <h3 className="otptxt">{languageJson?.OTPheading}</h3> */}
          <h3 className="otptxt">{contentDetails?.registration_page_data?.OTPheading}</h3>
          <div className="OtpBox">
            <OtpInput 
              inputType="number"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} className=" ModalOtp" />}
            />
            {otpError && otp!= registrationData.otp && otp.length == 6 && (
              // <span className="error">{languageJson?.OTPValidation2}</span>
              <span className="error">{contentDetails?.registration_page_data?.OTPValidation2}</span>
            )}
            {(otpError && (otp=='' || otp.length < 6)) && (
              // <span className="error">{languageJson?.OTPValidation1}</span>
              <span className="error">{contentDetails?.registration_page_data?.OTPValidation1}</span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="lftBtn btn btn-secondary" onClick={()=>{handleClose();setOtpError(false);setOtp('')}}>
            {/* {languageJson?.Cancel} */}
            {contentDetails?.registration_page_data?.Cancel}
          </Button>
          {otpDisabledButton ? (
            <Button className="rgtBtn btn btn-primary" disabled>
              {/* {languageJson?.Submit} */}
              {contentDetails?.registration_page_data?.Submit}
            </Button>
          ):(
            <Button className="rgtBtn btn btn-primary" onClick={() => handleRegister()}>
              {languageJson?.Submit} 
            </Button>
          )}
           
        </Modal.Footer>
      </Modal>


      {hide ? (
  <></>
):(
    tokenexist ? (
    <></>
    ):(

      <Modal
      show={show2}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="CookiesModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* Privacy settings for your DATEV experience */}
          Datenschutzeinstellungen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {/* We use cookies and other tracking technologies that can retrieve
          or store information on your terminal device. If you give us your
          consent, we process your personal data for analyses and
          optimizations as well as for marketing and advertising purposes.
          For this purpose, information is sometimes passed on to service
          providers that may be located in a third country and do not have a
          comparable level of data protection. By clicking on "Accept all
          cookies", you consent to this processing and the transfer of your
          data. You can revoke your consent at any time in the cookie
          settings in the footer. Click on the cookie settings to learn
          more, you can find more information in our Privacy noticeImprint */}
          Wir verwenden Cookies und andere Tracking-Technologien, die Daten auslesen oder Informationen auf Ihrem Endgerät speichern können. Mit Ihrer Einwilligung verarbeiten wir Ihre personenbezogenen Daten zu Analyse- und Optimierungszwecken sowie zu Marketing- und Werbezwecken. Dazu werden teilweise Informationen an Dienstleister weitergegeben, die ihren Sitz in einem Drittland haben können, in dem kein vergleichbares Datenschutzniveau herrscht. Indem Sie auf "Alle akzeptieren. Cookies“ erklären Sie sich mit dieser Verarbeitung und Übermittlung Ihrer Daten einverstanden. Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen in der Fußzeile widerrufen. Klicken Sie auf die Cookie-Einstellungen, um mehr zu erfahren Weitere Informationen finden Sie in unserer DatenschutzerklärungImpressum
        </p>
        <div className="newDiv">
          <div className="newDivTop">
            <label>
              <input
                type="checkbox"
                checked={true}
                disabled
                //onChange={handleCheckboxChange}
              />
              {/* Necessary cookies */}
              Notwendige Cookies
            </label>

            <table>
              <tr>
                <th>Cookie</th>
                <th>Ablaufdatum</th>
              </tr>
              <tr>
                <td>PHPSESSID</td>
                <td>
                  {/* When ending the browser session */}
                  Beim Beenden der Browsersitzung
                  </td>
              </tr>
              <tr>
                <td>ci_session</td>
                <td>
                    {/* After 2 hours */}
                    Nach 2 Stunden
                  </td>
              </tr>
              <tr>
                <td>showCookieNotify</td>
                <td>
                  {/* After 30 days */}
                  Nach 30 Tagen
                  </td>
              </tr>
              <tr>
                <td>allowCookieTranslate</td>
                <td>
                  {/* After 30 days */}
                  Nach 30 Tagen
                  </td>
              </tr>
            </table>
          </div>
          <div className="newDivTop">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              {/* Google Translate */}
              Google Übersetzer
            </label>

            <table>
              <tr>
                <th>Cookie</th>
                <th>Ablaufdatum</th>
              </tr>
              <tr>
                <td>NID</td>
                <td>
                  {/* After 1 day */}
                  Nach 1 Tag
                  </td>
              </tr>
              <tr>
                <td>__Secure-3PAPISID</td>
                <td>
                  {/* After 1 day */}
                  Nach 1 Tag
                  </td>
              </tr>
              <tr>
                <td>__Secure-3PSID</td>
                <td>{/* After 1 day */}
                  Nach 1 Tag</td>
              </tr>
              <tr>
                <td>__Secure-3PSIDCC</td>
                <td>{/* After 1 day */}
                  Nach 1 Tag</td>
              </tr>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/privacy" className="MOsaveBtn2">
          {/* More Information */}
          Mehr Informationen
        </Link>
        {/* <Button
          variant="primary"
          onClick={handleClose}
          className="MOsaveBtn2"
        >
          Decline
        </Button> */}

        <Button
          variant="primary"
          onClick={() => hideInstaAlert()}
          className="MOsaveBtn"
        >
          {/* Accept selection */}
          Auswahl akzeptieren
        </Button>
      </Modal.Footer>
    </Modal>

    )
  )}


</div>

  );
};

export default Registration;
