import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "./ScanPayment.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import cloud from "../../assets/images/CloudIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import TickBlue from "../../assets/images/TickBlue.svg";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { HiOutlinePlusSm, HiOutlineTrash, HiCheck } from "react-icons/hi";
import { useNavigate, Link, redirect, useLocation } from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import Modal from "react-bootstrap/Modal";
import {Formik} from 'formik';
import * as yup from 'yup';

import { sendScanRequest, sendMeAddressAsBilling, sendMeRequest, getPageContent } from "../../api/apiRequest";
import PayPalComponent from "../PaymentPages/PayPalComponent";
import PayPalPayment from "../PaymentPages/PayPalPayment";
import Conditions from "../accept-condition/Conditions";
import { useLangContext } from "../../context/languageContext";

const ScanPayment = () => {
  const {hostLanguage,languageJson} = useLangContext();
  const navigate = useNavigate();
  const {state} = useLocation();
  console.warn(state)
  const [thankShow, setThanksShow] = useState(false);
  const thankhandleClose = () => setThanksShow(false);
  const [isDisable,setIsDisable] = useState(false);
  const [checkBoxValue1,setCheckBoxValue1] = useState();
  const [checkBoxValue2,setCheckBoxValue2] = useState();
  const [contentDetails,setContentDetails] = useState();
  
  // console.warn(state?.dataObj);
  // console.warn(state?.dataObj?.phone_number);

  const [cartData, setCartData] = useState({
    card_number: "",
    expiry_date: "",
    name_card: "",
    CVC: "",
  });
  

  const [ErrorsCartData, setErrorsCartData] = useState({
    card_number: "",
    expiry_date: "",
    name_card: "",
    CVC: "",
  });
 
  const handleChangeSubmit = e => {
    const { name, value } = e.target;
    const formatCardNumber = (cardNumber) => {
      return cardNumber.replace(/\D/g, "").replace(/(\d{4})/g, "$1 ").trim();
    };
    
    if (name === "expiry_date") {
      const numericValue = value.replace(/\D/g, '');
      const formattedValue =
        numericValue.length >= 2
          ? numericValue.substring(0, 2) + '/' + numericValue.substring(2)
          : numericValue;
      setCartData({ ...cartData, [name]: formattedValue });
    }else if (name === "CVC") {
      const numericValue = value.replace(/\D/g, '');
      const formattedValue =
        numericValue.length >= 2
          ? numericValue.substring(0, 2) + numericValue.substring(2)
          : numericValue;
      setCartData({ ...cartData, [name]: formattedValue });
    } else if (name === "card_number") {
          const numericValue = value.replace(/\D/g, "");
          
          const isValidCardNumber = /^\d{4} \d{4} \d{4} \d{4}$/.test(
            formatCardNumber(numericValue)
          );

          if (isValidCardNumber) {
            const formattedValue = formatCardNumber(numericValue);
            setCartData({ ...cartData, [name]: formattedValue });
          } else {
            setCartData({ ...cartData, [name]: value });
          }
    }else {
      // For other fields, update the state normally
      setCartData({ ...cartData, [name]: value });
    }
  };


  //payment via credit-card
  const handleReqPayment = async (values, {resetForm}) =>
  {
    // alert(state?.type);
    // alert(state?.id);
    // alert(state?.deduct_wallet_balance);
    // alert(state?.amount);
    // return
    try
    {
      if(state?.type === 'scan')
      {
        // record_id,deduct_wallet_balance,amount
        let data = {
          id: state?.id,
          deduct_wallet_balance: state?.deduct_wallet_balance,
          amount: state?.amount
        }
        let apiRes = await sendScanRequest(data);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          // NotificationManager.success(responseData.msg,'',2000);
          setThanksShow(true);
          // navigate('/AllMail');
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      else if(state?.type === 'sendMe')
      {
        // return
        if(state?.purpose ==='sameAs' && state?.same_as_billing === 1)
        {
          try
          {
            let values = {
              id: state?.id,
              deduct_wallet_balance: state?.deduct_wallet_balance,
              amount: state?.amount,
              same_as_billing: state?.same_as_billing
            }
            let apiRes = await sendMeRequest(values);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
              setThanksShow(true);
            }
            else
            {
              NotificationManager.error(responseData.msg,'',2000);
            }
          }
          catch(error)
          {
            // NotificationManager.error('Something went wrong!', '',2000);
          }
        }
        // if(state?.purpose ==='sameAs' && state?.same_as_billing === 0)
        // {
        //   alert('2nd');
        //   return
        //   try
        //   {
        //     let values = {
        //       id: state?.id,
        //       deduct_wallet_balance: state?.deduct_wallet_balance,
        //       amount: state?.amount,
        //       same_as_billing: state?.same_as_billing
        //     }
        //     let apiRes = await sendMeRequest(values);
        //     let responseData = await apiRes.json();
        //     if(responseData.res)
        //     {
        //       setThanksShow(true);
        //     }
        //     else
        //     {
        //       NotificationManager.error(responseData.msg,'',2000);
        //     }
        //   }
        //   catch(error)
        //   {
        //     // NotificationManager.error('Something went wrong!', '',2000);
        //   }
        // }
        else if(state?.purpose ==='new')
        {
          try
          {
            let values = {
              id: state?.id,
              deduct_wallet_balance: state?.deduct_wallet_balance,
              amount:parseFloat(state?.amount).toFixed(2),
              same_as_billing:0,
              company_name:state?.dataObj?.company_name,
              title:state?.dataObj?.title,
              first_name: state?.dataObj?.first_name,
              last_name: state?.dataObj?.last_name,
              address: state?.dataObj?.address,
              country_id: state?.dataObj?.country_id,
              city_id: state?.dataObj?.city_id,
              region_id: state?.dataObj?.region_id,
              postal_code: state?.dataObj?.postal_code,
              area_code: state?.dataObj?.area_code,
              phone_number: state?.dataObj?.phone_number
            }
            let apiRes = await sendMeRequest(values);
            let responseData = await apiRes.json();
            if(responseData.res)
            {
              setThanksShow(true);
            }
            else
            {
              NotificationManager.error(responseData.msg,'',2000);
            }
          }
          catch(error)
          {
            console.error(error);
          }
        }
        else
        {
          throw new Error('Something Went Wrong!');
        }
      }
      else
      {
        throw new Error('Invalid Req Type');
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const handleParentData1 = (e) => {
    setCheckBoxValue1(e);
    console.log(e);
  }

  const handleParentData2 = (e) => {
    setCheckBoxValue2(e);
    console.log(e);
  }

  // let schema = yup.object().shape({
  //   card_number: yup
  //     .string()
  //     .required('Card Number is required')
  //     .matches(/^\d{16}$/, 'Must be 16 digits'),
  //   expiry_date:yup
  //     .string()
  //     .required('Required')
  //     .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid date format (MM/YY)'),
  //   cvc: yup
  //     .string()
  //     .required('Required')
  //     .matches(/^\d{3,4}$/, 'Must be 3 or 4 digits'),
  //   card_username: yup
  //   .string()
  //   .required('Name on Card is required')
  //   .matches(/^[A-Za-z\s]+$/, 'Name on Card must contain only alphabetic characters')
  //   .max(50, 'Name on Card must be at most 50 characters long'),
  // })

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="lightBlue">
      <Header />
      <div className="MainBody">
        <div className="container">
          <Row className="">
            <Col sm={8}>
              <div className="MainBodyRgt">
                {/* <h5>Credit Card payment method</h5> */}
                {/* <h5>Net Payabale Amount: € {parseFloat(state?.amount).toFixed(2)}</h5> */}
                <h5>{contentDetails?.after_login?.net_payable_amount} € {parseFloat(state?.amount).toFixed(2)}</h5>
                {/* <p>Your selected cleverdomizil services</p> */}
                <p>{contentDetails?.after_login?.your_selected_cleverdomizil_services}</p>
                <div className="MainBodyRgtInner">
                  {/* <Accordion defaultActiveKey="0"> */}
                    {/* <Accordion.Item eventKey="0"> */}
                      {/* <Accordion.Header>Credit Card/Debit Card</Accordion.Header> */}
                      {/* <Accordion.Body> */}
                        {/* <PayPalComponent /> */}
                        <Conditions propsData1 = {handleParentData1} propsData2 = {handleParentData2} type={state?.type?.toLowerCase()} amount={state?.amount}/>
                        {
                          checkBoxValue1 && checkBoxValue1 === 'accept_terms' &&
                          checkBoxValue2 && checkBoxValue2 === 'accept_contract' ? (
                            <>
                              {/* <h3>Payment With</h3> */}
                              <h3>{contentDetails?.after_login?.payment_with}</h3>
                              {state?.type === 'scan' ? (
                                  <PayPalComponent productInfo={'scan-payment'} scanData={state} productPrice ={state?.amount} type={'scan-payment'}/>
                              ):
                              state?.type === 'sendMe' && state?.purpose === 'sameAs'? (
                                <PayPalComponent productInfo={'sendMe-payment'} scanData={state} productPrice ={state?.amount} type={'sendMe-payment'}/>
                              ):
                              state?.type === 'sendMe' && state?.purpose === 'new'? (
                                <PayPalComponent productInfo={'sendMe-payment'} scanData={state} productPrice ={state?.amount} type={'sendMe-payment'}/>
                              ):null}

                              {state?.type === 'scan' ? (
                                  <PayPalPayment productInfo={'scan-payment'} scanData={state} productPrice ={state?.amount} type={'scan-payment'}/>
                              ):
                              state?.type === 'sendMe' && state?.purpose === 'sameAs'? (
                                <PayPalPayment productInfo={'sendMe-payment'} scanData={state} productPrice ={state?.amount} type={'sendMe-payment'}/>
                              ):
                              state?.type === 'sendMe' && state?.purpose === 'new'? (
                                <PayPalPayment productInfo={'sendMe-payment'} scanData={state} productPrice ={state?.amount} type={'sendMe-payment'}/>
                              ):null}
                            </>
                          ):(
                            <></>
                        )}

                      {/* </Accordion.Body> */}
                    {/* </Accordion.Item> */}
                  {/* </Accordion> */}

                  {/* <Accordion defaultActiveKey="1"> */}
                    {/* <Accordion.Item eventKey="1"> */}
                      {/* <Accordion.Header>PayPal Payment</Accordion.Header> */}
                      {/* <Accordion.Body> */}
                        {/* <h3>Payment With</h3> */}
                        {/* <PayPalComponent /> */}
                        {/* move */}
                        {/* {state?.type === 'scan' ? (
                            <PayPalPayment productInfo={'scan-payment'} scanData={state} productPrice ={state?.amount} type={'scan-payment'}/>
                        ):
                        state?.type === 'sendMe' && state?.purpose === 'sameAs'? (
                          <PayPalPayment productInfo={'sendMe-payment'} scanData={state} productPrice ={state?.amount} type={'sendMe-payment'}/>
                        ):
                        state?.type === 'sendMe' && state?.purpose === 'new'? (
                          <PayPalPayment productInfo={'sendMe-payment'} scanData={state} productPrice ={state?.amount} type={'sendMe-payment'}/>
                        ):null} */}
                        {/* move */}
                      {/* </Accordion.Body> */}
                    {/* </Accordion.Item> */}
                  {/* </Accordion> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
        <Modal
          show={thankShow}
          onHide={thankhandleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="CustomModal CustomModalTnk"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={TickBlue} className="TickBlueLogo" alt="logo" />
            <h5>Thank You for requesting</h5>
            {/* <h2>Your order is ready for shipment.</h2> */}
            <h2>We are processing your shipment.</h2>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="secondary"
              onClick={thankhandleClose}
              className="lftBtn"
            >
              Check Status
            </Button> */}
            {isDisable ? (
              <Button variant="primary" className="rgtBtn" disabled style={{cursor:'not-allowed'}}>Go to MailBox</Button>
            ):(
              <Button variant="primary" className="rgtBtn" onClick={()=>{setIsDisable(true);navigate('/AllMail')}}>Go to MailBox</Button>
            )}
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default ScanPayment;
