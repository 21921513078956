import React, { useEffect, useState } from 'react'
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/images/LogoW.svg";
import "./StudentHeader.scss";
import Dropdown from "react-bootstrap/Dropdown";
import {useNavigate, Link } from "react-router-dom";

import { useLangContext } from "../../context/languageContext";
import { getAllLanguages } from "../../api/apiRequest";


const StudentHeader = () => {
  const navigate = useNavigate();
  const {hostLanguage,setHostLanguage,languageJson, changeLanguage} = useLangContext();
  let userData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
  console.log(userData)
  const [contentDetails,setContentDetails] = useState();
  const [language,setLanguage] = useState([]);
  

  const shortName = () => {
    const first_word = userData?.first_name
    .split(' ')
    .map(word => word.charAt(0))
    .join('');
    return first_word
  }

  const getLanguages = async () => {
    let apiRes = await getAllLanguages();
    let responseData = await apiRes.json();
  
    if(responseData.res)
    {
      setLanguage(responseData.data);
    }
    else
    {
      console.error(responseData.msg,'',2000);
    }
  }

  const Logout = () => {
    localStorage.removeItem("virtualOfficeLoginInfo");
    localStorage.clear("virtualOfficeLoginInfo");
    navigate("/Login");
  };

  // CustomHoverItem component to display Dropdown.Item and handle hover effect
const CustomHoverItem = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/logout"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

useEffect(()=>{
  getLanguages();
},[hostLanguage])

  return (
    <div id="student-Header">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img src={logo} className="MainLogo" alt="logo" onClick={()=>navigate('/')} style={{cursor:'pointer'}}/>
          </Navbar.Brand>
          
          <Nav className="">
            <div>
              <Dropdown className="MainHrDropItem country-dropdown">
                <Dropdown.Toggle variant="" id="dropdown-en">
                  {
                    hostLanguage == "de" ? <img src='https://ipdata.co/flags/de.png'/>:
                    hostLanguage == "en" ? <img src='https://ipdata.co/flags/us.png'/>:
                    hostLanguage == "es" ? <img src='https://ipdata.co/flags/es.png'/>:
                    hostLanguage == "fa" ? <img src='https://ipdata.co/flags/ir.png'/>:
                    hostLanguage == "fr" ? <img src='https://ipdata.co/flags/fr.png'/>:
                    hostLanguage == "pt" ? <img src='https://ipdata.co/flags/pt.png'/>:
                    hostLanguage == "ru" ? <img src='https://ipdata.co/flags/ru.png'/>:
                    hostLanguage == "tr" ? <img src='https://ipdata.co/flags/tr.png'/>:
                    hostLanguage == "zh" ? <img src='https://ipdata.co/flags/cn.png'/>:''
                  }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {language?.map((item,index)=>{
                   return(
                    <Dropdown.Item>
                      {/* <a onClick={(e)=>setSelectedLanguage(item?.short_name.toUpperCase())}>{item?.short_name.toUpperCase()}<br/></a> */}
                      {/* <Link onClick={(e)=>{changeLanguage(item?.short_name);setHostLanguage(item?.short_name)}}>{item?.short_name.toUpperCase()}<br/></Link> */}
                      <Link onClick={(e)=>{changeLanguage(item?.short_name);setHostLanguage(item?.short_name)}}>
                        {/* {item?.short_name.toUpperCase()}<br/> */}
                        {
                          item?.country_code == "de" ? <img src='https://ipdata.co/flags/de.png'/>:
                          item?.country_code == "us" ? <img src='https://ipdata.co/flags/us.png'/>:
                          item?.country_code == "es" ? <img src='https://ipdata.co/flags/es.png'/>:
                          item?.country_code == "ir" ? <img src='https://ipdata.co/flags/ir.png'/>:
                          item?.country_code == "fr" ? <img src='https://ipdata.co/flags/fr.png'/>:
                          item?.country_code == "pt" ? <img src='https://ipdata.co/flags/pt.png'/>:
                          item?.country_code == "ru" ? <img src='https://ipdata.co/flags/ru.png'/>:
                          item?.country_code == "tr" ? <img src='https://ipdata.co/flags/tr.png'/>:
                          item?.country_code == "cn" ? <img src='https://ipdata.co/flags/cn.png'/>:''
                        }
                        <br/>
                      </Link>
                    </Dropdown.Item>
                    )
                })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Dropdown className="HrDropItem">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <span className="UserImg">{shortName()}</span>
                {userData?.first_name} 
              </Dropdown.Toggle>

              
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate('/student-dashboard')} >{languageJson?.dashboard}</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate('/student-profile')} >{languageJson?.profile}</Dropdown.Item>
                <Dropdown.Item onClick={() => Logout()} >{languageJson?.logout}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default StudentHeader;
