import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./Product.scss";
// import BannerImg from "../../assets/images/InnerBanner.png";
// import BannerImg from "../../assets/images/BannerImg-new.png";
// import BannerImg from "../../assets/images/Product-BannerImg-new.png";
import BannerImg from "../../assets/images/updated_Product-BannerImg-new.png";
import BoxIcon1 from "../../assets/images/BoxIcon1.svg";
import BoxIcon2 from "../../assets/images/BoxIcon2.svg";
import BoxIcon3 from "../../assets/images/BoxIcon3.svg";
import BoxIcon4 from "../../assets/images/BoxIcon4.svg";
import BoxImg from "../../assets/images/BoxImg.svg";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import badge from "../../assets/images/Badge.svg";
import badge2 from "../../assets/images/Badge2.svg";
// import FeaturesImg from "../../assets/images/FeaturesImg.svg";
import FeaturesImg from "../../assets/images/FeaturesImg-new.png";
// import iPhoneimg from "../../assets/images/iPhoneImg.png";
// import iPhoneimg from "../../assets/images/iPhoneimg-new.png";
import iPhoneimg from "../../assets/images/updated_iPhone-15-Pro.png";
import TickImg from "../../assets/images/TickTick.svg";
import {NotificationManager} from 'react-notifications';

import { getCmsDataById, getFeaturesByProductId, getServicesByProductId, getMailProductCountry, getMailProductCity, getMailProductCitiesByCountry, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const Product = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();

  const [selectedValue, setSelectedValue] = useState(1);
  const [features,setFeatures] = useState([]);
  const [service,setServices] = useState([]);
  const [countries,setCountries] = useState([]);
  const [cities,setCities] = useState([]);
  const [selectedCities,setSelectedCities] = useState([]);
  const [pageDetails,setPageDetails] = useState([]);
  const [contentDetails,setContentDetails] = useState();

  // Get Page Data
  const fetchPageData = async () =>{
    try{
      let apiRes = await getCmsDataById(15);
      let responseData = await apiRes.json();

      if(responseData.res){
        setPageDetails(responseData.data);
      }else{
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error){
      console.error(error);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get product features
  // const fetchProductFeatures = async(product_id) => {
  //   try
  //   {
  //     let apiRes = await getFeaturesByProductId(product_id);
  //     let responseData = await apiRes.json();
  //     if(responseData.res)
  //     {
  //       setFeatures(responseData.data)
  //     }
  //     else
  //     {
  //       NotificationManager.error(responseData.msg,'',2000);
  //     }
  //   }
  //   catch(error)
  //   {
  //     console.error(error);
  //   }
  // }

  // get product services
  // const fetchProductServices = async(product_id) => {
  //   try
  //   {
  //     let apiRes = await getServicesByProductId(product_id);
  //     let responseData = await apiRes.json();
  //     if(responseData.res)
  //     {
  //       setServices(responseData.data)
  //     }
  //     else
  //     {
  //       NotificationManager.error(responseData.msg,'',2000);
  //     }
  //   }
  //   catch(error)
  //   {
  //     console.error(error);
  //   }
  // }

  //Get All Mail Product Country
  const fetchMailProductCountry = async(product_id) => {
    try
    {
      let apiRes = await getMailProductCountry(product_id,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        const countriesArray = Object.values(responseData.data).flat();
        setCountries(countriesArray);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //Get All Mail Product City
  const fetchMailProductCity = async(product_id) => {
    try
    {
      let apiRes = await getMailProductCity(product_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        const citiesArray = Object.values(responseData.data).flat();
        setCities(citiesArray);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  //Get All Mail Product City
  const handleSelectedCities = async(country_id) => {
    try
    {
      let apiRes = await getMailProductCitiesByCountry(country_id,hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setSelectedCities(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get banner image link
  const getBannerImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const handleTabSelect = (key) => {
    const selectedCountryId = countries.find((item) => item?.country?.name === key);
    handleSelectedCities(selectedCountryId?.country?.id);
  }

  const appLink = (type) =>{
    if(type == 'android')
    {
       window.open('https://play.google.com/store/apps/details?id=com.cleverdomizilmail')
    }
    else if(type == 'apple')
    {
      window.open('https://apps.apple.com/in/app/cleverdomicile/id6502281781')
    }
  }

  useEffect(()=>{
    // fetchProductFeatures(1);
    // fetchProductServices(1);
    fetchMailProductCountry(1);
    fetchMailProductCity(1);
    fetchPageData();
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="InnerBannerScetion">
          <div className="InnerBannerScetionLft">
            {/* <div dangerouslySetInnerHTML={{ __html: pageDetails[0]?.banner_text}} ></div> */}
            <div dangerouslySetInnerHTML={{ __html: contentDetails?.product_mail_page_data?.banner_text}}></div>
            <div className="BtnGrp">
              {/* <Link className="BtnGrpLoc" to ='/register'>Standorte</Link> */}
              <Link className="BtnGrpLoc" to ='/register'>{contentDetails?.product_mail_page_data?.banner_button_1}</Link>
              {/* <Link className="BtnGrpBN" to ='/register'>Book Now</Link> */}
              {/* <Link className="BtnGrpBN" to ='/register'>Jetzt buchen</Link> */}
              <Link className="BtnGrpBN" to ='/register'>{contentDetails?.product_mail_page_data?.banner_button_2}</Link>
            </div>
          </div>

          <div className="InnerBannerScetionRgt">
            <Image
              src={BannerImg}
              // src={Product-BannerImg-new.png}
              className="BannerImg"
              alt="BannerImg"
              fluid
            />
          </div>
        </div>
        <div className="FourBoxScetion">
          <Container className="">
            <Row>
              {/* {features?.map((item,index)=>{ */}
              {contentDetails?.product_mail_page_feature_data?.map((item,index)=>{
                return(
                  <Col sm={3}>
                  <div className="FourBoxScetionBox">
                    <span>
                      {" "}
                      <Image
                        src={getBannerImgLink(item?.feature_image)}
                        className="BannerImg"
                        alt="BannerImg"
                        fluid
                      />
                    </span>
                    <h3>{item?.feature_name}</h3>
                    {/* <p>{item?.description}</p> */}
                    <div dangerouslySetInnerHTML={{ __html: item?.description}} />
                  </div>
                  </Col>
                )
              })}


              {/* <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      src={BoxIcon2}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  <h3>Site selection</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      src={BoxIcon3}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  <h3>Site selection</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </Col>

              <Col sm={3}>
                <div className="FourBoxScetionBox">
                  <span>
                    {" "}
                    <Image
                      src={BoxIcon4}
                      className="BannerImg"
                      alt="BannerImg"
                      fluid
                    />
                  </span>
                  <h3>Site selection</h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </Col> */}

            </Row>
          </Container>
        </div>

        <div className="LocationsScetion">
          <Container className="VideoScetionContainer">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel" dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_data?.country_heading}}>
                  {/* <p>CleverDomizil Postfach</p> */}
                  {/* <h2>Worldwide Postbox Locations</h2>  */}
                  {/* <h2>Weltweiter Postanschrift für Unternehmen</h2>  */}
                  {/* {contentDetails?.product_mail_page_data?.country_heading} */}
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col sm={12}>
                <Tabs
                  defaultActiveKey="all"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={handleTabSelect}
                >
                  {/* <Tab eventKey="all" title="All"> */}
                  {/* <Tab eventKey="all" title="Alle"> */}
                  <Tab eventKey="all" title={contentDetails?.product_mail_page_data?.country_list_first_text}>
                    <div className="LocationName">
                      <ul>
                        {cities?.map((item,index)=>{
                          return(
                            <li>
                              <div className="LocationNameBox">
                                <h4>{item?.city?.name.toUpperCase()}</h4>
                              </div>
                            </li>
                          )
                        })}
                        {/* <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li>
                        <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li>
                        <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li>
                        <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li>
                        <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li>
                        <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li>
                        <li>
                          <div className="LocationNameBox">
                            <h4>BEIJING</h4>
                          </div>
                        </li> */}
                      </ul>

                      {/* <Link to='/register'>Weiter</Link> */}
                      <Link to='/register'>{contentDetails?.product_mail_page_data?.country_list_button_text}</Link>
                    </div>
                  </Tab>
                  
                  {countries?.map((item, index) => (
                    <Tab
                      key={item?.country?.id}
                      eventKey={item?.country?.name}
                      title={item?.country?.name}
                    >
                      <div className="LocationName">
                        <ul>
                          {selectedCities?.map((city_item, city_index) => (
                            // <div key={city_index} className="LocationNameBox">
                            //   <h4>{city_item?.city.name}</h4>
                            // </div>
                              
                                <li key={city_index}>
                                  <div className="LocationNameBox">
                                  <h4>{city_item?.city.name}</h4>
                                  </div>
                                </li>
                              
                          ))}
                        </ul>
                      </div>
                    </Tab>
                  ))}

                  {/* <Tab eventKey="northamerica" title="North America">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="eastasia" title="East Asia">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="middleeast" title="Middle East">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="easterneurope" title="Eastern Europe">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="southeastasia" title="South East Asia">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="oceania" title="Oceania">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="southamerica" title="South America">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="southasia" title="South Asia">
                    Tab content for Profile
                  </Tab> */}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="DigitalmailboxScetion">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="DigitalmailboxScetionInner">
                  <Row>
                    <Col sm={7}>
                      <div className="DigitalmailboxinLft">
                        {/* <h4>Tariffs for the digital mailbox</h4> */}
                        {/* <h4>Tarife</h4> */}
                        {/* <p>
                          Choose the right tariff depending on your monthly mail
                          volume.
                        </p> */}
                        {/* <p>Wählen Sie den passenden Tarif für Ihr Unternehmen aus!</p> */}
                        <div dangerouslySetInnerHTML={{__html:contentDetails?.product_mail_page_data?.tarife_heading}}></div>
                        <div className="DigitalmailboxinLftCheckBox">
                          {/* <h5>1. First choose your client type</h5> */}
                          {/* <h5>1. Wählen Sie die passende Kontotyp aus?</h5> */}
                          <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_data?.tarife_text}}></div>

                          <div className="check-div check-divRadio">
                            <div className="form-check">
                              <Form.Check
                                type="radio"
                                aria-label="option 1"
                                id="flexCheckChecked1"
                                name="carr"
                                checked={selectedValue === 1 ? true : false}
                                onClick={() => setSelectedValue(1)}
                              />
                              <Form.Label
                                className="form-check-label"
                                for="flexCheckChecked1"
                              >
                                {/* <h3>Private Customer</h3> */}
                                {/* <h3>Privatkunde</h3> */}
                                <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_data?.tarife_element_1}}></div>
                              </Form.Label>
                            </div>
                            <div className="form-check">
                              <Form.Check
                                type="radio"
                                aria-label="option 1"
                                id="flexCheckChecked2"
                                name="carr"
                                checked={selectedValue === 2 ? true : false}
                                onClick={() => setSelectedValue(2)}
                              />
                              <Form.Label
                                className="form-check-label"
                                for="flexCheckChecked2"
                              >
                                {/* <h3>Business Customer</h3> */}
                                {/* <h3>Geschäftskunde</h3> */}
                                <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_data?.tarife_element_2}}></div>
                              </Form.Label>
                            </div>
                          </div>
                          {selectedValue === 1 ? (
                            <div className="BtnBottom">
                              {/* <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>navigate('/register')}>Book Now</Button> */}
                              <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>navigate('/register')}>
                                {/* Jetzt buchen */}
                                {contentDetails?.product_mail_page_data?.tarife_button_text}
                                </Button>
                            </div>
                          ):(
                            <div className="BtnBottom">
                              {/* <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>navigate('/register')}>Book Now</Button> */}
                              <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>navigate('/register')}>
                                {/* Jetzt buchen */}
                               {contentDetails?.product_mail_page_data?.tarife_button_text}
                                </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col sm={5}>
                      <div className="DigitalmailboxinRgt">
                        <Image src={BoxImg} className="BoxImg" alt="BoxImg" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="FeaturesScetion">
          <Container fluid>
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <h2>ClevverMail Software Features</h2> */}
                  {/* <h2>CleverDomizil Postfach der nächsten Generation</h2> */}
                  <h2>{contentDetails?.product_mail_page_data?.CleverDomizil_Postfach}</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="FeaturesScetionLft">
                  <Image
                    src={FeaturesImg}
                    className="FeaturesImg"
                    alt="FeaturesImg"
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="FeaturesScetionRgt">
                  <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_data?.CleverDomizil_Postfach_description}}></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="AppScetion">
          <Container className="VideoScetionContainer">
            <Row className="align-items-center">
              <Col sm={6}>
                <Image
                  src={iPhoneimg}
                  className="laptopbig"
                  alt="LaptopBig"
                  fluid
                />
              </Col>
              <Col sm={6}>
                <div className="AppScetionTxt AppScetionTxtPro">
                  {/* <h4>Download the APP</h4> */}
                  {/* <h4>CleverDomizil APP</h4> */}
                  {/* <h5> */}
                    {/* Your CleverDomizil Mail. Manage all the scans of your
                    letters and the worldwide dispatch quite simply online in
                    our platform. Or you can download our CleverDomizil APP. */}
                    {/* Ihr CleverDomizil Postfach. Verwalten Sie Ihre 
                    Briefe ganz einfach online in unserer Plattform 
                    oder laden Sie sich unsere CleverDomizil
                    APP herunter */}
                  {/* </h5> */}
                  <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_data?.CleverDomizil_APP}}></div>
                  <div className="ImgGrp">
                    <span className="ImgGrpImg">
                      <img src={badge} alt="badge" onClick={()=>appLink('android')} style={{cursor:'pointer'}}/>
                    </span>
                    <span className="ImgGrpImg">
                      <img src={badge2} alt="badge" onClick={()=>appLink('apple')} style={{cursor:'pointer'}}/>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="PlanScetion">
          <Container className="">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <p>CleverDomizil Postfach</p> */}
                  {/* <h2>CleverDomizil MailBOX</h2> */}
                  <h2>CleverDomizil Postfach</h2>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col sm={12}>
                <div className="PlanComparisonDiv">
                  <table>
                    <tr>
                      <th>&nbsp;</th>

                      <th>
                        {/* <h2>Private Customer</h2>
                        <p>Product: CleverDomizil Mail</p> */}
                        {/* <h2>Privatkunde</h2>
                        <p>CleverDomizil Adresse</p> */}
                        <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_service_data?.heading_1}}></div>
                      </th>
                      <th>
                        {/* <h2>Business Customer</h2>
                        <p>Product: CleverDomizil Mail</p> */}
                        {/* <h2>Geschäftskunde</h2>
                        <p>CleverDomizilMail Geschäftsadresse</p> */}
                        <div dangerouslySetInnerHTML={{__html: contentDetails?.product_mail_page_service_data?.heading_2}}></div>
                      </th>
                    </tr>
                    {/* {service?.map((item,index)=>{ */}
                    {contentDetails?.product_mail_page_service_data?.service?.map((item,index)=>{
                      return(
                        <tr>
                          <td>{item?.service_name}</td>
                          <td>
                            {item?.private_status === 1 && (
                              <Image
                                src={TickImg}
                                className="TickImg"
                                alt="TickImg"
                              />
                            )}
                              <br />
                              {item?.additional_private_status!= null ? item?.additional_private_status: ''}
                          </td>

                          <td>
                            {item?.business_status === 1 && (
                              <Image
                                src={TickImg}
                                className="TickImg"
                                alt="TickImg"
                              />
                            )}
                            <br />
                            {item?.additional_business_status!= null ? item?.additional_business_status: ''}
                          </td>
                        </tr>
                      )
                    })}

                    {/* <tr>
                      <td>Having a real street address</td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Use for marketing purposes (website, product packaging,
                        letterheads)
                      </td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Use as e-commerce return address</td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Having a summonable address</td>
                      <td></td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Use for legal notice /publication’s imprint</td>
                      <td></td>
                      <td>
                        {" "}
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Receive designated government-letters and court summons
                      </td>
                      <td></td>
                      <td>
                        {" "}
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Register company with local authorities</td>
                      <td></td>
                      <td>
                        {" "}
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                        <br />
                        Needs the Addon Registered Address
                      </td>
                    </tr>
                    <tr>
                      <td>Incorporate a company at this address</td>
                      <td></td>
                      <td>
                        <Image
                          src={TickImg}
                          className="TickImg"
                          alt="TickImg"
                        />
                        <br />
                        Needs the Addon Registered Address
                      </td>
                    </tr> */}
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;
