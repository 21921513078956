import React from 'react';
import ReactPlayer from 'react-player/vimeo';

const VideoPlayer = (props) => {
  // console.log(props)
  return (
    <div>
      <ReactPlayer
        url={props?.videoUrl!=null && props?.videoUrl}
        controls
        width='100%'
        height='100%'
      />
    </div>
  );
};
export default VideoPlayer;
