// import React, { useEffect, useState } from 'react'
// import "./StudentDashboard.scss";
// import StudentHeader from '../../components/header/StudentHeader';
// import { studentTimeline } from '../../api/apiRequest';
// import { useLangContext } from '../../context/languageContext';

// const StudentDashboard = () => {
//   const {hostLanguage,languageJson} = useLangContext();
//   let userData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
//   const [dashboardData,setDashboardData] = useState();

//   const fetchDashboardData = async() =>{
//     try
//     {
//       let apiRes = await studentTimeline(hostLanguage);
//       let responseData = await apiRes.json();

//       if(responseData.res)
//       {
//         console.log(responseData.data);
//         setDashboardData(responseData.data);
//         let temp_obj = userData;
//         temp_obj.upload_file = responseData.data.upload_file;
//         localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(temp_obj));
//       }
//       else
//       {
//         console.error(responseData.msg)
//       }
//     }
//     catch(error)
//     {
//       console.error(error);
//     }
//   }

//   const dateFormat = (dt) => {
//     let split_date = '';
//     let formattedDate = '';

//     if(dt!=undefined)
//     {
//       split_date = dt?.split('-');
//       formattedDate = split_date[0] + '/' + split_date[1] + '/' + split_date[2];
//     }

//     return formattedDate;
//   }

//   const timelineDateFormat = (dt) => {
//     let split_date = '';
//     let formattedDate = '';

//     if(dt!=undefined)
//     {
//       split_date = dt?.split('-');
//       formattedDate = split_date[2] + '/' + split_date[1] + '/' + split_date[0];
//     }

//     return formattedDate;
//   }

//   useEffect(()=>{
//     fetchDashboardData();
//   },[])

//   return (
//     <>
//       <StudentHeader/>
//       <div className="DashboardBox">
//         <h2>{languageJson?.Hello}, {userData?.first_name}!</h2>
//         <h3>{languageJson?.Welcome_to_your_Dashboard}</h3>
//       </div>
      
//       <div className="line_box stav_projektu">
//         <div className="text_circle done">
//           <div className="circle"></div>
//           <div className="content-box">
//             <h4>{dateFormat(dashboardData?.uploaded_date)}</h4>
//             <p>{languageJson?.EUTM_application_received}</p>
//           </div>
//           <a href="javascript:void(0)" className="tvar"><span></span></a>
//           <div className="infoTop">{languageJson?.Examination}</div>
//         </div>
//         <div className="text_circle done sub">
//           <div className="circle"></div>
//           <div className="content-box">
//             <h4>{timelineDateFormat(dashboardData?.student_timeline_record[0]?.date_of_action)}</h4>
//             <p>{dashboardData?.student_timeline_record[0]?.action_description}</p>
//           </div>
//           <div className="subline"></div>
//           <div className="subTxt">
//             <h4><h4>{timelineDateFormat(dashboardData?.student_timeline_record[1]?.date_of_action)}</h4></h4>
//             <p>{dashboardData?.student_timeline_record[1]?.action_description}</p>
//           </div>
//           <a href="javascript:void(0)" className="tvar"><span></span></a>
//           <div className="infoTop" style={{right: '-26%' }}>{languageJson?.opposition_proceedings}</div>
//           <div className="bottomInfo">0 {languageJson?.oppositions_received}</div>
//         </div>
//         <div className="text_circle point">
//           <div className="circle"></div>
//           <div className="content-box">
//             <h4><h4>{timelineDateFormat(dashboardData?.student_timeline_record[2]?.date_of_action)}</h4></h4>
//             <p>{dashboardData?.student_timeline_record[2]?.action_description}</p>
//           </div>
//           <div className="mapPoint"></div>
//           <div className="PointDate">{dateFormat(dashboardData?.present_date)}</div>
//           <div className="infoTop2">{languageJson?.Registered_EUTM}</div>
//           <a href="javascript:void(0)" className="tvar"></a>
//         </div>
//         <div className="text_circle">
//           <div className="circle"></div>
//           <div className="content-box">
//             <h4>{dateFormat(dashboardData?.end_date)}</h4>
//             <p>{languageJson?.EUTM_expiry_date}</p>
//           </div>

//           <a href="javascript:void(0)" className="tvar"></a>
//         </div>
//       </div>
//     </>
//   )
// }
// export default StudentDashboard


import React, { useEffect, useState } from 'react'
import './StudentDashboard.css';
import StudentHeader from '../../components/header/StudentHeader';
import { studentRegisterDetails, studentTimeline } from '../../api/apiRequest';
import { useLangContext } from '../../context/languageContext';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const StudentDashboard = () => {
  const {hostLanguage,languageJson} = useLangContext();
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
  const [dashboardData,setDashboardData] = useState();
  const [timeLineData,setTimeLineData] =  useState([]);
  const [isStudentExist,setIsStudentExist] = useState();


  const fetchDashboardData = async() =>{
    try
    {
      let apiRes = await studentTimeline(hostLanguage);
      let responseData = await apiRes.json();

      if(responseData.res)
      {
        console.log(responseData.data);
        setDashboardData(responseData.data);
        let temp_obj = userData;
        temp_obj.upload_file = responseData.data.upload_file;
        localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(temp_obj));

        if(responseData?.data?.student_timeline_record?.length > 0)
        {
          let temp_obj = {};
          let temp_arr = [];
          responseData?.data?.student_timeline_record.forEach(element => {
            temp_obj = {
              date: timelineDateFormat(element?.date_of_action),
              description: element?.action_description
            }
            temp_arr.push(temp_obj)
          });
          console.log(temp_arr);
          setTimeLineData(temp_arr);
        }
      }
      else
      {
        navigate('/student-account-set-up');
        console.error(responseData.msg);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // student registration checking
  const studentRegisterStep = async() => {
    try
    {
      let apiRes = await  studentRegisterDetails();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        console.warn(responseData);
        if(responseData?.is_user_data == 0)
        {
          navigate('/student-account-set-up');
        }
        else
        {
          if(responseData?.is_user_data == 1)
          {
            if(responseData?.is_file_upload == 0)
            {
              navigate('/student-account-details');
            }
            else if(responseData?.is_file_upload == 1)
            {
              navigate('/student-dashboard');
            }
          }
          else
          {
            console.error(responseData?.is_user_data);
          }
        }
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const dateFormat = (dt) => {
    let split_date = '';
    let formattedDate = '';

    if(dt!=undefined)
    {
      split_date = dt?.split('-');
      formattedDate = split_date[0] + '/' + split_date[1] + '/' + split_date[2];
    }

    return formattedDate;
  }

  const timelineDateFormat = (dt) => {
    let split_date = '';
    let formattedDate = '';

    if(dt!=undefined)
    {
      split_date = dt?.split('-');
      formattedDate = split_date[2] + '/' + split_date[1] + '/' + split_date[0];
    }

    return formattedDate;
  }

  const checkFileExist = async() =>
  {
    let apiRes = await studentTimeline(hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      // setIsStudentExist();**
    }
  }

  useEffect(()=>{
    studentRegisterStep();
    fetchDashboardData();
    // checkFileExist();
  },[hostLanguage])

    return (
      <>
        <StudentHeader/>
        <div className="DashboardBox">
          <h2>{languageJson?.Hello}, {userData?.first_name}!</h2>
          <h3>{languageJson?.Welcome_to_your_Dashboard}</h3>
        </div>
        
        <div className="timelineProceed">
          <ReviewTextList timeLineData={timeLineData} languageJson={languageJson}/>
          <ProgressBar currentDate={dateFormat(dashboardData?.present_date)} currentPercentage={`${dashboardData?.present_date_pointer_percentage}%`} />
          <TimelineProceedList timeLineData={timeLineData} nodeDistance = {dashboardData?.distance_presentage}/>
        </div>
      </>
    );
};

const ReviewTextList = (props) => {
  console.log(props)
    return (
        <ul className="reviewTextList">
            <li>{props?.languageJson?.under_review}</li>
            <li>{props?.languageJson?.under_review}</li>
            <li>{props?.languageJson?.under_review}</li>
            {/* <li>Under Review</li>
            <li>Under Review</li>
            <li>Under Review</li> */}
        </ul>
    );
};

const ProgressBar = (props) => {
    return (
        <>
        <div className="progress-bar">
            <div style={{ width:  props?.currentPercentage}} className="progressbar-proced">
                <span className="DateSpan">{props?.currentDate}</span>
            </div>
        </div>
        
    </>
        
    );
};

const TimelineProceedList = (props) => {
  console.log(props)
    return (
        <ul className="timelineProceedList">
            {props?.timeLineData?.map((event, index) => (
                <li key={index} style={{left: `${(index * props?.nodeDistance)}%`}}>
                    <div className="circleBox"></div>
                    <div className="event-content">
                        <h3>{event.date}</h3>
                        <p>{event.description}</p>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default StudentDashboard;
