import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ResetPassword.scss";
import logo from "../../assets/images/Logo.svg";
import view from "../../assets/images/view.png";
import hide from "../../assets/images/hide.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../app_url";
import { getUserStatus } from "../../api/apiRequest";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import InputGroup from "react-bootstrap/InputGroup";

const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState("");
  const [userData, setLoginData] = useState();
  const [tokenexist, setTokenexist] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    if (value != undefined) {
      console.log(value);
      if (value.go_to_step == 1) {
        navigate("/LetStarted");
      } else if (value.go_to_step == 2) {
        navigate("/SelectProduct");
      } else if (value.go_to_step == 3) {
        navigate("/YourDetails");
      } else if (value.go_to_step == 4) {
        navigate("/Payment");
      } else if (value.go_to_step == 5) {
        navigate("/Dashboard");
      }
    }
  }, []);

  const [formDataSignin, setFormDataSignin] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [formErrorsSignin, setFormErrorsSignin] = useState({
    new_password: "",
    confirm_password: "",
  });

  const handleChangeSignin = (e) => {
    const { name, value } = e.target;
    setFormDataSignin({ ...formDataSignin, [name]: value });
    setFormErrorsSignin({ ...formErrorsSignin, [name]: "" });
  };

  //get user status
  const handleUserStatus = async () => {
    try {
      let apiRes = await getUserStatus();
      let responseData = await apiRes.json();
      if (responseData.res) {
        // console.warn(responseData.data)
        // console.warn(responseData.data.go_to_step)
        if (responseData.data.product_id === 1) {
          if (responseData.data.go_to_step == 1) {
            navigate("/LetStarted");
          } else if (responseData.data.go_to_step == 2) {
            navigate("/SelectProduct");
          } else if (responseData.data.go_to_step == 3) {
            navigate("/YourDetails");
          } else if (responseData.data.go_to_step == 4) {
            navigate("/Payment");
          } else if (responseData.data.go_to_step == 5) {
            navigate("/Dashboard");
          }
          setIsSubmitting(false);
          setErrorMessage(""); // Clear any previous error messages
          setFormDataSignin({
            new_password: "",
            confirm_password: "",
          });
          setFormErrorsSignin({
            new_password: "",
            confirm_password: "",
          });
        } else if (responseData.data.product_id === 2) {
          if (responseData.data.go_to_step == 1) {
            navigate("/LetStarted");
          } else if (responseData.data.go_to_step == 2) {
            navigate("/SelectProduct");
          } else if (responseData.data.go_to_step == 3) {
            navigate("/SelectCountryPhoneNumber");
          } else if (responseData.data.go_to_step == 4) {
            navigate("/Payment");
          } else if (responseData.data.go_to_step == 5) {
            navigate("/Dashboard");
          }
          setIsSubmitting(false);
          setErrorMessage(""); // Clear any previous error messages
          setFormDataSignin({
            new_password: "",
            confirm_password: "",
          });
          setFormErrorsSignin({
            new_password: "",
            confirm_password: "",
          });
        } else {
          throw new Error("Product_id is not matched.");
        }
      } else {
        // NotificationManager.error(responseData.msg, '',2000);
      }
    } catch (error) {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validateFormSignin()) {
      setIsSubmitting(true);
      formDataSignin.id = id;
      //console.log(formDataSignin);//return;
      // Send the signup data to your Python Flask API
      // You can use libraries like Axios to make API requests
      // Example:
      axios
        .post(
          `${API_BASE_URL}user/reset-password`,
          JSON.stringify(formDataSignin),
          {
            headers: {
              "Content-Type": "application/json", // Set the content type
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.res == false) {
            setErrorMessage(response.data.msg);
            setIsSubmitting(false);
          } else {
            const token = response.data.token;
            //localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(response.data.data));
            // NotificationManager.success(response.data.msg, '',2000);
            setSuccessMessage(response.data.msg, "", 2000);
            console.warn(response.data.data);
            setErrorMessage("");
            setFormDataSignin({
              new_password: "",
              confirm_password: "",
            });
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error.response) {
            setErrorMessage(
              error.response.data.error ||
                "something went wrong please try again"
            );
          } else {
            setErrorMessage("something went wrong please try again");
          }
          setSuccessMessage(""); // Clear any previous success messages
        });
    }
  };

  const validateFormSignin = () => {
    let isValid = true;
    const errors = {
      new_password: "",
      confirm_password: "",
    };

    if (formDataSignin.new_password === "") {
      errors.new_password = "Please enter your new password";
      isValid = false;
    }

    if (formDataSignin.confirm_password === "") {
      errors.confirm_password = "Please enter confirm password";
      isValid = false;
    }

    setFormErrorsSignin(errors);
    return isValid;
  };

  const handleRoute = (route) => {
    navigate(route);
  };

  return (
    <div className="Loginody">
      <div className="MainBody">
        <Link to="/">
          <img src={logo} className="MainLogo" alt="logo" />
        </Link>
        <h1>Reset Password</h1>

        <div className="InnerBox">
          <form className="form" onSubmit={handleResetPassword}>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && <div className="error">{errorMessage}</div>}

            <Form.Group className="mb-4 lock2" controlId="">
              <Form.Label>Password</Form.Label>
              {/* <Form.Control type="email" placeholder="Enter your email" value={formDataSignin.email}
                    onChange={handleChangeSignin}/> */}

              <InputGroup>
                <input
                  name="new_password"
                  value={formDataSignin.new_password}
                  onChange={handleChangeSignin}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your new password"
                  className="form-control"
                />
                <Button
                  className="viewbtn"
                  variant="outline-secondary"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={hide} className="viewimg" alt="logo" />
                  ) : (
                    <img src={view} className="hideimg" alt="logo" />
                  )}
                </Button>
              </InputGroup>

              {/* <input
                name="new_password"
                value={formDataSignin.new_password}
                onChange={handleChangeSignin}
                type={showPassword ? "text" : "password"}
                placeholder="Enter your new password"
                className="form-control"
              />
              <Button
                variant="outline-secondary"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </Button> */}
              {formErrorsSignin.new_password && (
                <span className="error">{formErrorsSignin.new_password}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-4 lock2" controlId="">
              <Form.Label>Confirm Password</Form.Label>
              {/* <Form.Control type="password" placeholder="Password" value={formDataSignin.password}
                    onChange={handleChangeSignin}/> */}

              <InputGroup>
                <input
                  name="confirm_password"
                  value={formDataSignin.confirm_password}
                  onChange={handleChangeSignin}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter your confirm password"
                  className="form-control"
                />
                <Button
                  className="viewbtn"
                  variant="outline-secondary"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <img src={hide} className="viewimg" alt="logo" />
                  ) : (
                    <img src={view} className="hideimg" alt="logo" />
                  )}
                </Button>
              </InputGroup>

              {/* <input
                name="confirm_password"
                value={formDataSignin.confirm_password}
                onChange={handleChangeSignin}
                type={showConfirmPassword ? "text" : "password"}
                placeholder="confirm_password"
                className="form-control"
              />
              <Button
                variant="outline-secondary"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? "Hide" : "Show"}
              </Button> */}
              {formErrorsSignin.confirm_password && (
                <span className="error">
                  {formErrorsSignin.confirm_password}
                </span>
              )}
            </Form.Group>

            {isSubmitting ? (
              <Button variant="primary" type="submit" className="submitBtn">
                Submit
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                className="submitBtn"
                // href="/StuffDashboard"
                //onClick={() => handleRoute("/StuffDashboard")}
              >
                Submit
              </Button>
            )}
          </form>
          <Link to="/Login" className="Loginlink">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};
 
export default ResetPassword;
