// import React, {useState} from "react";
// import {Navigate, Outlet} from "react-router-dom";

// const Auth = () => {
//   const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")) ? true : false);
//   return (
//     isLoggedIn ? <Outlet /> : <Navigate to= {'/Login'} />  
//   )
// }

// export default Auth


import React, {useState, useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";

const Auth = ({allowedRoles}) => {
    const [userRole] = useState(JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role!=null? JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"))?.role : null);
  useEffect(()=>{
    console.log(userRole);
    // console.log(allowedRoles)
    // console.log(allowedRoles.find((role)=>role.includes(userRole)));
    
    // console.warn(JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")))
  },[])
    return (
      // userRole == 'client' ? (
      //   allowedRoles.find((role)=>role.includes(userRole))? <Outlet/> : <Navigate to= '/Login' />
      // ):
      // userRole == 'student' ? (
      //   allowedRoles.find((role)=>role.includes(userRole))? <Outlet/> : <Navigate to= '/Login' />
      // ):null
      
        // allowedRoles.find((role)=>role.includes(userRole))? <Outlet/> : userRole == 'client' ? <Navigate to= '/Dashboard' /> : <Navigate to= '/' />
        allowedRoles.find((role)=>role.includes(userRole))? <Outlet/> : <Navigate to= '/' />
      
    )
}

export default Auth