import React, { useState, useEffect } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./LocationsWithoutMap.scss";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import TickImg from "../../assets/images/TickTick.svg";
import { NotificationManager } from "react-notifications";

import { getHomeLocationCountries, getHomeLocationStates, getHomeMailArea, getHomePhoneArea, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const LocationsWithoutMap = () => {
  const {hostLanguage,languageJson} = useLangContext();
  const [countries,setCountries] = useState([]);
  const [states,setStates] = useState([]);
  const [selectTab,setSelectedTab] = useState('mailbox');
  const [selectedStateId,setSelectedStateId] = useState();
  const [mailBoxData,setMailBoxData] = useState([]);
  const [phoneAreaData,setPhoneAreaData] = useState([]);

  const [contentDetails,setContentDetails] = useState();

    // all countries
    const getAllCountries = async(product_id) => {
      try
      {
        let apiRes = await getHomeLocationCountries(product_id);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          if(selectTab === 'mailbox')
          {
            setCountries([]);
            // const dataArr = Object.values(responseData.data).filter(obj => typeof obj === 'object');
            const dataArr = Object.values(responseData.data).flat();
            setCountries(dataArr);
          }
          else if(selectTab === 'phonenumber')
          {
            setCountries([]);
            console.warn(responseData.data);
            setCountries(responseData.data);
          }
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      catch(error)
      {
          // NotificationManager.error('Something went wrong!', '',2000);
      }
    }
  
    // all states
    const getAllStates = async(product_id,country_id) => {
      try
      {
        let apiRes = await getHomeLocationStates(product_id,country_id);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          const dataArr = Object.values(responseData.data).filter(obj => typeof obj === 'object');
          setStates(dataArr);
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      catch(error)
      {
          // NotificationManager.error('Something went wrong!', '',2000);
      }
    }
  
    //get state lists
    const handleState = async(product_id,country_id) => {
      setStates([]);
      setMailBoxData([]);
      getAllStates(product_id,country_id);
    }

    //tabs selection
    const handleTabSelect =(tab_type) => {
      setSelectedTab(tab_type);
      setCountries([]);
      setStates([]);
      setMailBoxData([]);
      setPhoneAreaData([]);
    }

    const handleStateData = (item) =>{
      console.warn(item);
      let split_state_data = item.split('-');
      console.warn(split_state_data)
      // {`${item?.state?.id}-${item?.state?.country_id}`}
      // setSelectedStateId(split_state_data[0]);
      if(selectTab === 'mailbox')
      {
        getMailArea(1,split_state_data[1],split_state_data[0]);
      }
      else if(selectTab === 'phonenumber')
      {
        getPhoneArea(2,split_state_data[1],split_state_data[0]);
      }
    }
    
    // Mail Area
    const getMailArea = async(product_id,country_id,state_id) => {
      try
      {
        let apiRes = await getHomeMailArea(product_id,country_id,state_id);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          // const dataArr = Object.values(responseData.data).filter(obj => typeof obj === 'object');
          // setStates(dataArr);
          setMailBoxData(responseData.data);
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      catch(error)
      {
          // NotificationManager.error('Something went wrong!', '',2000);
      }
    }

    // Phone Area
    const getPhoneArea = async(product_id,country_id) => {
      try
      {
        let apiRes = await getHomePhoneArea(product_id,country_id,hostLanguage);
        let responseData = await apiRes.json();
        if(responseData.res)
        {
          setPhoneAreaData(responseData.data);
        }
        else
        {
          NotificationManager.error(responseData.msg,'',2000);
        }
      }
      catch(error)
      {
          // NotificationManager.error('Something went wrong!', '',2000);
      }
    }

    // getHomePhoneArea
    
      // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  
    useEffect(()=>{
      if(selectTab === 'mailbox')
      {
        getAllCountries(1);
      }
      else if(selectTab === 'phonenumber')
      {
        getAllCountries(2);
      }
    },[selectTab])

    useEffect(()=>{
      getAllPageContent();
      // console.log(contentDetails?.locations_without_map_page_data);
    },[hostLanguage])

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="PricelistBanner MapBanner">
          <Container>
            <Row>
              <Col sm={8}>
                {/* <p>Unsere Standorte</p>
                <h3>Finden Sie den perfekten Standort für Ihr Unternehmen</h3> */}

                  <div dangerouslySetInnerHTML={{__html: contentDetails?.locations_without_map_page_data?.banner_text}}></div>
                {/* <ul className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox3">
                  <li>
                    <Form.Select aria-label="Default select example" onChange={(e)=>handleState(1,e.target.value)}>
                      <option selected hidden>Country</option>
                      {countries?.length > 0 ? (
                        countries?.map((item,index)=>{
                          return(
                            <option value={item[0]?.country?.id}>{item[0]?.country?.name}</option>
                          )
                        })
                      ):(
                        <option disabled>No Countries Found.</option>
                      )}
                    </Form.Select>
                  </li>

                  <li>
                    <Form.Select aria-label="Default select example">
                      <option selected hidden>State</option>
                      {states?.length > 0 ? (
                        states?.map((item,index)=>{
                          return(
                            <option>{item?.state?.name}</option>
                          )
                        })
                      ):(
                        <option disabled>No States Found.</option>
                      )}
                    </Form.Select>
                  </li>
                </ul> */}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="PricelistBody">
          <Container>
            <Row>
              <Col sm={12}>
                {/* <h3>Wählen Sie den passenden Produkt und Standort aus</h3> */}
                <div dangerouslySetInnerHTML={{__html: contentDetails?.locations_without_map_page_data?.page_heading}}></div>
              </Col>
              {/* <p>Sollten Sie detaillierte Informationen zu unseren Standorte oder spezielle Anfragen haben, zögern Sie bitte nicht, uns zu kontaktieren. Unser engagiertes Team steht Ihnen jederzeit zur Verfügung, um Ihre Fragen zu beantworten und individuelle Lösungen anzubieten, die Ihre Geschäftsziele unterstützen.</p>
              <p>Bei CleverDomizil sind wir stets bestrebt, Ihnen den bestmöglichen Service zu bieten. Wir freuen uns darauf, Sie auf Ihrem Weg zu Innovation und Erfolg zu begleiten!</p> */}
              <div dangerouslySetInnerHTML={{__html: contentDetails?.locations_without_map_page_data?.description_1}}></div>
            </Row>
            <Row className="PricelistTab">
              <Col sm={12}>
                <Tabs
                  defaultActiveKey="mailbox"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={handleTabSelect}
                >
                  {/* <Tab eventKey="mailbox" title="Postfach"> */}
                  <Tab eventKey="mailbox" title={contentDetails?.locations_without_map_page_data?.tab_1_text}>
                    <div className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox5">
                      <span>
                        <Form.Select aria-label="Default select example" onChange={(e)=>handleState(1,e.target.value)}>
                          {/* <option selected hidden>Land auswählen</option> */}
                          <option selected hidden>{contentDetails?.locations_without_map_page_data?.tab_1_select_box_1_default_text}</option>
                            {countries?.length > 0 ? (
                              countries?.map((item,index)=>{
                                return(
                                  <option value={item?.country?.id}>{item?.country?.name}</option>
                                )
                              })
                            ):(
                              // <option disabled>No Countries Found</option>
                              <option disabled>{languageJson?.countryNotFound}</option>
                            )}
                        </Form.Select>
                      </span>

                      <span>
                        <Form.Select aria-label="Default select example" onChange={(e)=>handleStateData(e.target.value)}>
                          {/* <option selected hidden>Stadt auswählen</option> */}
                          <option selected hidden>{contentDetails?.locations_without_map_page_data?.tab_1_select_box_2_default_text}</option>
                          {states?.length > 0 ? (
                            states?.map((item,index)=>{
                              return(
                                <option value={`${item?.state?.id}-${item?.state?.country_id}`} key={index}>{item?.state?.name}</option>
                              )
                            })
                          ):(
                            // <option disabled>No States Found</option>
                            <option disabled>{languageJson?.stateNotFound}</option>
                          )}
                        </Form.Select>
                      </span>
                    </div>

                    <div className="BoxDesign">
                      <ul>
                        {mailBoxData?.map((item,index)=>{
                          return(
                            <li>
                              <div className="BoxDesignInner">
                                <span className="BoxDesignInnerLft">
                                  <h3>{item?.city?.name}</h3>
                                  <p>{item?.country?.name}</p>
                                </span>
                                <span className="BoxDesignInnerRgt">
                                  {/* <h4>from {item?.contract_period?.min_price} €</h4> */}
                                  <h4>{contentDetails?.locations_without_map_page_data?.from} {item?.contract_period?.min_price} €</h4>
                                </span>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>

                  </Tab>
                  {/* <Tab eventKey="phonenumber" title="Rufnummer"> */}
                  <Tab eventKey="phonenumber" title={contentDetails?.locations_without_map_page_data?.tab_2_text}>
                    <div className="DigitalmailboxinLftCheckBox DigitalmailboxinLftCheckBox5">
                      <span>
                        <Form.Select aria-label="Default select example" onChange={(e)=>getPhoneArea(2,e.target.value)}>
                          {/* <option selected hidden>Country</option> */}
                          <option selected hidden>{contentDetails?.locations_without_map_page_data?.tab_2_select_box_1_default_text}</option>
                            {countries?.length > 0 ? (
                              countries?.map((item,index)=>{
                                return(
                                  <option value={item?.id}>{item?.name}</option>
                                )
                              })
                            ):(
                              <option disabled>No Countries Found.</option>
                            )}
                        </Form.Select>
                      </span>
                    </div>
                    <div className="BoxDesign">
                      <ul>
                        {phoneAreaData?.map((item,index)=>{
                          return(
                            <li>
                              <div className="BoxDesignInner">
                                <span className="BoxDesignInnerLft">
                                  <h3>{item?.area_text}</h3>
                                  <p>{item?.country?.name}</p>
                                </span>
                                <span className="BoxDesignInnerRgt">
                                  {/* <h4>from {item?.total} €</h4> */}
                                  <h4>{contentDetails?.locations_without_map_page_data?.from} {item?.total} €</h4>
                                </span>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LocationsWithoutMap;
