import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import "./Login.scss";
// import logo from "../../assets/images/Logo.svg";
import newlogo from "../../assets/images/Clever-New-Logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import {API_BASE_URL} from "../../app_url";
import { getPageContent, getUserStatus } from "../../api/apiRequest";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import { useLangContext } from "../../context/languageContext";

import viewEye from "../../assets/images/view.png";
import hideEye from "../../assets/images/hide.png";
import InputGroup from "react-bootstrap/InputGroup";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { NotificationManager } from "react-notifications";
import LoggedByGoogle from "../../components/google-login/LoggedByGoogle";


const Login = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext ();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState("");
  const [userData, setLoginData] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [contentDetails,setContentDetails] = useState();

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [tokenexist, setTokenexist] = useState(false);
	
	const [hide, setHide] = useState(false);

	const hideInstaAlert = () => { 
    setHide(true);
    Cookies.set("showCookieNotify", "set", {
        expires: 30,
      });

    if(isChecked){
      Cookies.set("allowCookieTranslate", "set", {
        expires: 1,
      });
    }    
	}
   
  const GetCookie = () => {
      //alert(Cookies.get("token"));
      setTokenexist(Cookies.get("showCookieNotify"));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
      }
      else
      {
       console.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  

  useEffect(() => {console.log(tokenexist)
    GetCookie();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    if (value != undefined) {
      console.log(value)
      if(value.go_to_step==1){
        navigate("/LetStarted");
      }else if(value.go_to_step==2){
        navigate("/SelectProduct");
      }else if(value.go_to_step==3){
        navigate("/YourDetails");
      }else if(value.go_to_step==4){
        navigate("/Payment");
      }else if(value.go_to_step==5){
        navigate("/Dashboard");
      }
    }
    getAllPageContent();
  }, []);

  

  const [formDataSignin, setFormDataSignin] = useState({
    email: "",
    password: "",
    lang: hostLanguage
  });

  // useEffect(() => {
  //   const storedUserData = Cookies.get("cookies_user_data");
  //   if (storedUserData) {
  //     const userData = JSON.parse(storedUserData);
  //     setFormDataSignin({
  //       email: userData.email,
  //       password: userData.password,
  //     });
  //   }
  // }, []);

  const [formErrorsSignin, setFormErrorsSignin] = useState({
    email: "",
    password: "",
  });

  const handleChangeSignin = e => {
    const {name, value} = e.target;
    setFormDataSignin({...formDataSignin, [name]: value});
    setFormErrorsSignin({...formErrorsSignin, [name]: ''});
  };


const redirectUrl = async (user_data) => {
  if(user_data?.role == 'client')
  {
    navigate('/Dashboard');
  }
  else if(user_data?.role == 'student')
  {
    if(user_data?.upload_file == 1)
    {
      navigate('/student-dashboard');
    }
    else
    {
      navigate('/student-account-set-up');
    }
  }
  else
  {
    console.error('Error in: ' + user_data);
  }
}

  const handleSignIn = e => {
    e.preventDefault();
    if (validateFormSignin()) {
      setIsSubmitting(true);
      // Send the signup data to your Python Flask API
      // You can use libraries like Axios to make API requests
      // Example:
      axios
        .post(`${API_BASE_URL}user/login`, JSON.stringify(formDataSignin), {
          headers: {
            "Content-Type": "application/json", // Set the content type
          },
        })
        .then(response => {
          console.log(response.data)
        if (response.data.res==false)
        {
          setErrorMessage(response.data.msg)
          console.error(response.data.msg)
          setIsSubmitting(false);
        }
        else
        {
          // console.warn(response.data.data.authorisation.token)
          const token = response.data.data.authorisation.token;
          localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(response.data.data));
          let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
          console.warn(response.data.data);
          if (tokenexist) {
            // const userData = {
            //   email: formDataSignin.email,
            //   password: formDataSignin.password,
            // };              
          
            // const userDataString = JSON.stringify(userData);
          
            Cookies.set("cookies_user_data", value, {
              expires: 2/24,  // 2 hour
            });
          }
          
          redirectUrl(response.data.data);

          // if(response.data.data.go_to_step==1)
          // {
          //   navigate("/LetStarted");
          // }
          // else if(response.data.data.go_to_step==2)
          // {
          //   navigate("/SelectProduct");
          // }
          // else if(response.data.data.go_to_step==3)
          // {
          //   navigate("/YourDetails");
          // }
          // else if(response.data.data.go_to_step==4)
          // {
          //   navigate("/Payment");
          // }
          // else if(response.data.data.go_to_step==5)
          // {
          //   navigate("/Dashboard");
          // }

          // if (props?.from) {
          //   navigate(props?.from);
          //   navigate(0);
          // } else {
          //   navigate("/@" + response.data.data.user.email);
          // }

          // setIsSubmitting(false);
          // setErrorMessage(""); // Clear any previous error messages
          // setFormDataSignin({
          //   email: "",
          //   password: "",
          // });
          // setFormErrorsSignin({
          //   email: "",
          //   password: "",
          // });
        }
        })
        .catch(error => {
          setIsSubmitting(false);
          if (error.response) {
            setErrorMessage(error.response.data.error || languageJson?.somethingwentwrongpleasetryagain);
          } else {
            setErrorMessage(languageJson?.somethingwentwrongpleasetryagain);
          }
          setSuccessMessage(""); // Clear any previous success messages
        });
    }
  };

  const validateFormSignin = () => {
    let isValid = true;
    const errors = {
      email: "",
      password: "",
    };

    if (formDataSignin.email === "")
    {
      errors.email = languageJson?.LoginEmailValidation ;
      isValid = false;
    }

    if (formDataSignin.password === "")
    {
      errors.password = languageJson?.LoginPasswordValidation;
      isValid = false;
    }

    setFormErrorsSignin(errors);
    return isValid;
  };

  const handleRoute = (route) => {
    navigate(route);
  };

  return (
    <div className="Loginody">
      <div className="MainBody">
        <Link to='/'>
          {/* <img src={logo} className="MainLogo" alt="logo"/> */}
          <img src={newlogo} className="MainLogo" alt="logo"/>
        </Link>
        <h1>{contentDetails?.signIn_page_data?.signIn}</h1>

        <div className="InnerBox">
          <form className="form" onSubmit={handleSignIn}>
              {errorMessage && <div className="error">{errorMessage}</div>}
                
            <Form.Group className="mb-4 mail" controlId="">
              <Form.Label>{contentDetails?.signIn_page_data?.emailAddress}</Form.Label>
              <input
                name="email"
                value={formDataSignin.email}
                onChange={handleChangeSignin}
                type="text"
                placeholder={contentDetails?.signIn_page_data?.emailAddress_placeholder}
                className="form-control"
              />
              {formErrorsSignin.email && <span className="error">{formErrorsSignin.email}</span>}
            </Form.Group>

            <Form.Group className="mb-4 lock2" controlId="">
              <Form.Label>{contentDetails?.signIn_page_data?.password}</Form.Label>
              <InputGroup>
                <input
                  name="password"
                  value={formDataSignin.password}
                  onChange={handleChangeSignin}
                  type={showPassword ? "text" : "password"}
                  placeholder={contentDetails?.signIn_page_data?.password_placeholder}
                  className="form-control"
                />
                <Button
                  variant="outline-secondary"
                  className="viewbtn"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={hideEye} className="viewimg" alt="logo" />
                  ) : (
                    <img src={viewEye} className="hideimg" alt="logo" />
                  )}
                </Button>
              </InputGroup>

              {formErrorsSignin.password && (
                <span className="error">{formErrorsSignin.password}</span>
              )}
            </Form.Group>

            {isSubmitting ? (
              <Button variant="primary" type="submit" className="submitBtn" disabled>{contentDetails?.signIn_page_data?.signIn}</Button>
            ):(
              <Button variant="primary" type="submit" className="submitBtn">
                {contentDetails?.signIn_page_data?.signIn}
              </Button>
            )}
          </form>
          <div style={{textAlign:'center'}}>
            <div className="google-login" style={{margin:'8px',padding:'0px',display:'inline-block'}}>
              {/* <LoggedByGoogle/> */}
              <GoogleOAuthProvider 
                clientId="247115028285-a37tc4cpi926sq5ka0n7nj96g7mvkvo9.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={async(credentialResponse) => {
                    const detailsInfo = jwtDecode(credentialResponse.credential);
                    console.log(detailsInfo)
                    try
                    {
                      let apiRes =  await axios.post(`${API_BASE_URL}user/social-login`, {
                        social_media_id:'@gmail',
                        full_name:detailsInfo.name,
                        email:detailsInfo.email,
                        lang:hostLanguage,
                        social_media:'gmail'
                      });
                      if (apiRes.data.res == true)
                      {
                        localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(apiRes.data.data));
                        if(apiRes.data.data.role == 'client')
                        {
                          navigate('/Dashboard');
                          console.log('user-login successful');
                        }
                        else if(apiRes.data.data.role == 'student')
                        {
                          navigate('/student-dashboard');
                          console.log('student-login successful');
                        }
                      }
                      else
                      {
                        console.log('login not-successful');
                      }
                    }
                    catch(error)
                    {
                      console.warn(error.msg);
                    }
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
          <div className="BottomLink">
            <Link to="/register">{contentDetails?.signIn_page_data?.registerForANewAccount}</Link>
            <Link to="/ForgotPassword">{contentDetails?.signIn_page_data?.forgetPassword}</Link>
          </div>
        </div>
      </div>

{hide ? (
  <></>
):(
    tokenexist ? (
    <></>
    ):(

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        className="CookiesModal"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* Privacy settings for your DATEV experience */}
          Datenschutzeinstellungen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {/* We use cookies and other tracking technologies that can retrieve
          or store information on your terminal device. If you give us your
          consent, we process your personal data for analyses and
          optimizations as well as for marketing and advertising purposes.
          For this purpose, information is sometimes passed on to service
          providers that may be located in a third country and do not have a
          comparable level of data protection. By clicking on "Accept all
          cookies", you consent to this processing and the transfer of your
          data. You can revoke your consent at any time in the cookie
          settings in the footer. Click on the cookie settings to learn
          more, you can find more information in our Privacy noticeImprint */}
          Wir verwenden Cookies und andere Tracking-Technologien, die Daten auslesen oder Informationen auf Ihrem Endgerät speichern können. Mit Ihrer Einwilligung verarbeiten wir Ihre personenbezogenen Daten zu Analyse- und Optimierungszwecken sowie zu Marketing- und Werbezwecken. Dazu werden teilweise Informationen an Dienstleister weitergegeben, die ihren Sitz in einem Drittland haben können, in dem kein vergleichbares Datenschutzniveau herrscht. Indem Sie auf "Alle akzeptieren. Cookies“ erklären Sie sich mit dieser Verarbeitung und Übermittlung Ihrer Daten einverstanden. Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen in der Fußzeile widerrufen. Klicken Sie auf die Cookie-Einstellungen, um mehr zu erfahren Weitere Informationen finden Sie in unserer DatenschutzerklärungImpressum
        </p>
        <div className="newDiv">
          <div className="newDivTop">
            <label>
              <input
                type="checkbox"
                checked={true}
                disabled
                //onChange={handleCheckboxChange}
              />
              {/* Necessary cookies */}
              Notwendige Cookies
            </label>

            <table>
              <tr>
                <th>Cookie</th>
                <th>Ablaufdatum</th>
              </tr>
              <tr>
                <td>PHPSESSID</td>
                <td>
                  {/* When ending the browser session */}
                  Beim Beenden der Browsersitzung
                  </td>
              </tr>
              <tr>
                <td>ci_session</td>
                <td>
                    {/* After 2 hours */}
                    Nach 2 Stunden
                  </td>
              </tr>
              <tr>
                <td>showCookieNotify</td>
                <td>
                  {/* After 30 days */}
                  Nach 30 Tagen
                  </td>
              </tr>
              <tr>
                <td>allowCookieTranslate</td>
                <td>
                  {/* After 30 days */}
                  Nach 30 Tagen
                  </td>
              </tr>
            </table>
          </div>
          <div className="newDivTop">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              {/* Google Translate */}
              Google Übersetzer
            </label>

            <table>
              <tr>
                <th>Cookie</th>
                <th>Ablaufdatum</th>
              </tr>
              <tr>
                <td>NID</td>
                <td>
                  {/* After 1 day */}
                  Nach 1 Tag
                  </td>
              </tr>
              <tr>
                <td>__Secure-3PAPISID</td>
                <td>
                  {/* After 1 day */}
                  Nach 1 Tag
                  </td>
              </tr>
              <tr>
                <td>__Secure-3PSID</td>
                <td>{/* After 1 day */}
                  Nach 1 Tag</td>
              </tr>
              <tr>
                <td>__Secure-3PSIDCC</td>
                <td>{/* After 1 day */}
                  Nach 1 Tag</td>
              </tr>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/privacy" className="MOsaveBtn2">Mehr Informationen</Link>
        <Button
          variant="primary"
          onClick={() => hideInstaAlert()}
          className="MOsaveBtn"
        >
          {/* Accept selection */}
          Auswahl akzeptieren
        </Button>
      </Modal.Footer>
    </Modal>

    )
  )}
    </div>
  );
};

export default Login;
