import React, { useEffect, useState } from "react";
import Mainheader from "../../components/mainheader/Mainheader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../components/footer/Footer";
import "./ProductNumber.scss";
import PhoneBig from "../../assets/images/PhoneBig.png";
import BoxIcon5 from "../../assets/images/BoxIcon5.svg";
import BoxIcon7 from "../../assets/images/BoxIcon7.svg";
import BoxIcon8 from "../../assets/images/BoxIcon8.svg";
import BoxIcon9 from "../../assets/images/BoxIcon9.svg";
import BoxIcon10 from "../../assets/images/BoxIcon10.svg";
import BoxIcon11 from "../../assets/images/BoxIcon11.svg"; 
import BoxImg2 from "../../assets/images/BoxImg2.svg";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import badge from "../../assets/images/Badge.svg";
import badge2 from "../../assets/images/Badge2.svg";
import FeaturesImg from "../../assets/images/FeaturesImg.svg";
import iPhonePro from "../../assets/images/iPhonePro.png";
import TickImg from "../../assets/images/TickTick.svg";
import {NotificationManager} from 'react-notifications';

import { getCmsDataById, getFeaturesByProductId, getPageContent, getPhoneCountries } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";


const ProductNumber = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [selectedValue, setSelectedValue] = useState(1);
  const [features,setFeatures] = useState([]);
  const [countries,setCountries] = useState([]);
  const [pageDetails,setPageDetails] = useState([]);
  const [contentDetails,setContentDetails] = useState();

  // Get Page Data
  const fetchPageData = async () =>{
    try{
      let apiRes = await getCmsDataById(16);
      let responseData = await apiRes.json();

      if(responseData.res){
        setPageDetails(responseData.data);
      }else{
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error){
      console.error(error);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get number product features
  const fetchProductFeatures = async(product_id) => {
    try
    {
      let apiRes = await getFeaturesByProductId(product_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setFeatures(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get banner image link
  const getBannerImgLink = (img_link) => {
    try
    {
      let base_url = 'https://cleverdomizil.de';
      let url = base_url + img_link?.split("httpdocs")[1];
      return url
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get all phone countries
  const fetchAllProductCountries = async(product_id) => {
    try
    {
      let apiRes = await getPhoneCountries(product_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCountries(responseData.data)
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const appLink = (type) =>{
    if(type == 'android')
    {
       window.open('https://play.google.com/store/apps/details?id=com.cleverdomizilmail')
    }
    else if(type == 'apple')
    {
      window.open('https://apps.apple.com/in/app/cleverdomicile/id6502281781')
    }
  }
  
    useEffect(()=>{
      fetchProductFeatures(2);
      fetchAllProductCountries();
      // fetchPageData();
      getAllPageContent();
    },[hostLanguage])

  return (
    <div className="WebBody">
      <Mainheader />
      <div className="MainbBody">
        <div className="InnerBannerScetion InnerBannerScetion2">
          <Container>
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="InnerBannerScetionLft">
                  <div dangerouslySetInnerHTML={{ __html: contentDetails?.product_phone_page_data?.banner_text}}></div>
                  <div className="BtnGrp">
                    <Link className="BtnGrpLoc" to='/register'>{contentDetails?.product_phone_page_data?.banner_button_1}</Link>
                    <Link className="BtnGrpBN" to='/register'>{contentDetails?.product_phone_page_data?.banner_button_2}</Link>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="InnerBannerScetionRgt">
                  <Image
                    src={PhoneBig}
                    className="PhoneBig"
                    alt="PhoneBig"
                    fluid
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="FourBoxScetion">
          <Container className="">

            <Row>
              {/* {features?.map((item,index)=>{ */}
              {contentDetails?.product_phone_page_feature_data?.map((item,index)=>{
                return(
                  index < 3 && (
                    <Col sm={4}>
                      <div className="FourBoxScetionBox">
                        <span>
                          {" "}
                          <Image
                            src={getBannerImgLink(item?.feature_image)}
                            className="BannerImg"
                            alt="BannerImg"
                            fluid
                          />
                        </span>
                        <h3>{item?.feature_name}</h3>
                        {/* <p>{item?.description}</p> */}
                        <div dangerouslySetInnerHTML={{ __html: item?.description}} />
                      </div>
                    </Col>
                  )
                )
              })}
            </Row>

          </Container>
        </div>

        <div className="DigitalmailboxScetion DigitalmailboxScetion2">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="DigitalmailboxScetionInner">
                  <Row>
                    <Col sm={7}>
                      <div className="DigitalmailboxinLft">
                        {/* <h4>Tarifoptionen für Telefonanrufe.</h4>
                        <p>
                        Wählen Sie den für Sie passenden Flatrate-Telefontarif aus!
                        </p> */}
                          <div dangerouslySetInnerHTML={{__html: contentDetails?.product_phone_page_data?.tarifoptionen_heading}}></div>
                        <div className="DigitalmailboxinLftCheckBox">
                          {/* <h5>1. Wählen Sie Ihren Standort aus.</h5> */}
                          <div dangerouslySetInnerHTML={{__html: contentDetails?.product_phone_page_data?.tarifoptionen_text}}></div>

                          <Form.Select aria-label="Default select example">
                            {/* <option selected hidden>Land auswählen</option> */}
                            <option selected hidden>{contentDetails?.product_phone_page_data?.tarifoptionen_select_box_default_text}</option>
                            {countries?.map((item,index)=>{
                              return(
                                <option value={item?.id}>{item?.name}</option>
                              )
                            })}
                          </Form.Select>

                          <Button variant="primary" type="submit" className="BtnSubmit" onClick={()=>navigate('/register')}>
                            {/* Weiter */}
                            {contentDetails?.product_phone_page_data?.tarifoptionen_button_text}
                            </Button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={5}>
                      <div className="DigitalmailboxinRgt">
                        <Image src={BoxImg2} className="BoxImg" alt="BoxImg" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="AppScetion">
          <Container className="VideoScetionContainer">
            <Row className="align-items-center">
              <Col sm={6}>
                <Image
                  src={iPhonePro}
                  className="laptopbig"
                  alt="LaptopBig"
                  fluid
                />
              </Col>
              <Col sm={6}>
                <div className="AppScetionTxt AppScetionTxt2">
                  {/* <h4>CleverDomizil App herunterladen</h4> */}
                  {/* <h5> */}
                    {/* Phone Numbers
                    
                    Without Extra
                    <br /> Sim-CARD */}

                    {/* Anrufen ohne <br />
                    zusätzliche SIM- <br />
                    Karte.
                  </h5> */}
                    <div dangerouslySetInnerHTML={{__html: contentDetails?.product_phone_page_data?.CleverDomizil_APP}}></div>
                  {/* <div dangerouslySetInnerHTML={{__html: contentDetails?.product_phone_page_data?.CleverDomizil_APP}}></div> */}
                  <div className="ImgGrp">
                    <span className="ImgGrpImg">
                      <img src={badge} alt="badge" onClick={()=>appLink('android')} style={{cursor:'pointer'}}/>
                    </span>
                    <span className="ImgGrpImg">
                      <img src={badge2} alt="badge" onClick={()=>appLink('apple')} style={{cursor:'pointer'}}/>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="PlanScetion">
          <Container className="">
            <Row>
              <Col sm={12}>
                <div className="ScetionTitel">
                  {/* <h2>Was ist eine geografische oder eine gebührenfreie Rufnummer?</h2> */}
                  <h2>{contentDetails?.product_phone_page_data?.Was_ist_eine_geografische}</h2>
                </div>
              </Col>
            </Row>
            <Row className="GeographicScetion">
              {/* {features?.map((item,index)=>{ */}
              {contentDetails?.product_phone_page_feature_data?.map((item,index)=>{
                return(
                  index >= 3 && (
                    <Col sm={4}>
                    <div className="GeographicScetionBox">
                      <span>
                        <Image
                          src={getBannerImgLink(item?.feature_image)}
                          className="BannerImg"
                          alt="BannerImg"
                          fluid
                        />
                      </span>
                      <h3>{item?.feature_name}</h3>
                      {/* <p>{item?.description}</p> */}
                      <div dangerouslySetInnerHTML={{ __html: item?.description}} />
                    </div>
                    </Col>
                  )
                )
              })}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductNumber;
