import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import "./AccountSetUp.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import email from "../../assets/images/EMailIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import Button from "react-bootstrap/Button";
import { HiArrowNarrowRight, HiArrowNarrowLeft, HiCheck } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import {NotificationManager} from 'react-notifications'; 
import Remove from "../../assets/images/Remove.svg";
import SmllTick from "../../assets/images/SmllTick.svg";
import SmllCross from "../../assets/images/SmllCross.svg";
import StudentHeader from "../../components/header/StudentHeader";
import { useLangContext } from "../../context/languageContext";
import { getCityByRegionId, getCountry, getLegalRegionByCountryId, getPageContent, getStateByCountryId, studentInfoPortal, studentRegisterDetails, studentTimeline } from "../../api/apiRequest";

const AccountSetUp = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [contentDetails,setContentDetails] = useState();

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [radioValue, setRadioValue] = useState();
  const [checkVatValue, setCheckVatValue] = useState();
  const [sameAsBillingValue, setSameAsBillingValue] = useState(true);
  const [planCompShow, setPlanCompShow] = useState(false);
  const [showAllCountries2,setShowAllCountries2] = useState([]);
  const [selectedLegalRegionByCountryId,setLegalRegionByCountryId] = useState([]);
  const [selectedLegalRegionByCountryId2,setLegalRegionByCountryId2] = useState([]);
  const [selectedCityWithId2, setSelectedCityWithId2] = useState([]);
  const [stepRedirect,setStepRedirect] = useState();

  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formDataBillingAddress, setformDataBillingAddress] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  });

  const [formErrorsBillingAddress, setFormErrorsBillingAddress] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    title: "",
    address: "",
    city_id: "",
    country_id: "",
    postal_code: "",
    phone_number: "",
    region_id: "",
    vat_number:"",
  });

  const [billAddError,setBillAddError] = useState({
    title: languageJson?.title_validation,
    first_name: languageJson?.first_name_validation,
    last_name: languageJson?.last_name_validation,
    company_name: languageJson?.company_name_validation,
    address: languageJson?.address_validation,
    country: languageJson?.select_country_validation,
    region: languageJson?.select_region_validation,
    city: languageJson?.select_city_validation,
    postal_code: languageJson?.postal_code_validation,
    phone_number: languageJson?.phone_number_validation
  })

  const [billingPhoneCode, setBillingPhoneCode] = useState();

  const validateFormBillingAddress = () => {
    let isValid = true;
    const errors = {
      first_name: "",
      last_name: "",
      company_name: "",
      title: "",
      address: "",
      city_id: "",
      country_id: "",
      postal_code: "",
      phone_number: "",
      region_id: "",
      vat_number:"",
    };
    
    if (formDataBillingAddress.first_name === "")
    {
      errors.first_name =  billAddError?.first_name;
      isValid = false;
    }
  
    if (formDataBillingAddress.last_name === "")
    {
      errors.last_name = billAddError?.last_name;
      isValid = false;
    }
  
    // if (formDataBillingAddress.company_name === "")
    // {
    //   errors.company_name = billAddError?.company_name;
    //   isValid = false;
    // }
  
    if (formDataBillingAddress.title === "")
    {
      errors.title = billAddError?.title;
      isValid = false;
    }
  
    if (formDataBillingAddress.city_id === "")
    {
      errors.city_id = billAddError?.city;
      isValid = false;
    }
  
    if (formDataBillingAddress.region_id === "")
    {
      errors.region_id = billAddError?.region;
      isValid = false;
    }
  
    if (formDataBillingAddress.country_id === "")
    {
      errors.country_id = billAddError?.country;
      isValid = false;
    }
  
    if (formDataBillingAddress.postal_code === "")
    {
      errors.postal_code = billAddError?.postal_code;
      isValid = false;
    }
  
    if (formDataBillingAddress.address === "")
    {
      errors.address = billAddError?.address;
      isValid = false;
    }
  
    if (formDataBillingAddress.phone_number === "")
    {
      errors.phone_number = billAddError?.phone_number;
      isValid = false;
    }
    setFormErrorsBillingAddress(errors);
    return isValid;
    // console.log(isValid);
    // console.log(errors);
  };

  const handleChangeBillingAddress = e => {
    const {name, value} = e.target;
    setformDataBillingAddress({...formDataBillingAddress, [name]: value});
    setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
  };
  
  const handleCountryTOState = e => {
    handleRegionsByCountryId2(e.target.value);
    const {name, value} = e.target;
    setformDataBillingAddress({...formDataBillingAddress, [name]: value});
    setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
    const foundItem = showAllCountries2.find(item => item.id === parseInt(e.target.value));
    setBillingPhoneCode(foundItem.phonecode);
  };

  const handleRegionsByCountryId = async (country_id) => {
    try
    {
      let apiRes = await getLegalRegionByCountryId(country_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setLegalRegionByCountryId(responseData);
      }
      else
      {
        console.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  const handleCountryList2 = async() =>
{
  try
  {
    let apiRes = await getCountry();
    let responseData = await apiRes.json();
    if(responseData)
    {
      setShowAllCountries2(responseData.data);
    }
    else
    {
      console.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}
  
  const handleRegionTOCity = e => {
    getCityByRegionData(e.target.value);
    const {name, value} = e.target;
    setformDataBillingAddress({...formDataBillingAddress, [name]: value});
    setFormErrorsBillingAddress({...formErrorsBillingAddress, [name]: ''});
  };

  const handleRegionsByCountryId2 = async (country_id) => {
    try
    {
      let apiRes = await getStateByCountryId(country_id);
      let responseData = await apiRes.json();
      console.log(responseData)
      if(responseData)
      {
        setLegalRegionByCountryId2(responseData);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  
  const getCityByRegionData = async (region_id) => {
    try
    {
      let apiRes = await getCityByRegionId(region_id);
      let responseData = await apiRes.json();
      if(responseData)
      {
        setSelectedCityWithId2(responseData);
      }
      else
      {
        NotificationManager.error(responseData.msg, '',2000);
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
const getAllPageContent = async() => {
  try
  {
    let apiRes = await  getPageContent(hostLanguage);
    let responseData = await apiRes.json();
    if(responseData.res)
    {
      setContentDetails(responseData.data);
      // console.warn(responseData.data);
    }
    else
    {
      NotificationManager.error(responseData.msg,'',2000);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

const redirectURL = async() =>{
  if(validateFormBillingAddress())
  {
    // navigate("/student-account-details",{state:{
    //   title:formDataBillingAddress?.title,
    //   first_name:formDataBillingAddress?.first_name,
    //   last_name:formDataBillingAddress?.last_name,
    //   address:formDataBillingAddress?.address ? formDataBillingAddress?.address : '',
    //   country:formDataBillingAddress?.country_id,
    //   state:formDataBillingAddress?.region_id,
    //   city:formDataBillingAddress?.city_id,
    //   postcode:formDataBillingAddress?.postal_code,
    //   company_name:formDataBillingAddress?.company_name ? formDataBillingAddress?.company_name : '',
    //   phone_number:formDataBillingAddress?.phone_number
    // }})

    let params = {
      title: formDataBillingAddress?.title,
      first_name: formDataBillingAddress?.first_name,
      last_name: formDataBillingAddress?.last_name,
      address: formDataBillingAddress?.address ? formDataBillingAddress?.address : '',
      country: formDataBillingAddress?.country_id,
      state: formDataBillingAddress?.region_id,
      city: formDataBillingAddress?.city_id,
      postcode: formDataBillingAddress?.postal_code,
      phone_number: formDataBillingAddress?.phone_number,
      company_name: formDataBillingAddress?.company_name ? formDataBillingAddress?.company_name : '',
      lang: hostLanguage
    }
    let apiRes = await studentInfoPortal(params);
    if(apiRes)
    {
      let userData = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
      userData.first_name = formDataBillingAddress?.first_name + ' ' + formDataBillingAddress?.last_name;
      localStorage.setItem("virtualOfficeLoginInfo", JSON.stringify(userData));
      navigate('/student-account-details');
    }
    else
    {
      NotificationManager.error(apiRes.msg,'',2000);
    }
  }
  else
  {
    console.error('Something went wrong!');
  }
}

const backRedirection = async() =>{
  try
  {
    let apiRes = await studentTimeline(hostLanguage);
    let responseData = await apiRes.json();

    if(responseData.res)
    {
      navigate("/student-dashboard");
    }
    else
    {
      navigate('/student-account-set-up');
      console.error(responseData.msg);
    }
  }
  catch(error)
  {
    console.error(error);
  }
}

  // student registration checking
  const studentRegisterStep = async() => {
    try
    {
      let apiRes = await studentRegisterDetails();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        console.warn(responseData);
        if(responseData?.is_user_data == 0)
        {
          navigate('/student-account-set-up');
        }
        else
        {
          if(responseData?.is_user_data == 1)
          {
            if(responseData?.is_file_upload == 0)
            {
              navigate('/student-account-details');
            }
            else if(responseData?.is_file_upload == 1)
            {
              navigate('/student-dashboard');
            }
          }
          else
          {
            console.error(responseData?.is_user_data);
          }
        }
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

useEffect(()=>{
  studentRegisterStep();
  handleCountryList2();
  getAllPageContent();
  // backRedirection();
  console.log(languageJson)
  console.log(hostLanguage)

  setBillAddError({
    title: languageJson?.title_validation,
    first_name: languageJson?.first_name_validation,
    last_name: languageJson?.last_name_validation,
    company_name: languageJson?.company_name_validation,
    address: languageJson?.address_validation,
    country: languageJson?.select_country_validation,
    region: languageJson?.select_region_validation,
    city: languageJson?.select_city_validation,
    postal_code: languageJson?.postal_code_validation,
    phone_number: languageJson?.phone_number_validation
  })

  // setFormErrorsBillingAddress({
  //   first_name: languageJson?.first_name_validation,
  //   last_name: languageJson?.last_name_validation,
  //   company_name: languageJson?.company_name_validation,
  //   title: languageJson?.title_validation,
  //   address: languageJson?.address_validation,
  //   city: languageJson?.select_city_validation,
  //   country: languageJson?.select_country_validation,
  //   postal_code: languageJson?.postal_code_validation,
  //   phone_number: languageJson?.phone_number_validation,
  //   region: languageJson?.select_region_validation,
  //   vat_number:"",
  // })
},[hostLanguage,languageJson])

  return (
    <div className="lightBlue">
      <StudentHeader />
      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={5}>
              {/* <h2>Set up student account</h2> */}
              <h2>{languageJson?.set_up_student_account}</h2>
            </Col>
            <Col sm={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                {/* <div class="form-steps__step form-steps__step"> */}
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">01
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Let's Started</span> */}
                  <span class="form-step__step-name">{languageJson?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--last">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">02
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Account Setup</span> */}
                  <span class="form-step__step-name">{languageJson?.account_setup}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container">
          <div className="FrmBox">
            <div className="FrmBody">
            <Row className="DetailsList">
                <Col sm={6} className="pr-5">
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.title_label}</Form.Label>
                    <Form.Select 
                      aria-label="Default select example" 
                      id="title" 
                      name="title" 
                      onChange={handleChangeBillingAddress}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("first_name").focus();
                        }
                      }}
                    >
                      {/* <option value="" selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                      <option value="Mr." selected={formDataBillingAddress?.title === "Mr."}>{contentDetails?.your_details_page_data?.Mr}</option>
                      <option value="Mrs." selected={formDataBillingAddress?.title === "Mrs."}>{contentDetails?.your_details_page_data?.Mrs}</option>
                      <option value="Dr." selected={formDataBillingAddress?.title === "Dr."}>{contentDetails?.your_details_page_data?.Dr}</option>
                      <option value="Prof.Dr." selected={formDataBillingAddress?.title === "Prof.Dr."}>{contentDetails?.your_details_page_data?.Prof_Dr}</option> */}
                      
                      <option value="" selected hidden>{contentDetails?.your_details_page_data?.Select_Title}</option>
                      <option value="Mr." selected={formDataBillingAddress?.title === "Mr."}>Mr.</option>
                      <option value="Mrs." selected={formDataBillingAddress?.title === "Mrs."}>Mrs.</option>
                      <option value="Dr." selected={formDataBillingAddress?.title === "Dr."}>Dr.</option>
                      <option value="Prof.Dr." selected={formDataBillingAddress?.title === "Prof.Dr."}>Prof.Dr.</option>
                    </Form.Select>
                    {formErrorsBillingAddress.title && (<span className="error-message">{formErrorsBillingAddress.title}</span>)}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.lastName_label}</Form.Label>
                    <Form.Control
                      id="last_name"
                      name="last_name"
                      value={formDataBillingAddress.last_name}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.lastName_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("company_name").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.last_name && <span className="error-message">{formErrorsBillingAddress.last_name}</span>}
                    
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.address_label}</Form.Label>
                    <Form.Control
                      id="address"
                      name="address"
                      value={formDataBillingAddress.address}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.address_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("country_id").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.address && <span className="error-message">{formErrorsBillingAddress.address}</span>}
                  </Form.Group>
                  
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.region_label}</Form.Label>
                    <Form.Select 
                    aria-label="Default select example" 
                    id="region" 
                    name="region_id"  
                    onChange={handleRegionTOCity} 
                    onKeyDown={(e)=>{
                      if(e.key === 'Enter' || e.key === 'Tab')
                      {
                        e.preventDefault();
                        document.getElementById("city").focus();
                      }
                    }}
                  >
                    <option value="" selected hidden>{contentDetails?.your_details_page_data?.regionSelectBoxDefaultText}</option>
                    {selectedLegalRegionByCountryId2.length > 0 ? (
                      selectedLegalRegionByCountryId2.map((item,index)=>{
                        return(
                          <option value={item.id} selected={formDataBillingAddress?.region_id === item.id}>{item.name}</option>
                        )
                      })
                    ):(
                      <option disabled>{languageJson?.noRegionFound}</option>
                    )}
                    </Form.Select>
                    {formErrorsBillingAddress.region_id && (<span className="error-message">{formErrorsBillingAddress.region_id}</span>)}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.postalCode_label}</Form.Label>
                    <Form.Control 
                      id="postal_code"
                      name="postal_code"
                      value={formDataBillingAddress.postal_code}
                      onChange={handleChangeBillingAddress}
                      type="number"
                      placeholder={contentDetails?.your_details_page_data?.postalCode_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("phone_number").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.postal_code && <span className="error-message">{formErrorsBillingAddress.postal_code}</span>}
                  </Form.Group>
                </Col>

                <Col sm={6} className="pl-5">
                  <Form.Group className="mb-4 text-left">
                  <Form.Label>{contentDetails?.your_details_page_data?.First_Name}</Form.Label>
                    <Form.Control
                      id="first_name"
                      name="first_name"
                      value={formDataBillingAddress.first_name}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.Enter_First_Name}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("last_name").focus();
                        }
                      }}
                    />
                    {formErrorsBillingAddress.first_name && <span className="error-message">{formErrorsBillingAddress.first_name}</span>}
                  </Form.Group>  

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.companyName_label}</Form.Label>
                    <Form.Control
                      id="company_name"
                      name="company_name"
                      value={formDataBillingAddress.company_name}
                      onChange={handleChangeBillingAddress}
                      type="text"
                      placeholder={contentDetails?.your_details_page_data?.companyName_placeholder}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("address").focus();
                        }
                      }}
                    />
                    {/* {formErrorsBillingAddress.company_name && <span className="error-message">{formErrorsBillingAddress.company_name}</span>} */}
                  </Form.Group>  

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.country_label}</Form.Label>
                    <Form.Select 
                      id="country_id"
                      aria-label="Default select example" 
                      name="country_id" 
                      onChange={handleCountryTOState}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("region").focus();
                        }
                      }}
                    >
                    <option value="" selected hidden>{contentDetails?.your_details_page_data?.countrySelectBoxDefaultText}</option>
                    {showAllCountries2.map((item,index)=>{
                      return(
                        <option value={item.id} selected={formDataBillingAddress?.country_id === item.id}>{item.name}</option>
                      )
                    })}
                    </Form.Select>
                    {formErrorsBillingAddress.country_id && <span className="error-message">{formErrorsBillingAddress.country_id}</span>}
                  </Form.Group>

                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.city_label}</Form.Label>
                    <Form.Select 
                      id="city"
                      aria-label="Default select example" 
                      name="city_id" 
                      onChange={handleChangeBillingAddress}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter' || e.key === 'Tab')
                        {
                          e.preventDefault();
                          document.getElementById("postal_code").focus();
                        }
                      }}
                    >
                    <option value="" selected hidden>{contentDetails?.your_details_page_data?.citySelectBoxDefaultText}</option>
                    {selectedCityWithId2?.length > 0 ? (
                      selectedCityWithId2?.map((item,index)=>{
                        return(
                          <option value={item.id} selected={formDataBillingAddress?.city_id === item.id}>{item?.name}</option>
                        )})
                    ):(
                      <option disabled>{languageJson?.cityNotFound}</option>
                    )}
                    </Form.Select>
                    {formErrorsBillingAddress.city_id && <span className="error-message">{formErrorsBillingAddress.city_id}</span>}
                  </Form.Group>
                    
                  <Form.Group className="mb-4 text-left">
                    <Form.Label>{contentDetails?.your_details_page_data?.phoneNumber_label}</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        {billingPhoneCode? `+${billingPhoneCode}`:null}
                      </InputGroup.Text>
                      <Form.Control
                        id="phone_number"
                        type="number"
                        placeholder={contentDetails?.your_details_page_data?.phoneNumber_placeholder}
                        name="phone_number"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={formDataBillingAddress.phone_number}
                        onChange={(e) => {
                          if (e.target.value.length <= 20) {
                            handleChangeBillingAddress(e);
                          }
                        }}
                      />
                    </InputGroup>
                    {formErrorsBillingAddress.phone_number && <span className="error-message">{formErrorsBillingAddress.phone_number}</span>}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>

          <div className="BtnBottom">
            {/* <Button variant="primary" type="button" className="BtnBack" onClick={() => navigate("/register")}>
              <HiArrowNarrowLeft /> {languageJson?.back_buton}
            </Button> */}

            <Button variant="primary" type="submit" className="BtnSubmit" onClick={() => redirectURL()}>
            {languageJson?.proceed_button} <HiArrowNarrowRight />
            </Button>
          </div>
        </div>
      </div>

      {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="CustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Really change settings?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="otptxt">
            Remember, this restarts the registration process
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <Button className="lftBtn btn btn-secondary" onClick={handleClose}>
            KEEP SETTINGS
          </Button>
          <Button className="rgtBtn btn btn-primary">APPLY CHANGES</Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default AccountSetUp;
