import React, { useContext, useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Mainheader from '../../components/mainheader/Mainheader';
import Footer from '../../components/footer/Footer';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { getPageContent, imPrintData } from '../../api/apiRequest';
import { useLangContext } from '../../context/languageContext';

const Imprint = () =>{
  const {hostLanguage} = useLangContext();
  const [contentDetails,setContentDetails] = useState();

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="WebBody">
      <Mainheader/>
      <div className="PricelistBanner">
        <Container>
          <Row>
            <Col sm={12}>
              <p>CleverDomizil</p>
              <h3>{contentDetails?.imprint_page_data?.pagename}</h3>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="InfoScetion">
      <Container className="InfoScetionContainer">
        <Row className="align-items-center">
          <Col sm={12}>
            <div className="InfoScetionLft">
              <h3>{contentDetails?.imprint_page_data?.title}</h3>
              <div dangerouslySetInnerHTML={{__html: contentDetails?.imprint_page_data?.description1}}></div>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      <Footer />
    </div>
  )
}

export default Imprint;