import React from "react";
import ReactDOM from "react-dom/client";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { CommonContext } from "./context/commonContext";
import { LanguageContext, LanguageProvider } from "./context/languageContext";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>

  // <BrowserRouter basename="/updates/virtual-office/register/">
    <BrowserRouter basename="/">
    {/* <BrowserRouter basename=""> */}
      <LanguageProvider>
        <CommonContext>
          <ScrollToTop>
            {/* <GoogleOAuthProvider clientId="247115028285-a37tc4cpi926sq5ka0n7nj96g7mvkvo9.apps.googleusercontent.com"> */}
            <GoogleOAuthProvider clientId="969788662683-rfptvlffuia8ldgldk0mmlb5phq3t9h6.apps.googleusercontent.com">
              <App/>
            </GoogleOAuthProvider>
          </ScrollToTop>
        </CommonContext>
      </LanguageProvider>
    </BrowserRouter>
);
