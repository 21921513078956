import React, { useEffect, useState } from "react";
import InnerHeader from "../../components/header/InnerHeader";
import "./MailView.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../components/sidebar/Sidebar";
import Mailboxright from "../../components/mailboxright/Mailboxright";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ScanEnvelop from "../../assets/images/ScanEnvelop.svg";
import ScanIcon from "../../assets/images/ScanIcon.svg";
import Send from "../../assets/images/Send.svg";
import Collect from "../../assets/images/Collect.svg";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TickBlue from "../../assets/images/TickBlue.svg";
import Nav from "react-bootstrap/Nav";
import SendRed from "../../assets/images/SendRed.svg";
import SendYellow from "../../assets/images/SendYellow.svg";
import ScanIconYellow from "../../assets/images/ScanIconYellow.svg";
import ScanIconRed from "../../assets/images/ScanIconRed.svg";
import ScanGreenAll from "../../assets/images/ScanEnvelopGreen.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { HiOutlineTrash, HiArrowNarrowLeft } from "react-icons/hi";
import { NotificationManager } from "react-notifications";

import { viewRecord, getAllMailCategory, updateMailCategoryById, getWalletPrice, getScanPrice, sendScanRequest, recordMovedtoTrash, getPageContent} from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const MailView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {hostLanguage,languageJson} = useLangContext();

  const [viewData,setViewData] = useState();
  const [categories,setCategories] = useState([]);
  const [showReqPopUp, setShowReqPopUp] = useState(false);
  const [walletDetails,setWalletDetails] = useState();
  const [addressshow, addresssetShow] = useState(false);
  const [reqId, setReqId] = useState();
  const [deleteRecordId,setDeleteRecodId] = useState();
  const [contentDetails,setContentDetails] = useState();

  const [delshow, deletShow] = useState(false);
  const delhandleClose = () => deletShow(false);
  const delhandleShow = () => deletShow(true);

  // view record
  const handleRecordView = async(record_id) => {
    try
    {
      // setEventFire(true);
      let apiRes = await viewRecord(record_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        console.warn(responseData.data[0]);
        setViewData(responseData.data[0]);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // date format
  const dateFormat = (date) => {
    console.warn(date)
    let split_date = date?.split('-');
    let final_date = split_date[1] + '.' + split_date[2] + '.' + split_date[0];
    return final_date

    const formattedDate = viewData?.get_date?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  // fetching new mail data
  const fetchingAllMailCategories = async() => {
    try
    {
      let apiRes = await getAllMailCategory(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setCategories(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // update mail category by id
  const updateCategoryById = async(record_id,cat_id) => {
    try
    {
      let apiRes = await updateMailCategoryById(record_id,cat_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // fetching new mail data
  const gettingWalletPrice = async() => {
    try
    {
      let apiRes = await getWalletPrice();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setWalletDetails(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  // fetching new mail data
  const gettingScanPrice = async(record_id) => {
    try
    {
      let apiRes = await getScanPrice(record_id);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        if(responseData.data[0]?.plan?.envelope_scanning > walletDetails?.wallet_balance)
        {
          // navigate("/Payment");
          handleScanDocReq(record_id,0,responseData.data[0]?.plan?.envelope_scanning);
        }
        else
        {
          // deduct_wallet balance ta hoche status 0 r 1
          handleScanDocReq(record_id,1,responseData.data[0]?.plan?.envelope_scanning);
        }
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //send scan document request to admin
  const handleScanDocReq = async (record_id,deduct_wallet_balance,amount) =>
  {
    try
    {
      let data = {
        id: record_id,
        deduct_wallet_balance: deduct_wallet_balance,
        amount: parseFloat(amount).toFixed(2)
      }
      let apiRes = await sendScanRequest(data);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000);
        handleRecordView(state?.id);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  //download files
  const handleDownload = async (record_id) => {
    try 
    {
      let base_url = 'https://cleverdomizil.de';
      let envelop_file = viewData?.envelop;
      let scan_document = viewData?.scan_document;
      let download_arr = [];

      if(envelop_file === '' || envelop_file === null || envelop_file === undefined)
      {
        // console.warn('envelop_file: ' + envelop_file);
      }
      else
      {
        let env_url = base_url + envelop_file?.split("httpdocs")[1];
        // await downloadFile(env_url,'envelop.png');
        download_arr.push(env_url);
      }

      if(scan_document === '' || scan_document === null || scan_document === undefined)
      {
        // console.warn('scan_document: ' + scan_document);
      }
      else
      {
        let scan_url = base_url + scan_document?.split("httpdocs")[1];
        // await downloadFile(scan_url,'scan_document.pdf');
        download_arr.push(scan_url);
      }
      download_arr?.map((item,index)=>{
        downloadFile(item)
      })
      // NotificationManager.success("File Download has started..",'',2000);
    }
    catch (error)
    {
      console.error(error);
    }
  };

  const downloadFile = async (url, fileName) => 
  {
    window.open(url, '_blank');
  //   const response = await fetch(url);
  //   const blob = await response.blob();

  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  };

  // moved to trashed
  const MoveToTrashed = async() => {
    try
    {
      // setEventFire(true);
      let apiRes = await recordMovedtoTrash(deleteRecordId);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        NotificationManager.success(responseData.msg,'',2000, navigate('/AllMail'));
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
        // NotificationManager.error('Something went wrong!', '',2000);
    }
  }

  useEffect(()=>{
    fetchingAllMailCategories();
    gettingWalletPrice();
    getAllPageContent();
    // console.warn(state?.id)
    if(state?.id!= undefined)
    {
      handleRecordView(state?.id);
    }
  },[hostLanguage])

  return (
    <div className="lightBlue">
      <InnerHeader />
      <div className="mail-box-main-body">
        <Row className="mail-box-body">
          <Col sm={12}>
            <div className="NewaddressBox">
              {/* <h2 style={{textAlign:'center'}}>Mail Box</h2> */}
              <h2 style={{textAlign:'center'}}>{contentDetails?.after_login?.MailBox}</h2>

              <div className="NewaddressBoxtInner MailView">
                <div className="DetailsBox">
                  <Row className="DetailsList">
                    <Col sm={12} className="">
                      <h4>
                        {/* <em>Title</em> */}
                        <em>{contentDetails?.after_login?.Tittle}</em>
                        {viewData?.from}
                      </h4>
                    </Col>
                    <Col sm={12} className="">
                      <h4>
                        {/* <em>Type:</em> */}
                        <em>{contentDetails?.after_login?.Type}:</em>
                        {viewData?.mail_type?.name}
                      </h4>
                    </Col>
                    <Col sm={12} className="">
                      <h4>
                        {/* <em>Weight</em> */}
                        <em>{contentDetails?.after_login?.Weight}</em>
                        {viewData?.weight}g
                      </h4>
                    </Col>
                    <Col sm={12} className="">
                      <h4>
                        {/* <em>Date</em> */}
                        <em>{contentDetails?.after_login?.Date}</em>
                        {/* {dateFormat(viewData?.get_date)} */}
                        {viewData?.get_date}
                        {/* {viewData?.get_date?.toLocaleDateString('en-US', {year: 'numeric',month: '2-digit',day: '2-digit'})} */}
                      </h4>
                    </Col>

                    <Col sm={12} className="">
                      <h4>
                        {/* <em>Category</em> */}
                        <em>{contentDetails?.after_login?.Category}</em>
                        <td>
                          <Form.Select aria-label="Default select example" onChange={(e)=>updateCategoryById(viewData?.id,e.target.value)}>
                            {categories && categories.map((cat_item,cat_index)=>{
                            return(
                              <option value={cat_item?.id} selected={cat_item?.id === viewData?.mail_category?.id ? true : false}>{cat_item?.name}</option>
                            )
                            })}
                          </Form.Select>
                        </td>
                      </h4>
                    </Col>
                  </Row>
                  <Row className="DetailsList2">
                    <Col sm={1} className="">
                      <h5>
                        {/* <em>Envelop</em> */}
                        <em>{contentDetails?.after_login?.Envelop}</em>
                        <img
                          src={ScanGreenAll}
                          className="TableIcon  hover-scan-document3"
                          alt="Img"
                        />
                      </h5>
                    </Col>
                    
                    {/* Scan */}
                    <Col sm={1} className="">
                      <h5>
                        {/* <em>Scan</em> */}
                        <em>{contentDetails?.after_login?.Scan}</em>
                        {viewData?.send_scan_request == 0 && viewData?.send_scan_request_status == 0 ? (
                          <>
                            <a class="icon hover-scan-document">
                              <img
                                src={ScanIconRed}
                                className="TableIcon hover-scan-document"
                                alt="Img"
                              />
                            </a>
                            {/* {!showReqPopUp && (
                              <div class="show-scan-document-over">
                                <div class="show-scan-document">
                                  <h3>Do you want to scan this document?</h3>
                                  <button type="button" class="btn btn-secondary">
                                    No
                                  </button>
                                  <button type="button" class="btn btn-primary" onClick={()=>gettingScanPrice(viewData?.id)}>
                                    Yes
                                  </button>
                                </div>
                              </div>
                            )} */}
                            
                          </>
                        ):
                        viewData?.send_scan_request == 1 && viewData?.send_scan_request_status == 0 ? (
                            <a class="icon hover-scan-document2">
                              <img
                                src={ScanIconYellow}
                                className="TableIcon hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                        ):
                        viewData?.send_scan_request == 1 && viewData?.send_scan_request_status == 1 ? (
                          <>
                            <a class="icon hover-scan-document2">
                              <img
                                src={ScanIconYellow}
                                className="TableIcon hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                          </>
                        ):
                        viewData?.send_scan_request == 2 && viewData?.send_scan_request_status == 1 ? (
                          <>
                            <a class="icon hover-scan-document2">
                              <img
                                src={ScanIconYellow}
                                className="TableIcon hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                          </>
                        ):
                        viewData?.send_scan_request == 2 && viewData?.send_scan_request_status == 2 ? (
                          <>
                            <a class="icon hover-scan-document3">
                              <img
                                src={ScanGreenAll}
                                className="TableIcon hover-scan-document3"
                                alt="Img"
                              />
                            </a>
                          </>
                        ):null}
                      </h5>
                    </Col>

                      {/* Send Me */}
                      <Col sm={1} className="">
                        <h5>
                          {/* <em>Send Me</em> */}
                          <em>{contentDetails?.after_login?.SendMe}</em>
                          {viewData?.send_me_request == 0 && viewData?.send_me_request_status == 0 ? (
                            <td>
                            <a class="icon hover-scan-document2">
                              <img
                                src={SendRed}
                                className="TableIcon  hover-scan-document2"
                                alt="Img"
                              />
                            </a>

                            {/* <div class="show-scan-document-over2">
                              <div class="show-scan-document2">
                                <h3>
                                  Do you want this item to be send you immediately?
                                </h3>
                                <button type="button" class="btn btn-secondary">
                                  No
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  // onClick={addressshow}
                                  // onClick={()=>addressshow}
                                  onClick={()=>{addresssetShow(true);setReqId(viewData?.id)}}
                                  // onClick={addresssetShow}
                                >
                                  Yes
                                </button>
                              </div>
                            </div> */}
                          </td>
                          ):
                          viewData?.send_me_request == 1 && viewData?.send_me_request_status == 0 ? (
                            <td>
                            <a class="icon hover-scan-document2">
                              <img
                                src={SendYellow}
                                className="TableIcon  hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                          </td>
                          ):
                          viewData?.send_me_request == 1 && viewData?.send_me_request_status == 2 ? (
                            <td>
                            <a class="icon hover-scan-document2">
                              <img
                                src={SendYellow}
                                className="TableIcon  hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                          </td>
                          ):
                          viewData?.send_me_request == 1 && viewData?.send_me_request_status == 3 ? (
                            <td>
                            <a class="icon hover-scan-document2">
                              <img
                                src={SendYellow}
                                className="TableIcon  hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                          </td>
                          ):
                          viewData?.send_me_request == 2 && viewData?.send_me_request_status == 4 ? (
                            <td>
                            <a class="icon hover-scan-document2">
                              <img
                                src={ScanGreenAll}
                                className="TableIcon  hover-scan-document2"
                                alt="Img"
                              />
                            </a>
                          </td>
                          ):null}
                        </h5>
                      </Col>

                    <Col sm={1} className="">
                      <h5>
                        {/* <em>Collect</em> */}
                        <em>{contentDetails?.after_login?.Collect}</em>
                        <img
                          src={Collect}
                          className="TableIcon  hover-scan-document3"
                          alt="Img"
                          onClick={()=>handleDownload(viewData?.id)}
                          // onClick={()=>alert(viewData?.id)}
                        />
                      </h5>
                    </Col>
                  </Row>

                  <Row sm={12}>
                    <Col>
                      <div className="BtnBottom">
                        <Link to="/AllMail" className="DashboardBtnBtnBack">
                          <HiArrowNarrowLeft size={25} className="PlusIcon" />
                          {/* Back */}
                          {contentDetails?.after_login?.Back}
                        </Link>

                        <Link onClick={()=>{deletShow(true);setDeleteRecodId(viewData?.id)}} className="DashboardBtn35">
                          <HiOutlineTrash size={25} className="PlusIcon" />
                          {/* Delete */}
                          {contentDetails?.after_login?.Delete}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Modal
            show={delshow}
            onHide={delhandleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="CustomModal delCustomModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>
                Do you want this item/mail to be deleted? It will be automatically
                move to the trash for 30 day.
              </h3>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={delhandleClose}
                className="lftBtn"
              >
                No
              </Button>
              <Button variant="primary" className="rgtBtn" onClick={()=>MoveToTrashed()}>
                Yes
              </Button>
            </Modal.Footer>
         </Modal>
        </Row>
      </div>
    </div>
  );
};

export default MailView;
