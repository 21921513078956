import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { downloadAgreement, downloadAgreement2, getPageContent, renewPurchasedProduct } from '../../api/apiRequest';
import { useLangContext } from '../../context/languageContext';

  const Conditions = ({propsData1,propsData2,type,amount,productId}) => {
    // console.log(type);
    // console.log(amount);
    // console.log(productId)
  const {hostLanguage,languageJson} = useLangContext();
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [contentDetails,setContentDetails] = useState();

  const handleCheckboxChange1 = (e) => {
    // console.warn(e.target.checked);
    if (e.target.checked)
    {
      setIsChecked1(e.target.checked);
      propsData1(e.target.value);
    }
    else
    {
      setIsChecked1(e.target.checked);
      propsData1('not-selected1');
    }
  }
    
  const handleCheckboxChange2 = (e) => {
    console.warn(e.target.checked)
    if (e.target.checked)
    {
      setIsChecked2(e.target.checked);
      propsData2(e.target.value);
    }
    else
    {
      setIsChecked2(e.target.checked);
      propsData2('not-selected2');
    }
  }

  const handleRedirect = (type) => {
    if(type === 'terms')
    {
      window.open("/terms", "_blank");
    }
    else if(type === 'contract')
    {
      window.open("/terms", "_blank");
    }
  }

  const agreementPdf = async () => {
    if(type == 'purchase-product')
    {
      let apiRes = await downloadAgreement();
      let responseData = await apiRes.json();

      if(responseData.res)
      {
        // console.log(responseData.data);
        let base_url = 'https://cleverdomizil.de';
        let agreement_url = base_url + responseData.data.split("httpdocs")[1];
        window.open(agreement_url, '_blank');
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    else if(type == 'topup')
    {
      let apiRes = '';
      let responseData = '';
      if(amount == undefined || amount == '' || amount == null || amount == 0 || amount < 0)
      {
        apiRes = await downloadAgreement2(0,type);
        responseData = await apiRes.json();
      }
      else
      {
        apiRes = await downloadAgreement2(amount,type);
        responseData = await apiRes.json();
      }
      if(responseData.res)
      {
        // console.log(responseData.data);
        let base_url = 'https://cleverdomizil.de';
        let agreement_url = base_url + responseData.data.split("httpdocs")[1];
        window.open(agreement_url, '_blank');
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    else if(type == 'scan')
    {
      let apiRes = '';
      let responseData = '';
      if(amount == undefined || amount == '' || amount == null || amount == 0 || amount < 0)
      {
        apiRes = await downloadAgreement2(0,type);
        responseData = await apiRes.json();
      }
      else
      {
        apiRes = await downloadAgreement2(amount,type);
        responseData = await apiRes.json();
      }
      if(responseData.res)
      {
        // console.log(responseData.data);
        let base_url = 'https://cleverdomizil.de';
        let agreement_url = base_url + responseData.data.split("httpdocs")[1];
        window.open(agreement_url, '_blank');
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    else if(type == 'sendme')
    {
      let apiRes = '';
      let responseData = '';
      if(amount == undefined || amount == '' || amount == null || amount == 0 || amount < 0)
      {
        apiRes = await downloadAgreement2(0,type);
        responseData = await apiRes.json();
      }
      else
      {
        apiRes = await downloadAgreement2(amount,type);
        responseData = await apiRes.json();
      }
      if(responseData.res)
      {
        // console.log(responseData.data);
        let base_url = 'https://cleverdomizil.de';
        let agreement_url = base_url + responseData.data.split("httpdocs")[1];
        window.open(agreement_url, '_blank');
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    else if(type == 'renewal')
    {
      let apiRes = '';
      let responseData = '';
      if(amount == undefined || amount == '' || amount == null || amount == 0 || amount < 0 || amount == NaN)
      {
        // apiRes = await downloadAgreement2(0,type);
        apiRes = await renewPurchasedProduct(productId);
        responseData = await apiRes.json();
      }
      else
      {
        // console.log(amount,type)
        // apiRes = await downloadAgreement2(amount,type);
        apiRes = await renewPurchasedProduct(productId);
        responseData = await apiRes.json();
      }
      if(responseData.res)
      {
        // console.log(responseData.data);
        let base_url = 'https://cleverdomizil.de';
        let agreement_url = base_url + responseData.data.split("httpdocs")[1];
        window.open(agreement_url, '_blank');
      }
      else
      {
        console.error(responseData.msg,'',2000);
      }
    }
    else
    {
      console.error('Type Error: ' + type);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
      }
      else
      {
        console.error(responseData.msg);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])
  
  return (
    <div className='termsBoxNew'>
      {/* <h5>Terms and Conditions</h5> */}
      <h5>{contentDetails?.your_details_page_data?.Terms_and_Conditions}</h5>
      <ul>
        <li>
          {/* By submitting this form, you agree to the use and disclosure of your information in accordance with our Membership Terms and Privacy Policy. Please read the Privacy Policy for more information about how we handle your personal information, including the methods we use to link the information you provide to the information we collect using cookies. */}
          {contentDetails?.your_details_page_data?.Terms_and_Conditions_description}
        </li>
        <li>
          {/* Please read and accept the */}
          {contentDetails?.your_details_page_data?.Please_read_and_accept_the}
          {/* <Link to="javascript:void(0)" onClick={()=>handleRedirect('terms')}> General Terms and Conditions</Link>. */}
          <Link to="javascript:void(0)" onClick={()=>handleRedirect('terms')}> {contentDetails?.your_details_page_data?.General_Terms_and_Conditions}</Link>
        </li>
        <li>
          {/* Please read and accept the */}
          {contentDetails?.your_details_page_data?.Please_read_and_accept_the}
          {/* <Link to="javascript:void(0)" onClick={()=>agreementPdf()}> Cleverdomizil contract</Link>. */}
          <Link to="javascript:void(0)" onClick={()=>agreementPdf()}> {contentDetails?.your_details_page_data?.Cleverdomizil_contract}</Link>
        </li>
      </ul>
      <div className='radioterms'>
        <div className="check-div check-divRadio">
          <div className="form-check mr-3">
              <Form.Check type="checkbox" aria-label="option 1" id="flexCheckChecked1" value="accept_terms" checked={isChecked1} onChange={handleCheckboxChange1}/>
              <Form.Label className="form-check-label" for="flexCheckChecked1">
                {/* I hereby agree to the general terms and conditions */}
                {contentDetails?.your_details_page_data?.I_hereby_agree_to_the_general_terms_and_conditions}
              </Form.Label>
          </div>
          <div className="form-check mr-3">
            <Form.Check type="checkbox" aria-label="option 2" id="flexCheckChecked2" value="accept_contract" checked={isChecked2} onChange={handleCheckboxChange2}/>
              <Form.Label className="form-check-label" for="flexCheckChecked1">
                {/* I accept the contract */}
                {contentDetails?.your_details_page_data?.I_accept_the_contract}
              </Form.Label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conditions;
