import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "./SelectProduct.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step from "../../components/step/Step";
import Form from "react-bootstrap/Form";
import email from "../../assets/images/EMailIcon.svg";
import phone from "../../assets/images/PhnIcon.svg";
import Button from "react-bootstrap/Button";
import { HiArrowNarrowRight, HiArrowNarrowLeft, HiCheck } from "react-icons/hi";
import { useNavigate, Link } from "react-router-dom";
import  axios  from "axios";
import { API_BASE_URL } from "../../app_url";
import {NotificationManager} from 'react-notifications'; 
import { completeStep2, getPageContent, getProduct, checkPhoneNumberPurchaseStatus } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";

const SelectProduct = () => {
  const navigate = useNavigate();
  const {hostLanguage,languageJson} = useLangContext();
  const [loginData, setLoginData] = useState();
  const [showProduct, setShowProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [showError,setShowError] = useState(false);


  const [contentDetails,setContentDetails] = useState();
  const [isDataExist,setIsDataExist] = useState();
  const [isDataExistError,setIsDataExistError] = useState();

  // let errors ={ product: 'Please select a product'}

  useEffect(() => {
    //CreateBroadcast();
    //let data = logInDetails();
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    
;    if (value == undefined) {
      navigate("/");
    }
    // setLoginData(value["user"]);
    
  }, []);

  useEffect(()=>{
    phonePurchaseStatus();
    handleGetProduct(hostLanguage);
    getAllPageContent();
  },[hostLanguage])

  //alreday phone product purchased or not
  const phonePurchaseStatus = async() => {
    try
    {
      let apiRes = await  checkPhoneNumberPurchaseStatus(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setIsDataExist(responseData.data);
        // console.warn(responseData.data);
        setIsDataExistError(responseData.msg);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  const handleGetProduct = async() =>
    {
      try
      {
        let apiRes = await getProduct(hostLanguage);
        let responseData = await apiRes.json();
        if(responseData)
        {
          setShowProduct(responseData.data)
        }
        else
        {
          NotificationManager.error(responseData.msg, '',2000);
        }
      }
      catch(error)
      {
        // NotificationManager.error('Something went wrong!', '',2000);
      }
  }

  // phone roduct exist in cart or phone product is already purchased
  const cartDataMsg = (msg) => {
    if(msg)
    {
      NotificationManager.warning(isDataExistError,'',5000);
      return msg;
    }
    else
    {
      return msg;
    }
  }
  
// complete step 3
  const handleCompleteStep2 = async() => {
    try
    {
      if(selectedProduct == undefined)
      {
        setShowError(true)
      }
      else
      {
        if(selectedProduct == 1)
        {
          setShowError(false);
          let apiRes = await completeStep2(selectedProduct,hostLanguage);
          let responseData = await apiRes.json();
          if(responseData)
          {
            navigate("/YourDetails");
          }
          else
          {
            NotificationManager.error(responseData.msg, '',2000);
          }
        }
        else
        {
          setShowError(false);
          let apiRes = await completeStep2(selectedProduct);
          let responseData = await apiRes.json();
          if(responseData)
          {
            navigate("/SelectCountryPhoneNumber");
          }
          else
          {
            NotificationManager.error(responseData.msg, '',2000);
          }
        }
        
      }
    }
    catch(error)
    {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  }
  

  const handleProceed = () => {
    navigate("/YourDetails");
  };

  const handleBack = () => {
    navigate("/LetStarted");
  };

  return (
    <div className="lightBlue">
      <Header />
      <div className="Hrbottom">
        <div className="container">
          <Row className="align-items-center">
            <Col sm={5}>
              {/* <h2>Set up your account</h2> */}
              <h2>{contentDetails?.lets_start_page_data?.set_up_your_account}</h2>
            </Col>
            <Col sm={7}>
              <div class="form-steps">
                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">
                      <HiCheck size="20" />
                    </span>
                  </div>
                  {/* <span class="form-step__step-name">Let's Started</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.lets_started}</span>
                </div>

                <div class="form-steps__step form-steps__step--active">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">02</span>
                  </div>
                  {/* <span class="form-step__step-name">Select Product</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.select_product}</span>
                </div>

                <div class="form-steps__step form-steps__step--incomplete">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">03</span>
                  </div>
                  {/* <span class="form-step__step-name">Your Details</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.your_details}</span>
                </div>

                <div class="form-steps__step form-steps__step--last">
                  <div class="form-steps__step-circle">
                    <span class="form-steps__step-number">04</span>
                  </div>
                  {/* <span class="form-step__step-name">Payment</span> */}
                  <span class="form-step__step-name">{contentDetails?.lets_start_page_data?.payment}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="MainBody">
        <div className="container">
          <div className="FrmBody">
            <Row className="align-items-center">
              <Col sm={6}>
                <h2>
                  {/* Select your account currency This cannot be changed later! */}
                  {/* Select your product. This cannot be changed later! */}
                  {contentDetails?.lets_start_page_data?.product_left_heading}
                </h2>
              </Col>
              <Col sm={6}>
                <div className="check-div check-divRadio">
                  {showProduct?.map((item,index)=>{
                    return(
                      <div className="form-check mr-3">
                        <Form.Check aria-label="option 1" id="flexCheckChecked1" />
                        <Form.Check type="radio" name='currency' aria-label="option 1" 
                          id={`flexCheckChecked${index}`} 
                          value={item.id} 
                          // style={{ pointerEvents: (item.id === 2 && isDataExist === '1') ? 'none' : 'auto' }}
                          onChange={(e)=>setSelectedProduct(e.target.value)}

                          // onChange={(e) => {
                          //   if (item.id !== 2) {
                          //     setSelectedProduct(e.target.value);
                          //   }
                          // }}

                          // onClick={(e) => item.id === 2 && e.preventDefault()}

                          // onClick={(e)=>{
                          //   if (item.id === 1 || (item.id === 2 && isDataExist === '0'))
                          //   {
                          //     setSelectedProduct(e.target.value);
                          //   }
                          //   else if(item.id == 2 && isDataExist == '1')
                          //   {
                          //     e.preventDefault()
                          //     NotificationManager.warning(isDataExistError,'',2000);
                          //   }
                          // }}

                          // onChange={(e) => {
                          //   if ((item.id === 1 || (item.id === 2 && isDataExist === '0')) && !(item.id === 2 && isDataExist === '1')) 
                          //   {
                          //     setSelectedProduct(e.target.value);
                          //   }
                          //   else
                          //   {
                          //     NotificationManager.warning(isDataExistError,'',2000);
                          //   }
                          // }}
                        
                        />
                        <Form.Label
                          className="form-check-label"
                          for="flexCheckChecked1"
                        >
                          {item?.logo == "PhnIcon.svg" ? (
                            <img src={phone} className="checkImg" alt="Img" />
                          ) : (
                            <img src={email} className="checkImg" alt="Img" />
                          )}

                          {/* <img src={`../../assets/images/${item.logo}`} className="checkImg" alt="Img" /> */}
                          <h3>
                          {item.product_name} <br />
                          {item.product_type}
                          </h3>
                          <p>{item.product_details}</p>
                        </Form.Label>
                      </div>
                    );
                  })}
                </div>
                {/* {showError && selectedProduct == undefined && <span className="error" style={{paddingRight:'293px'}}>{errors.product}</span>} */}
                {showError && selectedProduct == undefined && <span className="error" style={{paddingRight:'200px'}}>{languageJson?.productValidation}</span>}
              </Col>
            </Row>
          </div>

          <div className="BtnBottom">
            <Button
              variant="primary"
              type="button"
              className="BtnBack"
              onClick={() => handleBack()}
              // href="/LetStarted"
            >
              <HiArrowNarrowLeft /> 
              {/* Back */}
              {contentDetails?.lets_start_page_data?.prouct_page_back_button}
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="BtnSubmit"
              // href="/YourDetails"
              onClick={() => handleCompleteStep2()}
              disabled ={isDataExist === '1' && selectedProduct == 2 ?  cartDataMsg(true) : cartDataMsg(false)}
            >
              {/* Proceed */}
              {contentDetails?.lets_start_page_data?.proceed_button_text}
              <HiArrowNarrowRight />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectProduct;
