import React, {useState, useEffect, useRef} from "react";
import Nav from "react-bootstrap/Nav";
import "./Sidebar.scss";
import { NavLink } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import {changeTitle, onLogout} from "../../utils/commonData";

import { getAllMailCount, getPageContent } from "../../api/apiRequest";
import { useContextValues } from "../../context/commonContext";
import { useLangContext } from "../../context/languageContext";

const Sidebar = (props) => {
  const {eventFire} = useContextValues();
  const {hostLanguage,languageJson} = useLangContext();
  const navigate = useNavigate();
  const [allMailCount,setAllMailCount] = useState();
  const [contentDetails,setContentDetails] = useState();

  const Logout = () => {
    onLogout();
    navigate("/Login");
  };

  // fetching sidebar count
  const fetchingSideBarCount = async() => {
    try
    {
      let apiRes = await getAllMailCount();
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setAllMailCount(responseData.data);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }

  // get page content
  const getAllPageContent = async() => {
    try
    {
      let apiRes = await  getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if(responseData.res)
      {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      }
      else
      {
        NotificationManager.error(responseData.msg,'',2000);
      }
    }
    catch(error)
    {
      console.error(error);
    }
  }
  
  useEffect(()=>{
      fetchingSideBarCount();
  },[eventFire])
  
  useEffect(()=>{
    getAllPageContent();
  },[hostLanguage])

  return (
    <div className="SidebarMenu">
      <Nav className="flex-column">
        <Nav.Item>
          <NavLink to={"/AllMail"}>
            {/* All Mail <span class="count-span">{(allMailCount?.all_mail_count > 0 && allMailCount?.all_mail_count < 10) ? `0${allMailCount?.all_mail_count}`: allMailCount?.all_mail_count}</span> */}
            {contentDetails?.after_login?.AllMail} <span class="count-span">{(allMailCount?.all_mail_count > 0 && allMailCount?.all_mail_count < 10) ? `0${allMailCount?.all_mail_count}`: allMailCount?.all_mail_count}</span>
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink to={"/MailBox"}>
            {/* New Mail <span class="count-span">{(allMailCount?.all_new_mail_count > 0 && allMailCount?.all_new_mail_count < 10) ? `0${allMailCount?.all_new_mail_count}`: allMailCount?.all_new_mail_count}</span> */}
            {contentDetails?.after_login?.NewMail} <span class="count-span">{(allMailCount?.all_new_mail_count > 0 && allMailCount?.all_new_mail_count < 10) ? `0${allMailCount?.all_new_mail_count}`: allMailCount?.all_new_mail_count}</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/EnvelopScan"}>
            {/* Envelop Scan <span class="count-span">{(allMailCount?.all_envelop_count > 0 && allMailCount?.all_envelop_count < 10) ? `0${allMailCount?.all_envelop_count}`: allMailCount?.all_envelop_count}</span> */}
            {contentDetails?.after_login?.EnvelopScan} <span class="count-span">{(allMailCount?.all_envelop_count > 0 && allMailCount?.all_envelop_count < 10) ? `0${allMailCount?.all_envelop_count}`: allMailCount?.all_envelop_count}</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/Scans"}>
            {/* Scan Documents <span className="count-span">{(allMailCount?.all_scan_document_count > 0 && allMailCount?.all_scan_document_count < 10) ? `0${allMailCount?.all_scan_document_count}` : allMailCount?.all_scan_document_count}</span> */}
            {contentDetails?.after_login?.ScanDocuments} <span className="count-span">{(allMailCount?.all_scan_document_count > 0 && allMailCount?.all_scan_document_count < 10) ? `0${allMailCount?.all_scan_document_count}` : allMailCount?.all_scan_document_count}</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/MyRequest"}>
            {/* My Request <span class="count-span">{(allMailCount?.all_request_count > 0 && allMailCount?.all_request_count < 10) ? `0${allMailCount?.all_request_count}`: allMailCount?.all_request_count}</span> */}
            {contentDetails?.after_login?.MyRequest} <span class="count-span">{(allMailCount?.all_request_count > 0 && allMailCount?.all_request_count < 10) ? `0${allMailCount?.all_request_count}`: allMailCount?.all_request_count}</span>
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={"/Trashed"}>
            {/* Trashed <span class="count-span"> {(allMailCount?.all_trash_count > 0 && allMailCount?.all_trash_count < 10) ? `0${allMailCount?.all_trash_count}`: allMailCount?.all_trash_count}</span> */}
            {contentDetails?.after_login?.Trashed} <span class="count-span"> {(allMailCount?.all_trash_count > 0 && allMailCount?.all_trash_count < 10) ? `0${allMailCount?.all_trash_count}`: allMailCount?.all_trash_count}</span>
          </NavLink>
        </Nav.Item>

        {/* <Nav.Item>
          <NavLink to={"/LetStarted"}>Go to Clevver Store</NavLink>
        </Nav.Item> */}

        <Nav.Item>
          {/* <NavLink to={"/InvoicesPayments"}>Invoices & Payments</NavLink> */}
          <NavLink to={"/InvoicesPayments"}>{contentDetails?.after_login?.InvoicesAndPayments}</NavLink>
        </Nav.Item>

        <Nav.Item>
          {/* <NavLink to={"/purchased-phone-number"}>Purchased Phone Number</NavLink> */}
          {/* <NavLink to={"/purchased-phone-number"}>Purchased Numbers</NavLink> */}
          <NavLink to={"/purchased-phone-number"}>{contentDetails?.after_login?.PurchasedNumbers}</NavLink>
        </Nav.Item>
        {/* <Nav.Item>
          <Link onClick={()=>Logout()}>Logout</Link>
        </Nav.Item> */}
      </Nav>
    </div>
  );
};

export default Sidebar;
